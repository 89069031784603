import * as apiCall from '../apiCall';

function getGeneralSettings(appId) {

    const options = {
        appId
    };

    let url = `/api/ebay/get_general_settings`;
    
    return apiCall.get(url, options);
}

export { getGeneralSettings };