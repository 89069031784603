import * as apiCall from '../apiCall';

function nextMarketplaceStep(appId) {

    const options = {
        appId
    };

    return apiCall.post(`/api/install/marketplace/next_step`, null, options);
}

export { nextMarketplaceStep };