import * as apiCall from '../apiCall';

function getCategoryRecommendations(appId, searchString) {

    const options = {
        appId
    };

    let url = `/api/trademe/get_category_recommendations?searchString=${searchString}`;

    return apiCall.get(url, options);
}

export { getCategoryRecommendations };