import * as apiCall from '../apiCall';

function getItemSpecifics(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/ebay/get_item_specifics`, options);
}

export { getItemSpecifics };