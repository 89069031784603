import * as apiCall from '../apiCall';

function getUserDetails(appId) {

    const options = {
        appId
    };

    let url = `/api/trademe/get_user_details`;  
    return apiCall.get(url, options);
}

export { getUserDetails };