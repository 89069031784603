import * as apiCall from '../apiCall';
import * as browserUtility from '../../utilities/browserUtility';

function logDebug(message) {

    const options = {
        handleErrorManually: true,
        isAnonymous: true
    };

    console.log(message);

    let browserInfo = browserUtility.getBrowserInfo();

    message = browserInfo + message;

    let model = {
        logLevel: "trace",
        message: message
    }

    try {
        return apiCall.post(`/api/log/log_message`, model, options);
    }
    catch(e) {
        console.log("error", e);
    }
    
}

export { logDebug };