import * as apiCall from '../apiCall';

function getCategories(appId, level, parentCategoryId = null) {

    const options = {
        appId
    };

    let url = `/api/trademe/get_categories?level=${level}`;

    if (parentCategoryId) {
        url += `&parentCategoryId=${parentCategoryId}`;
    }

    return apiCall.get(url, options);
}

export { getCategories };