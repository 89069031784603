import { configureStore } from '@reduxjs/toolkit'
import userReducer from './reducers/userReducer';
import widgetReducer from './reducers/widgetReducer';

export const store = configureStore({
    reducer: {
        user: userReducer,
        widgetStore: widgetReducer
    }
});

export default store;