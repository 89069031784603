import * as apiCall from '../apiCall';

function savePaymentDetails(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/trademe/save_payment_details`, model, options);
}

export { savePaymentDetails };