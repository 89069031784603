import * as apiCall from '../apiCall';

function getCategorySpecificsValueRecommendations(appId, categorySpecificsId) {

    const options = {
        appId
    };

    let url = `/api/ebay/get_category_specific_value_recommendations?categorySpecificsId=${categorySpecificsId}`;

    return apiCall.get(url, options);
}

export { getCategorySpecificsValueRecommendations };