import React from 'react';
import * as logger from '../services/log';

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            error: false
        };
    }

    componentDidCatch(error, errorInfo) {
        try {
            // Catch errors in any components below and re-render with error message
            this.setState({
                error: true
            });

            if (errorInfo && typeof errorInfo === "object") {
                errorInfo = JSON.stringify(errorInfo);
            }

            logger.logError(`Error fallback screen entered. Error Info: ${errorInfo}`, error);

            if (process.env.NODE_ENV !== "development") {
                window.location.href = "/unexpected-error";
            }    
        }
        catch (e) {
            logger.logError("Error in <ErrorFallback> screen", e);
        }
    }

    render() {
        if (this.state.error) {
            // Error path
            return null;
        }
        // Normally, just render children
        return this.props.children;
    }
}

export { ErrorBoundary };