import * as apiCall from '../apiCall';

function getFulfillmentPolicies(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/ebay/get_fulfillment_policies`, options);
}

export { getFulfillmentPolicies };