import React from 'react';
import { Grid, Card } from 'tabler-react';
import './InstallProgress.scss';
import "../../node_modules/progress-tracker/src/styles/progress-tracker.scss";

export class InstallProgress extends React.Component {

    render() {
        return (
            <div className="install-menu">

                <Card className="install-menu-inner">
                    <div className="card-inner">
                        <div className="app-header">
                            <div className="app-item-logo">
                                <img src={this.props.installStepHeader.appLogo} alt="App Logo" />
                            </div>
                            <div>
                                <h4 style={{ marginBottom: 0 }}>
                                    {this.props.installStepHeader.appName} Setup
                                </h4>
                                {
                                    this.props.installStepHeader.subheader ?
                                        <div>
                                            <small>{this.props.installStepHeader.subheader}</small>
                                        </div>
                                        : null
                                }
                            </div>


                        </div>

                        <ul className="progress-tracker progress-tracker--vertical">
                            {
                                this.props.installStepHeader.installSteps.map((m) => {
                                    return (
                                        <li key={m.id} className={`progress-step${m.completed ? " is-complete" : ""}${m.active ? " is-active" : ""}`}>
                                            <div className="progress-marker"></div>
                                            <div className="progress-text">
                                                <h4 className="progress-title">{m.name}</h4>
                                                {m.description}
                                            </div>
                                        </li>
                                    )
                                })
                            }
                        </ul>

                    </div>
                </Card>
            </div>
        )
    }
}