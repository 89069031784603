import * as apiCall from '../apiCall';

function updateInventoryLocation(appId, merchantLocationKey, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/update_inventory_location/${merchantLocationKey}`, model, options);
}

export { updateInventoryLocation };