import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import {
    Page,
    Grid,
    Card,
    Dimmer
} from "tabler-react";
import {
    RoundedLogo,
    CustomBreadcrumb,
    MainImages,
    Variants,
    ProductMetaData,
    ProductInformation
} from '../../components';
import {
    useSelector
} from "react-redux";
import SiteWrapper from '../SiteWrapper.react';
import * as marketplaceCtrl from '../../services/marketplace';
import * as appUtility from '../../utilities/appUtility';
import { ManualItemAsin } from './ManualItemAsin';
import "../ProductDetail.scss";

const ProductDetail = (props) => {

    const [vm, setVm] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);

    const app = user.info.apps.find(a => a.id === props.app);

    useEffect(() => {

        if (!props.productId) {
            return;
        }

        async function getData() {

            setLoading(true);

            let vm = await marketplaceCtrl.getProductDetail({
                app: props.app,
                productId: props.productId
            });

            setVm(vm);
            setLoading(false);
        }

        getData();

    }, [props.app, props.productId]);

    const product = vm && vm.product;
    const isAmazonApp = appUtility.isAmazonApp(props.app);

    const hideProductDetail = () => {
        setVm(null);
        props.hideProductDetail();
    }

    if (loading) {
        return <SiteWrapper>
            <Page.Content className="product-detail">
                <CustomBreadcrumb name="Products" customOnClick={() => hideProductDetail()} />
                <Grid.Row cards>
                    <Grid.Col xs={12}>
                        <Card loading>
                            <Card.Header>
                                <h4>{props.formTitle}</h4>
                            </Card.Header>
                            <Dimmer active loader>
                                <div style={{ height: 150 }}></div>
                            </Dimmer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    }

    else if (!loading && vm !== null && product !== null) {
        return (
            <SiteWrapper>
                <Page.Content className="product-detail">

                    <CustomBreadcrumb name="Products" customOnClick={() => hideProductDetail()} />

                    <div style={{ display: "flex" }}>
                        <RoundedLogo style={{ marginRight: 10 }} height={35} width={35} logoUrl={app.logoUrl} />
                        <h2>{product.name}</h2>
                    </div>

                    <Grid.Row cards>
                        <Grid.Col xs={12} sm={12} md={6} lg={7} xl={8}>
                            <MainImages images={product.images} />
                            <Variants isAmazonApp={isAmazonApp} product={product} app={props.app} />
                            {
                                isAmazonApp && (product.productVariations === null || product.productVariations === undefined || product.productVariations.length === 0) ?
                                <ManualItemAsin product={product} app={props.app}/>
                                : null
                            }

                        </Grid.Col>

                        <Grid.Col xs={12} sm={12} md={6} lg={5} xl={4} >
                            <ProductInformation product={product} />
                            <ProductMetaData product={product} />
                        </Grid.Col>

                    </Grid.Row>

                </Page.Content>
            </SiteWrapper>
        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state;
    return { user };
}

let wrapper = connect(mapStateToProps)(ProductDetail);
export { wrapper as ProductDetail };