import { connect } from 'react-redux';
import React from 'react';
import { Page, Grid, Card, Dropdown, Form, Button } from "tabler-react";
import SiteWrapper from '../../SiteWrapper.react';
import { ProductErrorModal } from '../../ProductErrorModal';
import * as tradeMeCtrl from '../../../services/trademe';
import { appType, productErrorMessageType, syncStatus, listingPriorityType } from '../../../constants';
import { AgGridReact } from "ag-grid-react";
import { CustomLoadingOverlay } from '../../CustomLoadingOverlay';
import { SetShippingMethodModal } from './SetShippingMethodModal';
import { SetListingPriorityModal } from './SetListingPriorityModal';
import { toast } from 'react-toastify';
import { CustomToast } from '../../../components';
import { syncStatusRenderer } from '../../CellRenderers/syncStatusRenderer';
import { listingPriorityRenderer } from '../../CellRenderers/listingPriorityRenderer';
import { customConfirmAlert } from '../../CustomConfirmAlert';
import * as mobileUtility from '../../../utilities/mobileUtility';
import { ProductDetail } from './ProductDetail';
import DontListPriorityOptions from './DontListPriorityOptions';
import * as fileUtility from '../../../utilities/fileUtility';
import { SettingsMenu } from '../../SettingsMenu';
import { NoProductsAlert } from '../../NoProductsAlert';
import './Products.scss';

const queryString = require('query-string');

const numberSort = (num1, num2) => {
  return num1 - num2;
};

class Products extends React.Component {

  constructor(props) {
    super(props);

    window.addEventListener("resize", this.handleGridSize);

    this.state = {
      productsVm: {},
      marketplaceUrl: "",
      loading: false,
      errorModalSettings: { show: false, category: productErrorMessageType.error, productTitle: null, errors: [] },
      setShippingMethodModalSettings: { show: false, products: [] },
      setListingPriorityModalSettings: { show: false, products: [] },
      gridHeight: 0,
      showProductDetail: false,
      productId: null
    }

    this.app = this.props.user.info.apps.find(a => a.id === appType.tradeMe);

    const productDetailRenderer = (params) => {
      let elem = document.createElement("div");

      elem.innerHTML = params.data.name;
      elem.classList.add("anchor");

      elem.addEventListener("click", (e) => {
        e.stopPropagation();
        this.showProductDetail(params.data.id);
      });

      return elem;

    }

    const tradeMeListingRenderer = (params) => {

      let elem = params.value ? document.createElement("a") : document.createElement("span");
      elem.classList.add("status-icon");

      if (params.value === "true" || params.value === true) {
        elem.classList.add("bg-success");
        elem.classList.add("bg-success-link");

        if (params.data && params.data.listingId) {
          elem.href = `${this.state.marketplaceUrl}/Browse/Listing.aspx?id=${params.data.listingId}`;
          elem.target = "_blank";
          elem.title = "Go to Trade Me listing";

          elem.addEventListener("click", (e) => {
            e.stopPropagation();
          });
        }

      }
      else {
        elem.classList.add("bg-danger");
        elem.title = "Not listed";
      }

      return elem;
    }

    this.columns = [
      {
        field: 'name',
        headerName: 'Name',
        tooltipField: "name",
        width: 500,
        sortable: true,
        cellRenderer: productDetailRenderer,
        filterParams: {
          cellRenderer: productDetailRenderer,
          suppressMiniFilter: true
        },
        filter: "agTextColumnFilter",
      },
      {
        field: "polySku",
        headerName: "Poly SKU",
        sortable: false,
        filterParams: {
          suppressMiniFilter: true
        }
      },
      {
        field: "sku",
        headerName: "Shop SKU",
        hide: true,
        sortable: false,
        filterParams: {
          suppressMiniFilter: true
        }
      },
      {
        field: "errorCount",
        headerName: "Errors",
        sortable: true,
        width: 125,
        filter: "agNumberColumnFilter",
        comparator: numberSort,
        valueGetter: function (params) {
          return params.data.errorCount
        },
        cellRenderer: (params) => {

          let eDiv = document.createElement("div");

          if (params.data.errorCount === 0) {
            eDiv.innerHTML = "0";
            return eDiv;
          }

          eDiv.innerHTML = params.data.errorCount;
          eDiv.classList.add("product-error");

          eDiv.addEventListener("click", (e) => {
            e.stopPropagation();
            params.colDef.cellRendererParams.showErrorModal(productErrorMessageType.error, params.data)
          });

          return eDiv;
        },
        cellRendererParams: {
          showErrorModal: this.showErrorModal
        }
      },
      {
        width: 125,
        field: 'stock',
        headerName: 'Stock',
        comparator: numberSort,
        sortable: true,
        filter: "agNumberColumnFilter"
      },
      {
        width: 165,
        field: 'tradeMe',
        headerName: 'Trade Me Listing',
        valueGetter: function (params) {
          return params.data.tradeMe
        },
        sortable: true,
        comparator: numberSort,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: tradeMeListingRenderer,
          suppressMiniFilter: true
        },
        cellRenderer: tradeMeListingRenderer
      },
      {
        width: 150,
        field: "syncStatus",
        tooltipField: "syncStatus",
        headerName: "Sync Status",
        sortable: true,
        comparator: numberSort,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: syncStatusRenderer,
          suppressMiniFilter: true
        },
        cellRenderer: syncStatusRenderer
      },
      {
        width: 120,
        field: 'listingPriorityId',
        headerName: 'Priority',
        valueGetter: function (params) {
          return params.data.listingPriorityId
        },
        sortable: true,
        comparator: numberSort,
        filter: "agSetColumnFilter",
        filterParams: {
          cellRenderer: listingPriorityRenderer,
          suppressMiniFilter: true
        },
        cellRenderer: listingPriorityRenderer
      },
      {
        width: 120,
        field: 'listingDurationDays',
        headerName: 'Age',
        comparator: numberSort,
        valueGetter: function (params) {
          return params.data.listingDurationDays.toFixed(2)
        },
        sortable: true,
        filter: "agSetColumnFilter",
        filterParams: {
          suppressMiniFilter: true
        }
      },
      {
        width: 120,
        field: "category",
        headerName: "Category",
        filter: "agSetColumnFilter",
        sortable: true,
        filterParams: {
          suppressMiniFilter: true
        }
      },
      {
        width: 120,
        field: "vendor",
        headerName: "Vendor",
        filter: "agSetColumnFilter",
        sortable: true,
        filterParams: {
          suppressMiniFilter: true
        }
      },
      {
        width: 120,
        field: "weight",
        headerName: "Weight",
        filter: "agSetColumnFilter",
        sortable: true,
        filterParams: {
          suppressMiniFilter: true
        }
      },
      {
        width: 180,
        field: 'shippingMethod',
        tooltipField: "shippingMethod",
        headerName: 'Shipping Method',
        filter: "agSetColumnFilter",
        sortable: true,
        filterParams: {
          suppressMiniFilter: true
        }
      }
    ];

    this.defaultColDef = {
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      sortable: true,
      filter: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        clearButton: true
      }
    };

    this.frameworkComponents = {
      customLoadingOverlay: CustomLoadingOverlay
    }
  }

  componentDidMount() {
    this.handleGridSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleGridSize);
  }

  handleGridSize = () => {

    // number of pixels to offset vertically
    let DesktopVerticalOffset = 320;

    // currently the navbar and footer are the same height accross all widths (e.g. desktop first design). 
    // This method will need modification if these components change.

    if (window.innerWidth < 768) {
      DesktopVerticalOffset -= 115; // footer
    }

    let gridHeight = window.innerHeight - DesktopVerticalOffset;
    this.setState({ gridHeight });
  }

  showSetShippingMethodModal = () => {

    let products = this.gridApi.getSelectedRows();

    if (!this.validateBulkAction(products)) {
      return;
    }

    this.setState({ setShippingMethodModalSettings: { show: true, products } });
  }

  showSetListingPriorityModal = () => {
    let products = this.gridApi.getSelectedRows();

    if (!this.validateBulkAction(products)) {
      return;
    }

    this.setState({ setListingPriorityModalSettings: { show: true, products } })
  }

  validateBulkAction = (products) => {

    if (products.length === 0) {
      toast.info(<CustomToast message={`No products have been selected.`} />, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    return true;
  }

  showErrorModal = (category, product) => {

    this.setState({
      errorModalSettings: {
        show: true,
        category,
        product,
        productTitle: product.name
      }
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.refreshProducts();
  };

  refreshProducts = async () => {
    this.gridApi.showLoadingOverlay();
    let productsVm = await tradeMeCtrl.getProducts(appType.tradeMe);
    this.gridApi.hideOverlay();


    const parsed = queryString.parse(window.location.search);

    if (parsed.filterByErrors === "true") {
      this.gridApi.setSortModel([
        {
          colId: "errorCount",
          sort: "desc"
        }
      ]);
    }

    if (parsed.filterByActive === "true") {
      this.gridApi.setSortModel([
        {
          colId: "category",
          sort: "desc",

        }
      ]);
    }

    this.setState({ productsVm, marketplaceUrl: productsVm.marketplaceUrl }, () => {
      if (parsed.filterByPendingSync === "true") {
        // Get a reference to the 'name' filter instance
        let filterInstance = this.gridApi.getFilterInstance('syncStatus');

        filterInstance.setModel({
          type: 'set',
          values: [syncStatus.syncPending.toString()]
        });

        this.gridApi.onFilterChanged();
      }

      if (parsed.filterByQueued === "true") {
        // Get a reference to the 'name' filter instance
        let filterInstance = this.gridApi.getFilterInstance('syncStatus');

        filterInstance.setModel({
          type: 'set',
          values: [syncStatus.queuedListing.toString()]
        });

        this.gridApi.onFilterChanged();
      }
    });
  }

  isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onQuickFilterChanged = (e) => {
    this.gridApi.setQuickFilter(e.target.value);
  }

  forceRetryProducts = async () => {

    let productIds = this.gridApi.getSelectedRows().map(p => p.id);

    if (!this.validateBulkAction(productIds)) {
      return;
    }

    const model = {
      productIds
    };

    await tradeMeCtrl.forceRetry(appType.tradeMe, model);

    toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

    this.refreshProducts();

  }

  updateProductPriority = async (products, listingPriorityId, unlistImmediately = false) => {

    let productIds = products.map(p => p.id);

    let model = {
      productIds,
      id: listingPriorityId,
      unlistImmediately
    };

    await tradeMeCtrl.saveListingPriorityOptions(appType.tradeMe, model);

    toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

    this.refreshProducts();
  }

  previewCustomRules = (productIds) => {
    let model = {
      productIds
    };

    customConfirmAlert(async () => {
      let response = await tradeMeCtrl.previewCustomRules(this.app.id, model);
      fileUtility.downloadFile(response.fileData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", response.fileName);
    }, <React.Fragment>Preview will be generated for {productIds.length} product{`${productIds.length > 1 || productIds.length === 0 ? "s" : ""}`}. An excel spreadsheet will be downloaded.</React.Fragment>, { header: "Confirm your action" });
  }

  updateShippingMethod = async (products, shippingMethodId) => {

    let productIds = products.map(p => p.id);

    let model = {
      productIds,
      id: shippingMethodId
    };

    await tradeMeCtrl.saveShippingMethodOptions(appType.tradeMe, model);

    toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

    this.refreshProducts();
  }

  getUpdateListingPriorityConfirmationMessage = (products, option) => {
    return <React.Fragment>You are about to update the priority to <strong>{option}</strong> for {products.length} product{products.length > 1 ? "s" : ""}.</React.Fragment>;
  }

  getContextMenuItems = (params) => {

    if (mobileUtility.mobileCheck()) {
      return [];
    }

    let products = this.gridApi.getSelectedRows();

    if (!products.length) {
      return [{
        disabled: true,
        name: "No products have been selected.",
      }];
    }

    var result = [
      {
        name: `${products.length} product${products.length > 1 ? "s" : ""} selected`,
        disabled: true
      },
      {
        name: "Shipping Method",
        subMenu: this.state.productsVm.shippingPolicyOptions.map(o => ({
          name: o.name,
          action: () => {
            customConfirmAlert(() => this.updateShippingMethod(products, o.id), <React.Fragment>You are about to update the shipping method to <strong>{o.name}</strong> for {products.length} product{products.length > 1 ? "s" : ""}.</React.Fragment>);
          }
        }))
      },
      {
        name: "Priority",
        subMenu: [
          {
            name: "Don't List",
            action: () => {

              let elem = (<DontListPriorityOptions products={products} />);

              customConfirmAlert(async () => {

                let unlistImmediately = false;
                var unlistImmediatelyElem = document.getElementsByName("unlistImmediately");

                if (unlistImmediatelyElem && unlistImmediatelyElem.length && unlistImmediatelyElem[0].checked) {
                  unlistImmediately = true;
                }

                this.updateProductPriority(products, listingPriorityType.DontList, unlistImmediately);
              }, elem);
            }

          },
          {
            name: "Low",
            action: () => {
              customConfirmAlert(() => this.updateProductPriority(products, listingPriorityType.Low), this.getUpdateListingPriorityConfirmationMessage(products, "Low"));
            }
          },
          {
            name: "Normal",
            action: () => {
              customConfirmAlert(() => this.updateProductPriority(products, listingPriorityType.Normal), this.getUpdateListingPriorityConfirmationMessage(products, "Normal"));
            }
          },
          {
            name: "High",
            action: () => {
              customConfirmAlert(() => this.updateProductPriority(products, listingPriorityType.High), this.getUpdateListingPriorityConfirmationMessage(products, "High"));
            }
          }
        ]
      },
      {
        name: "Preview Custom Rules",
        action: async () => {
          let productIds = products.map(p => p.id);
          this.previewCustomRules(productIds);
        }
      },
      {
        name: "Force Sync",
        action: async () => {

          let productIds = products.map(p => p.id);

          let model = {
            productIds
          }

          await tradeMeCtrl.forceRetry(appType.tradeMe, model);

          toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

          this.refreshProducts();
        }
      }
    ]

    return result;
  }

  hideProductDetail = () => {
    this.setState({ showProductDetail: false, productId: null });
  }

  showProductDetail = (productId) => {
    this.setState({ showProductDetail: true, productId })
  }

  render() {
    return (
      <div>

        <div style={{ display: this.state.showProductDetail ? "block" : "none" }}>
          <ProductDetail hideProductDetail={this.hideProductDetail} productId={this.state.productId} />
        </div>


        <div style={{ display: !this.state.showProductDetail ? "block" : "none" }}>
          <SiteWrapper key={this.props.match.path}>
            <Page.Content className="products">
              <Grid.Row cards>
                <div className="app-settings-wrapper">
                  <SettingsMenu app={appType.tradeMe} />

                  <div className="app-settings-content">
                    {Array.isArray(this.state.productsVm.products) && this.state.productsVm.products.length === 0 ? <NoProductsAlert /> : null}
                    <Card>
                      <div style={{ width: "100%", height: "100%", }}>
                        <div style={{ marginBottom: "5px" }}>
                          <div className="products-header">
                            <div className="search-bar">
                              <Form.Input className="search-input" type="text" onChange={this.onQuickFilterChanged} id="quickFilter" placeholder="Search..." />
                            </div>


                            <div className="action-menu">
                              <Dropdown
                                type="button"
                                className="action-dropdown"
                                toggle={false}
                                color="primary"
                                triggerContent="Actions"
                                itemsObject={[
                                  {
                                    value: "Shipping Method",
                                    onClick: () => this.showSetShippingMethodModal()
                                  },
                                  {
                                    value: "Priority",
                                    onClick: () => this.showSetListingPriorityModal()
                                  },
                                  {
                                    value: "Preview Custom Rules",
                                    onClick: () => {
                                      let productIds = this.gridApi.getSelectedRows().map(p => p.id);
                                      this.previewCustomRules(productIds);
                                    }
                                  },
                                  {
                                    value: "Force Sync",
                                    onClick: () => this.forceRetryProducts()
                                  }
                                ]}
                              />
                              <Button onClick={() => { this.refreshProducts() }} className="refresh-btn" color="primary">
                                <i className="fa fa-redo" />
                              </Button>
                            </div>

                          </div>
                        </div>

                        <div
                          id="productsGrid"
                          style={{
                            minHeight: 300,
                            height: this.state.gridHeight
                          }}
                          className="ag-theme-alpine">
                          <AgGridReact
                            onFirstDataRendered={() => {

                              const parsed = queryString.parse(window.location.search);

                              if (parsed.filterByCat !== undefined && parsed.filterByCat !== null && parsed.filterByCat.length > 0) {

                                var filterComponent = this.gridApi.getFilterInstance("category");

                                filterComponent.setModel({
                                  values: [parsed.filterByCat]
                                });

                                filterComponent.onFilterChanged();
                              }
                            }}
                            columnDefs={this.columns}
                            animateRows={false}
                            enableBrowserTooltips
                            enableCellTextSelection
                            defaultColDef={this.defaultColDef}
                            rowSelection="multiple"
                            onGridReady={this.onGridReady}
                            loadingOverlayComponent="customLoadingOverlay"
                            frameworkComponents={this.frameworkComponents}
                            rowData={(this.state.productsVm && this.state.productsVm.products) || []}
                            multiSortKey="ctrl"
                            getContextMenuItems={this.getContextMenuItems}
                            rowMultiSelectWithClick
                          />
                        </div>
                      </div>
                    </Card>

                  </div>
                </div>
              </Grid.Row>

              {
                this.state.errorModalSettings.show &&
                <ProductErrorModal refreshProducts={this.refreshProducts} app={appType.tradeMe} {...this.state.errorModalSettings} hideModal={() => this.setState({ errorModalSettings: { show: false, category: productErrorMessageType.error, productTitle: null } })} />
              }

              {
                this.state.setShippingMethodModalSettings.show &&
                <SetShippingMethodModal
                  shippingMethodOptions={this.state.productsVm.shippingPolicyOptions}
                  refreshProducts={this.refreshProducts}
                  {...this.state.setShippingMethodModalSettings}
                  hideModal={() => this.setState({ setShippingMethodModalSettings: { show: false, products: [] } })} />
              }

              {
                this.state.setListingPriorityModalSettings.show &&
                <SetListingPriorityModal refreshProducts={this.refreshProducts} {...this.state.setListingPriorityModalSettings} hideModal={() => this.setState({ setListingPriorityModalSettings: { show: false, products: [] } })} />
              }

            </Page.Content>
          </SiteWrapper>
        </div>
      </div>

    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
}

let wrapper = connect(mapStateToProps)(Products);
export { wrapper as Products };