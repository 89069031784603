import React, { useState } from 'react';
import Modal from 'react-modal';
import { 
    Icon, 
    Form, 
    Button 
} from 'tabler-react';
import { 
    useFormik 
} from 'formik';
import * as marketplaceCtrl from '../../services/marketplace';
import { 
    toast 
} from 'react-toastify';
import { 
    CustomToast, 
    CustomSelect 
} from '../../components';
import '../CustomModal.css';
import "./SetShippingPolicyModal.scss";

const SetShippingPolicyModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);
    const shippingPolicyOptions = props.shippingPolicyOptions.map(t => ({ label: t.name, value: t.id }));

    function closeModal() {
        props.hideModal();
    }

    const validate = values => {
        const errors = {};

        if (values.shippingPolicy === "") {
            errors.shippingPolicyError = "Required"
        }

        return errors;
    }

    const applyShippingPolicy = async () => {
        try {
            setFormSaving(true);

            let model = {
                productIds: props.products.map(p => p.id),
                id: parseInt(formik.values.shippingPolicy)
            };

            await marketplaceCtrl.saveShippingPolicyOptions(props.appId, model);

            toast.success(<CustomToast message={`Updated ${model.productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

            props.refreshProducts();
            closeModal();
        }
        finally {
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            shippingPolicy: ""
        },
        onSubmit: async (values) => {
            await applyShippingPolicy();
        },
        validate
    });

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>Shipping Policy</h2>
            <hr className="divider"></hr>

            <div className="set-shipping-policy-modal-content">

                <Form autocomplete="off" onSubmit={formik.handleSubmit}>
                    <Form.Group label="Shipping Policy">

                        <CustomSelect
                            value={shippingPolicyOptions ? shippingPolicyOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.shippingPolicy) : ""}
                            isDisabled={formSaving}
                            error={formik.errors.shippingPolicyError}
                            options={shippingPolicyOptions}
                            onChange={(option) => formik.setFieldValue("shippingPolicy", (option && option.value) || "")}
                        />
                    </Form.Group>

                    <div className="set-shipping-policy-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                        </div>
                    </div>
                </Form>

            </div>
        </Modal>
    )
}

export { SetShippingPolicyModal };