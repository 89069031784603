import React, { useState, useEffect } from 'react';
import { Grid, Card, Page, Form, Button } from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import * as ebayCtrl from '../../services/ebay';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components/CustomToast';
import { FormLoader } from '../FormLoader';
import { CustomLabel } from '../CustomLabel';
import * as logger from '../../services/log';
import { SaveToolbar } from '../SaveToolbar';
import "./Feedback.scss";

const MaxFeedbackMessages = 10;
const MinFeedbackMessages = 1;

const Feedback = (props) => {

  const [loading, setLoading] = useState(true);
  const [autoSendFeedback, setAutoSendFeedback] = useState(true);
  const [feedbackMessages, setFeedbackMessages] = useState([{ message: "", error: null }]);
  const [feedbackSaving, setFeedbackSaving] = useState(false);

  useEffect(() => {
    async function fetchData() {
      await instantiateForm();
    }
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.app]);

  const instantiateForm = async () => {
    setLoading(true);
    let feedbackSettings = await ebayCtrl.getFeedbackSettings(props.app);

    setAutoSendFeedback(feedbackSettings.autoPlaceFeedback);
    let transformedMessages = feedbackSettings.feedbackMessages.map(f => ({ message: f, error: null }));

    setFeedbackMessages(transformedMessages);
    setLoading(false);
  }

  const onUndoBtnClicked = async () => {
    try {
      setLoading(true);
      await instantiateForm();
    }
    catch (e) {
      logger.logError(`Ebay Feedback onUndoBtnClicked() error`, e);
      setLoading(false);
    }
  }

  function addFeedbackItem() {
    setFeedbackMessages(prevState => {
      return [...prevState, { message: "" }]
    });
  }

  function removeFeedbackItem(iteration) {

    if (feedbackSaving) {
      return;
    }

    if (feedbackMessages.length === MinFeedbackMessages) {
      return;
    }

    setFeedbackMessages(prevState => {

      let newFeedbackMessages = [...prevState];
      newFeedbackMessages.splice(iteration, 1);

      return newFeedbackMessages;
    });
  }

  function feedbackMessageChanged(iteration, message) {

    setFeedbackMessages(prevState => {
      let newFeedbackMessages = [...prevState];
      newFeedbackMessages[iteration].message = message;

      validateFeedbackElement(newFeedbackMessages[iteration]);

      return newFeedbackMessages;
    });
  }

  function autoSendFeedbackChanged() {
    setAutoSendFeedback(prevState => !prevState);
  }

  function validateFeedbackElement(elem) {

    const MAX_CHARACTERS = 80;

    if (elem.message === null || elem.message === undefined || elem.message.trim() === "") {
      elem.error = "Required.";
      return;
    }

    if (elem.message.length > MAX_CHARACTERS) {
      elem.error = `Maximimum length for feedback on eBay is ${MAX_CHARACTERS} characters.`;
      return;
    }

    elem.error = null;

  }

  async function saveFeedbackSettings() {

    let inError = false;
    feedbackMessages.forEach(f => {
      validateFeedbackElement(f);

      if (f.error) {
        let feedbackMsgsCopy = [...feedbackMessages];
        setFeedbackMessages(feedbackMsgsCopy);
        inError = true;
        return;
      }
    });

    if (inError) {
      return;
    }

    let model = {
      autoPlaceFeedback: autoSendFeedback,
      feedbackMessages: feedbackMessages.map(f => f.message)
    }

    setFeedbackSaving(true);
    await ebayCtrl.saveFeedbackSettings(props.app, model);
    setFeedbackSaving(false);
    toast.success(<CustomToast message={`Ebay Feedback settings updated.`} />, { position: toast.POSITION.TOP_RIGHT });
  }

  if (loading) {
    return (<FormLoader {...props} formTitle={"Feedback"} />)
  }

  return (
    <SiteWrapper>
      <Page.Content>
        <Grid.Row cards>
          <div className="app-settings-wrapper">
            <SettingsMenu app={props.app} />

            <div className="app-settings-content">
              <Card loading className="ebay-feedback">
                <Card.Header>

                  <h4>Feedback</h4>
                </Card.Header>
                <div className="card-inner">
                  <Form.Group>
                    <CustomLabel id="auto-feedback-tooltip" name="Automatically leave feedback" message="When enabled, PolyCommerce will automatically place feedback on your behalf. After marking your item as fulfilled on eBay, the system will randomly choose a pre-configured message." />
                    <Form.Radio
                      isInline
                      disabled={feedbackSaving}
                      label="Yes"
                      name="auto-feedback"
                      value="yes"
                      onChange={() => autoSendFeedbackChanged()}
                      checked={autoSendFeedback}
                    />
                    <Form.Radio
                      isInline
                      disabled={feedbackSaving}
                      label="No"
                      name="auto-feedback"
                      value="no"
                      onChange={() => autoSendFeedbackChanged()}
                      checked={!autoSendFeedback}
                    />
                  </Form.Group>

                  {
                    autoSendFeedback &&
                    <React.Fragment>
                      {feedbackMessages.map((f, i) => {
                        return (
                          <div className="feedback-msg" key={i}>
                            <Form.Group>
                              <Form.Input disabled={feedbackSaving} error={f.error} placeholder="Enter feedback message" onChange={(e) => feedbackMessageChanged(i, e.target.value)} value={f.message} />
                            </Form.Group>
                            <div className="remove-feedback-msg-btn">
                              <i onClick={() => removeFeedbackItem(i)} className="fas fa-trash"></i>
                            </div>

                          </div>
                        )
                      })}

                      <Button
                        color="primary"
                        icon="plus-circle"
                        disabled={feedbackMessages.length === MaxFeedbackMessages || feedbackSaving}
                        onClick={() => addFeedbackItem()}
                        className="add-feedback-msg-btn"
                        size="sm">Add feedback message</Button>
                    </React.Fragment>
                  }

                  <SaveToolbar show undoAction={() => onUndoBtnClicked()} saveAction={() => saveFeedbackSettings()} formSaving={feedbackSaving} />
                </div>
              </Card>
            </div>
          </div>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>

  );
}

export { Feedback };
