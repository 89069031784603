import React, { useState, useEffect } from 'react';
import * as ebayCtrl from '../../services/ebay';
import Modal from 'react-modal';
import { Icon, Loader } from 'tabler-react';
import '../CustomModal.css';
import "./ItemSpecificsValueRecommendationModal.scss";

const ItemSpecificsValueRecommendationModal = (props) => {

    const [recommendations, setRecommendations] = useState([]);
    const [loading, setLoading] = useState(true);

    function closeModal() {
        props.hideModal();
    }

    useEffect(() => {
        const fetchData = async () => {
            let recommendations = await ebayCtrl.getCategorySpecificsValueRecommendations(props.app, props.showValueRecommendationModalSettings.categorySpecificsId);
            setRecommendations(recommendations);
            setLoading(false);
        }

        fetchData();
    }, [props.app, props.showValueRecommendationModalSettings.categorySpecificsId]);

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />
            <h2 className="category-header" style={{ textAlign: "center" }}>Ebay Recommendations</h2>
            <div className="subtitle" style={{ textAlign: "center" }}>{props.showValueRecommendationModalSettings.categorySpecificsName}</div>
            <hr className="divider"></hr>

            <div className="ebay-recommendations">

                {
                    loading &&
                    <div className="recommendations-loader">
                        <Loader/>
                    </div>
                }

                {
                    !loading && recommendations.length === 0 &&
                    <div>
                        There are no recommendations to show.
                    </div>
                }

                {
                    !loading && recommendations.length > 0 &&
                    <React.Fragment>
                        <div className="recommendations-disclaimer">
                            The values below are recommendations and serve as a guideline. It's not always* strictly required to use a value from this list.
                        </div>

                        {
                            recommendations.map((r, i) => {
                                return (<div className="recommendation-value" key={i}>
                                    {r.value}
                                </div>);
                            })
                        }
                    </React.Fragment>
                }

            </div>

        </Modal>
    )
}

export { ItemSpecificsValueRecommendationModal };