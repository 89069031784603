import React, { useEffect, useState } from 'react';
import * as dashboardCtrl from '../../services/dashboard';
import {
    Widget,
    WidgetBody,
    WidgetHeader
} from './';
import {
    Link
} from 'react-router-dom';
import {
    alertCode
} from '../../constants';

const AlertsWidget = ({ userWidget }) => {

    const [loading, setLoading] = useState(true);
    const [vm, setVm] = useState({});

    useEffect(() => {

        refreshWidget();

        let widgetRefreshId = 'widgetRefresh';

        let widgetRefreshEvent = window.addEventListener(widgetRefreshId, (e) => {
            refreshWidget();
        });

        return () => {
            window.removeEventListener(widgetRefreshId, widgetRefreshEvent);
        }

    }, []);

    const refreshWidget = async () => {

        setLoading(true);

        let vm = await dashboardCtrl.getAlertsWidget();

        setVm(vm);
        setLoading(false);
    }


    const getAlertText = (alert) => {

        const app = vm.storeApps.find(a => a.id === alert.appId);

        switch (alert.code) {
            case alertCode.EbayCategoryMapping:
                let categoriesWithoutMapping = parseInt(alert.extraData);
                let preMessage = categoriesWithoutMapping === 1 ? <span>There is {categoriesWithoutMapping} category without a marketplace mapping.</span> : <span>There are {categoriesWithoutMapping} categories without marketplace mappings.</span>;
                return <span>{preMessage} Please update <Link to={`/marketplaces/${app.slug}/category-mappings`}>eBay Category Mappings</Link> for your store.</span>;

            case alertCode.InvalidEbayToken:
                return <span>Ebay authorisation failed. Please update your <Link to={`/marketplaces/${app.slug}/authorisation`}>Authorisation</Link> settings. Products can't be synced until a successful connection with Ebay is established.</span>;

            case alertCode.InvalidTradeMeToken:
                return <span>TradeMe authorisation failed. Please update your <Link to={`/marketplaces/${app.slug}/authorisation`}>Authorisation</Link> settings. Products can't be synced until a successful connection with TradeMe is established.</span>;

            case alertCode.TradeMeCategoryMapping:
                {
                    let categoriesWithoutMapping = parseInt(alert.extraData);
                    let preMessage = categoriesWithoutMapping === 1 ? <span>There is {categoriesWithoutMapping} category without a marketplace mapping.</span> : <span>There are {categoriesWithoutMapping} categories without marketplace mappings.</span>;
                    return <span>{preMessage} Please update <Link to={`/marketplaces/trademe/category-mappings`}>TradeMe Category Mappings</Link> for your store.</span>;
                }

            default:
                return <span>{alert.extraData}</span>
        }
    }

    let generalAlerts = vm !== null && Array.isArray(vm.storeAlerts) ? vm.storeAlerts.filter(a => a.appId === null || a.appId === undefined) : [];

    return (
        <Widget loading={loading} userWidget={userWidget}>
            <WidgetHeader userWidget={userWidget} useIcon refreshWidget={refreshWidget} />

            <WidgetBody loading={loading}>

                {
                    !loading ?
                        <div className="alerts">
                            {
                                Array.isArray(generalAlerts) && generalAlerts.length > 0 ?
                                    <div className="row-inner row-border">
                                        <div className="row-name">
                                            General
                                        </div>
                                        <div className="row-value">
                                            <ul>
                                                {
                                                    generalAlerts.map(aa => {
                                                        return (
                                                            <li key={aa.id}>
                                                                <div>
                                                                    {
                                                                        getAlertText(aa)
                                                                    }
                                                                </div>

                                                            </li>
                                                        )
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </div> : null
                            }
                            {
                                Array.isArray(generalAlerts) && generalAlerts.length === 0 ?
                                    <div className="row-inner row-border">
                                        <div className="row-name">
                                            General
                                        </div>
                                        <div className="row-value">
                                            <i className="fa fa-check alert-check"></i>&nbsp;&nbsp;None
                                        </div>
                                    </div> : null
                            }
                            {
                                vm.storeApps.length > 0 && vm.storeApps.filter(sa => !sa.isDisabled).map(sa => {

                                    let isOnVacation = vm.appsOnVacation.find(aov => aov === sa.id);

                                    if (isOnVacation) {
                                        return <div key={sa.id} className="row-inner row-border">
                                            <div className="row-name">
                                                {sa.altName}
                                            </div>
                                            <div className="row-value">
                                                <div><i className="fad fa-island-tropical"></i>&nbsp;&nbsp;Store is on vacation.</div>
                                            </div>
                                        </div>
                                    }

                                    let appAlerts = vm.storeAlerts.filter(a => a.appId === sa.id);

                                    return (
                                        <div key={sa.id} className="row-inner row-border">
                                            <div className="row-name">
                                                {sa.altName}
                                            </div>

                                            {
                                                appAlerts.length === 0 &&
                                                <div className="row-value">
                                                    <i className="fa fa-check alert-check"></i>&nbsp;&nbsp;None
                                                </div>
                                            }

                                            {
                                                appAlerts.length > 0 &&
                                                <div className="row-value">
                                                    <ul>
                                                        {
                                                            appAlerts.map(aa => {
                                                                return (
                                                                    <li key={aa.id}>
                                                                        <div>
                                                                            {
                                                                                getAlertText(aa)
                                                                            }
                                                                        </div>

                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ul>
                                                </div>
                                            }

                                        </div>)
                                })
                            }
                        </div> : null
                }
            </WidgetBody>
        </Widget>);

}

export { AlertsWidget };