import React, { useRef, useEffect, useState } from "react";
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';
import './ProgressBar.scss';


const ProgressBar = ({ percent, status }) => {
    const [value, setValue] = useState(0);

    const targetRef = useRef();
    const { width } = useResizeDetector({ targetRef });

    useEffect(() => {
        setWidth();
    });

    const setWidth = () => {

        if (width !== undefined && width !== null) {
            let value = percent * width;
            setValue(value);
        }
    }

    return (
        <div ref={targetRef} className="progressComp" id="test">
            <h1 className="percent-number">{status}</h1>
            <div className="progress-div" style={{ width: "100%" }}>
                <div style={{ width: value }} className="progress" />
            </div>
        </div>

    );
};

export { ProgressBar };