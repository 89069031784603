import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    Page,
    Form,
    Button,
    Icon,
    Dimmer
} from 'tabler-react';
import {
    InstallProgress
} from '../InstallProgress';
import {
    CustomToast
} from '../../components';
import {
    saveUserInfo
} from '../../actions/user';
import { useDispatch } from 'react-redux';
import SiteWrapper from '../SiteWrapper.react';
import * as installCtrl from '../../services/install';
import * as catchCtrl from '../../services/catch';
import * as accountCtrl from '../../services/account';
import * as logger from '../../services/log';
import history from '../../history';
import { useFormik } from 'formik';
import {
    toast
} from 'react-toastify';
import "./Install.scss";
import { appType } from '../../constants';
import { useSelector } from "react-redux";
import {
    LoadingPage
} from '../LoadingPage';

const Install = (props) => {

    const userInfo = useSelector(state => state.user.info);

    const [loading, setLoading] = useState(true);
    const [installStepHeader, setInstallStepHeader] = useState(null);
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    const dispatch = useDispatch();

    useEffect(() => {

        const fetchData = async () => {

            let userDetails = await catchCtrl.getAuthorisationDetail(props.app);
            let steps = await installCtrl.getMarketplaceSteps(props.app);

            setUserDetails(userDetails);
            setInstallStepHeader(steps);

            formik.resetForm({
                values: {
                    apiKey: userDetails.apiKey
                }
            });

            setLoading(false);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const validate = values => {
        const errors = {};

        setFormSubmitted(true);

        if (values.apiKey === null
            || values.apiKey === undefined
            || values.apiKey.trim() === "") {
            errors.apiKeyError = "Required";
        }

        return errors;
    }

    const formik = useFormik({
        validateOnBlur: formSubmitted,
        validateOnChange: formSubmitted,
        initialValues: {
            apiKey: ""
        },
        onSubmit: async (values) => {
            await authoriseAccount();
        },
        validate
    });

    const authoriseAccount = async () => {
        if (authorizeLoading) {
            return;
        }

        try {
            setAuthorizeLoading(true);

            let model = {
                apiKey: formik.values.apiKey
            };

            await catchCtrl.authoriseAccount(appType.catch, model);
            let userDetails = await catchCtrl.getAuthorisationDetail(props.app);
            setUserDetails(userDetails);

            formik.resetForm({
                values: {
                    apiKey: userDetails.apiKey
                }
            });

            if (userDetails.connectionValid) {
                await loadNextStep();
            }

            setAuthorizeLoading(false);
        }
        catch (e) {
            setAuthorizeLoading(false);
            logger.logError("Could not authorise Catch account.", e);
        }
    }

    const loadNextStep = async () => {

        setLoading(true);

        try {
            let nextStopRes = await installCtrl.nextMarketplaceStep(props.app);

            if (nextStopRes.installComplete) {
                toast.success(<CustomToast message={`${installStepHeader.appName} installation successful. If you have any questions, please contact us.`} />, { position: toast.POSITION.TOP_RIGHT });

                let userInfo = await accountCtrl.getUserData();
                dispatch(saveUserInfo(userInfo));

                let app = userInfo.apps.find(a => a.id === props.app);

                if (app) {
                    history.push(`/marketplaces/${app.slug}/products`);
                }

                return;
            }

            let steps = await installCtrl.getMarketplaceSteps(props.app);

            setInstallStepHeader(steps);
        }
        finally {
            setLoading(false);
        }
    }

    const renderComponent = () => {
        const selectedInstallStep = installStepHeader && installStepHeader.installSteps.find(x => x.active);

        if (selectedInstallStep) {
            switch (selectedInstallStep.id) {
                case "authorisation":
                    return authorisationComponent();
                default: throw new Error(`Unhandled install step ${selectedInstallStep.id}`);
            }
        }
    }

    const authorisationComponent = () => {
        return (<div className="install-app-content">
            <Card className="catch-install">
                <Card.Header>
                    <h4>Authorisation</h4>
                </Card.Header>
                <div className="card-inner">
                    <div>
                        <Grid.Row>
                            <Grid.Col width={12}>
                                Authorise your account with Catch to get started.
                            </Grid.Col>
                        </Grid.Row>

                        {
                            userDetails.apiKey && !authorizeLoading ? <div style={{ marginTop: 20 }}>
                                Status: <span style={{ color: userDetails.connectionValid ? "green" : "red", fontWeight: userDetails.connectionValid ? "bold" : "normal" }}>{userDetails.catchTokenStatus}</span>
                            </div> : null
                        }

                        <Grid.Row>
                            <Grid.Col width={12}>
                                <Form autocomplete="off" onSubmit={formik.handleSubmit} className="install-catch-form">

                                    <div className="api-key-wrapper">
                                        <div style={{ width: "100%" }}>
                                            <Form.Group label="API Key">
                                                <Form.Input
                                                    type="text"
                                                    disabled={(authorizeLoading)}
                                                    error={formik.errors.apiKeyError}
                                                    value={formik.values.apiKey || ""}
                                                    autocomplete="off"
                                                    name="apiKey"
                                                    onChange={formik.handleChange} />

                                            </Form.Group>
                                        </div>
                                    </div>

                                </Form>
                            </Grid.Col>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Col width={12}>
                                <Form.Footer className="install-footer">
                                    <Button size="lg" type="button" color="primary" onClick={formik.handleSubmit} disabled={(authorizeLoading)} loading={authorizeLoading}><Icon prefix="fa" name="plug" />&nbsp;&nbsp;Connect</Button>
                                </Form.Footer>
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                </div>
            </Card>
        </div>);
    }

    if (loading && !userInfo.firstAppAdded && userInfo.appId) {
        return (<LoadingPage/>);
    }

    if (loading) {
        return (<SiteWrapper installWizard>
            <Page.Content>
                <Grid.Row cards>

                    <div className="install-app-wrapper">

                        {
                            installStepHeader && <InstallProgress installStepHeader={installStepHeader} />
                        }

                        <div className="install-app-content">
                            <Card loading>
                                <Card.Header>
                                    <h4>Authorisation</h4>
                                </Card.Header>
                                <Dimmer active loader>
                                    <div style={{ height: 150 }}></div>
                                </Dimmer>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    }

    return <SiteWrapper installWizard>
        <Page.Content>
            {
                !userInfo.firstAppAdded && userInfo.appId ? null : <div onClick={() => history.goBack()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>
            }
            <Grid.Row cards>

                <div className="install-app-wrapper">

                    {
                        installStepHeader && <InstallProgress installStepHeader={installStepHeader} />
                    }

                    {
                        renderComponent()
                    }

                </div>

            </Grid.Row>
        </Page.Content>
    </SiteWrapper>
}

export { Install };