import * as apiCall from '../apiCall';

function saveReturnPolicy(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/save_return_policy`, model, options);
}

export { saveReturnPolicy };