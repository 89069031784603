import * as apiCall from '../apiCall';

function nextEbayStep(appId) {

    const options = {
        appId
    };

    return apiCall.post(`/api/install/ebay/next_step`, null, options);
}

export { nextEbayStep };