import * as apiCall from '../apiCall';

function forceRetry(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/force_retry`, model, options);
}

export { forceRetry };