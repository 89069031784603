import * as apiCall from '../apiCall';

function updateWidgetPosition(model) {

    let options = {
        handleErrorManually: true
    };

    return apiCall.post(`/api/dashboard/update_widget_position`, model, options);
}

export { updateWidgetPosition };