import * as apiCall from '../apiCall';

function unlistProduct(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/trademe/unlist_product`, model, options);
}

export { unlistProduct };