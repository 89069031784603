import React, { useEffect, useState } from 'react';
import { Grid, Card, Page, Button } from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import "./FeedHistory.scss";
import { AgGridReact } from "ag-grid-react";
import { CustomLoadingOverlay } from '../CustomLoadingOverlay';
import * as amazonCtrl from '../../services/amazon';
import * as fileUtility from '../../utilities/fileUtility';
import moment from 'moment-timezone';
import { v4 as uuidv4 } from 'uuid';

const PAGE_NAME = "Feed History";

let gridApi;

function serverSideDatasource(app) {
    return {
        getRows: async function (params) {
            var tz = moment.tz.guess();
            let encodedTz = encodeURIComponent(tz);

            let vm = await amazonCtrl.getFeedHistory(app, encodedTz, params.request);

            let count = vm.feeds.length > 0 ? vm.feeds[0].totalRows : 0;

            params.successCallback(vm.feeds, count);
        },
    };
}

const FeedHistory = (props) => {

    const [key, setGridKey] = useState(uuidv4());

    const resizeWindow = (e) => {
        gridApi && gridApi.sizeColumnsToFit();
    }

    const refreshFeeds = async () => {
        setGridKey(uuidv4());
    }

    useEffect(() => {

        window.addEventListener("resize", resizeWindow);

        return () => {
            window.removeEventListener("resize", resizeWindow);
        }
    }, [props.app]);

    const columns = [
        {
            field: 'feedId',
            headerName: 'Feed ID',
            tooltipField: "feedId",
            resizable: true
        },
        {
            field: "feedProcessingStatus",
            headerName: "Status",
            tooltipField: "feedProcessingStatus",
            resizable: true
        },
        {
            field: "feedType",
            headerName: "Type",
            tooltipField: "feedType",
            resizable: true
        },
        {
            field: "formattedSubmittedDate",
            headerName: "Submitted",
            tooltipField: "formattedSubmittedDate",
            resizable: true
        },
        {
            field: "inputFeedPath",
            headerName: "Input File",
            tooltipField: "inputFeedPath",
            resizable: true,
            cellRenderer: (params) => {
                let data = params.data;

                if (!data) {
                    return;
                }

                let link = document.createElement("span");

                let pathDisplay = data.inputFeedPath;

                if (!data.inputFeedPath && data.outputFeedPath) {
                    pathDisplay = "N/A";
                }
                else {
                    link.classList.add("anchor");
                }

                link.textContent = pathDisplay;

                if (pathDisplay !== "N/A") {
                    link.onclick = async () => {
                        let response = await amazonCtrl.downloadFeed(props.app, data.id, "input");
                        fileUtility.downloadFile(response.fileData, "text/plain;base64,", response.fileName);
                    };
                }

                return link;
            }
        },
        {
            field: "outputFeedPath",
            headerName: "Output File",
            tooltipField: "outputFeedPath",
            resizable: true,
            cellRenderer: (params) => {

                let data = params.data;

                if (!data) {
                    return;
                }

                if (!data.outputFeedPath || data.outputFeedPath.length === 0) {
                    let link = document.createElement("span");
                    return link;
                }

                let link = document.createElement("span");
                link.classList.add("anchor");
                link.textContent = data.outputFeedPath;

                link.onclick = async () => {
                    let response = await amazonCtrl.downloadFeed(props.app, data.id, "output");
                    fileUtility.downloadFile(response.fileData, "text/plain;base64,", response.fileName);
                };

                return link;
            }
        }
    ];

    const defaultColDef = {
        resizable: false,
        sortable: true,
        filter: false
    };

    const frameworkComponents = {
        customLoadingOverlay: CustomLoadingOverlay
    }

    const onGridReady = (params) => {
        gridApi = params.api;

        gridApi && gridApi.sizeColumnsToFit();
    };

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="feed-history">
                                <Card.Header>
                                    <h4>{PAGE_NAME}</h4>
                                    <div className="refresh-feeds-btn">
                                        <Button onClick={() => refreshFeeds()} className="refresh-btn" color="primary">
                                            <i className="fa fa-redo" />
                                        </Button>
                                        <div className="clear-fix"></div>
                                    </div>
                                </Card.Header>
                                <div className="card-inner">
                                    <div
                                        id="amazon-templates-grid"
                                        style={{
                                            minHeight: 300,
                                            height: 500
                                        }}
                                        className="ag-theme-alpine">
                                        <AgGridReact
                                            gridOptions={{
                                                localeText: { noRowsToShow: 'No feeds to show.' }
                                            }
                                            }
                                            key={key}
                                            columnDefs={columns}
                                            pagination={true}
                                            paginationPageSize={100}
                                            rowModelType="serverSide"
                                            serverSideDatasource={new serverSideDatasource(props.app)}
                                            cacheBlockSize={100}
                                            animateRows={false}
                                            enableBrowserTooltips
                                            noRow
                                            enableCellTextSelection
                                            defaultColDef={defaultColDef}
                                            suppressRowClickSelection={true}
                                            onGridReady={onGridReady}
                                            loadingOverlayComponent="customLoadingOverlay"
                                            frameworkComponents={frameworkComponents}
                                            multiSortKey="ctrl"
                                        />
                                    </div>



                                </div>
                            </Card>
                        </div>
                    </div>

                </Grid.Row>

            </Page.Content>
        </SiteWrapper>

    );
}

export { FeedHistory };