import React, { useState, useEffect } from 'react';
import * as marketplaceCtrl from '../../services/marketplace';
import Modal from 'react-modal';
import {
    Icon,
    Form,
    Button
} from 'tabler-react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
    CustomToast,
    CustomSelect
} from '..';
import * as logger from '../../services/log';
import * as templateCtrl from '../../services/template';
import '../CustomModal.css';
import "./SelectMarketplaceTemplateModal.scss";

const SelectMarketplaceTemplateModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);
    const [templateTypes, setTemplateTypes] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let templateTypes = await templateCtrl.getTemplateTypes(props.app);

            console.log("Template types", templateTypes);
            setTemplateTypes(templateTypes);
        }

        fetchData();
    }, []);

    function closeModal() {
        props.hideModal();
    }

    const validate = values => {
        const errors = {};

        if (values.name.trim() === "") {
            errors.nameError = "Required";
        }

        if (values.templateId === null || values.templateId === "") {
            errors.templateIdError = "Required";
        }

        return errors;
    }

    const addTemplate = async () => {
        try {
            setFormSaving(true);

            let model = {
                name: formik.values.name,
                templateId: formik.values.templateId
            };

            await marketplaceCtrl.createTemplate(props.app, model);

            await props.refreshTemplates();

            toast.success(<CustomToast message={`Template created.`} />, { position: toast.POSITION.TOP_RIGHT });
            setFormSaving(false);
            closeModal();

        }
        catch (e) {
            logger.logError(`AmazonTemplateModal addTemplate() error`, e);
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            name: "",
            templateId: null
        },
        onSubmit: async () => {
            await addTemplate();
        },
        validate
    });

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            onAfterClose={() => {
                if (props.gridApi) {
                    setTimeout(() => {
                        props.gridApi.sizeColumnsToFit();
                    }, 120);
                }
            }}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />
            <h2 className="category-header" style={{ textAlign: "center" }}>Add Template</h2>
            <hr className="divider"></hr>

            <div className="select-marketplace-template">


                <Form autocomplete="off" onSubmit={formik.handleSubmit}>

                    <Form.Group label="Name">
                        <Form.Input
                            name="name"
                            type="text"
                            error={formik.touched.name && formik.errors.nameError}
                            autocomplete="off"
                            disabled={formSaving}
                            value={formik.values.name || ""}
                            onChange={formik.handleChange}
                        />
                    </Form.Group>

                    <Form.Group label="Type">
                        <CustomSelect
                            value={templateTypes ? templateTypes.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.templateId) : ""}
                            isDisabled={formSaving}
                            error={formik.touched.templateId && formik.errors.templateIdError}
                            options={templateTypes}
                            onChange={(option) => formik.setFieldValue("templateId", (option && option.value) || "")}
                        />
                    </Form.Group>

                    <div className="select-marketplace-template-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Create</Button>
                        </div>
                    </div>
                </Form>


            </div>

        </Modal>
    )
}

export { SelectMarketplaceTemplateModal };