import React, { useRef, useState } from "react";
import CreatableSelect from "react-select/creatable";
import { components } from "react-select";
import "./CustomCreatableMulti.scss";

const CustomCreatableMulti = ({ handleChange, options, values, error, isDisabled=false, hideSelectedOptions=true, menuPlacement="auto", height=38 }) => {

    // if string contains this value, it will be formatted. React Select is using value as key so each value needs a unique value. This is to get around duplicate values.
    const internalSpaceIdentifier = "ws-9b5d-";
    const ref = useRef(null);
    const [uniqueKey, setUniqueKey] = useState(Date.now);

    const onChange = (newValue) => {
        handleChange(newValue);
    };

    const handleKeyDown = (e) => {
        if (e.keyCode === 32 && ref.current.state.inputValue === "") {
            ref.current.onInputChange(ref.current.state.inputValue + " ", "set-value");
            e.preventDefault();
        }

        if (e.keyCode === 13) {
            e.preventDefault();

            let valuesCpy = [...(values || [])] || [];

            setUniqueKey((uniqueKey) => {
                return ++uniqueKey;
            });

            valuesCpy.push({
                value: ref.current.state.inputValue + internalSpaceIdentifier + uniqueKey,
                label: internalSpaceIdentifier + uniqueKey
            });

            handleChange(valuesCpy);

            ref.current.onInputChange("");
        }
    };

    return (
        <React.Fragment>
            <CreatableSelect
                isDisabled={isDisabled}
                hideSelectedOptions={hideSelectedOptions}
                placeholder="Select and/or add custom value(s)..."
                styles={{
                    control: (base, state) => ({
                        ...base,
                        boxShadow: state.isFocused ? "inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(25, 145, 235, 0.5)" : "none",
                        borderColor: state.isFocused ? "#1991eb" : base.borderColor,
                        border: error ? "1px solid #cd201f" : !state.isFocused ? "1px solid rgba(0, 40, 100, 0.12)" : base.border,
                        outline: state.isFocused ? "0" : base.outline,
                        minHeight: height,
                        borderRadius: "3px",
                        '&:hover': {
                            border: error ? "1px solid #cd201f" : !state.isFocused ? "1px solid rgba(0, 40, 100, 0.12)" : base.border
                        }
                        // You can also use state.isFocused to conditionally style based on the focus state
                    }),
                    singleValue: (styles) => ({
                        ...styles,
                        color: "#495057"
                    }),
                    clearIndicator: (styles) => ({
                        ...styles,
                        padding: '2px 8px',
                    }),
                    dropdownIndicator: (styles) => ({
                        ...styles,
                        padding: '2px 8px',
                    }),
                    loadingIndicator: (styles) => ({
                        ...styles,
                        padding: '2px 8px',
                    }),
                    menu: (styles) => ({
                        ...styles,
                        zIndex: 9999, // Without this menu will be overlapped by other fields
                    }),
                    menuPortal: base => ({
                        ...base,
                        zIndex: 9999
                    })
                }}
                menuPlacement={menuPlacement}
                menuPortalTarget={document.body}
                isMulti
                isValidNewOption={() => true}
                value={values}
                labe
                components={{
                    MultiValueLabel: ({ children, ...innerProps }) => {

                        var regex = new RegExp(internalSpaceIdentifier + ".*", "g");
                        var match = regex.exec(innerProps.data.value);
                        let val = innerProps.data.value;
                        if (match) {
                            val = val.substring(0, match.index);
                        }

                        return (
                            <components.MultiValueLabel {...innerProps}>

                                <pre className="createable-pre-value" title={`'${val}'`}>
                                    {val}
                                </pre>
                            </components.MultiValueLabel>
                        );
                    },
                    Option: ({ children, ...innerProps }) => {
                        if (innerProps.label !== "") {
                            return (
                                <components.Option {...innerProps}>
                                    <pre className="createable-pre-option">
                                        {innerProps.data.value}
                                    </pre>
                                </components.Option>
                            );
                        }

                        return null;

                    }
                }}
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                formatCreateLabel={(v) => `${v}`}
                openMenuOnClick
                onKeyDown={(e) => handleKeyDown(e)}
                onChange={onChange}
                options={options}
                ref={ref}
            />
            <span style={{ display: error ? "block" : "none" }} className="invalid-feedback">{error}</span>
        </React.Fragment>

    );

}

export { CustomCreatableMulti };