import * as apiCall from '../apiCall';

function getCountries(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/ebay/get_countries`, options);
}

export { getCountries };