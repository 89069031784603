import * as apiCall from '../apiCall';

function getMappings(appId, templateMappingDefinitionId) {

    let options = {
        appId
    }

    return apiCall.get(`/api/template/get_mappings/${templateMappingDefinitionId}`, options);
}

export { getMappings };