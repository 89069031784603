import React, { useRef } from 'react';
import SiteWrapper from './SiteWrapper.react';
import {
    Grid,
    Card,
    Page
} from 'tabler-react';
import {
    SettingsMenu
} from './SettingsMenu';
import {
    useSelector
} from "react-redux";
import * as appUtility from '../utilities/appUtility';
import "./Instructions.scss";

const Instructions = (props) => {

    const apps = useSelector(state => state.user.info.apps);
    const shoppingFeedApp = useRef(apps.find(a => a.slug === props.match?.params?.shoppingFeedSlug));

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} shoppingFeedApp={shoppingFeedApp.current} />

                        <div className="app-settings-content">
                            <Card className="shopping-feed-instructions">
                                <Card.Header>
                                    <h4>Instructions</h4>
                                </Card.Header>

                                {
                                    appUtility.getFeedInstructions(shoppingFeedApp.current)
                                }
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>

    );
}

export { Instructions };