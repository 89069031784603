import * as apiCall from '../apiCall';

function getProductErrors(appId, productId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/trademe/get_product_errors/${productId}`, options);
}

export { getProductErrors };