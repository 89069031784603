import React from 'react';
import { 
    RoundedLogo 
} from '../../components';
import { 
    Card 
} from "tabler-react";
import history from '../../history';
import { 
    useSelector 
} from "react-redux";
import { 
    appCategory 
} from '../../constants';

const WidgetHeader = ({ userWidget, useIcon, refreshWidget }) => {

    const user = useSelector(state => state.user);

    const handleSettingsClick = () => {
        if (user.info && Array.isArray(user.info.apps)) {
            let app = user.info.apps.find(a => a.id === userWidget.widget.appId);

            if (app) {

                if (app.slug && app.initialRoute) {
                    if (app.appCategoryId === appCategory.marketplace) {
                        history.push(`/marketplaces/${app.slug}/${app.initialRoute ?? "authorisation"}`);
                    }
                    else if (app.appCategoryId === appCategory.shoppingFeed) {
                        history.push(`/shopping-feeds/${app.slug}/${app.initialRoute ?? "templates"}`);
                    }
                    else {
                        throw new Error(`appCategory ${app.appCategoryId} not recognised.`);
                    }
                }
            }
            else {
                throw new Error("app could not be found.");
            }
        }
    }

    return (<div className={`${!userWidget.widget.isLocked ? "draggable-heading" : ""}`}>
        <Card.Header>
            <div className="card-header-content">
                <div className="card-header-inner">
                    {!useIcon && <RoundedLogo height={35} width={35} logoUrl={userWidget.widget.imageUrl} />}
                    {useIcon && <div className="icon-wrapper"><i className={userWidget.widget.iconClass} /></div>}
                    <h4>{userWidget.widget.displayName}</h4>
                </div>
                {
                    userWidget.widget.appId ?
                    <i title="Settings" onMouseDown={(e) => e.preventDefault()} onClick={handleSettingsClick} className="fa fa-cog widget-settings" />
                    : null
                }
                {
                    refreshWidget ? <i title="Refresh" onMouseDown={(e) => e.preventDefault()} onClick={() => refreshWidget()} className="fa fa-sync widget-refresh" />
                    : null
                }
            </div>
        </Card.Header>
    </div>);
}

export { WidgetHeader };