import React from 'react';
import { Loader } from "tabler-react";

class CustomLoadingOverlay extends React.Component {
    render() {
        return (
            <Loader />
        );
    }
}

export { CustomLoadingOverlay };