import * as apiCall from '../apiCall';

function login(model) {

    if (apiCall.isMockMode()) {

        let loginResult = {
            authToken: "MOCK_MODE_TOKEN"
        };

        return apiCall.simulateDelay(50, () => loginResult);
    }

    let options = {
        isAnonymous: true
    };

    return apiCall.post("/api/account/login", model, options);
}

export { login };