import React, { useEffect, useState, useRef } from 'react';
import { Grid, Card, Page, Button } from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { FormLoader } from '../FormLoader';
import { AgGridReact } from "ag-grid-react";
import { CustomToast } from '../../components';
import { CustomLoadingOverlay } from '../CustomLoadingOverlay';
import { SelectMarketplaceTemplateModal } from './SelectMarketplaceTemplateModal';
import * as marketplaceCtrl from '../../services/marketplace';
import { FieldMappingsModal } from '../FieldMappingsModal';
import history from '../../history';
import { customConfirmAlert } from '../CustomConfirmAlert';
import { toast } from 'react-toastify';
import * as logger from '../../services/log';
import {
    useSelector
} from "react-redux";
import "./Templates.scss";

const PAGE_NAME = "Templates";

const Templates = (props) => {

    const [loading, setLoading] = useState(true);
    const [listingLoaderTemplates, setListingLoaderTemplates] = useState([]);
    const [showSelectMarketplaceTemplateModalSettings, setShowSelectMarketplaceTemplateModalSettings] = useState({ show: false });
    const [fieldMappingsModalSettings, setFieldMappingsModalSettings] = useState({ show: false });
    const gridApi = useRef(null);
    const user = useSelector(state => state.user);
    const app = user.info.apps.find(a => a.id === props.app);

    const resizeWindow = (e) => {
        gridApi.current && gridApi.current.sizeColumnsToFit();
    }

    useEffect(() => {

        const fetchData = async () => {

            try {
                let templates = await marketplaceCtrl.getTemplates(props.app);
                setListingLoaderTemplates(templates);

                setLoading(false);
            }
            catch (e) {
                logger.logError(`AmazonListingLoader useEffect() error`, e);
            }
        }

        window.addEventListener("resize", resizeWindow);

        fetchData();

        return () => {
            window.removeEventListener("resize", resizeWindow);
        }
    }, [props.app]);

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            tooltipField: "name",
            width: 200,
            resizable: true
        },
        {
            field: "templateName",
            headerName: "Template",
            tooltipField: "templateName",
            width: 250,
            resizable: true
        },
        {
            field: "manage",
            headerName: "",
            sortable: false,
            headerClass: "manage-header",
            width: 200,
            cellClass: "manage-cell",
            cellRenderer: (params) => {
                let outerDiv = document.createElement("div");
                outerDiv.classList.add("manage-btns");

                let deleteBtn = document.createElement("i");
                deleteBtn.classList.add("fa");
                deleteBtn.classList.add("fa-minus-circle");
                deleteBtn.classList.add("template-icon");
                deleteBtn.classList.add("template-icon-danger");
                deleteBtn.classList.add("delete-template-btn");

                deleteBtn.onclick = () => {
                    let data = params.data;

                    customConfirmAlert(async () => {
                        try {
                            setLoading(true);
                            await marketplaceCtrl.deleteTemplate(props.app, data.templateMappingDefinitionId);

                            let templates = await marketplaceCtrl.getTemplates(props.app);
                            setListingLoaderTemplates(templates);

                            setLoading(false);

                            toast.success(<CustomToast message={`Template '${data.name}' has been deleted.`} />, { position: toast.POSITION.TOP_RIGHT });
                        }
                        catch (e) {
                            logger.logError(`AmazonListingLoader onDelete() error`, e);
                            setLoading(false);
                        }

                    }, `Are you sure you want to delete the template '${data.name}'`);

                }



                let editMappingsBtn = document.createElement("i");

                editMappingsBtn.classList.add("fa");
                editMappingsBtn.classList.add("fa-edit");
                editMappingsBtn.classList.add("template-icon");
                editMappingsBtn.onclick = () => {
                    let data = params.data;
                    history.push(`/marketplaces/${app.slug}/edit-template-mappings/${data.templateMappingDefinitionId}`);
                }

                outerDiv.appendChild(editMappingsBtn);
                outerDiv.appendChild(deleteBtn);

                return outerDiv;
            }
        }
    ];

    const defaultColDef = {
        resizable: false,
        sortable: true,
        filter: false
    };

    const frameworkComponents = {
        customLoadingOverlay: CustomLoadingOverlay
    }

    const onGridReady = (params) => {
        gridApi.current = params.api;
        gridApi.current && gridApi.current.sizeColumnsToFit();
    };

    const showSelectMarketplaceTemplateModal = () => {
        setShowSelectMarketplaceTemplateModalSettings({ show: true });
    }

    const refreshTemplates = async () => {
        let templates = await marketplaceCtrl.getTemplates(props.app);
        setListingLoaderTemplates(templates);
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={PAGE_NAME} />)
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="marketplace-templates">
                                <Card.Header>
                                    <h4>{PAGE_NAME}</h4>
                                    <div className="add-listing-loader-template-btn">
                                        <Button color="primary" onClick={showSelectMarketplaceTemplateModal}>Add Template</Button>
                                        <div className="clear-fix"></div>
                                    </div>
                                </Card.Header>
                                <div className="card-inner">

                                    {
                                        !loading && listingLoaderTemplates.length === 0 &&
                                        <div>
                                            There are no templates to show.
                                        </div>
                                    }
                                    {
                                        !loading && listingLoaderTemplates.length > 0 &&
                                        <div
                                            id="amazon-templates-grid"
                                            style={{
                                                minHeight: 300,
                                                height: 500
                                            }}
                                            className="ag-theme-alpine">
                                            <AgGridReact
                                                gridOptions={{
                                                    localeText: { noRowsToShow: 'No templates to show.' }
                                                }
                                                }
                                                columnDefs={columns}
                                                animateRows={false}
                                                enableBrowserTooltips
                                                noRow
                                                enableCellTextSelection
                                                defaultColDef={defaultColDef}
                                                suppressRowClickSelection={true}
                                                onGridReady={onGridReady}
                                                loadingOverlayComponent="customLoadingOverlay"
                                                frameworkComponents={frameworkComponents}
                                                rowData={listingLoaderTemplates}
                                                multiSortKey="ctrl"
                                            />
                                        </div>
                                    }


                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>

                {
                    showSelectMarketplaceTemplateModalSettings.show &&
                    <SelectMarketplaceTemplateModal app={props.app} gridApi={gridApi.current} refreshTemplates={refreshTemplates} hideModal={() => setShowSelectMarketplaceTemplateModalSettings({ show: false })} />
                }

                {
                    fieldMappingsModalSettings.show &&
                    <FieldMappingsModal fieldMappingsModalSettings={fieldMappingsModalSettings} hideModal={() => setFieldMappingsModalSettings({ show: false })} />
                }
            </Page.Content>
        </SiteWrapper>

    );
}

export { Templates };