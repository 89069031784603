import React, { Component } from 'react';
import SiteWrapper from '../SiteWrapper.react';
import { Grid, Page } from 'tabler-react';
import { SettingsMenu } from '../SettingsMenu';
import { ReturnsInner } from './ReturnsInner';
import './Returns.scss';

export class Returns extends Component {
  static displayName = Returns.name;

  render() {

    return (
      <SiteWrapper>
        <Page.Content>
          <Grid.Row cards>
            <div className="app-settings-wrapper">
              <SettingsMenu app={this.props.app} />
              <ReturnsInner app={this.props.app} />
            </div>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}