import * as apiCall from '../apiCall';

function previewCustomRules(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/trademe/preview_custom_rules`, model, options);
}

export { previewCustomRules };