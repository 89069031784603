import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Form } from 'tabler-react';

const CustomLabel = ({ name, message, id, place = "top" }) => {

    const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

    if (isMobile) {
        return (<Form.Label>
            {name}
        </Form.Label>);
    }

    return (<div className="custom-label">
        <Form.Label>
            {name}
        </Form.Label>
        {
            message ?
                <div>
                    <i data-tip data-for={id} className="fa fa-info-circle"></i>
                    <ReactTooltip place={place} id={id} effect="solid">
                        <div className="tooltip-message">
                            {message}
                        </div>
                    </ReactTooltip>
                </div>
                :
                null
        }

    </div>);
}

export { CustomLabel }