import React from 'react';
import history from '../history';

const CustomBreadcrumb = ({ name, link, customOnClick }) => {
    return (<div onClick={() => {
        if (customOnClick) {
            customOnClick();
            return;
        }

        if (link) {
            history.push(link);
        }
        
    }} className="custom-breadcrumb">
        <i className="far fa-chevron-left"></i>&nbsp;&nbsp;{name}
    </div>);
}

export { CustomBreadcrumb };