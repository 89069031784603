import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { Page, Grid, Card, Button, Dimmer } from "tabler-react";
import SiteWrapper from '../../SiteWrapper.react';
import * as ebayCtrl from '../../../services/ebay';
import { toast } from 'react-toastify';
import { RoundedLogo, CustomBreadcrumb, MainImages, Variants, ProductMetaData, ProductInformation, CustomToast } from '../../../components';
import { useSelector } from "react-redux";
import { customConfirmAlert } from '../../CustomConfirmAlert';
import { ManualItemSpecifics } from './ManualItemSpecifics';
import "../../ProductDetail.scss";

const ProductDetail = (props) => {

    const [vm, setVm] = useState(null);
    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);

    const app = user.info.apps.find(a => a.id === props.app);

    useEffect(() => {

        if (!props.productId) {
            return;
        }

        async function getData() {
            let vm = await ebayCtrl.getProductDetail(props.app, props.productId);

            setVm(vm);
            setLoading(false);
        }

        getData();

    }, [props.app, props.productId]);

    const product = vm && vm.product;

    const unlistProduct = async () => {

        await ebayCtrl.unlistProduct(props.app, product.id);
        toast.success(<CustomToast message={`'${product.name}' is pending to be unlisted from eBay. Please allow several minutes...`} />, { position: toast.POSITION.TOP_RIGHT });

        let vmCopy = { ...vm };
        vmCopy.product.ebayProductOverride.listingId = null;

        setVm(vmCopy);
    }

    const confirmUnlistProduct = () => {
        customConfirmAlert(() => unlistProduct(), <React.Fragment><strong>{product.name}</strong> will be unlisted from eBay and listing priority will be set to "Don't List"</React.Fragment>);
    }

    const saveManualItemSpecifics = (manualItemSpecifics) => {
        if (!manualItemSpecifics) {
            return;
        }

        let vmCopy = { ...vm };
        vmCopy.product.ebayProductOverride.manualItemSpecifics = manualItemSpecifics;

        setVm(vmCopy);
    }

    const hideProductDetail = () => {
        setVm(null);
        props.hideProductDetail();
    }

    if (loading) {
        return <SiteWrapper>
            <Page.Content className="product-detail">
                <CustomBreadcrumb name="Products" customOnClick={() => hideProductDetail()} />
                <Grid.Row cards>
                    <Grid.Col xs={12}>
                        <Card loading>
                            <Card.Header>
                                <h4>{props.formTitle}</h4>
                            </Card.Header>
                            <Dimmer active loader>
                                <div style={{ height: 150 }}></div>
                            </Dimmer>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    }

    else if (!loading && product !== null) {
        return (
            <SiteWrapper>
                <Page.Content className="product-detail">

                    <CustomBreadcrumb name="Products" customOnClick={() => hideProductDetail()} />

                    <div style={{ display: "flex" }}>
                        <RoundedLogo style={{ marginRight: 10 }} height={35} width={35} logoUrl={app.logoUrl} />
                        <h2>{product.name}</h2>
                    </div>

                    {
                        product.ebayProductOverride && product.ebayProductOverride.listingId &&
                        <a href={`${vm.marketplaceUrl}/itm/${product.ebayProductOverride.listingId}`} target="_blank" rel="noreferrer" className="view-product">
                            <i className="fas fa-eye"></i>&nbsp;&nbsp;View
                        </a>
                    }

                    <Grid.Row cards>
                        <Grid.Col xs={12} sm={12} md={6} lg={7} xl={8}>
                            <MainImages images={product.images} />
                            <Variants product={product} />
                            <ManualItemSpecifics product={product} ebayCategorySpecifics={vm.ebayCategorySpecifics} saveManualItemSpecifics={saveManualItemSpecifics} app={props.app} />

                        </Grid.Col>

                        <Grid.Col xs={12} sm={12} md={6} lg={5} xl={4} >
                            <ProductInformation product={product} />
                            <ProductMetaData product={product} />
                        </Grid.Col>

                    </Grid.Row>

                    {
                        product.ebayProductOverride && product.ebayProductOverride.listingId &&
                        <Button className="unlist-product" onClick={() => confirmUnlistProduct()} color="danger"><i className="fad fa-exclamation-triangle"></i>&nbsp;&nbsp;Unlist product</Button>
                    }

                </Page.Content>
            </SiteWrapper>

        );
    }
}

const mapStateToProps = (state) => {
    const { user } = state;
    return { user };
}

let wrapper = connect(mapStateToProps)(ProductDetail);
export { wrapper as ProductDetail };