import React, { useRef } from 'react';
import {
    Loader,
    Card,
    Grid,
    Page
} from "tabler-react";
import BootstrapTable from 'react-bootstrap-table-next';
import SiteWrapper from '../SiteWrapper.react';
import './CategoryMappings.scss';
import {
    Link
} from 'react-router-dom';
import {
    useSelector
} from "react-redux";
import {
    SettingsMenu
} from '../SettingsMenu';

const CategoryMappingsList = (props) => {

    const apps = useSelector(state => state.user.info.apps);
    const shoppingFeedApp = useRef(apps.find(a => a.slug === props.match?.params?.shoppingFeedSlug));

    console.log("Shopping feed app", shoppingFeedApp);

    const columns = [
        {
            dataField: 'name',
            text: 'Name',
            formatter: (cell, row) => <div style={{ fontSize: 18 }}><Link to={`/settings/category-mappings/${row.link}`}>{cell}</Link></div>
        },
        {
            dataField: "description",
            text: "Description"
        }
    ];

    const data = [
        {
            categoryId: 1,
            name: "Google Taxonomy",
            description: "Category mappings configured in this section can be applied to shopping feeds where the Google Taxonomy is supported.",
            link: "google",
            formatter: (cell, row) => <div>{cell}</div>
        }
    ]

    return (<SiteWrapper>

        <Page.Content>
            <Grid.Row cards>

                <div className="app-settings-wrapper">
                    <SettingsMenu app={shoppingFeedApp.current} shoppingFeedApp={shoppingFeedApp.current} />

                    <div className="app-settings-content">

                        <Card className="external-category-mappings">
                            <Card.Header>

                                <h4>Compatible Options</h4>
                            </Card.Header>
                            <div className="card-inner">
                                <BootstrapTable
                                    bordered={false}
                                    classes="card-table table-vcenter text-nowrap external-category-mappings-table"
                                    bootstrap4
                                    keyField="categoryId"
                                    noDataIndication={() => {

                                        if (this.state.loading) {
                                            return <div className="table-loader-container"><Loader /></div>;
                                        }
                                        else {
                                            return <div className="table-loader-container">No categories to show.</div>;
                                        }
                                    }
                                    }

                                    data={data}
                                    columns={columns} />
                            </div>
                        </Card>
                    </div>
                </div>

            </Grid.Row>
        </Page.Content>
    </SiteWrapper>);

}

export { CategoryMappingsList };