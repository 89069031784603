import React, { Component } from 'react';
import {
    StandaloneFormPage
} from 'tabler-react';
import {
    Loader
} from "tabler-react";
import * as subscribeCtrl from '../services/subscribe';
import history from '../history';
const queryString = require('query-string');

class SubscriptionConfirm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true
        }

    }

    componentDidMount() {
        this.validateSubscription();
    }

    validateSubscription = async () => {
        let queryParams = window.location && window.location.search;
        const parsed = queryString.parse(queryParams);

        let storeType = this.props.match?.params?.storeType?.toLowerCase();

        if (!storeType) {
            window.location.href = "/";
            return;
        }

        console.log("store type", storeType.toLowerCase());

        switch (storeType.toLowerCase()) {

            case "bigcommerce":
            case "nopcommerce":
                {
                    await subscribeCtrl.validate({});
                    history.push("/");
                    break;
                }

            case "shopify":
                {
                    let chargeId = parsed["charge-id"];

                    if (!chargeId) {
                        window.location.href = "/";
                        return;
                    }

                    let model = {
                        subscriptionId: chargeId
                    };

                    let response = await subscribeCtrl.validate(model);

                    window.location.href = response.redirectUrl;
                    return;
                }

            default:
                window.location.href = "/";
                return;
        }
    }

    render() {

        return (<StandaloneFormPage imageURL={"/images/logo.png"}>
            <div style={{ display: "flex", justifyContent: "center", marginBottom: 15, fontSize: 16 }}>Confirming subscription...</div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>

                <Loader />
            </div>


        </StandaloneFormPage>);
    }
}

export { SubscriptionConfirm };