import * as apiCall from '../apiCall';
import * as browserUtility from '../../utilities/browserUtility';

function logInfo(message) {

    const options = {
        handleErrorManually: true,
        isAnonymous: true
    };

    console.log(message);

    let browserInfo = browserUtility.getBrowserInfo();

    message = browserInfo + message;

    let model = {
        logLevel: "info",
        message: message
    }

    return apiCall.post(`/api/log/log_message`, model, options);
}

export { logInfo };