import * as apiCall from '../apiCall';

function saveFeedbackSettings(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/save_feedback_settings`, model, options);
}

export { saveFeedbackSettings };