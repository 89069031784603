import * as apiCall from '../apiCall';

function getAmazonWidget(timezone, appId) {

    const options = {
        appId
    };

    let url = `/api/dashboard/get_amazon_widget?timezone=${timezone}`;
    
    return apiCall.get(url, options);
}

export { getAmazonWidget };