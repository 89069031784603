import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { Form, Button } from "tabler-react";
import { CustomToast } from './';
import * as dashboardCtrl from '../services/dashboard';
import * as logger from '../services/log';
import './PauseProductsModalContent.scss';

const PauseProductsModalContent = (props) => {

    const [pauseProductsInput, setPauseProductsInput] = useState(30);
    const [confirmPauseProductsLoading, setConfirmPauseProductsLoading] = useState(false);

    const setPauseProductsInputChanged = (e) => {
        let val = e.target.value;
        setPauseProductsInput(val);
    }

    const confirmPauseProducts = async () => {
        try {
            setConfirmPauseProductsLoading(true);
            await dashboardCtrl.pauseProductSync(pauseProductsInput);
            setConfirmPauseProductsLoading(false);
            toast.success(<CustomToast message={`Product sync is paused for ${pauseProductsInput} minutes.`} />, { position: toast.POSITION.TOP_RIGHT });
            props.closeModal();
            props.refreshDashboard();
        }
        catch (e) {
            logger.logError(`PauseProductsModalContent confirmPauseProducts() error`, e);
            setConfirmPauseProductsLoading(false);
        }
    }

    return (<div className="pause-products-modal-content">

        <p>
            Pausing product sync can be useful when you're working on feed changes and you want to prevent unfinished work going to marketplaces until you've finished your task.
        </p>

        <Form.Label>
            Enter number of minutes to pause products
        </Form.Label>
        <Form.Input type="number" value={pauseProductsInput} onChange={setPauseProductsInputChanged} />

        <div className="confirm-footer">
            <Button disabled={confirmPauseProductsLoading} className="confirm-cancel-btn" onClick={props.closeModal} type="button" color="secondary">Cancel</Button>
            <Button loading={confirmPauseProductsLoading} onClick={() => confirmPauseProducts()} type="button" color="primary">Confirm</Button>
        </div>

    </div>);
}

export { PauseProductsModalContent };