import React, { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Icon, Button } from "tabler-react";
import * as logger from '../services/log';
import "./CustomConfirmAlert.scss";

const ConfirmModal = ({ message, confirmAction, onClose, customOptions }) => {

    const [confirmLoading, setConfirmLoading] = useState(false);

    let options = {
        header: "Are you sure?",
        confirmButtonName: "Confirm",
        showCancelButton: true
    };

    if (typeof customOptions === "string") {
        options.header = customOptions; // backwards compatibility
    }
    else if (customOptions !== undefined && customOptions !== null) {

        if (customOptions.header !== undefined) {
            options.header = customOptions.header;
        }

        if (customOptions.confirmButtonName !== undefined) {
            options.confirmButtonName = customOptions.confirmButtonName;
        }

        if (customOptions.showCancelButton !== undefined) {
            options.showCancelButton = customOptions.showCancelButton;
        }
    }

    const executeConfirmAction = async (onClose) => {

        try {
            setConfirmLoading(true);
            await confirmAction();
            setConfirmLoading(false);
            onClose();
        }
        catch (e) {
            logger.logError("Modal save error", e);
            setConfirmLoading(false);
            onClose();
        }
    }

    return (
        <div className='custom-confirm-alert'>
            <Icon onClick={() => onClose()} className="confirm-close-icon" prefix="fa" name="times" />
            <h2 style={{ textAlign: "center", marginTop: 10 }}>{options.header}</h2>
            <hr className="divider"></hr>
            <div className="confirm-content">
                {message}
            </div>
            <div className="confirm-footer">
                {
                    options.showCancelButton ? <Button disabled={confirmLoading} className="confirm-cancel-btn" onClick={onClose} type="button" color="secondary">Cancel</Button> : null
                }
                
                <Button loading={confirmLoading} onClick={() => executeConfirmAction(onClose)} type="button" color="primary">{options.confirmButtonName}</Button>
            </div>
        </div>
    );
}

const customConfirmAlert = (confirmAction, message, customOptions) => {
    confirmAlert({
        closeOnClickOutside: false,
        customUI: ({ onClose }) => <ConfirmModal message={message} confirmAction={confirmAction} onClose={onClose} customOptions={customOptions} />
    });
}

export { customConfirmAlert };