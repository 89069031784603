import * as apiCall from '../apiCall';

function getEbayWidget(timezone, appId) {

    const options = {
        appId
    };

    let url = `/api/dashboard/get_ebay_widget?timezone=${timezone}`;
    
    return apiCall.get(url, options);
}

export { getEbayWidget };