import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    Page,
    Form,
    Button,
    Icon
} from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import * as catchCtrl from '../../services/catch';
import * as logger from '../../services/log';
import { useFormik } from 'formik';
import {
    toast
} from 'react-toastify';
import { appType } from '../../constants';
import { useSelector } from "react-redux";
import { SettingsMenu } from '../SettingsMenu';
import { FormLoader } from '../FormLoader';

const Authorisation = (props) => {

    const userInfo = useSelector(state => state.user.info);
    const [loading, setLoading] = useState(true);
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [apiKeyDisabled, setApiKeyDisabled] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {

        const fetchData = async () => {

            let userDetails = await catchCtrl.getAuthorisationDetail(props.app);

            if (!userDetails.apiKey) {
                setApiKeyDisabled(false);
            }

            setUserDetails(userDetails);

            formik.resetForm({
                values: {
                    apiKey: userDetails.apiKey
                }
            });

            setLoading(false);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const validate = values => {
        const errors = {};

        setFormSubmitted(true);

        if (values.apiKey === null
            || values.apiKey === undefined
            || values.apiKey.trim() === "") {
            errors.apiKeyError = "Required";
        }

        return errors;
    }

    const formik = useFormik({
        validateOnBlur: formSubmitted,
        validateOnChange: formSubmitted,
        initialValues: {
            apiKey: ""
        },
        onSubmit: async (values) => {
            await authoriseAccount();
        },
        validate
    });

    const authoriseAccount = async () => {
        if (authorizeLoading) {
            return;
        }

        try {
            setAuthorizeLoading(true);
            setApiKeyDisabled(true);

            let model = {
                apiKey: formik.values.apiKey
            };

            await catchCtrl.authoriseAccount(appType.catch, model);
            let userDetails = await catchCtrl.getAuthorisationDetail(props.app);
            setUserDetails(userDetails);

            formik.resetForm({
                values: {
                    apiKey: userDetails.apiKey
                }
            });

            setAuthorizeLoading(false);
        }
        catch (e) {
            setAuthorizeLoading(false);
            logger.logError("Could not authorise Catch account.", e);
        }
    }

    const toggleApiKeyValid = () => {
        setApiKeyDisabled(false);
    }


    if (loading) {
        return (<FormLoader {...props} formTitle={"Authorisation"} />)
    }

    return (<SiteWrapper>
        <Page.Content>
            <Grid.Row cards>
                <div className="app-settings-wrapper">
                    <SettingsMenu app={props.app} />

                    <div className="app-settings-content"><Card className="catch-install">
                        <Card.Header>
                            <h4>Authorisation</h4>
                        </Card.Header>
                        <div className="card-inner">
                            <div>
                                <Grid.Row>
                                    <Grid.Col width={12}>
                                        Authorise your account with Catch.
                                    </Grid.Col>
                                </Grid.Row>

                                {
                                    userDetails.apiKey && !authorizeLoading ? <div style={{ marginTop: 20 }}>
                                        Status: <span style={{ color: userDetails.connectionValid ? "green" : "red", fontWeight: userDetails.connectionValid ? "bold" : "normal" }}>{userDetails.catchTokenStatus}</span>
                                    </div> : null
                                }

                                <Grid.Row>
                                    <Grid.Col width={12}>
                                        <Form autocomplete="off" onSubmit={formik.handleSubmit} className="install-catch-form">

                                            <div className="api-key-wrapper">
                                                <div style={{ width: "100%" }}>
                                                    <Form.Group label="API Key">
                                                        <Form.Input
                                                            type="text"
                                                            disabled={(authorizeLoading || apiKeyDisabled)}
                                                            error={formik.errors.apiKeyError}
                                                            value={formik.values.apiKey || ""}
                                                            autocomplete="off"
                                                            name="apiKey"
                                                            onChange={formik.handleChange} />

                                                    </Form.Group>
                                                </div>
                                                {
                                                    userDetails.apiKey && (authorizeLoading || apiKeyDisabled) ? <div style={{ width: 30, marginLeft: 15, marginTop: 35 }}>
                                                        <i onClick={toggleApiKeyValid} className="edit-api-key fa fa-pencil" />
                                                    </div> : null
                                                }


                                            </div>

                                        </Form>
                                    </Grid.Col>
                                </Grid.Row>

                                <Grid.Row>
                                    <Grid.Col width={12}>
                                        <Form.Footer className="install-footer">
                                            <Button size="lg" type="button" color="primary" onClick={formik.handleSubmit} disabled={(authorizeLoading || apiKeyDisabled)} loading={authorizeLoading}><Icon prefix="fa" name="plug" />&nbsp;&nbsp;Connect</Button>
                                        </Form.Footer>
                                    </Grid.Col>
                                </Grid.Row>
                            </div>
                        </div>
                    </Card>
                    </div>
                </div>
            </Grid.Row>
        </Page.Content>
    </SiteWrapper>);
}

export { Authorisation };