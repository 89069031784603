import React from 'react';
import { Grid, Page } from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import "./Authorisation.scss";
import { AmazonAuthorisationInner } from './AmazonAuthorisationInner';

const Authorisation = (props) => {

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />
                        <AmazonAuthorisationInner app={props.app} />
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>

    );
}

export { Authorisation };