import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { 
    Icon, 
    Form, 
    Button 
} from 'tabler-react';
import { 
    useFormik 
} from 'formik';
import * as tradeMeCtrl from '../../services/trademe';
import { 
    toast 
} from 'react-toastify';
import { 
    CustomToast, 
    CustomSelect 
} from '../../components';
import { 
    CustomLabel 
} from '../CustomLabel';
import { 
    tradeMeShippingType, 
    tradeMePickupOptionType 
} from '../../constants';
import * as logger from '../../services/log';
import '../CustomModal.css';
import "./ShippingMethodModal.scss";

const ShippingMethodModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);

    const pickupOptions = [
        {
            label: "No pick-ups",
            value: tradeMePickupOptionType.forbid
        },
        {
            label: "Buyer can pick-up",
            value: tradeMePickupOptionType.allow
        },
        {
            label: "Buyer must pick-up",
            value: tradeMePickupOptionType.demand
        }
    ];

    const shippingTypes = [
        {
            label: "Custom",
            value: tradeMeShippingType.custom
        },
        {
            label: "Free",
            value: tradeMeShippingType.free
        }
    ];

    useEffect(() => {

        const { shippingMethod } = props.shippingModalSettings;

        if (shippingMethod) {
            setIsEdit(true);

            if (Array.isArray(shippingMethod.customShippingOptions) && shippingMethod.customShippingOptions.length > 0) {
                shippingMethod.customShippingOptions.forEach((cso) => {

                    if (typeof cso.price === "string") {
                        cso.price = parseFloat(cso.price).toFixed(2);
                    }
                    else {
                        cso.price = cso.price.toFixed(2);
                    }        
                });
            }

            // set formik model
            formik.setValues(shippingMethod);

        }
        else {
            formik.setValues({
                pickupOptionId: tradeMePickupOptionType.forbid,
                shippingTypeId: tradeMeShippingType.free,
                customShippingOptions: [{}],
                name: ""
            });
        }

        setFormLoaded(true);


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shippingModalSettings]);

    function closeModal() {
        props.hideModal();
    }

    const validate = values => {

        const errors = {
            customShippingOptions: {}
        };

        setFormSubmitted(true);

        if (values.name === undefined || values.name === null || values.name.trim() === "") {
            errors.name = "Required";
        }
        else if (values.name.length > 100) {
            errors.name = "Max length is 100 characters.";
        }
        else if (values.name.trim().length < 3) {
            errors.name = "Min length is 3 characters.";
        }

        if (parseInt(values.shippingTypeId) === tradeMeShippingType.custom && Array.isArray(values.customShippingOptions)) {
            values.customShippingOptions.forEach((so, i) => {

                let error = {};

                if (so.price !== null && so.price !== undefined) {
                    so.price = so.price.toString().trim();
                }
                else {
                    so.price = "";
                }

                const priceRegex = RegExp(/^(\d+)?([.]?\d{0,2})?$/g);

                if (so.price === "") {
                    error.price = "Required";
                }
                else if (!priceRegex.test(so.price)) {
                    error.price = "Invalid price entered.";
                }
                else if (parseFloat(so.price) > 999) {
                    error.price = "The maximum value is 999";
                }
                else if (parseFloat(so.price) < 0) {
                    error.price = "Must be a positive value";
                }

                if (so.method === null || so.method === undefined || so.method.toString().trim() === "") {
                    error.method = "Required";
                }

                if (Object.keys(error).length) {
                    errors.customShippingOptions[i] = error;
                }

            });
        }

        if (errors.customShippingOptions && !Object.keys(errors.customShippingOptions).length) {
            delete (errors.customShippingOptions);
        }

        return errors;
    }

    const save = async (values) => {
        try {

            setFormSaving(true);

            let model = {
                pickupOptionId: parseInt(values.pickupOptionId),
                shippingTypeId: parseInt(values.shippingTypeId),
                customShippingOptions: (values.shippingTypeId === tradeMeShippingType.custom && values.customShippingOptions && values.customShippingOptions.length > 0 && values.customShippingOptions.map(cso => ({price: parseFloat(cso.price), method: cso.method}))) || null,
                name: values.name,
                id: (values.id && parseInt(values.id)) || 0
            }

            await tradeMeCtrl.saveShippingMethod(props.app, model)

            toast.success(<CustomToast message={`Shipping method saved.`} />, { position: toast.POSITION.TOP_RIGHT });

            props.refreshShippingMethods();
            closeModal();
        }
        catch (e) {
            logger.logError("ShippingMethodModal.save() error", e);
        }
        finally {
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        validateOnMount: false,
        validateOnBlur: formSubmitted,
        validateOnChange: formSubmitted,
        onSubmit: async (values) => {
            await save(values);
        },
        validate
    });

    const addShippingMethod = () => {

        if (formSaving) {
            return;
        }

        const MAX_SHIPPING_OPTIONS = 10;

        if (formik.values.customShippingOptions.length === 10) {
            toast.warn(<CustomToast message={`The maximum number of custom shipping options is ${MAX_SHIPPING_OPTIONS}`} />, { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        formik.setFieldValue("customShippingOptions", [...formik.values.customShippingOptions, {}]);
    }

    const removeShippingMethod = (index) => {

        if (formSaving) {
            return;
        }

        if (formik.values.customShippingOptions.length === 1) {
            toast.warn(<CustomToast message={`At least one shipping method is required for shipping type: 'Custom'`} />, { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        let customShippingOptions = [...formik.values.customShippingOptions];
        customShippingOptions.splice(index, 1);

        formik.setFieldValue("customShippingOptions", customShippingOptions);
    }

    if (!formLoaded) {
        return null;
    }

    if (!formik.values.customShippingOptions) {
        return null;
    }

    return (
        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={!formSaving}
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>{isEdit ? "Edit" : "Add"} Shipping Policy</h2>
            <hr className="divider"></hr>

            <div className="shipping-method-modal">
                <Form autocomplete="off" onSubmit={formik.handleSubmit}>

                    <Form.Group>
                        <CustomLabel place="right" name="Name" id="name-tooltip" message="Choose a name for this shipping method." />
                        <Form.Input
                            name="name"
                            error={formik.errors.name}
                            disabled={formSaving}
                            onChange={formik.handleChange}
                            value={formik.values.name || ""}
                        />
                    </Form.Group>

                    <Form.Group>
                        <CustomLabel place="right" name="Pickup" id="pickup-tooltip" message="Indicates whether the buyer can (or is required to) pick-up the item. For auctions any value other than Demand implies that at least one shipping option must be provided." />
                        <CustomSelect
                            value={pickupOptions ? pickupOptions.find(option => /* eslint eqeqeq: 0 */ option.value === parseInt(formik.values.pickupOptionId)) : ""}
                            isDisabled={formSaving}
                            options={pickupOptions}
                            onChange={(option) => formik.setFieldValue("pickupOptionId", (option && option.value) || "")}
                        />
                    </Form.Group>

                    {

                        parseInt(formik.values.pickupOptionId) !== tradeMePickupOptionType.demand &&
                        <React.Fragment>

                            <Form.Group>
                                <CustomLabel place="right" name="Shipping Type" id="shipping-type-tooltip" message="The type of delivery." />

                                <CustomSelect
                                    value={shippingTypes ? shippingTypes.find(option => /* eslint eqeqeq: 0 */ option.value === parseInt(formik.values.shippingTypeId)) : ""}
                                    isDisabled={formSaving}
                                    options={shippingTypes}
                                    onChange={(option) => formik.setFieldValue("shippingTypeId", (option && option.value) || "")}
                                />
                            </Form.Group>

                            {
                                formik.values.shippingTypeId === tradeMeShippingType.custom && formik.values.customShippingOptions.map((sm, i) =>
                                    (
                                        <div className="shipping-method" key={i}>

                                            <h5>Shipping Method #{i + 1} <i title="Remove shipping method" onClick={() => removeShippingMethod(i)} className="fa fa-minus-circle remove-shipping-method-icon"></i></h5>

                                            <div className="shipping-method-field">
                                                <Form.Group label="Name">
                                                    <Form.Input
                                                        disabled={formSaving}
                                                        error={formik.errors.customShippingOptions &&
                                                            formik.errors.customShippingOptions[i] &&
                                                            formik.errors.customShippingOptions[i].method}
                                                        name={`customShippingOptions[${i}].method`}
                                                        value={formik.values.customShippingOptions[i].method || ""}
                                                        onChange={formik.handleChange}
                                                    />
                                                </Form.Group>
                                            </div>


                                            <div className="shipping-method-field">
                                                <Form.Group label="Price">
                                                    <Form.InputGroup>
                                                        <Form.InputGroupPrepend>
                                                            <Form.InputGroupText>$</Form.InputGroupText>
                                                        </Form.InputGroupPrepend>
                                                        <Form.Input
                                                            error={formik.errors.customShippingOptions &&
                                                                formik.errors.customShippingOptions[i] &&
                                                                formik.errors.customShippingOptions[i].price}
                                                            disabled={formSaving}
                                                            value={formik.values.customShippingOptions[i].price || ""}
                                                            type="text" 
                                                            onChange={formik.handleChange}
                                                            name={`customShippingOptions[${i}].price`}
                                                            className="cost-input" />
                                                    </Form.InputGroup>
                                                </Form.Group>
                                            </div>

                                        </div>
                                    )

                                )
                            }

                            {
                                parseInt(formik.values.shippingTypeId) === tradeMeShippingType.custom &&
                                <React.Fragment>
                                    <div onClick={() => addShippingMethod()} className="anchor"><i className="fa fa-plus-circle"></i> Add Shipping Method</div>
                                </React.Fragment>
                            }
                        </React.Fragment>
                    }

                    <div className="shipping-method-modal-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                        </div>
                    </div>
                </Form>

            </div>

        </Modal>
    )
}

export { ShippingMethodModal };