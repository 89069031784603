import {
    appType
} from "../constants";
import history from "../history";

const handleAppRedirect = (userInfo) => {
    if (!userInfo.firstAppAdded && userInfo.appId) {

        switch (userInfo.appId) {
            case appType.catch:
                history.push("/install/catch");
                return true;

            case appType.tradeMe:
                history.push("/install/trademe");
                return true;

            case appType.amazonUs:
                history.push("/install/amazon-us");
                return true;

            case appType.amazonUk:
                history.push("/install/amazon-uk");
                return true;

            case appType.amazonCa:
                history.push("/install/amazon-ca");
                return true;

            case appType.amazonAU:
                history.push("/install/amazon-au");
                return true;

            case appType.ebayCA:
                history.push("/install/ebay-ca");
                return true;

            case appType.ebayIE:
                history.push("/install/ebay-ie");
                return true;

            case appType.ebayUK:
                history.push("/install/ebay-uk");
                return true;

            case appType.ebayUS:
                history.push("/install/ebay-us");
                return true;

            case appType.eBayAU:
                history.push("/install/ebay-au");
                return true;
        }
    }

    return false;
}

const isAmazonApp = (appTypeId) => {

    switch (appTypeId) {
        case appType.amazonAU:
        case appType.amazonSg:
        case appType.amazonJp:
        case appType.amazonTr:
        case appType.amazonSa:
        case appType.amazonNl:
        case appType.amazonIt:
        case appType.amazonIn:
        case appType.amazonUk:
        case appType.amazonFr:
        case appType.amazonEs:
        case appType.amazonEg:
        case appType.amazonDe:
        case appType.amazonAe:
        case appType.amazonUs:
        case appType.amazonMx:
        case appType.amazonCa:
        case appType.amazonBr:
            return true;

        default:
            return false;
    }
}

const isEbayApp = (appTypeId) => {
    switch (appTypeId) {
        case appType.eBayAU:
        case appType.ebayCA:
        case appType.ebayIE:
        case appType.ebayUS:
        case appType.ebayUK:
            return true;

        default:
            return false;
    }
}

const isTradeMeApp = (appTypeId) => {
    switch (appTypeId) {
        case appType.tradeMe:
            return true;

        default:
            return false;
    }
}

const getFeedFieldInstructions = (app, field) => {
    switch (app.id) {

        case appType.pinterest:
            switch (field) {
                case "availability":
                    return {
                        description: <div>The availability of the product. Must be one of the following values: ‘in stock’, ‘out of stock’, ‘preorder’.</div>,
                        syntax: <div>in stock<br />out of stock<br />preorder</div>,
                        example: <div>in stock</div>
                    };

                case "description":
                    return {
                        description: <div>The description of the product. This field does not support HTML and must be in plain text for optimal experience.</div>,
                        syntax: <div>Max 10,000 characters</div>,
                        example: <div>Casual fit denim shirt made with the finest quality Japanese denim.</div>
                    }

                case "id":
                    return {
                        description: <div>The user-created unique ID that represents the product.<br /><br />Only Unicode characters are accepted.</div>,
                        syntax: <div>Max 127 characters</div>,
                        example: <div>DS0294-L</div>
                    }

                case "image_link":
                    return {
                        description: <div>The link to the main product image. Images should be at least 75x75 pixels to avoid errors. Use the additional_image_link field to add more images of your product. The image link must be accessible by the Pinterest user-agent, and send the accurate images. Make sure there are no template or placeholder images at the link.<br /><br />The image link can have a maximum of up to 2000 characters and must start with http:// or https://. Encode or remove commas from the image link as we’re unable to process image links containing commas. We recommend enclosing the whole string with quotes.<br /><br />If you change the image later, the new image must use a different link or the image will not update.</div>,
                        syntax: null,
                        example: <div>https://scene.example.com/image/image.jpg</div>
                    }

                case "link":
                    return {
                        description: <div>The landing page for the product. The link must lead directly to the same product and showcase the same data. We do not recommend hardwalls. The URL must start with http:// or https://</div>,
                        syntax: <div>Max 511 characters</div>,
                        example: <div>https://www.example.com/cat/womens-clothing/denim-shirt-0294</div>
                    }

                default:
                    return null;
            }

        default:
            return null;
    }
}

const getFeedInstructions = (app) => {
    switch (app.id) {
        case appType.googleShopping:

            return (<div className="card-inner">

                <div className="step-header">
                    Step 1:
                </div>
                <div className="step-body">
                    Log into your 'Google Merchant' account.
                </div>

                <div className="step-header">
                    Step 2:
                </div>
                <div className="step-body">
                    Go to 'Products', then 'Feeds'.
                </div>
                <div className="step-header">
                    Step 3:
                </div>
                <div className="step-body">
                    <div>
                        Add your feed by clicking on the blue plus button.
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <img src="/images/shopping-feeds/google-shopping/GoogleShopping_Instructions_1.png" alt="Google Shopping Feed Instructions" />
                    </div>

                </div>
                <div className="step-header">
                    Step 4:
                </div>
                <div className="step-body">
                    <div>
                        Pick the language and country you're selling in.
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <img src="/images/shopping-feeds/google-shopping/GoogleShopping_Instructions_2.png" alt="Google Shopping Feed Instructions" />
                    </div>
                </div>
                <div className="step-header">
                    Step 5:
                </div>
                <div className="step-body">
                    <div>
                        Give your feed a name.
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <img src="/images/shopping-feeds/google-shopping/GoogleShopping_Instructions_3.png" alt="Google Shopping Feed Instructions" />
                    </div>
                </div>
                <div className="step-header">
                    Step 6:
                </div>
                <div className="step-body">
                    Select the 'Scheduled fetch' option. Your feed will be automatically updated with this option.
                </div>
                <div className="step-header">
                    Step 7:
                </div>
                <div className="step-body">
                    Click continue.
                </div>
                <div className="step-header">
                    Step 8:
                </div>
                <div className="step-body">
                    <div>
                        Give your feed a name and enter the URL from PolyCommerce for your feed. The feed URL can be found on the 'Templates' page.
                    </div>
                    <div style={{ marginTop: 20, marginBottom: 20 }}>
                        <img src="/images/shopping-feeds/google-shopping/GoogleShopping_Instructions_4.png" alt="Google Shopping Feed Instructions" />
                    </div>
                </div>

            </div>);

        case appType.facebook:
            return (<div className="card-inner">

                <div className="step-header">
                    Step 1:
                </div>
                <div className="step-body">
                    Navigate to <a href="https://business.facebook.com" target="_blank" rel="noreferrer">https://business.facebook.com</a> and select your Facebook page.
                </div>

                <div className="step-header">
                    Step 2:
                </div>
                <div className="step-body">
                    Navigate to Commerce -&gt; Catalog -&gt; Data Sources.
                </div>
                <div className="step-header">
                    Step 3:
                </div>
                <div className="step-body">
                    Enter your feed URL.
                </div>

            </div>);

        default:
            return null;
    }
}

export {
    isAmazonApp,
    isEbayApp,
    isTradeMeApp,
    getFeedInstructions,
    getFeedFieldInstructions,
    handleAppRedirect
};