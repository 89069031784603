import React from 'react';
import Modal from 'react-modal';
import { Icon } from 'tabler-react';
import './CustomModal.css';

class CustomModal extends React.Component {

    render() {
        return (
            <Modal
                appElement={document.body}
                isOpen={this.props.isOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.props.closeModal}
                shouldCloseOnOverlayClick
                className="custom-modal"
                overlayClassName="custom-modal-overlay">

                <Icon onClick={this.props.closeModal} className="close-icon" prefix="fa" name="times" />

                {
                    this.props.title && <div>
                        <h2 className="category-header" style={{ textAlign: "center" }}>{this.props.title}</h2>
                        <hr className="divider"></hr>
                    </div>
                }

                {
                    this.props.children
                }

            </Modal>)
    }
}

export { CustomModal }