import React, { useEffect, useState } from 'react';
import * as dashboardCtrl from '../../services/dashboard';
import {
    Widget,
    WidgetBody,
    WidgetHeader
} from './';
import {
    Link
} from 'react-router-dom';
import moment from 'moment-timezone';
import * as chartUtility from '../../utilities/chartUtility';
import {
    WidgetChartRefresher
} from '../WidgetChartRefresher';

const TradeMeWidget = ({ userWidget }) => {

    const [loading, setLoading] = useState(true);
    const [widgetData, setWidgetData] = useState({});

    useEffect(() => {

        refreshWidget();

        let widgetRefreshId = 'widgetRefresh';

        let widgetRefreshEvent = window.addEventListener(widgetRefreshId, (e) => {
            refreshWidget();
        });

        return () => {
            window.removeEventListener(widgetRefreshId, widgetRefreshEvent);
        }

    }, []);

    const refreshWidget = async () => {

        setLoading(true);

        var tz = moment.tz.guess();
        let encodedTz = encodeURIComponent(tz);

        let tradeMeWidget = await dashboardCtrl.getTradeMeWidget(encodedTz);

        setWidgetData(tradeMeWidget);
        setLoading(false);

        chartUtility.bindChart(tradeMeWidget, "#trademe-chart-wrapper");
    }

    return (
        <Widget loading={loading} userWidget={userWidget}>
            <WidgetHeader userWidget={userWidget} refreshWidget={refreshWidget} />

            <WidgetBody loading={loading}>

                <WidgetChartRefresher widgetData={widgetData} />

                {
                    !loading ?
                        <div>
                            <div>
                                <div className="row-outer">
                                    <div className="row-inner row-border">
                                        <div className="row-name">
                                            Active
                                        </div>
                                        <div className="row-value bold">
                                            {widgetData.activeListings === 0 && widgetData.activeListings}
                                            {
                                                widgetData.activeListings > 0 && <Link className="active-link" to={`/marketplaces/trademe/products?filterByActive=true`}>{widgetData.activeListings}</Link>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="row-outer">
                                    <div className="row-inner">
                                        <div className="row-name">
                                            Errors
                                        </div>
                                        <div className="row-value bold">
                                            {
                                                widgetData.listingsWithErrors === 0 && widgetData.listingsWithErrors
                                            }
                                            {
                                                widgetData.listingsWithErrors > 0 && <Link className="errors-link" to={`/marketplaces/trademe/products?filterByErrors=true`}>{widgetData.listingsWithErrors}</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-outer">
                                    <div className="row-inner">
                                        <div className="row-name">
                                            Sync Pending
                                        </div>
                                        <div className="row-value bold">
                                            {
                                                widgetData.needToSyncCount === 0 && widgetData.needToSyncCount
                                            }
                                            {
                                                widgetData.needToSyncCount > 0 && <Link className="misc-link" to={`/marketplaces/trademe/products?filterByPendingSync=true`}>{widgetData.needToSyncCount}</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    widgetData.queuedCount !== null &&
                                    <div className="row-outer">
                                        <div className="row-inner">
                                            <div className="row-name">
                                                Queued
                                            </div>
                                            <div className="row-value bold">
                                                {
                                                    widgetData.queuedCount === 0 && widgetData.queuedCount
                                                }
                                                {
                                                    widgetData.queuedCount > 0 && <Link className="misc-link" to={`/marketplaces/trademe/products?filterByQueued=true`}>{widgetData.queuedCount}</Link>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className="sales-inner">
                                <div className="chart-wrapper" id="trademe-chart-wrapper"></div>

                                <div className="sales-summary-outer sales-summary-border">
                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Today
                                        </div>
                                        <div className="period-value">
                                            ${widgetData.lastDayTotalSales.toFixed(2)} ({widgetData.lastDayTotalUnits})
                                        </div>
                                    </div>
                                </div>

                                <div className="sales-summary-outer sales-summary-border">
                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Last 7 Days
                                        </div>
                                        <div className="period-value">
                                            ${widgetData.last7DaysTotalSales.toFixed(2)} ({widgetData.last7DaysTotalUnits})
                                        </div>
                                    </div>
                                </div>

                                <div className="sales-summary-outer sales-summary-border">
                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Last 30 Days
                                        </div>
                                        <div className="period-value">
                                            ${widgetData.last30DaysTotalSales.toFixed(2)} ({widgetData.last30DaysTotalUnits})
                                        </div>
                                    </div>
                                </div>

                                <div className="sales-summary-outer">

                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Last 90 Days
                                        </div>
                                        <div className="period-value">
                                            ${widgetData.last90DaysTotalSales.toFixed(2)} ({widgetData.last90DaysTotalUnits})
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div> : null
                }
            </WidgetBody>
        </Widget>);
}

export { TradeMeWidget };