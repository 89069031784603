import * as apiCall from '../apiCall';

function getMarketplaceSteps(appId) {
    
    let url = `/api/install/marketplace/get_steps`;

    const options = {
        appId
    };

    return apiCall.get(url, options);
}

export { getMarketplaceSteps };