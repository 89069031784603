import * as apiCall from '../apiCall';

function getAuthorisationDetail(appId) {

    const options = {
        appId
    };

    let url = `/api/amazon/get_authorisation_detail`;
    
    return apiCall.get(url, options);
}

export { getAuthorisationDetail };