import * as apiCall from '../apiCall';

function nextAmazonStep(appId) {

    const options = {
        appId
    };

    return apiCall.post(`/api/install/amazon/next_step`, null, options);
}

export { nextAmazonStep };