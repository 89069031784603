import React, { Component } from 'react';
import {
    StandaloneFormPage,
    FormTextInput,
    Form,
    Card,
    Button
} from 'tabler-react';
import * as accountCtrl from '../services/account';
import * as formUtility from '../utilities/formUtility';
import {
    Formik
} from "formik";
import history, {disableBackButton} from '../history';
import {
    connect
} from 'react-redux';
import {
    bindActionCreators
} from 'redux';
import {
    saveUserInfo,
    saveToken,
    saveShopifyData
} from '../actions/user';
import {
    Loader
} from "tabler-react";
import * as authUtility from '../utilities/authUtility';
import * as appUtility from '../utilities/appUtility';
import { appType } from '../constants';
const queryString = require('query-string');


class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);

        this.state = {
            queryParams: null,
            loadViaToken: true,
            emailLogin: false
        }
    }

    async componentDidMount() {
        let queryParams = window.location && window.location.search;

        const parsed = queryString.parse(queryParams);

        this.setState({ queryParams, loadViaToken: parsed.token });

        if (parsed.token) {
            let response = await accountCtrl.login({ token: parsed.token });

            this.performLogin(response);
        }

        if (parsed.emailLogin) {
            this.setState({ emailLogin: true });
        }

        if (parsed.shopifyHost && parsed.shopifyShop) {

            const shopifyData = {
                host: parsed.shopifyHost,
                shop: parsed.shopifyShop,
                clientId: parsed.shopifyClientId
            };

            this.props.saveShopifyData(shopifyData);
        }

        setTimeout(() => {
            let email = document.getElementsByName("email");

            if (email && email.length) {
                email[0].focus();
            }
        }, 0);

    }

    performLogin = async (response) => {

        authUtility.setAuthToken(response.authToken);

        let userInfo = await accountCtrl.getUserData();

        this.props.saveUserInfo(userInfo);

        if (userInfo.authToken) {
            authUtility.setAuthToken(userInfo.authToken);
        }

        const parsed = queryString.parse(this.state.queryParams);

        if (!userInfo.firstAppAdded && userInfo.appId && userInfo.appId !== appType.bulkDiscountManager) {

            switch (userInfo.appId) {
                case appType.catch:
                    history.push("/install/catch");
                    disableBackButton();
                    return;

                case appType.tradeMe:
                    history.push("/install/trademe");
                    disableBackButton();
                    return;
            }
        }

        if (appUtility.handleAppRedirect(userInfo)) {
            return;
        }

        if (parsed.redirectUri) {
            let decodedUri = decodeURIComponent(parsed.redirectUri);
            history.push(decodedUri);
            disableBackButton();
        }
        else {
            history.push("/");
            disableBackButton();
        }
    }

    render() {

        if (this.state.loadViaToken) {
            return <div style={{ justifyContent: "center", display: "flex", alignItems: "center" }}><Loader /></div>
        }

        return (
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validate={(values, test) => {
                    let errors = {};
                    if (!values.email) {
                        errors.email = "Email is equired";
                    } else if (
                        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
                    ) {
                        errors.email = "Invalid email address";
                    }

                    if (!values.password) {
                        errors.password = "Password is required";
                    }

                    return errors;
                }}
                onSubmit={async (
                    values,
                    { setSubmitting, setErrors, setFieldValue }
                ) => {

                    try {
                        let response = await accountCtrl.login({ email: values.email, password: values.password });

                        if (response.inError) {
                            formUtility.handleErrors(response, setErrors);
                            setFieldValue("password", "", false);
                            setSubmitting(false);
                            return;
                        }

                        await this.performLogin(response);
                    }
                    catch (e) {
                        setSubmitting(false);
                    }
                }}
                render={({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                    <StandaloneFormPage imageURL={"/images/logo.png"}>
                        <Form className="card" onSubmit={handleSubmit}>
                            <Card.Body className="p-6">
                                {
                                    this.state.emailLogin &&
                                    <React.Fragment>
                                        <Card.Title RootComponent="div">Login</Card.Title>
                                        <FormTextInput
                                            id="email"
                                            name="email"
                                            label="Email"
                                            placeholder="Enter email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.email}
                                            error={errors && touched.email && errors.email}
                                        />
                                        <FormTextInput
                                            name="password"
                                            type="password"
                                            label="Password"
                                            placeholder="Password"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values && values.password}
                                            error={errors && touched.password && errors.password}
                                        />
                                        <Form.Footer>
                                            <Button loading={isSubmitting} type="submit" color="primary" block={true}>
                                                Login
                                            </Button>
                                        </Form.Footer>
                                    </React.Fragment>
                                }
                                {
                                    !this.state.emailLogin &&
                                    <React.Fragment>
                                        <Card.Title RootComponent="div">Session Expired</Card.Title>
                                        <div style={{ paddingBottom: 100 }}>
                                            Please login again with the PolyCommerce plugin.
                                        </div>
                                    </React.Fragment>
                                }

                            </Card.Body>
                        </Form>
                    </StandaloneFormPage>

                )}
            />
        );
    }
}


const mapDispatchToProps = dispatch => (
    bindActionCreators({
        saveUserInfo,
        saveToken,
        saveShopifyData
    }, dispatch)
);

export default connect(null, mapDispatchToProps)(Login);