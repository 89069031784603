import React from 'react';
import {
    Link
  } from "react-router-dom";
import {
    Alert
  } from "tabler-react";

const NoProductsAlert = () => {
    return (<Alert type="primary" icon="alert-triangle">
        No products have been loaded. If this is your first app, please allow some time for the first product import to complete. Current progress of the import can be viewed on the <Link to="/">Dashboard</Link>
    </Alert>);
}

export { NoProductsAlert };