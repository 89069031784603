import {
    SET_WIDGET_DATA,
} from '../actions/types';

const initialState = {
    widgetData: {}
};

const widgetReducer = (state = initialState, action) => {

    switch (action.type) {

        case SET_WIDGET_DATA:

            const widgetDataCopy = { ...state.widgetData };
            widgetDataCopy[action.payload.userWidgetId] = action.payload.widgetData;

            return {
                ...state,
                widgetData: widgetDataCopy
            };

        default:
            return state;
    }
}

export default widgetReducer;