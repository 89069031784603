import * as apiCall from '../apiCall';

function deleteCategoryMapping(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/trademe/delete_category_mapping`, model, options);
}

export { deleteCategoryMapping };