import React, { useState, useEffect } from 'react';
import { Button, Alert, Form, Card, Grid } from 'tabler-react';
import * as amazonCtrl from '../../services/amazon';
import { InnerComponentLoader } from '../InnerComponentLoader';
import * as logger from '../../services/log';
import * as authUtility from '../../utilities/authUtility';
import "./Authorisation.scss";

const AmazonAuthorisationInner = (props) => {

    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({});
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [pollTimer, setPollTimer] = useState(null);

    useEffect(() => {

        const fetchData = async () => {

            let userDetails = await amazonCtrl.getAuthorisationDetail(props.app, false);
            setUserDetails(userDetails);
            setLoading(false);
        }

        fetchData();

        return () => {
            if (pollTimer) {
                clearInterval(pollTimer);
            }
        }

    }, []);

    const authoriseAccount = async () => {
        if (authorizeLoading) {
            return;
        }

        setAuthorizeLoading(true);

        let pollTimer = authUtility.openPopupWithPoller(() => new Promise((res) => res(userDetails.authorizationUrl)), async () => {
            try {
                setLoading(true);

                let sellerIdResult = await amazonCtrl.getSellerId(props.app);

                let userDetailsCopy = { ...userDetails };
                userDetailsCopy.sellerId = sellerIdResult.sellerId;
                setUserDetails(userDetailsCopy);

                setLoading(false);

                setAuthorizeLoading(false);
            }
            catch (e) {
                logger.logError("Amazon Authorisation pollTimer error", e);
                setLoading(false);
                setAuthorizeLoading(false);
            }

        }, () => {
            setAuthorizeLoading(false);
        });

        setPollTimer(pollTimer);
    }

    if (loading) {
        return (<InnerComponentLoader title="Authorisation" />);
    }

    return (
        <div className="app-settings-content">
            <Card className="amazon-connect">
                <Card.Header>
                    <h4>Authorisation</h4>
                </Card.Header>

                <div className="card-inner">
                    {
                        userDetails.amazonTokenStatus && userDetails.amazonTokenStatus !== "OK" && userDetails.amazonTokenStatus !== "Pending" &&
                        <Alert type="danger">
                            Unable to authorise with Amazon. Please try again.
                        </Alert>
                    }

                    <div>
                        <div className="connect-settings">
                            <div className="connect-label">
                                Seller ID
                            </div>
                            <div className="connect-input">
                                <Form.Input disabled value={userDetails.sellerId || ""} />
                            </div>
                        </div>
                    </div>

                    {
                        userDetails.amazonTokenStatus &&
                        userDetails.amazonTokenStatus !== "Pending" &&
                        <div className="connect-settings">
                            <div className="connect-label">
                                Connection status
                            </div>
                            <div className="connect-value">
                                {
                                    userDetails.amazonTokenStatus !== "Pending" && userDetails.amazonTokenStatus !== "OK" ? <span style={{ color: "red", fontWeight: "bold" }}>{userDetails.amazonTokenStatus}</span> : <span>{userDetails.amazonTokenStatus}</span>
                                }

                            </div>
                        </div>
                    }

                    <div className="connect-footer">
                        <div className="button-container">
                            <Button onClick={() => authoriseAccount()} type="button" color="primary" disabled={authorizeLoading} loading={authorizeLoading}><i className="fas fa-sign-in"></i>&nbsp;&nbsp;Authorise</Button>
                        </div>
                    </div>

                </div>
            </Card>
        </div>);
}

export { AmazonAuthorisationInner }