import * as apiCall from '../apiCall';

function getAppMappings(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/template/get_app_mappings`, options);
}

export { getAppMappings };