import * as apiCall from '../apiCall';

function createInventoryLocation(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/create_inventory_location`, model, options);
}

export { createInventoryLocation };