import React, { useEffect, useRef } from 'react';
import * as chartUtility from '../utilities/chartUtility';
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill';

const WidgetChartRefresher = ({ widgetData }) => {
    const targetRef = useRef();
    useResizeDetector({ targetRef });

    useEffect(() => {
        if (widgetData && Object.keys(widgetData).length > 0) {
            chartUtility.bindChart(widgetData, `#${widgetData.chartWrapperId}`);
        }
    });

    return <div ref={targetRef}></div>;
};

export { WidgetChartRefresher };