import * as apiCall from '../apiCall';

function getItemSpecificsRules(appId, categoryRecommendationId) {

    const options = {
        appId
    };

    let url = `/api/ebay/get_item_specifics_rules/${categoryRecommendationId}`;  
    return apiCall.get(url, options);
}

export { getItemSpecificsRules };