import * as apiCall from '../apiCall';

function assignListingTemplate(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/marketplace/assign_listing_template`, model, options);
}

export { assignListingTemplate };