import * as apiCall from '../apiCall';

function previewTemplate(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/marketplace/preview_template`, model, options);
}

export { previewTemplate };