import React, { useState } from 'react';
import * as ebayCtrl from '../../services/ebay';
import Modal from 'react-modal';
import { Form, Button, Icon } from 'tabler-react';
import { toast } from 'react-toastify';
import { CustomToast, CustomSelect } from '../../components';
import { useFormik } from 'formik';
import { isoCountries } from '../../constants';
import '../CustomModal.css';
import "./InventoryLocationModal.scss";

const AddInventoryLocationModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);

    const validate = values => {
        const errors = {};

        setFormSubmitted(true);

        if (values.inventoryLocation.name === null
            || values.inventoryLocation.name === undefined
            || values.inventoryLocation.name.trim() === "") {
            errors.nameError = "Required";
        }

        let address = values.inventoryLocation.location.address;

        if (address.country === null
            || address.country === undefined
            || address.country.trim() === "") {
            errors.countryError = "Required";
        }

        if (address.city === null
            || address.city === undefined
            || address.city.trim() === "") {
            errors.cityError = "Required";
        }

        if (values.inventoryLocation.locationTypes.find(l => l === "STORE")) {
            if (address.addressLine1 === null
                || address.addressLine1 === undefined
                || address.addressLine1.trim() === "") {
                errors.addressLine1Error = "Address Line 1 is required when Location Type is Store (or both Store and Warehouse)"
            }
        }

        return errors;
    }

    const formik = useFormik({
        validateOnBlur: formSubmitted,
        validateOnChange: formSubmitted,
        initialValues: {
            inventoryLocation: {
                location: {
                    address: {
                        addressLine1: null,
                        addressLine2: null,
                        city: null,
                        country: null,
                        county: null,
                        postalCode: null,
                        stateOrProvince: null
                    }
                },
                locationTypes: ["WAREHOUSE"],
                merchantLcationKey: "AUCKLAND_WAREHOUSE",
                merchantLocationStatus: "ENABLED",
                name: ""
            }

        },
        onSubmit: async (values) => {
            await createInventoryLocation();
        },
        validate
    });

    const countryOptions = Object.keys(isoCountries).map((key) => {
        return { label: isoCountries[key].name, value: key };
    });

    function closeModal() {
        props.hideModal();
    }

    async function createInventoryLocation() {
        setFormSaving(true);

        try {

            if (!formik.values.inventoryLocation.locationTypes.find(l => l === "STORE")) {
                formik.setFieldValue("inventoryLocation.location.address.addressLine1", null, false);
                formik.setFieldValue("inventoryLocation.location.address.addressLine2", null, false);
            }


            await ebayCtrl.createInventoryLocation(props.app, formik.values.inventoryLocation);
            toast.success(<CustomToast message={`Created inventory location '${formik.values.inventoryLocation.name}'.`} />, { position: toast.POSITION.TOP_RIGHT });
            props.hideModal();
            props.refreshInventoryLocations();
        }
        finally {
            setFormSaving(false);
        }
    }

    function onLocationTypeChanged(value) {
        let formikValues = { ...formik.values };

        if (value === "STORE") {
            formikValues.inventoryLocation.locationTypes = ["STORE"];
        }

        if (value === "WAREHOUSE") {
            formikValues.inventoryLocation.locationTypes = ["WAREHOUSE"];
        }

        if (value === "BOTH") {
            formikValues.inventoryLocation.locationTypes = ["WAREHOUSE", "STORE"];
        }

        formik.setValues(formikValues);
    }

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>Add Inventory Location</h2>
            <hr className="divider"></hr>

            <Form autocomplete="off" onSubmit={formik.handleSubmit} className="inventory-location-form">

                <Form.Group label="Name">
                    <Form.Input
                        type="text"
                        disabled={formSaving}
                        error={formik.errors.nameError}
                        value={formik.values.inventoryLocation.name || ""}
                        autocomplete="off"
                        name="inventoryLocation.name"
                        onChange={formik.handleChange} />

                </Form.Group>

                <Form.Group label="Location Type">
                    <div>
                        <Form.Radio
                            onChange={() => onLocationTypeChanged("WAREHOUSE")}
                            disabled={formSaving}
                            label="Warehouse"
                            value="WAREHOUSE"
                            checked={formik.values.inventoryLocation.locationTypes.findIndex(i => i === "WAREHOUSE") > -1 && formik.values.inventoryLocation.locationTypes.findIndex(i => i === "STORE") === -1} />
                    </div>

                    <div>
                        <Form.Radio
                            onChange={() => onLocationTypeChanged("STORE")}
                            disabled={formSaving}
                            label="Store"
                            value="STORE"
                            checked={formik.values.inventoryLocation.locationTypes.findIndex(i => i === "STORE") > -1 && formik.values.inventoryLocation.locationTypes.findIndex(i => i === "WAREHOUSE") === -1} />
                    </div>

                    <div>
                        <Form.Radio
                            onChange={() => onLocationTypeChanged("BOTH")}
                            disabled={formSaving}
                            label="Both"
                            value="BOTH"
                            checked={formik.values.inventoryLocation.locationTypes.findIndex(i => i === "STORE") > -1 && formik.values.inventoryLocation.locationTypes.findIndex(i => i === "WAREHOUSE") > -1} />
                    </div>

                </Form.Group>

                {
                    formik.values.inventoryLocation.locationTypes.find(l => l === "STORE") &&
                    <React.Fragment>
                        <Form.Group label="Address Line 1">
                            <Form.Input
                                type="text"
                                disabled={formSaving}
                                autocomplete="off"
                                value={formik.values.inventoryLocation.location.address.addressLine1 || ""}
                                name="inventoryLocation.location.address.addressLine1"
                                onChange={formik.handleChange}
                                error={formik.errors.addressLine1Error} />
                        </Form.Group>

                        <Form.Group label="Address Line 2">
                            <Form.Input
                                type="text"
                                disabled={formSaving}
                                autocomplete="off"
                                value={formik.values.inventoryLocation.location.address.addressLine2 || ""}
                                name="inventoryLocation.location.address.addressLine2"
                                onChange={formik.handleChange} />
                        </Form.Group>

                    </React.Fragment>

                }

                <Form.Group label="Country">

                    <CustomSelect
                        value={countryOptions ? countryOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.inventoryLocation.location.address.country) : ""}
                        isDisabled={formSaving}
                        error={formik.errors.countryError}
                        options={countryOptions}
                        onChange={(option) => formik.setFieldValue("inventoryLocation.location.address.country", (option && option.value) || "")}
                    />
                </Form.Group>

                <Form.Group label="City">
                    <Form.Input
                        type="text"
                        disabled={formSaving}
                        autocomplete="off"
                        value={formik.values.inventoryLocation.location.address.city || ""}
                        name="inventoryLocation.location.address.city"
                        onChange={formik.handleChange}
                        error={formik.errors.cityError} />

                </Form.Group>

                <Form.Group label="State">
                    <Form.Input
                        type="text"
                        disabled={formSaving}
                        autocomplete="off"
                        value={formik.values.inventoryLocation.location.address.state || ""}
                        name="inventoryLocation.location.address.state"
                        onChange={formik.handleChange}
                        error={formik.errors.stateError} />
                </Form.Group>

                <Form.Group label="Postal/zip code">
                    <Form.Input
                        type="text"
                        disabled={formSaving}
                        autocomplete="off"
                        value={formik.values.inventoryLocation.location.address.postalCode || ""}
                        name="inventoryLocation.location.address.postalCode"
                        onChange={formik.handleChange}
                        error={formik.errors.postalCodeError} />
                </Form.Group>

                <div className="inventory-locations-footer">
                    <div>
                        <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                        <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                    </div>
                </div>
            </Form>


        </Modal>
    )
}

export { AddInventoryLocationModal };