import React from "react";
import SiteWrapper from './SiteWrapper.react';
import "./LoadingPage.scss";

const LoadingPage = ({ includeHeader }) => {

    if (includeHeader) {
        return (<SiteWrapper>
            <div className="wrap">
                <div className="loading">

                    <div className="lds-dual-ring"></div>
                    <div className="text">LOADING</div>

                </div>
            </div>

        </SiteWrapper>);
    }

    return <div className="wrap">
        <div className="loading">

            <div className="lds-dual-ring"></div>
            <div className="text">LOADING</div>

        </div>
    </div>
}

export { LoadingPage };