import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Card,
    Page,
    Form,
    Button,
    Icon,
    Dimmer
} from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import {
    InstallProgress
} from '../InstallProgress';
import * as installCtrl from '../../services/install';
import * as ebayCtrl from '../../services/ebay';
import * as accountCtrl from '../../services/account';
import {
    ShippingInner
} from './ShippingInner';
import {
    InventoryLocationsInner
} from './InventoryLocationsInner';
import {
    ReturnsInner
} from './ReturnsInner';
import history from '../../history';
import {
    toast
} from 'react-toastify';
import {
    CustomToast
} from '../../components';
import {
    saveUserInfo
} from '../../actions/user';
import * as authUtility from '../../utilities/authUtility';
import * as logger from '../../services/log';
import {
    LoadingPage
} from '../LoadingPage';
import { useSelector } from "react-redux";
import "./Install.scss";

const Install = (props) => {

    const userInfo = useSelector(state => state.user.info);

    const [loading, setLoading] = useState(true);
    const [installStepHeader, setInstallStepHeader] = useState(null);
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [pollTimer, setPollTimer] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            let ebaySteps = await installCtrl.getEbaySteps(props.app);

            setInstallStepHeader(ebaySteps);

            setLoading(false);
        }

        fetchData();

        return () => {
            if (pollTimer) {
                clearInterval(pollTimer);
            }
        }
    }, [props.app, pollTimer]);

    async function onAuthorize() {

        if (authorizeLoading) {
            return;
        }

        setAuthorizeLoading(true);

        let pollTimer = authUtility.openPopupWithPoller(() => ebayCtrl.getAuthorizationUrl(props.app), async () => {
            try {
                setLoading(true);

                let ebaySteps = await installCtrl.getEbaySteps(props.app);

                setInstallStepHeader(ebaySteps);

                setLoading(false);
                setAuthorizeLoading(false);
            }
            catch (e) {
                logger.logError("Ebay Install pollTimer error", e);
                setLoading(false);
                setAuthorizeLoading(false);
            }

        }, () => {
            setAuthorizeLoading(false);
        });

        setPollTimer(pollTimer);
    }

    const renderComponent = () => {
        const selectedInstallStep = installStepHeader && installStepHeader.installSteps.find(x => x.active);

        if (selectedInstallStep) {
            switch (selectedInstallStep.id) {
                case "authorisation":
                    return authorisationComponent();
                case "shipping":
                    return <ShippingInner app={props.app} loadNextStep={loadNextStep} installWizard />;
                case "inventory_location":
                    return <InventoryLocationsInner app={props.app} loadNextStep={loadNextStep} installWizard />;
                case "return_policy":
                    return <ReturnsInner app={props.app} loadNextStep={loadNextStep} installWizard />;
                default: throw new Error(`Install step ${selectedInstallStep.id} not recognised.`);
            }
        }
    }

    const loadNextStep = async () => {

        setLoading(true);

        try {
            let nextStopRes = await installCtrl.nextEbayStep(props.app);

            if (nextStopRes.installComplete) {
                toast.success(<CustomToast message={`${installStepHeader.appName} installation successful. If you have any questions, please contact us.`} />, { position: toast.POSITION.TOP_RIGHT });

                let userInfo = await accountCtrl.getUserData();
                dispatch(saveUserInfo(userInfo));

                let app = userInfo.apps.find(a => a.id === props.app);

                if (app) {
                    history.push(`/marketplaces/${app.slug}/products`);
                }

                return;
            }

            let ebaySteps = await installCtrl.getEbaySteps(props.app);

            setInstallStepHeader(ebaySteps);
        }
        finally {
            setLoading(false);
        }
    }

    const authorisationComponent = () => {
        return (<div className="install-app-content">
            <Card className="ebay-install">
                <Card.Header>
                    <h4>Authorisation</h4>
                </Card.Header>
                <div className="card-inner">
                    <div>
                        <Grid.Row>
                            <Grid.Col width={12}>
                                {`By clicking connect, you will be redirected to ${installStepHeader.appName}'s website to authenticate. Once authenticated, you will return to this screen to continue installation.`}

                            </Grid.Col>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Col width={12}>
                                <Form.Footer className="install-footer">
                                    <Button size="lg" type="button" color="primary" onClick={() => onAuthorize()} disabled={authorizeLoading}><Icon prefix="fa" name="plug" />&nbsp;&nbsp;Connect</Button>
                                </Form.Footer>
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                </div>
            </Card>
        </div>);
    }

    if (loading && !userInfo.firstAppAdded && userInfo.appId) {
        return (<LoadingPage/>);
    }

    if (loading) {
        return (<SiteWrapper installWizard>
            <Page.Content>
                <Grid.Row cards>

                    <div className="install-app-wrapper">

                        {
                            installStepHeader && <InstallProgress installStepHeader={installStepHeader} app={props.app} />
                        }

                        <div className="install-app-content">
                            <Card loading>
                                <Card.Header>
                                    <h4>...</h4>
                                </Card.Header>
                                <Dimmer active loader>
                                    <div style={{ height: 150 }}></div>
                                </Dimmer>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    }

    return <SiteWrapper installWizard>
        <Page.Content>
            <div onClick={() => history.goBack()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>
            <Grid.Row cards>
                <div className="install-app-wrapper">

                    {
                        installStepHeader && <InstallProgress installStepHeader={installStepHeader} />
                    }

                    {
                        renderComponent()
                    }
                </div>

            </Grid.Row>
        </Page.Content>
    </SiteWrapper>
}

export { Install };