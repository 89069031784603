import React from 'react';
import { 
    Card 
} from "tabler-react";
import * as mobileUtility from '../../utilities/mobileUtility';
import 'gridstack/dist/h5/gridstack-dd-native';
import 'gridstack/dist/gridstack.min.css';
import '../Dashboard.scss';

const Widget = ({ userWidget, children }) => {

    const isMobile = mobileUtility.mobileCheck();

    if (isMobile) {
        return (<Card className="custom-panel-mobile">
            {children}
        </Card>);
    }

    return (
        <div g-id={userWidget.id} gs-w={userWidget.width} gs-min-w={userWidget.widget.minWidth} gs-x={userWidget.posX} gs-y={userWidget.posY} gs-min-h={userWidget.widget.minHeight} gs-h={userWidget.height} gs-no-resize={userWidget.widget.isLocked ? "yes" : "no"} gs-no-move={userWidget.widget.isLocked ? "yes" : "no"} className="grid-stack-item">
            <div className="grid-stack-item-​content">
                <Card className="custom-panel">
                    {children}
                </Card>
            </div>
        </div>);
}

export { Widget };