import { connect } from 'react-redux';
import React, { useState } from 'react';
import { Button, Form, Table, Card } from "tabler-react";
import * as ebayCtrl from '../../../services/ebay';
import { CustomSelect } from '../../../components';
import "../../ProductDetail.scss";
import { useSelector } from "react-redux";
import { customConfirmAlert } from '../../CustomConfirmAlert';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import * as logger from '../../../services/log';
import {
    ItemSpecificsValueRecommendationModal
} from '../ItemSpecificsValueRecommendationModal';

const ManualItemSpecifics = ({ product, ebayCategorySpecifics, saveManualItemSpecifics, app }) => {

    const user = useSelector(state => state.user);
    const polyCommmerceApp = user.info.apps.find(a => a.id === app);

    const [selectedCategorySpecific, setSelectedCategorySpecific] = useState(null);
    const [itemSpecificValue, setItemSpecificValue] = useState("");
    const [itemSpecificsSaving, setItemSpecificsSaving] = useState(false);
    const [itemSpecificError, setItemSpecificError] = useState(null);
    const [itemSpecificValueError, setItemSpecificValueError] = useState(null);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showValueRecommendationModalSettings, setShowValueRecommendationModalSettings] = useState({ show: false });

    const validateItemSpecific = (formSubmitted, itemSpecific, itemSpecificValue) => {

        if (!formSubmitted) {
            return;
        }

        let isValid = true;
        if (!itemSpecific) {
            isValid = false;
            setItemSpecificError("Required");
        }
        else {
            setItemSpecificError(null);
        }

        if (!itemSpecificValue || itemSpecificValue.trim() === "") {
            isValid = false;
            setItemSpecificValueError("Required");
        }
        else {
            setItemSpecificValueError(null);
        }

        return isValid;
    }

    const onItemSpecificValueChanged = (e) => {
        let val = e.target.value;
        setItemSpecificValue(val);

        validateItemSpecific(formSubmitted, selectedCategorySpecific, val);
    }

    const onAddItemSpecifics = async () => {

        setFormSubmitted(true);

        if (!validateItemSpecific(true, selectedCategorySpecific, itemSpecificValue)) {
            return;
        }

        let model = {
            productId: product.id,
            categorySpecificsId: selectedCategorySpecific,
            value: itemSpecificValue
        };

        try {
            setItemSpecificsSaving(true);

            let res = await ebayCtrl.saveManualItemSpecifics(app, model);

            if (res.isValid) {
                saveManualItemSpecifics(res.manualItemSpecifics);

                setItemSpecificValue("");
                setSelectedCategorySpecific(null);
                setFormSubmitted(false);
            }

            setItemSpecificsSaving(false);
        }
        catch (e) {
            logger.logError("onAddItemSpecifics error", e);
            setItemSpecificsSaving(false);
        }
    }

    const confirmRemoveItemSpecific = async (itemSpecific) => {
        let model = {
            productId: product.id,
            manualItemSpecificId: itemSpecific.id
        }

        let res = await ebayCtrl.removeManualItemSpecifics(app, model);

        if (res.isValid) {
            saveManualItemSpecifics(res.manualItemSpecifics);
        }
    }

    const removeItemSpecific = (itemSpecific, name) => {
        customConfirmAlert(() => confirmRemoveItemSpecific(itemSpecific), <React.Fragment>Confirm you would like to remove item specific '{name}'?</React.Fragment>);
    }

    const showRecommendedValues = () => {
        var categorySpecificsName = ebayCategorySpecifics.find(x => x.categorySpecificsId === selectedCategorySpecific)?.name;
        setShowValueRecommendationModalSettings({ show: true, categorySpecificsId: selectedCategorySpecific, categorySpecificsName })
    }

    if (!product.ebayCategoryMapping) {
        return (<Card>
            <Card.Header><h4>Alerts</h4></Card.Header>
            <Card.Body>

            <div>Category {product.category && product.category.name && `'${product.category.name}'`} is unmapped. Please update <Link to={`/marketplaces/${polyCommmerceApp.slug}/category-mappings`}>Category Mappings</Link> for your store.</div>
            </Card.Body>
        </Card>);
    }

    if (!product.ebayCategoryMapping || !ebayCategorySpecifics) {
        return null;
    }

    if (product.ebayProductOverride &&
        product.ebayProductOverride.manualItemSpecifics &&
        product.ebayProductOverride.manualItemSpecifics.length === 0) {

    }

    return (<Card>
        <Card.Header>
            <h4>Manual Item Specifics</h4>
            <div className="manual-item-specifics-tooltip-wrapper">
                <i data-tip data-for="manual-item-specifics-tooltip" className="fa fa-info-circle"></i>
                <ReactTooltip place="top" id="manual-item-specifics-tooltip" effect="solid">
                    <div className="tooltip-message">
                        Item Specifics help buyers on eBay find your item. Item Specifics may help improve search rankings and visibility on eBay. Please note that 'Manual Item Specifics' will override any 'Auto Item Specifics' configured (Settings &gt; {polyCommmerceApp.altName} &gt; Auto Item Specifics)
                    </div>
                </ReactTooltip>
            </div>
        </Card.Header>

        <Card.Body><div>

            <div className="item-specifics-selection-wrapper">
                <div className="item-specifics-select-wrapper">
                    <CustomSelect
                        key={`select-${selectedCategorySpecific || ""}`}
                        isClearable
                        error={itemSpecificError}
                        id="item-specifics-select"
                        value={ebayCategorySpecifics.map(c => ({ label: c.name, value: c.categorySpecificsId })).find(option => /* eslint eqeqeq: 0 */ option.value == selectedCategorySpecific)}
                        options={ebayCategorySpecifics.map(c => ({ label: c.name, value: c.categorySpecificsId }))}
                        onChange={(option) => {
                            let value = (option && option.value) || null;
                            setSelectedCategorySpecific(value);
                            validateItemSpecific(formSubmitted, value, itemSpecificValue);
                        }}
                    />
                </div>
                <div className="item-specifics-input">
                    <Form.Input error={itemSpecificValueError} value={itemSpecificValue} onChange={onItemSpecificValueChanged} />
                    {
                        selectedCategorySpecific ? <div style={{marginTop: 10, marginLeft: 3, fontSize: "0.97em"}} onClick={() => showRecommendedValues()} className="anchor">Show Recommendations</div> : null
                    }
                </div>
                <div className="item-specifics-btn">
                    <Button loading={itemSpecificsSaving} onClick={() => onAddItemSpecifics()}><i className="fa fa-plus-circle"></i> Add</Button>
                </div>
            </div>

            {
                product.ebayProductOverride &&
                product.ebayProductOverride.manualItemSpecifics &&
                product.ebayProductOverride.manualItemSpecifics.length === 0 &&
                <div className="no-manual-specifics">
                    This product does not currently contain any manual item specifics.
                </div>
            }

            {
                product.ebayProductOverride &&
                product.ebayProductOverride.manualItemSpecifics &&
                product.ebayProductOverride.manualItemSpecifics.length > 0 &&
                <div className="item-specifics-table">
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.ColHeader>Name</Table.ColHeader>
                                <Table.ColHeader>Value</Table.ColHeader>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {
                                product.ebayProductOverride &&
                                product.ebayProductOverride.manualItemSpecifics &&
                                product.ebayProductOverride.manualItemSpecifics.map(itemSpecific => {

                                    let itemSpecificMatch = ebayCategorySpecifics.find(x => x.categorySpecificsId === itemSpecific.categorySpecificsId);

                                    if (itemSpecificMatch) {

                                        return (<Table.Row key={itemSpecific.categorySpecificsId}>
                                            <Table.Col>{itemSpecificMatch.name}</Table.Col>
                                            <Table.Col>{itemSpecific.value}</Table.Col>
                                            <Table.Col><i onClick={() => removeItemSpecific(itemSpecific, itemSpecificMatch.name)} className="fas fa-trash remove-item-specific-icon"></i></Table.Col>
                                        </Table.Row>
                                        )
                                    }

                                    return null;
                                })
                            }
                        </Table.Body>
                    </Table>
                </div>

            }
        </div>
        </Card.Body>

        {
            showValueRecommendationModalSettings.show &&
            <ItemSpecificsValueRecommendationModal app={app} showValueRecommendationModalSettings={showValueRecommendationModalSettings} hideModal={() => setShowValueRecommendationModalSettings({ show: false })} />
        }
    </Card>)
}


const mapStateToProps = (state) => {
    const { user } = state;
    return { user };
}

let wrapper = connect(mapStateToProps)(ManualItemSpecifics);
export { wrapper as ManualItemSpecifics };