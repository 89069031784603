import React, { Component } from 'react';
import {
  Button,
  Loader,
  Card,
  Grid,
  Icon
} from "tabler-react";
import BootstrapTable from 'react-bootstrap-table-next';
import { ShippingPolicyModal } from './ShippingPolicyModal';
import * as ebayCtrl from '../../services/ebay';
import { InnerComponentLoader } from '../InnerComponentLoader';
import './Shipping.scss';

export class ShippingInner extends Component {
  static displayName = ShippingInner.name;

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      fulfillmentPolicies: [],
      fulfillmentPoliciesLoading: true,
      shippingPolicy: {},
      defaultFulfillmentPolicyId: null,
      loading: true
    }

    this.columns = [
      {
        dataField: "fulfillmentPolicyId",
        text: "Id",
        formatter: (cell, row) => <React.Fragment>
          {cell} {row.fulfillmentPolicyId === this.state.defaultFulfillmentPolicyId && <strong>(default)</strong>}
        </React.Fragment>,
        headerStyle: (column, columnIndex) => {
          return { width: "200px" }
        }
      },
      {
        dataField: 'name',
        text: 'Name'
      },
      {
        dataField: "manage",
        text: "",
        align: "right",
        formatter: (cell, row) => <React.Fragment>
          <Button className="edit-btn" onClick={() => this.onEditShippingPolicy(row)} size="sm" color="secondary"><Icon prefix="fa" name="edit" /> Edit</Button>
        </React.Fragment>
      }
    ]
  }

  async componentDidMount() {
    this.refreshFulfillmentOptions();
  }

  onNewShippingPolicy = () => {
    this.shippingPolicyModalRef.openModal();
  }

  onEditShippingPolicy = (shippingPolicy) => {
    this.shippingPolicyModalRef.openModal(shippingPolicy);
  }

  refreshFulfillmentOptions = async () => {

    this.setState({ fulfillmentPoliciesLoading: true, fulfillmentPolicies: [], loading: true });

    let fulfillmentPolicyResult = await ebayCtrl.getFulfillmentPolicies(this.props.app);

    if (!fulfillmentPolicyResult.errors.length) {
      this.setState({
        fulfillmentPolicies: fulfillmentPolicyResult.fulfillmentPolicies,
        defaultFulfillmentPolicyId: fulfillmentPolicyResult.defaultFulfillmentPolicyId,
        fulfillmentPoliciesLoading: false,
        loading: false
      });
    }
  }

  render() {

    if (this.state.loading) {
      return (<InnerComponentLoader title="Shipping" />);
    }

    return (

      <div className="app-settings-content">
        <Card className="ebay-shipping">
          <Card.Header>

            <h4>Shipping</h4>
            <div className="add-shipping-policy-btn">
              <Button color="primary" onClick={() => this.onNewShippingPolicy()}><i className="far fa-plus-circle" aria-hidden="true"></i>&nbsp;&nbsp;Add</Button>
              <div className="clear-fix"></div>
            </div>
          </Card.Header>
          <div className="card-inner">

            {
              !this.state.fulfillmentPoliciesLoading && this.state.fulfillmentPolicies.length === 0 &&
              <div>
                There are no shipping policies to show.
              </div>
            }

            {
              !this.state.fulfillmentPoliciesLoading && this.state.fulfillmentPolicies.length > 0 &&
              <BootstrapTable
                bordered={false}
                classes="card-table table-vcenter text-nowrap ebay-category-mappings-table"
                bootstrap4
                keyField="fulfillmentPolicyId"
                noDataIndication={() => this.state.fulfillmentPoliciesLoading && <div className="table-loader-container"><Loader /></div>}
                data={this.state.fulfillmentPolicies}
                columns={this.columns} />
            }

          </div>
          {
            this.props.installWizard &&
            <div className="next-btn">
              <Button onClick={() => this.props.loadNextStep()} type="submit" color="primary"><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
            </div>
          }
        </Card>
        <ShippingPolicyModal app={this.props.app} refreshFulfillmentOptions={this.refreshFulfillmentOptions} ref={(ref) => this.shippingPolicyModalRef = ref} />
      </div>

    );
  }
}