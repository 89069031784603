import React from 'react';
import Modal from 'react-modal';
import { Icon, Button, Form, Loader } from 'tabler-react';
import * as categoryMappingCtrl from '../../services/categoryMapping';
import Fade from 'react-reveal/Fade';
import * as logger from '../../services/log';
import '../CategoriesModal.css';

class CategoriesModal extends React.Component {

    constructor() {
        super();

        this.defaultParentCategories = [{ name: "Browse All Categories", level: 0, currentlySelected: true, externalCategoryId: null, parentExternalCategoryId: null, expandable: false }];

        this.categoryMappingVm = {};

        this.state = {
            modalIsOpen: false,
            allCategoriesLoading: true,
            categoryLoading: {},
            categories: [],
            parentCategories: [...this.defaultParentCategories],
            categoryRecommendations: [],
            categoryRecommendationsInput: "",
            categoryRecommendationsLoading: true,
            searchLoading: false
        };
    }

    openModal = async (categoryMappingVm) => {

        this.categoryMappingVm = categoryMappingVm;

        let parentCategories = [...this.defaultParentCategories];
        parentCategories[0].currentlySelected = true;
        this.setState(
            {
                modalIsOpen: true,
                parentCategories,
                categories: [],
                categoryRecommendations: [],
                categoryRecommendationsInput: "",
                searchLoading: false,
                categoryRecommendationsLoading: true
            });

        let categoryPath = this.categoryMappingVm.categoryPath;

        let serachResult = await categoryMappingCtrl.searchCategories(categoryPath, this.props.categoryMappingProfileId);

        this.setState({
            categoryRecommendationsLoading: false,
            categoryRecommendations: serachResult
        });
    }

    afterOpenModal = async () => {
        // load first level of categories

        this.setState({ allCategoriesLoading: true });

        const topLevelCategories = await categoryMappingCtrl.getCategories(1, this.props.categoryMappingProfileId);

        this.setState({ categories: topLevelCategories, allCategoriesLoading: false });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    toggleCategoryLoading = (externalCategoryId, loading) => {
        this.setState(prevState => {
            let categoryLoading = { ...prevState.categoryLoading };

            if (!loading && categoryLoading[externalCategoryId]) {
                delete (categoryLoading[externalCategoryId]);
            }
            else if (loading) {
                categoryLoading[externalCategoryId] = true;
            }

            return {
                categoryLoading
            };
        })
    }

    onSearchCategoryRecommendations = async () => {

        if (this.state.categoryRecommendationsInput && this.state.categoryRecommendationsInput.length > 2) {

            try {
                this.setState({ searchLoading: true, categoryRecommendations: [] });
                let categoryRecommendations = await categoryMappingCtrl.searchCategories(this.state.categoryRecommendationsInput, this.props.categoryMappingProfileId);

                this.setState({ categoryRecommendations, searchLoading: false });
            }
            catch (e) {
                logger.logError(`CategoriesModal onSearchCategoryRecommendations() error`, e);
                this.setState({ searchLoading: false });
            }
        }
    }

    onCategoryRecommendationSelected = (externalCategoryId) => {
        this.closeModal();
        this.props.onCategorySelected(externalCategoryId);
    }

    onSelectCategory = async (category) => {

        if (category.leafCategory) {
            this.closeModal();
            this.props.onCategorySelected(category.externalCategoryId);
        }

        if (Object.keys(this.state.categoryLoading).length > 0) {
            return;
        }

        this.toggleCategoryLoading(category.externalCategoryId, true);

        let parentCategories = [...this.state.parentCategories];
        parentCategories.forEach(x => x.currentlySelected = false);
        parentCategories.push({ name: category.name, level: category.level, currentlySelected: true, externalCategoryId: category.externalCategoryId, parentExternalCategoryId: category.parentExternalCategoryId });

        const categories = await categoryMappingCtrl.getCategories(category.level + 1, this.props.categoryMappingProfileId, category.externalCategoryId);

        this.toggleCategoryLoading(category.externalCategoryId, false);

        this.setState({ categories, parentCategories }, () => {
            let elem = document.querySelector(".ReactModal__Content");

            if (elem) {
                elem.scrollTo(0, 0);
            }

        });
    }

    onSelectParentCategory = async (parentCategory) => {

        
        if (parentCategory.currentlySelected) {
            return;
        }

        if (Object.keys(this.state.categoryLoading).length > 0) {
            return;
        }

        this.toggleCategoryLoading(parentCategory.externalCategoryId, true);

        if (parentCategory.level === 0) {
            let parentCategories = [...this.defaultParentCategories];
            parentCategories[0].currentlySelected = true;
            this.setState({ parentCategories, categories: [] });

            const topLevelCategories = await categoryMappingCtrl.getCategories(1, this.props.categoryMappingProfileId);

            this.toggleCategoryLoading(parentCategory.externalCategoryId, false);

            this.setState({ categories: topLevelCategories });
            return;
        }

        let parentCategories = [...this.state.parentCategories].filter(pc => pc.level <= parentCategory.level);

        parentCategories.forEach(x => x.currentlySelected = false);
        parentCategories[parentCategories.length - 1].currentlySelected = true;

        this.setState({ parentCategories, categories: [] });

        const categories = await categoryMappingCtrl.getCategories(parentCategory.level + 1, this.props.categoryMappingProfileId, parentCategory.externalCategoryId);

        this.toggleCategoryLoading(parentCategory.externalCategoryId, false);

        this.setState({ categories }, () => {
            let elem = document.querySelector(".ReactModal__Content");

            if (elem) {
                elem.scrollTo(0, 0);
            }
        });
    }

    render() {
        return (
            <Modal
                appElement={document.body}
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                className="category-modal"
                overlayClassName="category-modal-overlay"
                shouldCloseOnOverlayClick
                contentLabel="Category">

                <Icon onClick={this.closeModal} className="close-icon" prefix="fa" name="times" />
                <div className="category-header">
                    <h2>{this.props.taxonomyName} Categories</h2>
                    <div><strong>Mapping store category:</strong> <span className="mapping-category">{this.categoryMappingVm.categoryPath}</span></div>
                </div>

                <hr className="divider"></hr>

                <div className="category-wrapper">

                    <div className="category-recommendations">
                        <div className="category-recommendations-header">
                            Search
                        </div>
                        {
                            this.state.categoryRecommendationsLoading && <div style={{ display: "flex", alignItems: "center", height: 120 }}><Loader /></div>
                        }
                        {
                            !this.state.categoryRecommendationsLoading &&
                            <div className="category-recommendations-body">
                                <div className="category-recommendations-search">
                                    <Form.InputGroup append={<Button color="primary" disabled={this.state.searchLoading} loading={this.state.searchLoading} onClick={() => { this.onSearchCategoryRecommendations() }}>Search</Button>}>
                                        <Form.Input onKeyDown={(e) => { if (e.key === "Enter") { this.onSearchCategoryRecommendations() } }} onChange={(e) => this.setState({ categoryRecommendationsInput: e.target.value })} value={this.state.categoryRecommendationsInput} className="category-recommendations-input" placeholder="Search for..." />
                                    </Form.InputGroup>
                                </div>

                                {
                                    this.state.categoryRecommendations.length > 0 && this.state.categoryRecommendations.map(c => (<div key={c.externalCategoryId}>
                                        <div onClick={() => this.onCategoryRecommendationSelected(c.externalCategoryId)} className="anchor category-recommendation">{c.externalCategoryPath}</div>
                                    </div>))
                                }
                                {
                                    this.state.categoryRecommendations.length === 0 && !this.state.searchLoading &&
                                    <div className="no-category-recommendations">No category recommendations found.</div>
                                }
                            </div>
                        }

                    </div>

                    <ul className="parent-categories">
                        {
                            this.state.parentCategories.map((pc, i) => {
                                return <li key={`parent-category-${i}`} onClick={() => { this.onSelectParentCategory(pc); }}>
                                    <div className={`parent-category${!pc.currentlySelected ? " expandable" : ""}`}>
                                        <div className="parent-category-icon">
                                            {
                                                !pc.currentlySelected &&
                                                !this.state.categoryLoading[pc.externalCategoryId] &&
                                                <Icon prefix="fa" name="chevron-up" />
                                            }
                                            {
                                                (this.state.categoryLoading[pc.externalCategoryId] || this.state.allCategoriesLoading) &&
                                                <i className="fa fa-spinner fa-spin"></i>
                                            }
                                        </div>
                                        <div className="parent-category-header">
                                            {pc.name}
                                        </div>


                                    </div>
                                </li>
                            })
                        }
                    </ul>
                    <ul className="child-categories">
                        {
                            this.state.categories.map(c => {
                                return <li key={c.externalCategoryId} onClick={() => { this.onSelectCategory(c); }} >
                                    <Fade force top duration={400}>
                                        <div className="child-category">
                                            <div className="child-category-header">
                                                {c.name}
                                            </div>
                                            <div className="child-category-icon">
                                                {
                                                    !c.leafCategory &&
                                                    !this.state.categoryLoading[c.externalCategoryId] &&
                                                    <Icon prefix="fa" name="chevron-down" />
                                                }
                                                {
                                                    !c.leafCategory &&
                                                    this.state.categoryLoading[c.externalCategoryId] &&
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                }
                                            </div>
                                        </div>
                                    </Fade>

                                </li>
                            })
                        }
                    </ul>
                </div>
            </Modal>
        );
    }

}

export { CategoriesModal };