import React from 'react';
import { CustomSelect } from '../components';

const SelectAttributeRenderer = (props) => {

    return <div className="attribute-selection">
        <CustomSelect
            height={34}
            value={props.options.find(option => /* eslint eqeqeq: 0 */ option.value == (props.data && props.data.selectedProductAttribute))}
            options={props.options}
            onChange={async (option) => {
                let opt = option && (option.value || "");

                props.node.setDataValue("selectedProductAttribute", opt);
            }}
        />
    </div>
}

export { SelectAttributeRenderer };