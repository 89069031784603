import React, { useEffect, useState, useRef } from 'react';
import { Icon, Loader, Button } from 'tabler-react';
import Modal from 'react-modal';
import * as templateCtrl from "../services/template";
import { AgGridReact } from "ag-grid-react";
import { SelectAttributeRenderer } from '../components/SelectAttributeRenderer';
import './FieldMappingsModal.scss';
import './CustomModal.css';

const FieldMappingsModal = (props) => {

    const [loading, setLoading] = useState(true);
    const [fieldMappings, setFieldMappings] = useState([]);
    const [mappingAttributeOptions, setMappingAttributeOptions] = useState([]);
    const gridApi = useRef(null); 

    useEffect(() => {

        if (!props.fieldMappingsModalSettings || !props.fieldMappingsModalSettings.templateMappingDefinitionId) {
            throw new Error("TemplateMappingDefinitionId required.");
        }

        const fetchData = async () => {
            let fieldMappingVm = await templateCtrl.getFieldMappings(props.fieldMappingsModalSettings.templateMappingDefinitionId);

            setFieldMappings(fieldMappingVm.mappings);

            let mappingAttributeOptions = fieldMappingVm.valueFields.map(mao => ({ label: mao.value, value: mao.value, selectedAttributeTypeId: mao.typeId }));
            setMappingAttributeOptions(mappingAttributeOptions);

            setLoading(false);
        }

        fetchData();


    }, [props.app, props.fieldMappingsModalSettings]);

    function closeModal() {
        props.hideModal();
    }

    const columnDefs = [
        {
            headerName: "Column",
            field: "columnName",
            editable: false,
            sortable: false,
            filter: true,
            resizable: true
        },
        {
            headerName: "Attribute Mapping",
            field: "selectedProductAttribute",
            width: 120,
            editable: false,
            sortable: false,
            filter: false,
            resizable: false,
            suppressMenu: true,
            cellRenderer: "selectAttributeRenderer",
            cellRendererParams: {
                options: mappingAttributeOptions,
                templateMappingDefinitionId: props.fieldMappingsModalSettings.templateMappingDefinitionId
            }
        },
        {
            headerName: "Mapping Rule",
            field: "selectedMappingRule",
            filter: false,
            sortable: false,
            resizable: false,
            suppressMenu: true
        }
    ];

    const frameworkComponents = {
        selectAttributeRenderer: SelectAttributeRenderer
    };

    const onGridReady = params => {
        gridApi.current = params.api;
        params.api.sizeColumnsToFit();
    };

    return (
        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="field-mappings-modal"
            overlayClassName="field-mappings-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />
            <h2 className="category-header" style={{ textAlign: "center" }}>Field Mappings</h2>
            <hr className="divider"></hr>

            <div className="field-mappings">

                {
                    loading &&
                    <div className="field-mappings-loader">
                        <Loader />
                    </div>
                }

                {
                    !loading && fieldMappings.length > 0 &&
                    <div className="card-inner">

                        <div className="ag-theme-alpine" style={{ height: "100%", width: "100%" }}>
                            <AgGridReact
                                columnDefs={columnDefs}
                                rowData={fieldMappings}
                                onGridReady={onGridReady}
                                suppressContextMenu
                                frameworkComponents={frameworkComponents}
                            />
                        </div>
                    </div>
                }

            </div>
            <div>
                    <Button type="submit" color="primary"><i className="fa fa-cloud" aria-hidden="true"></i>&nbsp;&nbsp;Save</Button>
                </div>

        </Modal>
    );
}

export { FieldMappingsModal };