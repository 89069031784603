import React, { useEffect, useState, useRef } from 'react';
import SiteWrapper from './SiteWrapper.react';
import {
    Form,
    Grid,
    Card,
    Page,
    Dimmer
} from "tabler-react";
import {
    itemSpecificsRuleConditionType,
    specialRuleType
} from '../constants';
import {
    toast
} from 'react-toastify';
import {
    CustomToast,
    CustomSelect,
    CustomCreatableMulti
} from './';
import {
    SaveToolbar
} from './SaveToolbar';
import {
    useSelector
} from "react-redux";
import * as templateCtrl from '../services/template';
import history from '../history';
import * as logger from '../services/log';
import "./EditTemplateMappings.scss";

const PAGE_NAME = "Template";
const MIN_TEMPLATE_NAME_CHARACTERS = 2;

let formSubmitted = false;

const EditTemplateMappings = (props) => {

    const [loading, setLoading] = useState(true);
    const [formSaving, setFormSaving] = useState(false);
    const [templateMappingRules, setTemplateMappingRules] = useState([]);
    const [templateFilters, setTemplateFilters] = useState([]);
    const [specialTemplateRules, setSpecialTemplateRules] = useState([]);
    const [specialShippingTemplateRules, setSpecialShippingTemplateRules] = useState([]);
    const [errors, setErrors] = useState({});
    const [filterErrors, setFilterErrors] = useState({});
    const [specialTemplateRuleErrors, setSpecialTemplateRuleErrors] = useState({});
    const [specialShippingTemplateRuleErrors, setSpecialShippingTemplateRuleErrors] = useState({});
    const [templateMappingsVm, setTemplateMappingsVm] = useState({});
    const [templateDefinitionId, setTemplateDefinitionId] = useState(null);
    const [lockedMappingRules, setLockedMappingRules] = useState({});
    const [categoryMappingRule, setCategoryMappingRule] = useState({});
    const [feedColumnOptions, setFeedColumnOptions] = useState([]);
    const [valueFieldOptions, setValueFieldOptions] = useState([]);
    const [templateNameErrorMessage, setTemplateNameErrorMessage] = useState(null);
    const [shippingProfileOptions, setShippingProfileOptions] = useState([]);

    const apps = useSelector(state => state.user.info.apps);
    const shoppingFeedApp = useRef(apps.find(a => a.slug === props.match?.params?.shoppingFeedSlug));

    const selectHeight = 43;

    const initialise = async () => {
        try {
            setLoading(true);
            setTemplateDefinitionId(parseInt(props.match.params.templateDefinitionId));
            let templateMappingsVm = await templateCtrl.getMappings((props.app || shoppingFeedApp.current.id), props.match.params.templateDefinitionId);

            let lockedDic = {};
            templateMappingsVm.templateMappingRules.filter(x => x.isLocked).forEach(rule => lockedDic[rule.templateHeaderColumnId] = true);

            let categoryMappingColumn = templateMappingsVm.templateMappingRules.find(tmr => tmr.categoryMappingProfileId && tmr.categoryMappingProfileId > 0);

            if (categoryMappingColumn) {
                setCategoryMappingRule({
                    categoryMappingProfileId: categoryMappingColumn.categoryMappingProfileId,
                    templateHeaderColumnId: categoryMappingColumn.templateHeaderColumnId
                });
            }

            templateMappingsVm.templateMappingRules = templateMappingsVm.templateMappingRules.filter(tmr => tmr.categoryMappingProfileId === null || tmr.categoryMappingProfileId === undefined);

            setLockedMappingRules(lockedDic);
            setTemplateMappingRules(templateMappingsVm.templateMappingRules);
            setTemplateFilters(templateMappingsVm.templateFilters);
            setSpecialTemplateRules(templateMappingsVm.templateSpecialRules.filter(t => t.templateSpecialRuleTypeId !== specialRuleType.shippingProfile));
            setSpecialShippingTemplateRules(templateMappingsVm.templateSpecialRules.filter(t => t.templateSpecialRuleTypeId === specialRuleType.shippingProfile));

            if (templateMappingsVm && Array.isArray(templateMappingsVm.valueFields)) {
                let valueFieldOpts = templateMappingsVm.valueFields.map(vf => ({
                    label: vf.value,
                    value: vf.value
                }));

                setValueFieldOptions(valueFieldOpts);
            }

            if (templateMappingsVm && Array.isArray(templateMappingsVm.templateColumns)) {
                let feedColOptions = templateMappingsVm.templateColumns.map(tc => {

                    const formatedColumnName = `[F].${tc.columnName}`;

                    return {
                        label: formatedColumnName,
                        value: formatedColumnName
                    }
                });

                setFeedColumnOptions(feedColOptions);
            }

            if (templateMappingsVm && Array.isArray(templateMappingsVm.templateColumns) && shoppingFeedApp.current !== null) {
                templateMappingsVm.templateColumns.forEach(c => c.columnName = `[F].${c.columnName}`);
            }

            setShippingProfileOptions(templateMappingsVm.shippingProfileOptions);

            setTemplateMappingsVm(templateMappingsVm);
            setLoading(false);
        }
        catch (e) {
            logger.logError("EditTemplateMappings useEffect() error", e);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await initialise();
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const templateRuleTypes = templateCtrl.getRuleTypes();
    const specialRuleTypes = templateCtrl.getSpecialRuleTypes();

    const applyTemplateMappingOptions = [
        { label: "To all products", value: "TO_ALL_PRODUCTS" },
        { label: "Parent products only", value: "PARENT_ONLY" },
        { label: "Children products only", value: "CHILDREN_ONLY" }
    ];

    const validateSpecialRules = (specialRulesCopy, submitAction, isShippingProfile = false) => {
        let errors = {};
        let isValid = true;

        if (!formSubmitted && !submitAction) {
            return;
        }

        for (let i = 0; i < specialRulesCopy.length; i++) {

            let r = specialRulesCopy[i];

            if (!errors[i]) {
                errors[i] = {
                    conditions: {}
                };
            }

            if (!r.selectedProductAttribute || r.selectedProductAttribute === "") {
                isValid = false;
                errors[i]["selectedProductAttribute"] = "Required";
            }

            if (!r.templateSpecialRuleTypeId || r.templateSpecialRuleTypeId === "") {
                isValid = false;
                errors[i]["templateSpecialRuleTypeId"] = "Required";
            }

            // rule validation
            if (r.templateSpecialRuleClauses && r.templateSpecialRuleClauses.length > 0) {
                for (let j = 0; j < r.templateSpecialRuleClauses.length; j++) {
                    let c = r.templateSpecialRuleClauses[j];

                    if (!errors[i]["conditions"][j]) {
                        errors[i]["conditions"][j] = {};
                    }

                    if (!c.selectedProductAttribute || c.selectedProductAttribute === "") {
                        errors[i]["conditions"][j]["selectedProductAttribute"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["selectedProductAttribute"]);
                    }

                    if (!c.templateRuleTypeId || c.templateRuleTypeId === "") {
                        errors[i]["conditions"][j]["templateRuleTypeId"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["templateRuleTypeId"]);
                    }
                }
            }
        }

        console.log("ERRORS", errors);

        if (isShippingProfile) {
            setSpecialShippingTemplateRuleErrors(errors);
        }
        else 
        {
            setSpecialTemplateRuleErrors(errors);
        }

        

        return isValid;
    }

    const validateFilters = (filtersCopy, submitAction) => {
        let errors = {};
        let isValid = true;

        if (!formSubmitted && !submitAction) {
            return;
        }

        for (let i = 0; i < filtersCopy.length; i++) {

            let r = filtersCopy[i];

            if (!errors[i]) {
                errors[i] = {
                    conditions: {}
                };
            }

            // rule validation
            if (r.templateFilterRuleClauses && r.templateFilterRuleClauses.length > 0) {
                for (let j = 0; j < r.templateFilterRuleClauses.length; j++) {
                    let c = r.templateFilterRuleClauses[j];

                    if (!errors[i]["conditions"][j]) {
                        errors[i]["conditions"][j] = {};
                    }

                    if (!c.selectedProductAttribute || c.selectedProductAttribute === "") {
                        errors[i]["conditions"][j]["selectedProductAttribute"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["selectedProductAttribute"]);
                    }

                    if (!c.templateRuleTypeId || c.templateRuleTypeId === "") {
                        errors[i]["conditions"][j]["templateRuleTypeId"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["templateRuleTypeId"]);
                    }
                }
            }
        }

        setFilterErrors(errors);

        return isValid;
    }

    const validate = (templateMappingRulesCopy, submitAction) => {
        let errors = {};
        let isValid = true;

        if (!formSubmitted && !submitAction) {
            return;
        }

        if (templateMappingsVm.template.name.trim().length < MIN_TEMPLATE_NAME_CHARACTERS) {
            isValid = false;
        }

        for (let i = 0; i < templateMappingRulesCopy.length; i++) {

            let r = templateMappingRulesCopy[i];

            if (!errors[i]) {
                errors[i] = {
                    conditions: {}
                };
            }

            if (!r.templateHeaderColumnId || r.templateHeaderColumnId === "") {
                errors[i]["templateHeaderColumnId"] = "Required";
                isValid = false;
            }
            else if (errors[i]) {
                delete (errors[i]["templateHeaderColumnId"]);
            }

            if (r.selectedCustomValues === undefined || r.selectedCustomValues === null || r.selectedCustomValues.length === 0) {
                errors[i]["selectedCustomValues"] = "Required";
                isValid = false;
            }
            else if (errors[i]) {
                delete (errors[i]["selectedCustomValues"]);
            }

            // rule validation
            if (r.templateMappingRuleClauses && r.templateMappingRuleClauses.length > 0) {
                for (let j = 0; j < r.templateMappingRuleClauses.length; j++) {
                    let c = r.templateMappingRuleClauses[j];

                    if (!errors[i]["conditions"][j]) {
                        errors[i]["conditions"][j] = {};
                    }

                    if (!c.selectedProductAttribute || c.selectedProductAttribute === "") {
                        errors[i]["conditions"][j]["selectedProductAttribute"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["selectedProductAttribute"]);
                    }

                    if (!c.templateRuleTypeId || c.templateRuleTypeId === "") {
                        errors[i]["conditions"][j]["templateRuleTypeId"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["templateRuleTypeId"]);
                    }
                }
            }
        }

        setErrors(errors);

        return isValid;
    }

    function getSpecialShippingTemplateRuleErrorMessage(fieldName, mappingIndex, conditionIndex = null) {
        if (conditionIndex === null) {
            return (formSubmitted && specialShippingTemplateRuleErrors[mappingIndex] && specialShippingTemplateRuleErrors[mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted && specialShippingTemplateRuleErrors[mappingIndex] && specialShippingTemplateRuleErrors[mappingIndex]["conditions"] && specialShippingTemplateRuleErrors[mappingIndex]["conditions"][conditionIndex] && specialShippingTemplateRuleErrors[mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    function getSpecialTemplateRuleErrorMessage(fieldName, mappingIndex, conditionIndex = null) {
        if (conditionIndex === null) {
            return (formSubmitted && specialTemplateRuleErrors[mappingIndex] && specialTemplateRuleErrors[mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted && specialTemplateRuleErrors[mappingIndex] && specialTemplateRuleErrors[mappingIndex]["conditions"] && specialTemplateRuleErrors[mappingIndex]["conditions"][conditionIndex] && specialTemplateRuleErrors[mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    function getFilterErrorMessage(fieldName, mappingIndex, conditionIndex = null) {
        if (conditionIndex === null) {
            return (formSubmitted && filterErrors[mappingIndex] && filterErrors[mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted && filterErrors[mappingIndex] && filterErrors[mappingIndex]["conditions"] && filterErrors[mappingIndex]["conditions"][conditionIndex] && filterErrors[mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    function getErrorMessage(fieldName, mappingIndex, conditionIndex = null) {

        if (conditionIndex === null) {
            return (formSubmitted && errors[mappingIndex] && errors[mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted && errors[mappingIndex] && errors[mappingIndex]["conditions"] && errors[mappingIndex]["conditions"][conditionIndex] && errors[mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    function addNewTemplateFilter() {
        if (formSaving) {
            return;
        }

        setTemplateFilters((prevState) => {
            let templateFiltersCopy = [...prevState];

            templateFiltersCopy.push({ templateFilterRuleClauses: [{ logicalOperator: "AND" }] });

            return templateFiltersCopy;
        });
    }

    function addNewSpecialShippingTemplateRule() {
        if (formSaving) {
            return;
        }

        setSpecialShippingTemplateRules((prevState) => {
            let specialTemplateRulesCopy = [...prevState];
            specialTemplateRulesCopy.push({ templateSpecialRuleClauses: [], templateSpecialRuleTypeId: specialRuleType.shippingProfile });
            return specialTemplateRulesCopy;
        });
    }

    function addNewSpecialTemplateRule() {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialTemplateRulesCopy = [...prevState];
            specialTemplateRulesCopy.push({ templateSpecialRuleClauses: [] });
            return specialTemplateRulesCopy;
        });
    }

    function addNewTemplateMappingRule() {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let templateMappingRulesCopy = [...prevState];

            templateMappingRulesCopy.push({ setValueTo: "CUSTOM_VALUE", apply: "TO_ALL_PRODUCTS" });

            return templateMappingRulesCopy;
        });
    }

    function removeSpecialShippingTemplateRule(idx) {
        if (formSaving) {
            return;
        }

        setSpecialShippingTemplateRules((prevState) => {
            let specialTemplateRulesCopy = [...prevState];

            specialTemplateRulesCopy.splice(idx, 1);

            return specialTemplateRulesCopy;
        });
    }

    function removeSpecialTemplateRule(idx) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialTemplateRulesCopy = [...prevState];

            specialTemplateRulesCopy.splice(idx, 1);

            return specialTemplateRulesCopy;
        });
    }

    function removeTemplateFilter(idx) {
        if (formSaving) {
            return;
        }

        setTemplateFilters((prevState) => {
            let filtersCopy = [...prevState];

            filtersCopy.splice(idx, 1);

            return filtersCopy;
        });
    }

    function removeTemplateMappingRule(idx) {

        if (formSaving) {
            return;
        }


        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            rulesCopy.splice(idx, 1);

            return rulesCopy;
        });
    }

    function categoryMappingFieldChanged(field, value) {

        setCategoryMappingRule(prevValue => {
            let mappingCopy = { ...prevValue };
            mappingCopy[field] = value;
            return mappingCopy;
        });
    }

    function specialShippingTemplateFieldChanged(index, fieldName, value) {
        if (formSaving) {
            return;
        }

        setSpecialShippingTemplateRules((prevState) => {

            let specialTemplateRulesCopy = [...prevState];

            specialTemplateRulesCopy[index][fieldName] = value;

            return specialTemplateRulesCopy;

        });

        validateSpecialRules(specialShippingTemplateRules, false, true);
    }

    function specialTemplateFieldChanged(index, fieldName, value) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {

            let specialTemplateRulesCopy = [...prevState];

            specialTemplateRulesCopy[index][fieldName] = value;

            return specialTemplateRulesCopy;

        });

        validateSpecialRules(specialTemplateRules);
    }

    function templateMappingsFieldChanged(index, fieldName, value) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            if (fieldName === "apply" && value === "WHEN") {
                rulesCopy[index].templateMappingRuleClauses = [{}];
            }
            else if (fieldName === "apply" && value === "TO_ALL_PRODUCTS") {
                rulesCopy[index].templateMappingRuleClauses = [];
            }

            if (fieldName === "selectedProductAttribute") {
                rulesCopy[index].customValue = null;
            }
            else if (fieldName === "customValue") {
                rulesCopy[index].selectedProductAttribute = null;
            }

            rulesCopy[index][fieldName] = value;

            return rulesCopy;
        });

        validate(templateMappingRules);
    }

    function specialShippingTemplateRuleTypeChange(mappingIndex, conditionIndex, fieldName, value) {
        if (formSaving) {
            return;
        }

        setSpecialShippingTemplateRules((prevState) => {

            let specialTemplateRulesCopy = [...prevState];
            specialTemplateRulesCopy[mappingIndex].templateSpecialRuleClauses[conditionIndex][fieldName] = value;

            return specialTemplateRulesCopy;

        });

        validateSpecialRules(specialShippingTemplateRules, false, true);
    }

    function specialTemplateRuleTypeChange(mappingIndex, conditionIndex, fieldName, value) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {

            let specialTemplateRulesCopy = [...prevState];
            specialTemplateRulesCopy[mappingIndex].templateSpecialRuleClauses[conditionIndex][fieldName] = value;

            return specialTemplateRulesCopy;

        });

        validateSpecialRules(specialTemplateRules);
    }

    function templateFilterRuleTypeChange(mappingIndex, conditionIndex, fieldName, value) {
        if (formSaving) {
            return;
        }

        setTemplateFilters((prevState) => {
            let filtersCopy = [...prevState];
            filtersCopy[mappingIndex].templateFilterRuleClauses[conditionIndex][fieldName] = value;

            return filtersCopy;
        });

        validateFilters(templateFilters);
    }

    function templateRuleTypeChange(mappingIndex, conditionIndex, fieldName, value) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            rulesCopy[mappingIndex].templateMappingRuleClauses[conditionIndex][fieldName] = value;

            return rulesCopy;
        });

        validate(templateMappingRules);
    }

    function addSpecialShippingRuleCondition(mappingIndex) {
        if (formSaving) {
            return;
        }

        setSpecialShippingTemplateRules((prevState) => {
            let specialRulesCopy = [...prevState];

            if (!specialRulesCopy[mappingIndex].templateSpecialRuleClauses) {
                specialRulesCopy[mappingIndex].templateSpecialRuleClauses = [{}];
            }
            else {
                specialRulesCopy[mappingIndex].templateSpecialRuleClauses.push({});
            }

            return specialRulesCopy;
        });
    }

    function addSpecialRuleCondition(mappingIndex) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialRulesCopy = [...prevState];

            if (!specialRulesCopy[mappingIndex].templateSpecialRuleClauses) {
                specialRulesCopy[mappingIndex].templateSpecialRuleClauses = [{}];
            }
            else {
                specialRulesCopy[mappingIndex].templateSpecialRuleClauses.push({});
            }

            return specialRulesCopy;
        });
    }

    function addFilterCondition(mappingIndex) {

        if (formSaving) {
            return;
        }

        setTemplateFilters((prevState) => {
            let filtersCopy = [...prevState];

            if (!filtersCopy[mappingIndex].templateFilterRuleClauses) {
                filtersCopy[mappingIndex].templateFilterRuleClauses = [{}];
            }
            else {
                filtersCopy[mappingIndex].templateFilterRuleClauses.push({});
            }

            return filtersCopy;
        });
    }

    function addCondition(mappingIndex) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            if (!rulesCopy[mappingIndex].templateMappingRuleClauses) {
                rulesCopy[mappingIndex].templateMappingRuleClauses = [{}];
            }
            else {
                rulesCopy[mappingIndex].templateMappingRuleClauses.push({});
            }


            return rulesCopy;
        });
    }

    function removeSpecialShippingRuleCondition(mappingIndex, conditionIndex) {
        if (formSaving) {
            return;
        }

        setSpecialShippingTemplateRules((prevState) => {
            let specialRulesCopy = [...prevState];

            specialRulesCopy[mappingIndex].templateSpecialRuleClauses.splice(conditionIndex, 1);

            return specialRulesCopy;
        });
    }

    function removeSpecialRuleCondition(mappingIndex, conditionIndex) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialRulesCopy = [...prevState];

            specialRulesCopy[mappingIndex].templateSpecialRuleClauses.splice(conditionIndex, 1);

            return specialRulesCopy;
        });
    }

    function removeFilterCondition(mappingIndex, conditionIndex) {

        if (formSaving) {
            return;
        }

        setTemplateFilters((prevState) => {
            let filtersCopy = [...prevState];

            filtersCopy[mappingIndex].templateFilterRuleClauses.splice(conditionIndex, 1);

            return filtersCopy;
        });
    }

    function removeCondition(mappingIndex, conditionIndex) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            rulesCopy[mappingIndex].templateMappingRuleClauses.splice(conditionIndex, 1);

            return rulesCopy;
        });
    }

    function arrayMove(oldArr, old_index, new_index, setter, idPrefix) {

        if (new_index < 0) {
            return;
        }

        if (new_index > oldArr.length - 1) {
            return;
        }

        let arr = [...oldArr];

        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

        setter(arr);

        let elem = document.getElementById(`${idPrefix}${new_index}`);
        elem.classList.add("rule-container-animate");

        setTimeout(() => {
            elem.classList.remove("rule-container-animate");
        }, 1000);
    };

    async function saveTemplateMappings() {

        try {

            setFormSaving(true);

            let templateMappingRulesCopy = (templateMappingRules && [...templateMappingRules]) || [];
            let tempalteFiltersCopy = (templateFilters && [...templateFilters] || []);
            let templateSpecialRulesCopy = (specialTemplateRules && [...specialTemplateRules] || []);
            let templateSpecialShippingRulesCopy = (specialShippingTemplateRules && [...specialShippingTemplateRules]) || [];

            formSubmitted = true;

            let isValid = validate(templateMappingRulesCopy, true) && validateFilters(tempalteFiltersCopy, true) && validateSpecialRules(templateSpecialRulesCopy, true, false) && validateSpecialRules(templateSpecialShippingRulesCopy, true, true);

            if (!isValid) {
                setFormSaving(false);

                toast.error(<CustomToast message="There is one or more validation errors on the page. Please review form and try again." />, { position: toast.POSITION.TOP_RIGHT });
                return;
            }

            templateSpecialRulesCopy = templateSpecialRulesCopy.concat(templateSpecialShippingRulesCopy);

            tempalteFiltersCopy.forEach((tf, i) => tf.sortOrder = i + 1);
            templateSpecialRulesCopy.forEach((sf, i) => sf.sortOrder = i + 1);

            // massage data before sending to API.
            for (let i = 0; i < templateMappingRulesCopy.length; i++) {
                let r = templateMappingRulesCopy[i];

                if (typeof r.templateHeaderColumnId === "string") {
                    r.templateHeaderColumnId = parseInt(r.templateHeaderColumnId);
                }

                r.sortOrder = i + 1;

                if (r.templateMappingRuleClauses && r.templateMappingRuleClauses.length > 0) {
                    for (let j = 0; j < r.templateMappingRuleClauses.length; j++) {
                        let c = r.templateMappingRuleClauses[j];

                        if (typeof c.templateRuleTypeId === "string") {
                            c.templateRuleTypeId = parseInt(c.templateRuleTypeId);
                        }
                    }
                }
                else if (!r.templateMappingRuleClauses) {
                    r.templateMappingRuleClauses = [];
                }
            }

            for (let i = 0; i < templateSpecialRulesCopy.length; i++) {
                let r = templateSpecialRulesCopy[i];

                if (typeof r.templateSpecialRuleTypeId === "string") {
                    r.templateSpecialRuleTypeId = parseInt(r.templateSpecialRuleTypeId);
                }

                r.sortOrder = i + 1;

                if (Array.isArray(r.templateSpecialRuleClauses) && r.templateSpecialRuleClauses.length > 0) {
                    for (let j = 0; j < r.templateSpecialRuleClauses.length; j++) {
                        let c = r.templateSpecialRuleClauses[j];

                        if (typeof c.templateRuleTypeId === "string") {
                            c.templateRuleTypeId = parseInt(c.templateRuleTypeId);
                        }
                    }
                }
                else if (!r.templateSpecialRuleClauses) {
                    r.templateSpecialRuleClauses = [];
                }
            }

            if (categoryMappingRule &&
                categoryMappingRule.templateHeaderColumnId &&
                categoryMappingRule.templateHeaderColumnId > 0 &&
                categoryMappingRule.categoryMappingProfileId &&
                categoryMappingRule.categoryMappingProfileId > 0 &&
                Array.isArray(templateMappingRulesCopy)) {
                templateMappingRulesCopy.push(
                    {
                        templateHeaderColumnId: categoryMappingRule.templateHeaderColumnId,
                        customValue: null,
                        sortOrder: 9999,
                        apply: "TO_ALL_PRODUCTS",
                        categoryMappingProfileId: categoryMappingRule.categoryMappingProfileId
                    });
            }

            let model = {
                templateMappingDefinitionId: templateDefinitionId,
                templateMappingRules: templateMappingRulesCopy,
                templateFilters: tempalteFiltersCopy,
                templateSpecialRules: templateSpecialRulesCopy,
                appId: props.app || shoppingFeedApp.current.id,
                templateName: templateMappingsVm.template.name
            };

            await templateCtrl.saveTemplateMappings(model);

            toast.success(<CustomToast message="Mappings saved." />, { position: toast.POSITION.TOP_RIGHT });

            setFormSaving(false);

        }
        catch (e) {
            logger.logError("EditTemplateMappings saveTemplateMappings() error", e);
            setFormSaving(false);
        }
    }

    const backBtnHandler = () => {
        history.goBack();
    }

    const getFileName = (filePath) => {

        if (!filePath || filePath.length === 0) {
            return "";
        }

        let parts = filePath.split("/");

        return parts[parts.length - 1];
    }

    const categoryMappingColumns = (templateMappingsVm && templateMappingsVm.templateColumns && templateMappingsVm.templateColumns.filter(c => c.isCategoryMappingColumn).map(c => ({ label: c.columnName, value: c.templateHeaderColumnId }))) || [];

    if (loading) {
        return (<SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <Grid.Col sm={12}>
                        <Card>
                            <Card.Header>
                                <h4>{PAGE_NAME}</h4>
                            </Card.Header>
                            <Card.Body>
                                <Dimmer active loader>
                                    <div style={{ height: 500 }}></div>
                                </Dimmer>
                            </Card.Body>
                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>

                    <Grid.Col sm={12}>

                        <div onClick={() => backBtnHandler()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>
                        <Card className="edit-template-mappings" loading={loading}>
                            <Card.Header>

                                <h4>{PAGE_NAME}</h4>

                            </Card.Header>

                            <div className="card-inner">
                                {
                                    templateMappingsVm.template &&
                                    <div className="template-details">
                                        <div className="template-details-row">
                                            <div className="template-details-label">
                                                Name:
                                            </div>
                                            <div className="template-details-value">
                                                <Form.Input
                                                    invalid={templateNameErrorMessage !== null}
                                                    className="template-name"
                                                    disabled={formSaving}
                                                    value={templateMappingsVm.template.name || ""}
                                                    onChange={(e) => {
                                                        var value = e.target.value;

                                                        if (value.trim().length < MIN_TEMPLATE_NAME_CHARACTERS) {
                                                            setTemplateNameErrorMessage(`At least ${MIN_TEMPLATE_NAME_CHARACTERS} characters required.`);
                                                        }
                                                        else {
                                                            setTemplateNameErrorMessage(null);
                                                        }

                                                        setTemplateMappingsVm((prev) => {
                                                            let copy = { ...prev };
                                                            copy.template.name = value;

                                                            return copy;
                                                        });
                                                    }}
                                                />
                                                <span style={{ display: templateNameErrorMessage ? "block" : "none", float: "left" }} className="invalid-feedback">{templateNameErrorMessage}</span>
                                            </div>
                                        </div>
                                        <div className="template-details-row">
                                            <div className="template-details-label">
                                                Type:
                                            </div>
                                            <div className="template-details-value">
                                                {shoppingFeedApp?.current?.altName || templateMappingsVm.template.templateType.description}
                                            </div>
                                        </div>
                                        {
                                            templateMappingsVm.template.sourceFileName ?
                                                <div className="template-details-row">
                                                    <div className="template-details-label">
                                                        Example:
                                                    </div>
                                                    <div className="template-details-value">
                                                        <a target="_blank" rel="noreferrer" href={templateMappingsVm.template.sourceFileName}>{getFileName(templateMappingsVm.template.sourceFileName)}</a>
                                                    </div>
                                                </div>
                                                : null
                                        }

                                    </div>

                                }
                                <div className="template-subheader">
                                    <h3>Field Mappings</h3>
                                </div>

                                {
                                    templateMappingRules.length === 0 &&
                                    <div className="no-mappings">There are no mappings to show.</div>
                                }
                                {
                                    templateMappingRules.length > 0 &&
                                    templateMappingRules.map((templateMappingRule, i) => (
                                        <div className="rule-container" key={i} id={`mapping-${i}`} >
                                            <div className="mapping-header">
                                                <h4>Mapping #{i + 1} {!templateMappingRule.isLocked ? <i title="Remove mapping" onClick={() => removeTemplateMappingRule(i)} className="fa fa-minus-circle remove-mapping-icon"></i> : <i className="fa fa-lock locked-icon" title="This mapping rule is locked and can't be edited or deleted."></i>}</h4>
                                                <div className="sort-icon-wrapper">
                                                    {
                                                        !templateMappingRule.isLocked
                                                        &&
                                                        <React.Fragment>
                                                            <i onClick={(e) => arrayMove(templateMappingRules, i, i - 1, (arr) => setTemplateMappingRules(arr), "mapping-")} title="Move up one position. Rules at the top will be evaluated first." className={`fal fa-chevron-up sort-up${i === 0 || ((i - 1) >= 0 && templateMappingRules[i - 1].isLocked) ? " hover-ban" : ""}`}></i>
                                                            <i onClick={(e) => arrayMove(templateMappingRules, i, i + 1, (arr) => setTemplateMappingRules(arr), "mapping-")} title="Move down one position. Rules at the bottom will be evaluated first." className={`fal fa-chevron-down sort-down${i === templateMappingRules.length - 1 ? " hover-ban" : ""}`}></i>
                                                        </React.Fragment>
                                                    }

                                                </div>
                                            </div>

                                            <div className="item-specific-rule">
                                                <div className="specific-col">
                                                    <div className="set-specific">SET</div>

                                                    <CustomSelect
                                                        height={selectHeight}
                                                        value={templateMappingsVm.templateColumns.map(c => ({ label: c.columnName, value: c.templateHeaderColumnId, isDisabled: lockedMappingRules && lockedMappingRules[c.templateHeaderColumnId] })).find(option => /* eslint eqeqeq: 0 */ option.value == templateMappingRule.templateHeaderColumnId)}
                                                        isDisabled={formSaving || templateMappingRule.isLocked}
                                                        error={getErrorMessage("templateHeaderColumnId", i)}
                                                        options={templateMappingsVm.templateColumns.map(c => ({ label: c.columnName, value: c.templateHeaderColumnId }))}
                                                        onChange={(option) => templateMappingsFieldChanged(i, "templateHeaderColumnId", (option && option.value) || "")} />
                                                </div>

                                                <div className="specific-col">
                                                    <div className="set-specific">TO</div>
                                                    <CustomCreatableMulti
                                                        hideSelectedOptions={false}
                                                        isDisabled={formSaving || templateMappingRule.isLocked}
                                                        height={selectHeight}
                                                        error={getErrorMessage("selectedCustomValues", i)}
                                                        options={valueFieldOptions}
                                                        values={templateMappingRules[i].selectedCustomValues}
                                                        handleChange={(values) => { templateMappingsFieldChanged(i, "selectedCustomValues", values) }} />
                                                </div>
                                            </div>

                                            <div className="specific-conditions">

                                                {
                                                    (templateMappingsVm && templateMappingsVm.template && templateMappingsVm.template.includeParentProductsWithVariations) &&
                                                    <div style={{ width: 360 }} className="specific-col">
                                                        <div className="set-specific">APPLY</div>

                                                        <CustomSelect
                                                            height={selectHeight}
                                                            isClearable={false}
                                                            value={applyTemplateMappingOptions.find(option => /* eslint eqeqeq: 0 */ option.value == templateMappingRule.apply) || applyTemplateMappingOptions[0]}
                                                            isDisabled={formSaving || templateMappingRule.isLocked}
                                                            options={applyTemplateMappingOptions}
                                                            onChange={(option) => templateMappingsFieldChanged(i, "apply", (option && option.value) || "")}
                                                        />

                                                    </div>
                                                }

                                                {
                                                    templateMappingRule.templateMappingRuleClauses &&
                                                    templateMappingRule.templateMappingRuleClauses.length > 0 &&
                                                    <div style={{ marginBottom: 10, marginTop: 20 }} className="set-specific">WHEN</div>
                                                }

                                                {
                                                    templateMappingRule.templateMappingRuleClauses &&
                                                    templateMappingRule.templateMappingRuleClauses.length > 0 &&
                                                    templateMappingRule.templateMappingRuleClauses.map((clause, j) => {

                                                        return (
                                                            <React.Fragment key={j}>
                                                                {
                                                                    j >= 1 &&
                                                                    <div className="specifics-and-condition">
                                                                        <select onChange={(e) => {
                                                                            templateRuleTypeChange(i, j, "logicalOperator", e.target.value);
                                                                        }} value={clause.logicalOperator || "AND"} className="form-control specifics-select">
                                                                            <option value="AND">AND</option>
                                                                            <option value="OR">OR</option>
                                                                        </select>
                                                                    </div>
                                                                }

                                                                <div className="item-specific-condition">
                                                                    <div className="specific-col">
                                                                        <div className="condition-header">ATTRIBUTE</div>

                                                                        <CustomSelect
                                                                            height={selectHeight}
                                                                            value={clause.selectedProductAttribute ? valueFieldOptions.find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                            isDisabled={formSaving}
                                                                            error={getErrorMessage("selectedProductAttribute", i, j)}
                                                                            options={valueFieldOptions}
                                                                            onChange={(option) => {
                                                                                let value = (option && option.value) || "";
                                                                                templateRuleTypeChange(i, j, "selectedProductAttribute", value);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="specific-col">
                                                                        <div className="condition-header">CONDITION</div>

                                                                        <CustomSelect
                                                                            height={selectHeight}
                                                                            value={templateRuleTypes ? templateRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.templateRuleTypeId) : ""}
                                                                            isDisabled={formSaving}
                                                                            error={getErrorMessage("templateRuleTypeId", i, j)}
                                                                            options={templateRuleTypes}
                                                                            onChange={(option) => templateRuleTypeChange(i, j, "templateRuleTypeId", (option && option.value) || "")}
                                                                        />

                                                                    </div>

                                                                    {
                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&
                                                                        <div className="condition-header">
                                                                            <div className="set-specific">VALUE</div>
                                                                            <Form.Input
                                                                                disabled={formSaving}
                                                                                value={clause.customValue || ""}
                                                                                onChange={(e) => {
                                                                                    var value = e.target.value;
                                                                                    templateRuleTypeChange(i, j, "customValue", value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }


                                                                    <div className="remove-condition-icon-container"><i title="Remove condition" onClick={() => removeCondition(i, j)} className="fa fa-minus-circle remove-condition-icon"></i></div>

                                                                </div>
                                                            </React.Fragment>

                                                        );
                                                    })

                                                }

                                                {
                                                    !templateMappingRule.isLocked && <div onClick={() => { addCondition(i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                }


                                            </div>
                                        </div>
                                    ))
                                }

                                <div>
                                    <div onClick={() => addNewTemplateMappingRule()} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Mapping</div>
                                </div>

                                {
                                    categoryMappingColumns.length > 0 &&
                                    <div className="template-subheader">
                                        <h3>Category Mapping</h3>

                                        <div style={{ marginBottom: 10, marginTop: 10 }}>
                                            Category definitions can be applied here.
                                        </div>

                                        <div className="item-specific-rule">
                                            <div className="specific-col">
                                                <div className="set-specific">SET</div>

                                                <CustomSelect
                                                    height={selectHeight}
                                                    value={templateMappingsVm.templateColumns.map(c => ({ label: c.columnName, value: c.templateHeaderColumnId, isDisabled: lockedMappingRules && lockedMappingRules[c.templateHeaderColumnId] })).find(option => /* eslint eqeqeq: 0 */ option.value == categoryMappingRule.templateHeaderColumnId) || ""}
                                                    isDisabled={formSaving}
                                                    isClearable
                                                    options={categoryMappingColumns}
                                                    onChange={(option) => categoryMappingFieldChanged("templateHeaderColumnId", option && option.value)} />
                                            </div>

                                            <div className="specific-col">
                                                <div className="set-specific">TO PROFILE</div>
                                                <CustomSelect
                                                    isDisabled={formSaving}
                                                    value={(templateMappingsVm.categoryMappingOptions || []).find(option => parseInt(option.value) === categoryMappingRule.categoryMappingProfileId) || ""}
                                                    height={selectHeight}
                                                    isClearable
                                                    options={templateMappingsVm.categoryMappingOptions || []}
                                                    onChange={(option) => {
                                                        categoryMappingFieldChanged("categoryMappingProfileId", option && parseInt(option.value))
                                                    }} />
                                            </div>


                                        </div>

                                    </div>
                                }


                                <div className="template-subheader">
                                    <h3>Filters</h3>
                                    {
                                        Array.isArray(templateFilters) && templateFilters.length > 0 &&
                                        <h5>Only include products when...</h5>
                                    }

                                </div>

                                {
                                    Array.isArray(templateFilters) && templateFilters.length === 0 &&
                                    <div className="no-mappings">There are no filters to show.</div>
                                }

                                {
                                    templateFilters.length > 0 &&
                                    templateFilters.map((templateFilter, i) => (
                                        <div className="rule-container" key={i} id={`filter-${i}`} >
                                            <div className="mapping-header">
                                                <h4>Filter #{i + 1} <i title="Remove filter" onClick={() => removeTemplateFilter(i)} className="fa fa-minus-circle remove-mapping-icon"></i></h4>

                                            </div>
                                            <div className="specific-conditions">
                                                {
                                                    Array.isArray(templateFilter.templateFilterRuleClauses) &&
                                                    templateFilter.templateFilterRuleClauses.length > 0 &&
                                                    templateFilter.templateFilterRuleClauses.map((clause, j) => {

                                                        return (
                                                            <React.Fragment key={j}>
                                                                {
                                                                    j >= 1 &&
                                                                    <div className="specifics-and-condition">
                                                                        <select onChange={(e) => {
                                                                            templateFilterRuleTypeChange(i, j, "logicalOperator", e.target.value);
                                                                        }} value={clause.logicalOperator || "AND"} className="form-control specifics-select">
                                                                            <option value="AND">AND</option>
                                                                            <option value="OR">OR</option>
                                                                        </select>
                                                                    </div>
                                                                }

                                                                <div className="item-specific-condition">
                                                                    <div className="specific-col">
                                                                        <div className="condition-header">ATTRIBUTE</div>

                                                                        <CustomSelect
                                                                            height={selectHeight}
                                                                            value={clause.selectedProductAttribute ? valueFieldOptions.find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                            isDisabled={formSaving}
                                                                            error={getFilterErrorMessage("selectedProductAttribute", i, j)}
                                                                            options={valueFieldOptions}
                                                                            onChange={(option) => {
                                                                                let value = (option && option.value) || "";
                                                                                templateFilterRuleTypeChange(i, j, "selectedProductAttribute", value);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="specific-col">
                                                                        <div className="condition-header">CONDITION</div>

                                                                        <CustomSelect
                                                                            height={selectHeight}
                                                                            value={templateRuleTypes ? templateRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.templateRuleTypeId) : ""}
                                                                            isDisabled={formSaving}
                                                                            error={getFilterErrorMessage("templateRuleTypeId", i, j)}
                                                                            options={templateRuleTypes}
                                                                            onChange={(option) => templateFilterRuleTypeChange(i, j, "templateRuleTypeId", (option && option.value) || "")}
                                                                        />

                                                                    </div>

                                                                    {
                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&
                                                                        <div className="condition-header">
                                                                            <div className="set-specific">VALUE</div>
                                                                            <Form.Input
                                                                                disabled={formSaving}
                                                                                value={clause.customValue || ""}
                                                                                onChange={(e) => {
                                                                                    var value = e.target.value;
                                                                                    templateFilterRuleTypeChange(i, j, "customValue", value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }

                                                                    <div className="remove-condition-icon-container">{templateFilter.templateFilterRuleClauses.length > 1 && <i title="Remove condition" onClick={() => removeFilterCondition(i, j)} className="fa fa-minus-circle remove-condition-icon"></i>}</div>

                                                                </div>
                                                            </React.Fragment>

                                                        );
                                                    })

                                                }

                                                {
                                                    <div onClick={() => { addFilterCondition(i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                }
                                            </div>
                                        </div>))
                                }


                                <div>
                                    <div onClick={() => addNewTemplateFilter()} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Filter</div>
                                </div>

                                <div className="template-subheader">
                                    <h3>Special Rules</h3>
                                </div>

                                {
                                    Array.isArray(specialTemplateRules) && specialTemplateRules.length === 0 &&
                                    <div className="no-mappings">There are no special rules to show.</div>
                                }

                                {
                                    specialTemplateRules.length > 0 &&
                                    specialTemplateRules.map((specialTemplateRule, i) => (
                                        <div className="rule-container" key={i} id={`special-rule-${i}`} >
                                            <div className="mapping-header">
                                                <h4>Special Rule #{i + 1} <i title="Remove filter" onClick={() => removeSpecialTemplateRule(i)} className="fa fa-minus-circle remove-mapping-icon"></i></h4>

                                                <div className="sort-icon-wrapper">
                                                    {
                                                        <React.Fragment>
                                                            <i onClick={(e) => arrayMove(specialTemplateRules, i, i - 1, (arr) => setSpecialTemplateRules(arr), "special-rule-")} title="Move up one position. Rules at the top will be evaluated first." className={`fal fa-chevron-up sort-up${i === 0 ? " hover-ban" : ""}`}></i>
                                                            <i onClick={(e) => arrayMove(specialTemplateRules, i, i + 1, (arr) => setSpecialTemplateRules(arr), "special-rule-")} title="Move down one position. Rules at the bottom will be evaluated first." className={`fal fa-chevron-down sort-down${i === templateMappingRules.length - 1 ? " hover-ban" : ""}`}></i>
                                                        </React.Fragment>
                                                    }

                                                </div>
                                            </div>


                                            <div className="item-specific-rule">
                                                <div className="specific-col">
                                                    <div className="condition-header">RULE TYPE</div>

                                                    <CustomSelect
                                                        height={selectHeight}
                                                        value={specialRuleTypes ? specialRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == specialTemplateRule.templateSpecialRuleTypeId) : ""}
                                                        isDisabled={formSaving}
                                                        error={getSpecialTemplateRuleErrorMessage("templateSpecialRuleTypeId", i)}
                                                        options={specialRuleTypes}
                                                        onChange={(option) => specialTemplateFieldChanged(i, "templateSpecialRuleTypeId", (option && option.value) || "")}
                                                    />

                                                </div>

                                                <div className="specific-col">
                                                    <div className="condition-header">ATTRIBUTE</div>

                                                    <CustomSelect
                                                        height={selectHeight}
                                                        value={specialTemplateRule.selectedProductAttribute ? feedColumnOptions.find(option => /* eslint eqeqeq: 0 */ option.value == specialTemplateRule.selectedProductAttribute) : ""}
                                                        isDisabled={formSaving}
                                                        error={getSpecialTemplateRuleErrorMessage("selectedProductAttribute", i)}
                                                        options={feedColumnOptions}
                                                        onChange={(option) => {
                                                            let value = (option && option.value) || "";
                                                            specialTemplateFieldChanged(i, "selectedProductAttribute", value);
                                                        }}
                                                    />
                                                </div>

                                                {
                                                    specialTemplateRules[i]["templateSpecialRuleTypeId"] === specialRuleType.replaceText &&

                                                    <div className="condition-header">
                                                        <div className="set-specific">VALUE</div>
                                                        <Form.Input
                                                            disabled={formSaving}
                                                            value={specialTemplateRule.customValue || ""}
                                                            onChange={(e) => {
                                                                var value = e.target.value;
                                                                specialTemplateFieldChanged(i, "customValue", value);
                                                            }}
                                                        />
                                                    </div>

                                                }

                                            </div>


                                            <div className="specific-conditions">

                                                {
                                                    specialTemplateRule.templateSpecialRuleClauses &&
                                                    specialTemplateRule.templateSpecialRuleClauses.length > 0 &&
                                                    <div style={{ marginBottom: 10, marginTop: 20 }} className="set-specific">WHEN</div>
                                                }


                                                {
                                                    specialTemplateRule.templateSpecialRuleClauses &&
                                                    specialTemplateRule.templateSpecialRuleClauses.length > 0 &&
                                                    specialTemplateRule.templateSpecialRuleClauses.map((clause, j) => {

                                                        return (
                                                            <React.Fragment key={j}>
                                                                {
                                                                    j >= 1 &&
                                                                    <div className="specifics-and-condition">
                                                                        <select onChange={(e) => {
                                                                            specialTemplateRuleTypeChange(i, j, "logicalOperator", e.target.value);
                                                                        }} value={clause.logicalOperator || "AND"} className="form-control specifics-select">
                                                                            <option value="AND">AND</option>
                                                                            <option value="OR">OR</option>
                                                                        </select>
                                                                    </div>
                                                                }

                                                                <div className="item-specific-condition">
                                                                    <div className="specific-col">
                                                                        <div className="condition-header">ATTRIBUTE</div>

                                                                        <CustomSelect
                                                                            height={selectHeight}
                                                                            value={clause.selectedProductAttribute ? valueFieldOptions.find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                            isDisabled={formSaving}
                                                                            error={getSpecialTemplateRuleErrorMessage("selectedProductAttribute", i, j)}
                                                                            options={valueFieldOptions}
                                                                            onChange={(option) => {
                                                                                let value = (option && option.value) || "";
                                                                                specialTemplateRuleTypeChange(i, j, "selectedProductAttribute", value);
                                                                            }}
                                                                        />
                                                                    </div>

                                                                    <div className="specific-col">
                                                                        <div className="condition-header">CONDITION</div>

                                                                        <CustomSelect
                                                                            height={selectHeight}
                                                                            value={templateRuleTypes ? templateRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.templateRuleTypeId) : ""}
                                                                            isDisabled={formSaving}
                                                                            error={getSpecialTemplateRuleErrorMessage("templateRuleTypeId", i, j)}
                                                                            options={templateRuleTypes}
                                                                            onChange={(option) => specialTemplateRuleTypeChange(i, j, "templateRuleTypeId", (option && option.value) || "")}
                                                                        />

                                                                    </div>

                                                                    {
                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&
                                                                        <div className="condition-header">
                                                                            <div className="set-specific">VALUE</div>
                                                                            <Form.Input
                                                                                disabled={formSaving}
                                                                                value={clause.customValue || ""}
                                                                                onChange={(e) => {
                                                                                    var value = e.target.value;
                                                                                    specialTemplateRuleTypeChange(i, j, "customValue", value);
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    }


                                                                    <div className="remove-condition-icon-container"><i title="Remove condition" onClick={() => removeSpecialRuleCondition(i, j)} className="fa fa-minus-circle remove-condition-icon"></i></div>

                                                                </div>
                                                            </React.Fragment>

                                                        );
                                                    })

                                                }

                                                {
                                                    <div onClick={() => { addSpecialRuleCondition(i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                }


                                            </div>

                                        </div>
                                    ))
                                }

                                < div >
                                    <div onClick={() => addNewSpecialTemplateRule()} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Special Rule</div>
                                </div>

                                {
                                    shippingProfileOptions.length > 0 ?
                                        <React.Fragment>
                                            <div className="template-subheader">
                                                <h3>Shipping Rules</h3>
                                            </div>

                                            {
                                                Array.isArray(specialShippingTemplateRules) && specialShippingTemplateRules.length === 0 &&
                                                <div className="no-mappings">There are no Shipping rules to show.</div>
                                            }

                                            {
                                                specialShippingTemplateRules.length > 0 &&
                                                specialShippingTemplateRules.map((specialTemplateRule, i) => (
                                                    <div className="rule-container" key={i} id={`special-rule-${i}`} >
                                                        <div className="mapping-header">
                                                            <h4>Shipping Rule #{i + 1} <i title="Remove filter" onClick={() => removeSpecialShippingTemplateRule(i)} className="fa fa-minus-circle remove-mapping-icon"></i></h4>

                                                            <div className="sort-icon-wrapper">
                                                                {
                                                                    <React.Fragment>
                                                                        <i onClick={(e) => arrayMove(specialShippingTemplateRules, i, i - 1, (arr) => setSpecialShippingTemplateRules(arr), "special-rule-")} title="Move up one position. Rules at the top will be evaluated first." className={`fal fa-chevron-up sort-up${i === 0 ? " hover-ban" : ""}`}></i>
                                                                        <i onClick={(e) => arrayMove(specialShippingTemplateRules, i, i + 1, (arr) => setSpecialShippingTemplateRules(arr), "special-rule-")} title="Move down one position. Rules at the bottom will be evaluated first." className={`fal fa-chevron-down sort-down${i === templateMappingRules.length - 1 ? " hover-ban" : ""}`}></i>
                                                                    </React.Fragment>
                                                                }

                                                            </div>
                                                        </div>


                                                        <div className="item-specific-rule">

                                                            <div className="specific-col">
                                                                <div className="condition-header">APPLY PROFILE</div>

                                                                <CustomSelect
                                                                    height={selectHeight}
                                                                    value={specialTemplateRule.selectedProductAttribute ? shippingProfileOptions.find(option => /* eslint eqeqeq: 0 */ option.value == specialTemplateRule.selectedProductAttribute) : ""}
                                                                    isDisabled={formSaving}
                                                                    error={getSpecialShippingTemplateRuleErrorMessage("selectedProductAttribute", i)}
                                                                    options={shippingProfileOptions}
                                                                    onChange={(option) => {
                                                                        let value = (option && option.value) || "";
                                                                        specialShippingTemplateFieldChanged(i, "selectedProductAttribute", value);
                                                                    }}
                                                                />
                                                            </div>

                                                        </div>


                                                        <div className="specific-conditions">

                                                            {
                                                                specialTemplateRule.templateSpecialRuleClauses &&
                                                                specialTemplateRule.templateSpecialRuleClauses.length > 0 &&
                                                                <div style={{ marginBottom: 10, marginTop: 20 }} className="set-specific">WHEN</div>
                                                            }


                                                            {
                                                                specialTemplateRule.templateSpecialRuleClauses &&
                                                                specialTemplateRule.templateSpecialRuleClauses.length > 0 &&
                                                                specialTemplateRule.templateSpecialRuleClauses.map((clause, j) => {

                                                                    return (
                                                                        <React.Fragment key={j}>
                                                                            {
                                                                                j >= 1 &&
                                                                                <div className="specifics-and-condition">
                                                                                    <select onChange={(e) => {
                                                                                        specialShippingTemplateRuleTypeChange(i, j, "logicalOperator", e.target.value);
                                                                                    }} value={clause.logicalOperator || "AND"} className="form-control specifics-select">
                                                                                        <option value="AND">AND</option>
                                                                                        <option value="OR">OR</option>
                                                                                    </select>
                                                                                </div>
                                                                            }

                                                                            <div className="item-specific-condition">
                                                                                <div className="specific-col">
                                                                                    <div className="condition-header">ATTRIBUTE</div>

                                                                                    <CustomSelect
                                                                                        height={selectHeight}
                                                                                        value={clause.selectedProductAttribute ? valueFieldOptions.find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                                        isDisabled={formSaving}
                                                                                        error={getSpecialShippingTemplateRuleErrorMessage("selectedProductAttribute", i, j)}
                                                                                        options={valueFieldOptions}
                                                                                        onChange={(option) => {
                                                                                            let value = (option && option.value) || "";
                                                                                            specialShippingTemplateRuleTypeChange(i, j, "selectedProductAttribute", value);
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                                <div className="specific-col">
                                                                                    <div className="condition-header">CONDITION</div>

                                                                                    <CustomSelect
                                                                                        height={selectHeight}
                                                                                        value={templateRuleTypes ? templateRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.templateRuleTypeId) : ""}
                                                                                        isDisabled={formSaving}
                                                                                        error={getSpecialShippingTemplateRuleErrorMessage("templateRuleTypeId", i, j)}
                                                                                        options={templateRuleTypes}
                                                                                        onChange={(option) => specialShippingTemplateRuleTypeChange(i, j, "templateRuleTypeId", (option && option.value) || "")}
                                                                                    />

                                                                                </div>

                                                                                {
                                                                                    clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                                    clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&
                                                                                    <div className="condition-header">
                                                                                        <div className="set-specific">VALUE</div>
                                                                                        <Form.Input
                                                                                            disabled={formSaving}
                                                                                            value={clause.customValue || ""}
                                                                                            onChange={(e) => {
                                                                                                var value = e.target.value;
                                                                                                specialShippingTemplateRuleTypeChange(i, j, "customValue", value);
                                                                                            }}
                                                                                        />
                                                                                    </div>
                                                                                }


                                                                                <div className="remove-condition-icon-container"><i title="Remove condition" onClick={() => removeSpecialShippingRuleCondition(i, j)} className="fa fa-minus-circle remove-condition-icon"></i></div>

                                                                            </div>
                                                                        </React.Fragment>

                                                                    );
                                                                })

                                                            }

                                                            {
                                                                <div onClick={() => { addSpecialShippingRuleCondition(i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                            }


                                                        </div>

                                                    </div>
                                                ))
                                            }

                                            < div >
                                                <div onClick={() => addNewSpecialShippingTemplateRule()} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Shipping Rule</div>
                                            </div>
                                        </React.Fragment>
                                        : null
                                }

                            </div>

                        </Card>
                    </Grid.Col>
                </Grid.Row>
                <SaveToolbar show undoAction={() => initialise()} saveAction={() => saveTemplateMappings()} formSaving={formSaving} />
            </Page.Content>
        </SiteWrapper >
    )
}

export { EditTemplateMappings };