import * as apiCall from '../apiCall';

function removeManualItemSpecifics(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/remove_manual_item_specifics/${model.productId}/${model.manualItemSpecificId}`, model, options);
}

export { removeManualItemSpecifics };