import * as apiCall from '../apiCall';

function getShippingLocations(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/ebay/get_shipping_locations`, options);
}

export { getShippingLocations };