import * as apiCall from '../apiCall';

function getProductDetail(appId, productId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/trademe/get_product_detail?productId=${productId}`, options);
}

export { getProductDetail };