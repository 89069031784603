import * as apiCall from '../apiCall';

function getShippingMethods(appId) {

    const options = {
        appId
    };

    let url = `/api/trademe/get_shipping_methods`;
    
    return apiCall.get(url, options);
}

export { getShippingMethods };