import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    Page,
    Form
} from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { useFormik } from 'formik';
import * as marketplaceCtrl from '../../services/marketplace';
import { FormLoader } from '../FormLoader';
import { toast } from 'react-toastify';
import {
    CustomToast,
    CustomSelect,
    CustomLabel
} from '../../components';
import * as logger from '../../services/log';
import { SaveToolbar } from '../SaveToolbar';
import { appType } from '../../constants';
import "./General.scss";

const General = (props) => {

    const [loading, setLoading] = useState(true);
    const [vm, setVm] = useState(null);
    const [generalSettings, setGeneralSettings] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            let generalSettings = await marketplaceCtrl.getGeneralSettings(props.app);
            instantiateForm(generalSettings);
            setGeneralSettings(generalSettings);
            setLoading(false);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const onUndoBtnClicked = async () => {
        try {
            setLoading(true);

            let generalSettings = await marketplaceCtrl.getGeneralSettings(props.app);
            instantiateForm(generalSettings);
            setLoading(false);
        }
        catch (e) {
            logger.logError(`General onUndoBtnClicked() error`, e);
            setLoading(false);
        }
    }

    function instantiateForm(generalSettings) {

        formik.resetForm({
            values: {
                initialTemplateMappingDefinitionId: generalSettings.initialTemplateMappingDefinitionId,
                initialSecondaryTemplateMappingDefinitionId: generalSettings.initialSecondaryTemplateMappingDefinitionId,
                koganSettings: props.app === appType.kogan ? generalSettings.koganSettings : {}
            }
        });

        setVm(generalSettings);
    }

    function validate(values) {

        let errors = {};

        if (props.app === appType.kogan) {
            if (!values.koganSettings.nzEnabled && !values.koganSettings.auEnabled) {
                errors.koganMarketplaceError = "At least one marketplace must be enabled (Australia or New Zealand).";
            }
        }

        return errors;
    }

    const formik = useFormik({
        validateOnMount: false,
        onSubmit: async (values) => {

            try {


                let model = {
                    initialTemplateMappingDefinitionId: values.initialTemplateMappingDefinitionId && parseInt(values.initialTemplateMappingDefinitionId),
                    initialSecondaryTemplateMappingDefinitionId: values.initialSecondaryTemplateMappingDefinitionId && parseInt(values.initialSecondaryTemplateMappingDefinitionId),
                }

                if (props.app === appType.kogan) {
                    model.koganSettings = values.koganSettings;
                }

                await marketplaceCtrl.saveGeneralSettings(props.app, model);
                let generalSettings = await marketplaceCtrl.getGeneralSettings(props.app);
                instantiateForm(generalSettings);

                toast.success(<CustomToast message={`General settings saved.`} />, { position: toast.POSITION.TOP_RIGHT });
            }
            catch (e) {
                logger.logError(`General onSubmit() error`, e);
            }
            finally {
                formik.setSubmitting(false);
            }
        },
        validate
    });

    const getPrimaryTemplateName = () => {
        if (generalSettings.multiTemplateOptions) {
            return `Initial ${generalSettings.multiTemplateOptions.primaryTemplatePrefix} Template`;
        }
        else {
            return "Initial Template";
        }
    }

    const getSecondaryTemplateName = () => {
        return `Initial ${generalSettings.multiTemplateOptions.secondaryTemplatePrefix} Template`;
    }

    const getPrimaryTemplateTooltipMessage = () => {
        if (generalSettings.multiTemplateOptions) {
            return `Automatically assign a ${generalSettings.multiTemplateOptions.primaryTemplatePrefix} template when a product is first created.`;
        }
        else {
            return "Automatically assign a template when a product is first created.";
        }
    }

    const getSecondaryTemplateTooltipMessage = () => {
        return `Automatically assign a ${generalSettings.multiTemplateOptions.secondaryTemplatePrefix} template when a product is first created.`;
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={"General"} />);
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="marketplace-general">
                                <Card.Header>
                                    <h4>General</h4>
                                </Card.Header>
                                <div className="card-inner">
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Grid.Row>
                                            <Grid.Col width={6}>
                                                <Form.Group>
                                                    <CustomLabel id="initial-template"
                                                        name={getPrimaryTemplateName()}
                                                        message={getPrimaryTemplateTooltipMessage()} />

                                                    <CustomSelect
                                                        value={vm.templateMappingDefinitions ? vm.templateMappingDefinitions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.initialTemplateMappingDefinitionId) : ""}
                                                        isDisabled={formik.isSubmitting}
                                                        isSearchable={false}
                                                        isClearable
                                                        options={vm.templateMappingDefinitions || []}
                                                        onChange={(option) => formik.setFieldValue("initialTemplateMappingDefinitionId", (option && option.value) || "")}
                                                    />

                                                </Form.Group>

                                                {
                                                    generalSettings.multiTemplateOptions ?

                                                        <Form.Group>
                                                            <CustomLabel id="initial-secondary-template"
                                                                name={getSecondaryTemplateName()}
                                                                message={getSecondaryTemplateTooltipMessage()} />

                                                            <CustomSelect
                                                                value={vm.secondaryTemplateMappingDefinitions ? vm.secondaryTemplateMappingDefinitions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.initialTemplateMappingDefinitionId) : ""}
                                                                isDisabled={formik.isSubmitting}
                                                                isSearchable={false}
                                                                isClearable
                                                                options={vm.secondaryTemplateMappingDefinitions || []}
                                                                onChange={(option) => formik.setFieldValue("initialSecondaryTemplateMappingDefinitionId", (option && option.value) || "")}
                                                            />

                                                        </Form.Group> : null
                                                }

                                                {
                                                    props.app === appType.kogan ?
                                                        <Form.Group>
                                                            <CustomLabel id="kogan-marketplace"
                                                                name="Marketplace"
                                                                message="Select which Kogan marketplaces to support (at least one selection is required)." />

                                                            <Form.Checkbox
                                                                disabled={formik.isSubmitting}
                                                                label="Australia"
                                                                name="koganSettings.auEnabled"
                                                                error={formik.errors.koganMarketplaceError}
                                                                onChange={formik.handleChange}
                                                                checked={formik.values?.koganSettings?.auEnabled}
                                                            />

                                                            <Form.Checkbox
                                                                disabled={formik.isSubmitting}
                                                                label="New Zealand"
                                                                name="koganSettings.nzEnabled"
                                                                error={formik.errors.koganMarketplaceError}
                                                                onChange={formik.handleChange}
                                                                checked={formik.values?.koganSettings?.nzEnabled}
                                                            />

                                                            {
                                                                formik.errors.koganMarketplaceError && formik.touched ? <div style={{color: "red"}}>{formik.errors.koganMarketplaceError}</div> : null
                                                            }
                                                        </Form.Group>
                                                        : null
                                                }

                                            </Grid.Col>
                                        </Grid.Row>
                                        <SaveToolbar show undoAction={() => onUndoBtnClicked()} saveAction={() => { }} formSaving={formik.isSubmitting} />
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>

            </Page.Content>
        </SiteWrapper>

    );
}

export { General };