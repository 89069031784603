import React, { Component } from 'react';
import {
  Button,
  Loader,
  Card,
  Grid,
  Icon
} from "tabler-react";
import BootstrapTable from 'react-bootstrap-table-next';
import { ShippingMethodModal } from './ShippingMethodModal';
import * as tradeMeCtrl from '../../services/trademe';
import { InnerComponentLoader } from '../InnerComponentLoader';
import './Shipping.scss';

export class ShippingInner extends Component {
  static displayName = ShippingInner.name;

  constructor(props) {
    super(props);

    this.state = {
      modalOpen: false,
      shippingMethods: [],
      shippingMethodsLoading: true,
      defaultShippingMethodId: null,
      shippingModalSettings: { open: false },
      loading: true
    }

    this.columns = [
      {
        dataField: "name",
        text: "Name",
        formatter: (cell, row) => <React.Fragment>
          {cell} {row.isDefault && <strong>(default)</strong>}
        </React.Fragment>
      },
      {
        dataField: "manage",
        text: "",
        align: "right",
        formatter: (cell, row) => <React.Fragment>
          <Button className="edit-btn" onClick={() => this.onEditShippingPolicy(row)} size="sm" color="secondary"><Icon prefix="fa" name="edit" />&nbsp;Edit</Button>
        </React.Fragment>
      }
    ]
  }

  async componentDidMount() {
    this.refreshShippingMethods();
  }

  onNewShippingMethod = () => {
    this.setState({ shippingModalSettings: { open: true } });
  }

  onEditShippingPolicy = (shippingMethod) => {
    this.setState({ shippingModalSettings: { open: true, shippingMethod } });
  }

  refreshShippingMethods = async () => {

    this.setState({ shippingMethodsLoading: true, shippingMethods: [], loading: true });

    let shippingMethodVm = await tradeMeCtrl.getShippingMethods(this.props.app);

    this.setState({
      shippingMethods: shippingMethodVm.shippingMethods,
      defaultShippingMethodId: shippingMethodVm.defaultShippingMethodId,
      shippingMethodsLoading: false,
      loading: false
    });

  }

  render() {

    if (this.state.loading) {
      return <InnerComponentLoader title="Shipping" />
    }

    return (

      <div className="app-settings-content">
        <Card className="trademe-shipping">
          <Card.Header>

            <h4>Shipping</h4>
            <div className="add-shipping-policy-btn">
              <Button color="primary" onClick={() => this.onNewShippingMethod()}><i className="far fa-plus-circle" aria-hidden="true"></i>&nbsp;&nbsp;Add</Button>
              <div className="clear-fix"></div>
            </div>
          </Card.Header>
          <div className="card-inner">

            {
              this.state.shippingMethodsLoading &&
              <div className="table-loader-container"><Loader /></div>
            }

            {
              !this.state.shippingMethodsLoading && this.state.shippingMethods.length === 0 &&
              <div>
                There are no shipping methods to show.
              </div>
            }

            {
              !this.state.shippingMethodsLoading && this.state.shippingMethods.length > 0 &&
              <BootstrapTable
                bordered={false}
                classes="card-table table-vcenter text-nowrap"
                bootstrap4
                keyField="id"
                data={this.state.shippingMethods}
                columns={this.columns} />
            }

          </div>

          {
            this.props.installWizard &&
            <div className="next-btn">
              <Button onClick={() => this.props.loadNextStep()} type="submit" color="primary"><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
            </div>
          }
        </Card>
        {
          this.state.shippingModalSettings.open &&
          <ShippingMethodModal app={this.props.app} shippingModalSettings={this.state.shippingModalSettings} refreshShippingMethods={this.refreshShippingMethods} hideModal={() => { this.setState({ shippingModalSettings: { open: false } }) }} />
        }
      </div>

    );
  }
}