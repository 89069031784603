const categoryMappingProfileType = {
    google: 1,
    catch: 2,
    kogan: 3,
    ebayUs: 4,
    ebayCa: 5,
    ebayUk: 6,
    ebayAu: 7,
    ebayIe: 8
};

export { categoryMappingProfileType };