import React, { useState, useEffect } from 'react';
import { Grid, Card, Page, Form } from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { useFormik } from 'formik';
import * as tradeMeCtrl from '../../services/trademe';
import { pricingRulesOptionType, listingPriorityType } from '../../constants';
import { FormLoader } from '../FormLoader';
import { toast } from 'react-toastify';
import { CustomToast, CustomSelect, CustomLabel } from '../../components';
import * as logger from '../../services/log';
import "./General.scss";
import {
    SaveToolbar
} from '../SaveToolbar';
import {
    useSelector
} from "react-redux";
import {
    Link
} from "react-router-dom";

const General = (props) => {

    const [loading, setLoading] = useState(true);
    const user = useSelector(state => state.user);

    useEffect(() => {
        const fetchData = async () => {
            let generalSettings = await tradeMeCtrl.getGeneralSettings(props.app);
            instantiateForm(generalSettings);
            setLoading(false);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const onUndoBtnClicked = async () => {
        try {
            setLoading(true);

            let generalSettings = await tradeMeCtrl.getGeneralSettings(props.app);
            instantiateForm(generalSettings);

            setLoading(false);
        }
        catch (e) {
            logger.logError("onUndoBtnClicked error", e);
            setLoading(false);
        }
    }

    function instantiateForm(generalSettings) {

        formik.resetForm({
            values: {
                currencyConversionEnabled: generalSettings.currencyConversionEnabled ? "yes" : "no",
                currencyConversionUpdateFrequency: generalSettings.currencyConversionUpdateFrequency || 1,
                maxListingsEnabled: generalSettings.maxListingsEnabled ? "yes" : "no",
                fixedPriceOffersEnabled: generalSettings.fixedPriceOffersEnabled ? "yes" : "no",
                listingDuration: generalSettings.listingDuration,
                maxListings: generalSettings.maxListings,
                fixedPriceOfferDuration: generalSettings.fixedPriceOfferDuration,
                pricingRulesOptionId: generalSettings.pricingRulesOptionId.toString(),
                fixedPriceRule: generalSettings.fixedPriceRule && generalSettings.fixedPriceRule.toFixed(2),
                percentagePriceRule: generalSettings.percentagePriceRule && generalSettings.percentagePriceRule.toFixed(2),
                fpoPricingRulesOptionId: generalSettings.fpoPricingRulesOptionId.toString(),
                fpoFixedPriceRule: generalSettings.fpoFixedPriceRule && generalSettings.fpoFixedPriceRule.toFixed(2),
                fpoPercentagePriceRule: generalSettings.fpoPercentagePriceRule && generalSettings.fpoPercentagePriceRule.toFixed(2),
                initialListingPriority: generalSettings.initialListingPriority,
                queueNewListingsEnabled: generalSettings.queueNewListingsEnabled ? "yes" : "no",
                defaultMinimumStockBuffer: generalSettings.defaultMinimumStockBuffer
            }
        });
    }

    function validate(values) {

        let errors = {};

        if (values.pricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString()) {

            if (values.fixedPriceRule === null || values.fixedPriceRule === undefined || values.fixedPriceRule === "") {
                errors.fixedPriceRuleError = "Required";
            }
            else if (values.fixedPriceRule !== null && values.fixedPriceRule !== undefined && values.fixedPriceRule === 0) {
                errors.fixedPriceRuleError = "Dollar amount can't be 0";
            }

        }
        else if (values.pricingRulesOptionId === pricingRulesOptionType.percentage.toString()) {

            if (values.percentagePriceRule === null || values.percentagePriceRule === undefined || values.percentagePriceRule === "") {
                errors.percentagePriceRuleError = "Required";
            }
            if (values.percentagePriceRule && values.percentagePriceRule < -75) {
                errors.percentagePriceRuleError = "Percentage can't be less than -75";
            }
            if (values.percentagePriceRule && values.percentagePriceRule > 75) {
                errors.percentagePriceRuleError = "Percentage can't be more than 75";
            }
            if (values.percentagePriceRule !== null && values.percentagePriceRule !== undefined && values.percentagePriceRule === 0) {
                errors.percentagePriceRuleError = "Percentage can't be 0";
            }
        }

        if (values.fpoPricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString()) {

            if (values.fpoFixedPriceRule === null || values.fpoFixedPriceRule === undefined || values.fpoFixedPriceRule === "") {
                errors.fpoFixedPriceRuleError = "Required";
            }
            else if (values.fpoFixedPriceRule !== null && values.fpoFixedPriceRule !== undefined && values.fpoFixedPriceRule === 0) {
                errors.fpoFixedPriceRuleError = "Dollar amount can't be 0";
            }
            else if (values.fpoFixedPriceRule !== null && values.fpoFixedPriceRule !== undefined && values.fpoFixedPriceRule > 0) {
                errors.fpoFixedPriceRuleError = "Dollar amount must be negative (i.e. a discount)";
            }

        }
        else if (values.fpoPricingRulesOptionId === pricingRulesOptionType.percentage.toString()) {

            if (values.fpoPercentagePriceRule === null || values.fpoPercentagePriceRule === undefined || values.fpoPercentagePriceRule === "") {
                errors.fpoPercentagePriceRuleError = "Required";
            }
            if (values.fpoPercentagePriceRule && values.fpoPercentagePriceRule < -75) {
                errors.fpoPercentagePriceRuleError = "Percentage can't be less than -75";
            }
            if (values.fpoPercentagePriceRule && values.fpoPercentagePriceRule > 75) {
                errors.fpoPercentagePriceRuleError = "Percentage can't be more than 75";
            }
            if (values.fpoPercentagePriceRule !== null && values.fpoPercentagePriceRule !== undefined && values.fpoPercentagePriceRule === 0) {
                errors.fpoPercentagePriceRuleError = "Percentage can't be 0";
            }
            if (values.fpoPercentagePriceRule !== null && values.fpoPercentagePriceRule !== undefined && values.fpoPercentagePriceRule > 0) {
                errors.fpoPercentagePriceRuleError = "Percentage must be negative (i.e. a discount)";
            }
        }

        if (values.maxListingsEnabled === "yes") {

            if (values.maxListings === null || values.maxListings === undefined) {
                errors.maxListingsError = "Required";
            }
            else if (values.maxListings < 0) {
                errors.maxListingsError = "Please enter a value greater than or equal to 0";
            }

        }

        if (values.fixedPriceOffersEnabled === "yes") {
            if (values.fixedPriceOfferDuration === null || values.fixedPriceOfferDuration === undefined) {
                errors.fixedPriceOfferDurationError = "Required";
            }
        }

        if (values.defaultMinimumStockBuffer === null || values.defaultMinimumStockBuffer === undefined || values.defaultMinimumStockBuffer === "") {
            errors.defaultMinimumStockBufferError = "Required";
        }
        else if (parseInt(values.defaultMinimumStockBuffer) < 1) {
            errors.defaultMinimumStockBufferError = "Value must be more than or equal to 1";
        }

        return errors;
    }

    const formik = useFormik({
        validateOnMount: false,
        onSubmit: async (values) => {

            try {
                let vm = {
                    currencyConversionEnabled: values.currencyConversionEnabled === "yes",
                    currencyConversionUpdateFrequency: values.currencyConversionUpdateFrequency,
                    maxListingsEnabled: values.maxListingsEnabled === "yes",
                    maxListings: ((values.maxListings || values.maxListings === 0) && parseInt(values.maxListings)) || null,
                    pricingRulesOptionId: values.pricingRulesOptionId && parseInt(values.pricingRulesOptionId),
                    fixedPriceRule: values.fixedPriceRule && parseFloat(values.fixedPriceRule),
                    percentagePriceRule: values.percentagePriceRule && parseFloat(values.percentagePriceRule),
                    fpoPricingRulesOptionId: values.fpoPricingRulesOptionId && parseInt(values.fpoPricingRulesOptionId),
                    fpoFixedPriceRule: values.fpoFixedPriceRule && parseFloat(values.fpoFixedPriceRule),
                    fpoPercentagePriceRule: values.fpoPercentagePriceRule && parseFloat(values.fpoPercentagePriceRule),
                    listingDuration: parseInt(values.listingDuration),
                    fixedPriceOffersEnabled: values.fixedPriceOffersEnabled === "yes",
                    fixedPriceOfferDuration: values.fixedPriceOfferDuration && parseInt(values.fixedPriceOfferDuration),
                    initialListingPriority: values.initialListingPriority && parseInt(values.initialListingPriority),
                    queueNewListingsEnabled: values.queueNewListingsEnabled === "yes",
                    defaultMinimumStockBuffer: parseInt(values.defaultMinimumStockBuffer)
                }

                await tradeMeCtrl.saveGeneralSettings(props.app, vm);
                let generalSettings = await tradeMeCtrl.getGeneralSettings(props.app);
                instantiateForm(generalSettings);

                toast.success(<CustomToast message={`General settings saved.`} />, { position: toast.POSITION.TOP_RIGHT });
            }
            catch (e) {
                logger.logError("General onSubmit() error", e);
            }
            finally {
                formik.setSubmitting(false);
            }
        },
        validate
    });

    const fixedPriceOfferDurationOptions = [
        { label: "1", value: "1" },
        { label: "3", value: "3" },
        { label: "7", value: "7" }
    ];

    const listingDurationOptions = [
        { label: "2", value: "2" },
        { label: "3", value: "3" },
        { label: "4", value: "4" },
        { label: "5", value: "5" },
        { label: "6", value: "6" },
        { label: "7", value: "7" }
    ];

    const listingPriorityOptions = [
        {
            label: "Don't List",
            value: listingPriorityType.DontList
        },
        {
            label: "Low",
            value: listingPriorityType.Low
        },
        {
            label: "Normal",
            value: listingPriorityType.Normal
        },
        {
            label: "High",
            value: listingPriorityType.High
        }
    ];

    if (loading) {
        return (<FormLoader {...props} formTitle={"General"} />);
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="trademe-general">
                                <Card.Header>
                                    <h4>General</h4>
                                </Card.Header>
                                <div className="card-inner">
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Grid.Row>
                                            <Grid.Col width={6}>

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="listing-duration"
                                                        name="Listing duration (days)"
                                                        message="Mandatory. The duration of each listing." />

                                                    <CustomSelect
                                                        isClearable={false}
                                                        value={listingDurationOptions ? listingDurationOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.listingDuration) : "7"}
                                                        isDisabled={formik.isSubmitting}
                                                        options={listingDurationOptions}
                                                        isSearchable={false}
                                                        onChange={(option) => formik.setFieldValue("listingDuration", (option && option.value) || "7")}
                                                    />
                                                </Form.Group>

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="fixed-price-offer-duration"
                                                        name="Enable fixed price offers"
                                                        message="Recommended. When enabled, PolyCommerce will send out fixed price offers to all members or guests watching the listing before relisting the product. If the product becomes out of stock while the fixed price offer is active, PolyCommerce will attempt to withdraw the offer from TradeMe." />

                                                    <Form.Radio
                                                        isInline
                                                        label="Yes"
                                                        name="fixedPriceOffersEnabled"
                                                        value="yes"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("fixedPriceOffersEnabled", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.fixedPriceOffersEnabled === "yes"}
                                                    />
                                                    <Form.Radio
                                                        isInline
                                                        label="No"
                                                        name="fixedPriceOffersEnabled"
                                                        value="no"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("fixedPriceOffersEnabled", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.fixedPriceOffersEnabled === "no"}
                                                    />
                                                </Form.Group>

                                                {
                                                    formik.values.fixedPriceOffersEnabled === "yes" ?
                                                        <React.Fragment>
                                                            <Form.Group className="indent" label="Fixed price offer duration (days)">

                                                                <CustomSelect
                                                                    isClearable={false}
                                                                    value={fixedPriceOfferDurationOptions ? fixedPriceOfferDurationOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.fixedPriceOfferDuration) : "3"}
                                                                    isDisabled={formik.isSubmitting}
                                                                    error={formik.errors.fixedPriceOfferDurationError}
                                                                    options={fixedPriceOfferDurationOptions}
                                                                    isSearchable={false}
                                                                    onChange={(option) => formik.setFieldValue("fixedPriceOfferDuration", (option && option.value) || "3")}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group className="indent">
                                                                <CustomLabel id="offer-pricing-rule"
                                                                    name="Fixed price offer pricing rule"
                                                                    message="When enabled, pricing rule will be applied to all fixed price offers that are sent out. Only negative values are accepted." />
                                                                <div className="block-radio">
                                                                    <Form.Radio
                                                                        label="None"
                                                                        name="fpoPricingRulesOptionId"
                                                                        value={pricingRulesOptionType.none}
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        disabled={formik.isSubmitting}
                                                                        checked={formik.values.fpoPricingRulesOptionId === pricingRulesOptionType.none.toString()}
                                                                    />
                                                                </div>

                                                                <div className="block-radio">
                                                                    <Form.Radio
                                                                        label="Subtract dollar amount"
                                                                        name="fpoPricingRulesOptionId"
                                                                        value={pricingRulesOptionType.fixedPrice}
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        disabled={formik.isSubmitting}
                                                                        checked={formik.values.fpoPricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString()}
                                                                    />
                                                                </div>

                                                                <div className="block-radio">
                                                                    <Form.Radio
                                                                        label="Subtract percentage"
                                                                        name="fpoPricingRulesOptionId"
                                                                        value={pricingRulesOptionType.percentage}
                                                                        onChange={(e) => {
                                                                            formik.handleChange(e);
                                                                        }}
                                                                        disabled={formik.isSubmitting}
                                                                        checked={formik.values.fpoPricingRulesOptionId === pricingRulesOptionType.percentage.toString()}
                                                                    />
                                                                </div>

                                                            </Form.Group>

                                                            {
                                                                formik.values.fpoPricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString() ?
                                                                    <Form.Group className="indent" label="Dollar amount">
                                                                        <Form.InputGroup>
                                                                            <Form.InputGroupPrepend>
                                                                                <Form.InputGroupText>$</Form.InputGroupText>
                                                                            </Form.InputGroupPrepend>
                                                                            <Form.Input
                                                                                name="fpoFixedPriceRule"
                                                                                error={formik.touched.fpoFixedPriceRule && formik.errors.fpoFixedPriceRuleError}
                                                                                disabled={formik.isSubmitting}
                                                                                value={formik.values.fpoFixedPriceRule}
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={formik.handleChange}
                                                                                type="number" />
                                                                        </Form.InputGroup>
                                                                    </Form.Group> : null
                                                            }

                                                            {
                                                                formik.values.fpoPricingRulesOptionId === pricingRulesOptionType.percentage.toString() ?
                                                                    <Form.Group className="indent" label="Percentage value">
                                                                        <Form.InputGroup>
                                                                            <Form.InputGroupPrepend>
                                                                                <Form.InputGroupText>%</Form.InputGroupText>
                                                                            </Form.InputGroupPrepend>
                                                                            <Form.Input
                                                                                name="fpoPercentagePriceRule"
                                                                                error={formik.touched.fpoPercentagePriceRule && formik.errors.fpoPercentagePriceRuleError}
                                                                                disabled={formik.isSubmitting}
                                                                                value={formik.values.fpoPercentagePriceRule}
                                                                                onBlur={formik.handleBlur}
                                                                                onChange={formik.handleChange}
                                                                                type="number" />
                                                                        </Form.InputGroup>

                                                                    </Form.Group> : null
                                                            }

                                                        </React.Fragment> : null
                                                }

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="listings-limit"
                                                        name="Enable listings limit"
                                                        message="When enabled, PolyCommerce will ensure that the number of live listings does not exceed 'Max listings' setting. This setting can be useful to control high volume listing fees. Check your TradeMe account details for more information on listing fees." />

                                                    <Form.Radio
                                                        isInline
                                                        label="Yes"
                                                        name="maxListingsEnabled"
                                                        value="yes"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("maxListings", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.maxListingsEnabled === "yes"}
                                                    />
                                                    <Form.Radio
                                                        isInline
                                                        label="No"
                                                        name="maxListingsEnabled"
                                                        value="no"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("maxListings", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.maxListingsEnabled === "no"}
                                                    />
                                                </Form.Group>

                                                {
                                                    formik.values.maxListingsEnabled === "yes" ?
                                                        <Form.Group className="form-group indent" label="Max listings">
                                                            <Form.Input
                                                                name="maxListings"
                                                                disabled={formik.isSubmitting}
                                                                error={formik.touched.maxListings && formik.errors.maxListingsError}
                                                                type="number"
                                                                value={formik.values.maxListings}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange} />
                                                        </Form.Group>
                                                        : null
                                                }

                                                {
                                                    user.info.baseCurrency !== "NZD" ?
                                                        <React.Fragment>
                                                            <Form.Group>
                                                                <CustomLabel id="currency-conversion"
                                                                    name="Enable Currency Conversion"
                                                                    message="When enabled, product prices will be converted from the store base currency to the target marketplace currency." />

                                                                <div style={{ fontSize: "0.9em" }}>Conversions applied from <strong style={{ textDecoration: "underline" }}>{user.info.baseCurrency}</strong> to <strong style={{ textDecoration: "underline" }}>NZD</strong></div>
                                                                <div style={{ marginBottom: 15 }}>
                                                                    <Link style={{ fontSize: "0.9em" }} to="/settings/currency">Latest exchange rates we use</Link>
                                                                </div>

                                                                <Form.Radio
                                                                    isInline
                                                                    label="Yes"
                                                                    name="currencyConversionEnabled"
                                                                    value="yes"
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue("currencyConversionEnabled", null, false);
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    disabled={formik.isSubmitting}
                                                                    checked={formik.values.currencyConversionEnabled === "yes"}
                                                                />
                                                                <Form.Radio
                                                                    isInline
                                                                    label="No"
                                                                    name="currencyConversionEnabled"
                                                                    value="no"
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue("currencyConversionEnabled", null, false);
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    disabled={formik.isSubmitting}
                                                                    checked={formik.values.currencyConversionEnabled === "no"}
                                                                />
                                                            </Form.Group>

                                                            {
                                                                formik.values.currencyConversionEnabled === "yes" ?
                                                                    <Form.Group className="form-group indent">
                                                                        <CustomLabel id="global-pricing-rule"
                                                                            name="Update Frequency (days)"
                                                                            message="Determines how frequently prices should be updated." />
                                                                        <Form.Input
                                                                            name="currencyConversionUpdateFrequency"
                                                                            disabled={formik.isSubmitting}
                                                                            error={formik.touched.currencyConversionUpdateFrequency && formik.errors.currencyConversionUpdateFrequency}
                                                                            type="number"
                                                                            min="1"
                                                                            value={formik.values.currencyConversionUpdateFrequency}
                                                                            onBlur={formik.handleBlur}
                                                                            onChange={formik.handleChange} />
                                                                    </Form.Group>
                                                                    : null
                                                            }
                                                        </React.Fragment> : null
                                                }


                                                <Form.Group>
                                                    <CustomLabel id="global-pricing-rule"
                                                        name="Global pricing rule"
                                                        message="When enabled, pricing rule will apply to all products. To increase prices, use a positive number. To decrease prices, use a negative number." />
                                                    <div className="block-radio">
                                                        <Form.Radio
                                                            label="None"
                                                            name="pricingRulesOptionId"
                                                            value={pricingRulesOptionType.none}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            disabled={formik.isSubmitting}
                                                            checked={formik.values.pricingRulesOptionId === pricingRulesOptionType.none.toString()}
                                                        />
                                                    </div>

                                                    <div className="block-radio">
                                                        <Form.Radio
                                                            label="Add/subtract dollar amount"
                                                            name="pricingRulesOptionId"
                                                            value={pricingRulesOptionType.fixedPrice}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            disabled={formik.isSubmitting}
                                                            checked={formik.values.pricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString()}
                                                        />
                                                    </div>

                                                    <div className="block-radio">
                                                        <Form.Radio
                                                            label="Add/subtract percentage"
                                                            name="pricingRulesOptionId"
                                                            value={pricingRulesOptionType.percentage}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            disabled={formik.isSubmitting}
                                                            checked={formik.values.pricingRulesOptionId === pricingRulesOptionType.percentage.toString()}
                                                        />
                                                    </div>

                                                </Form.Group>

                                                {
                                                    formik.values.pricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString() ?
                                                        <Form.Group className="indent" label="Dollar amount">
                                                            <Form.InputGroup>
                                                                <Form.InputGroupPrepend>
                                                                    <Form.InputGroupText>$</Form.InputGroupText>
                                                                </Form.InputGroupPrepend>
                                                                <Form.Input
                                                                    name="fixedPriceRule"
                                                                    error={formik.touched.fixedPriceRule && formik.errors.fixedPriceRuleError}
                                                                    disabled={formik.isSubmitting}
                                                                    value={formik.values.fixedPriceRule}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    type="number" />
                                                            </Form.InputGroup>
                                                        </Form.Group> : null
                                                }

                                                {
                                                    formik.values.pricingRulesOptionId === pricingRulesOptionType.percentage.toString() ?
                                                        <Form.Group className="indent" label="Percentage value">
                                                            <Form.InputGroup>
                                                                <Form.InputGroupPrepend>
                                                                    <Form.InputGroupText>%</Form.InputGroupText>
                                                                </Form.InputGroupPrepend>
                                                                <Form.Input
                                                                    name="percentagePriceRule"
                                                                    error={formik.touched.percentagePriceRule && formik.errors.percentagePriceRuleError}
                                                                    disabled={formik.isSubmitting}
                                                                    value={formik.values.percentagePriceRule}
                                                                    onBlur={formik.handleBlur}
                                                                    onChange={formik.handleChange}
                                                                    type="number" />
                                                            </Form.InputGroup>

                                                        </Form.Group> : null
                                                }

                                                <Form.Group>
                                                    <CustomLabel id="initial-listing-priority"
                                                        name="Initial Listing Priority"
                                                        message="Defines the initial listing priority when a product is created. Setting can be useful if you want to prevent listings from automatically being listed (by using 'Don't list option)." />

                                                    <CustomSelect
                                                        value={listingPriorityOptions ? listingPriorityOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.initialListingPriority) : ""}
                                                        isDisabled={formik.isSubmitting}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        options={listingPriorityOptions}
                                                        onChange={(option) => formik.setFieldValue("initialListingPriority", (option && option.value) || "")}
                                                    />

                                                </Form.Group>

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="queue-new-listings"
                                                        name="Queue New Listings"
                                                        message="When enabled, listing creation and relist operations will be paused until all active listings have ended. This strategy can be used to save on high volume listing fees. Only listing edits and withdrawals will work as they don't attract HVL fees. The disadvantage of having this setting enabled is that all auctions must expire before listings can be created. Some listings (the first to expire) will be offline for a small period of time while waiting for all auctions to come off." />
                                                    <div style={{ color: "red", marginBottom: 10, fontSize: "0.8em" }}>Warning: Please understand how this setting works before enabling. Listing create and relist operations will be postponed until all auctions expire (when there are 0 active listings) as a strategy to avoid 'High Volume Listing' fees.</div>
                                                    <Form.Radio
                                                        isInline
                                                        label="Yes"
                                                        name="queueNewListingsEnabled"
                                                        value="yes"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("queueNewListingsEnabled", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.queueNewListingsEnabled === "yes"}
                                                    />
                                                    <Form.Radio
                                                        isInline
                                                        label="No"
                                                        name="queueNewListingsEnabled"
                                                        value="no"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("queueNewListingsEnabled", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.queueNewListingsEnabled === "no"}
                                                    />
                                                </Form.Group>

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="default-minimum-stock-buffer"
                                                        name="Default Minimum Stock Buffer"
                                                        message="Defines the default minimum inventory level for all products. If the inventory level for an active product falls below this value, the product will be withdrawn from TradeMe." />

                                                    <Form.Input
                                                        name="defaultMinimumStockBuffer"
                                                        type="number"
                                                        onChange={formik.handleChange}
                                                        disabled={formik.isSubmitting}
                                                        value={formik.values.defaultMinimumStockBuffer}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.defaultMinimumStockBuffer && formik.errors.defaultMinimumStockBufferError}
                                                    />
                                                </Form.Group>

                                            </Grid.Col>
                                        </Grid.Row>

                                        <SaveToolbar show undoAction={() => onUndoBtnClicked()} saveAction={() => { }} formSaving={formik.isSubmitting} />
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>

    );
}

export { General };