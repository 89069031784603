import { syncStatus } from '../../constants';

const syncStatusRenderer = (params) => {

    params.value = parseInt(params.value);

    let elem = document.createElement("i");

    if (params.value === syncStatus.queuedListing) {
        elem.classList.add("fas");
        elem.classList.add("fa-hourglass-half");
        elem.title = "Listing queued";
    }
    else if (params.value === syncStatus.syncPending) {
        elem.classList.add("fas");
        elem.classList.add("fa-clock");
        elem.title = "Sync pending";
    }
    else if (params.value === syncStatus.completed) {
        elem.classList.add("fas");

        elem.classList.add("fa-flag-checkered");
        elem.title = "Sync complete";
    }

    elem.classList.add("sync-pending-icon");

    return elem;
}

export { syncStatusRenderer };