import React, { useEffect, useState } from 'react';
import * as dashboardCtrl from '../../services/dashboard';
import {
    Widget,
    WidgetBody,
    WidgetHeader
} from './';
import moment from 'moment-timezone';
import {
    Link
} from 'react-router-dom';
import * as chartUtility from '../../utilities/chartUtility';
import {
    WidgetChartRefresher
} from '../WidgetChartRefresher';

const AmazonWidget = ({ userWidget }) => {
    const [loading, setLoading] = useState(true);
    const [amazonData, setAmazonData] = useState({});

    useEffect(() => {

        refreshWidget();

        let widgetRefreshId = 'widgetRefresh';

        let widgetRefreshEvent = window.addEventListener(widgetRefreshId, (e) => {
            refreshWidget();
        });

        return () => {
            window.removeEventListener(widgetRefreshId, widgetRefreshEvent);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshWidget = async () => {

        setLoading(true);
        var tz = moment.tz.guess();
        let encodedTz = encodeURIComponent(tz);

        let amazonWidget = await dashboardCtrl.getAmazonWidget(encodedTz, userWidget.widget.appId);

        setAmazonData(amazonWidget);

        setLoading(false);

        chartUtility.bindChart(amazonWidget, `#${amazonWidget.chartWrapperId}`);
    }

    return (<Widget loading={loading} userWidget={userWidget}>
        <WidgetHeader userWidget={userWidget} refreshWidget={refreshWidget} />
        <WidgetBody loading={loading}>

            <WidgetChartRefresher widgetData={amazonData} />

            {
                !loading ?
                    <div>
                        <div className="row-outer">
                            <div className="row-inner row-border">
                                <div className="row-name">
                                    Active
                                </div>
                                <div className="row-value bold">
                                    {
                                        amazonData.listed === 0 && amazonData.listed
                                    }
                                    {
                                        amazonData.listed > 0 && <Link className="active-link" to={`/marketplaces/${amazonData.slug}/products?filterByActive=true`}>{amazonData.listed}</Link>
                                    }

                                </div>
                            </div>
                        </div>
                        <div className="row-outer">
                            <div className="row-inner">
                                <div className="row-name">
                                    Errors
                                </div>
                                <div className="row-value bold">
                                    {
                                        amazonData.listingsWithErrors === 0 && amazonData.listingsWithErrors
                                    }
                                    {
                                        amazonData.listingsWithErrors > 0 && <Link className="errors-link" to={`/marketplaces/${amazonData.slug}/products?filterByErrors=true`}>{amazonData.listingsWithErrors}</Link>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row-outer">
                            <div className="row-inner">
                                <div className="row-name">
                                    Sync Pending
                                </div>
                                <div className="row-value bold">
                                    {
                                        amazonData.needToSyncCount === 0 && amazonData.needToSyncCount
                                    }
                                    {
                                        amazonData.needToSyncCount > 0 && <Link className="misc-link" to={`/marketplaces/${amazonData.slug}/products/?filterByPendingSync=true`}>{amazonData.needToSyncCount}</Link>
                                    }
                                </div>
                            </div>
                        </div>

                        <div className="sales-inner">
                            <div className="chart-wrapper" id={amazonData.chartWrapperId}></div>

                            <div className="sales-summary-outer sales-summary-border">
                                <div className="sales-summary">
                                    <div className="period-name">
                                        Today
                                    </div>
                                    <div className="period-value">
                                        ${amazonData.lastDayTotalSales.toFixed(2)} ({amazonData.lastDayTotalUnits})
                                    </div>
                                </div>
                            </div>

                            <div className="sales-summary-outer sales-summary-border">
                                <div className="sales-summary">
                                    <div className="period-name">
                                        Last 7 Days
                                    </div>
                                    <div className="period-value">
                                        ${amazonData.last7DaysTotalSales.toFixed(2)} ({amazonData.last7DaysTotalUnits})
                                    </div>
                                </div>
                            </div>

                            <div className="sales-summary-outer sales-summary-border">
                                <div className="sales-summary">
                                    <div className="period-name">
                                        Last 30 Days
                                    </div>
                                    <div className="period-value">
                                        ${amazonData.last30DaysTotalSales.toFixed(2)} ({amazonData.last30DaysTotalUnits})
                                    </div>
                                </div>
                            </div>

                            <div className="sales-summary-outer">

                                <div className="sales-summary">
                                    <div className="period-name">
                                        Last 90 Days
                                    </div>
                                    <div className="period-value">
                                        ${amazonData.last90DaysTotalSales.toFixed(2)} ({amazonData.last90DaysTotalUnits})
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div> : null
            }
        </WidgetBody>
    </Widget>);

}

export { AmazonWidget };