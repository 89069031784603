import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Icon, Loader, Button } from 'tabler-react';
import * as tradeMeCtrl from '../services/trademe';
import * as ebayCtrl from '../services/ebay';
import * as marketplaceCtrl from '../services/marketplace';
import { appType, productErrorMessageType } from '../constants';
import moment from 'moment';
import { toast } from 'react-toastify';
import { CustomToast } from './';
import * as logger from '../services/log';
import * as appUtility from '../utilities/appUtility';
import './CustomModal.css';
import "./ProductErrorModal.scss";

const ProductErrorModal = (props) => {

    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {

        const fetchData = async () => {

            let errors = [];

            if (appUtility.isTradeMeApp(props.app)) {
                errors = await tradeMeCtrl.getProductErrors(appType.tradeMe, props.product.id);
            }
            else if (appUtility.isEbayApp(props.app)) {
                errors = await ebayCtrl.getProductErrors(props.app, props.product.id);
            }
            else if (appUtility.isAmazonApp(props.app)) {
                errors = await marketplaceCtrl.getProductErrors(props.app, props.product.id);
            }
            else if (props.app === appType.catch) {
                errors = await marketplaceCtrl.getProductErrors(props.app, props.product.id);
            }
            else if (props.app === appType.kogan) {
                errors = await marketplaceCtrl.getProductErrors(props.app, props.product.id);
            }
            else {
                logger.logError(`ProductErrorModal - App '${props.app}' not recognised.`);
                errors = [];
            }

            errors = errors.filter(e => e.productErrorMessageTypeId === props.category);

            setErrors(errors);
            setLoading(false);
        }

        fetchData();

    }, [props.app, props.category, props.product.id]);

    function closeModal() {
        props.hideModal();
    }

    function getCategoryColor() {
        return props.category === productErrorMessageType.warning ? "#f1c40f" : "#cd201f";
    }

    async function forceSync() {

        try {
            setLoading(true);
            let model = {
                productIds: [props.product.id]
            }

            if (appUtility.isEbayApp(props.app)) {
                await ebayCtrl.forceRetry(props.app, model);
            }
            else if (appUtility.isAmazonApp(props.app)) {
                await marketplaceCtrl.forceRetry(props.app, model);
            }
            else if (props.app === appType.catch) {
                await marketplaceCtrl.forceRetry(props.app, model);
            }
            else if (props.app === appType.kogan) {
                await marketplaceCtrl.forceRetry(props.app, model);
            }
            else if (appUtility.isTradeMeApp(props.app)) {
                await tradeMeCtrl.forceRetry(appType.tradeMe, model);
            }
            else {
                logger.logError(`App '${props.app}' not recognised.`);
                toast.error(<CustomToast message={`Operation failed. Please contact support.`} />, { position: toast.POSITION.TOP_RIGHT })

                return;
            }

            closeModal();
            props.refreshProducts();
            toast.success(<CustomToast message={`Product queued for processing.`} />, { position: toast.POSITION.TOP_RIGHT });
            setLoading(false);
        }
        catch (e) {
            setLoading(false);
        }
    }

    return (
        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h3 className="category-header" style={{ textAlign: "center" }}>{props.productTitle}</h3>
            <hr className="divider"></hr>

            <div className="product-error-modal-content">

                {
                    loading && <div className="loader-container"><Loader /></div>
                }

                {
                    !loading && (!errors || errors.length === 0) &&
                    <div style={{ marginLeft: 10, marginRight: 10 }}>Errors could not be retrieved for this product. Please try refreshing the products grid to see if the error has already been resolved.</div>
                }

                {
                    !loading && errors && errors.length > 0 && errors.map((error) => {

                        let createdDate = moment.utc(error.createdDate).local().format("dddd, MMMM Do YYYY hh:mm A");

                        return (
                            <div key={error.id} style={{ borderLeft: `solid 3px ${getCategoryColor()}` }} className="error-container">
                                <div style={{ color: getCategoryColor() }} className={"error-date"}>{createdDate}</div>
                                <div style={{ color: getCategoryColor() }} className="error-category">
                                    {
                                        error.productErrorMessageTypeId === productErrorMessageType.warning ? "Warning" : "Error"
                                    }
                                </div>
                                <div className="error-text">
                                 {error.errorId && <React.Fragment><strong>{error.errorId}</strong> - </React.Fragment>}{error.errorMessage}
                                </div>
                            </div>
                        )
                    })
                }

                <div className="product-error-modal-footer">
                    <Button disabled={loading || (!errors || errors.length === 0)} onClick={() => forceSync()} color="primary"><i className="fal fa-sync-alt"></i>&nbsp;&nbsp;Force Sync</Button>
                    <Button onClick={closeModal}><i className="fal fa-times-circle"></i>&nbsp;&nbsp;Close</Button>
                </div>

            </div>



        </Modal>
    )
}

export { ProductErrorModal };