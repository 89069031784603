import { connect } from 'react-redux';
import React, { useEffect } from 'react';
import { 
    Button, 
    Form, 
    Card 
} from "tabler-react";
import { useSelector } from "react-redux";
import { customConfirmAlert } from '../CustomConfirmAlert';
import { Link } from 'react-router-dom';
import ReactTooltip from 'react-tooltip';
import * as logger from '../../services/log';
import { useFormik } from 'formik';
import * as marketplaceCtrl from '../../services/marketplace';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components';

const ManualItemAsin = ({product, app}) => {

    const user = useSelector(state => state.user);

    useEffect(() => {
        formik.resetForm({
            values: {
                asin: product.asin
            }
        });
    }, []);

    const validate = (values) => {
        let errors = {};

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            asin: ""
        },
        validate,
        onSubmit: async (values) => {
            try {

                let model = {
                    asin: values.asin,
                    productId: product.id,
                    isVariation: false
                };

                await marketplaceCtrl.saveAsin(app, model);

                toast.success(<CustomToast message={`ASIN updated.`} />, { position: toast.POSITION.TOP_RIGHT });

            }
            catch (e) {
                logger.logError(`ASIN onSubmit() error`, e);
            }
            finally {
                formik.setSubmitting(false);
            }
        }
    });

    return (<Card>
        <Card.Header>
            <h4>ASIN</h4>
            <div className="asin-tooltip-wrapper">
                <i data-tip data-for="asin-tooltip" className="fa fa-info-circle"></i>
                <ReactTooltip place="top" id="asin-tooltip" effect="solid">
                    <div className="tooltip-message">
                        Opt for matching by ASIN when there are issues matching a product on Amazon. Assigning an ASIN to an Amazon product eliminates ambiguity when multiple products on Amazon have the same identifier (UPC/EAN/GTIN/ISBN).
                    </div>
                </ReactTooltip>
            </div>
        </Card.Header>

        <Card.Body>
            <div style={{ width: 300 }}>

                <Form.Input
                    name="asin"
                    error={formik.errors.asinError}
                    disabled={formik.isSubmitting}
                    value={formik.values.asin}
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange} />
                <div style={{ marginTop: 10 }}>
                    <Button onClick={formik.handleSubmit} type="submit" loading={formik.isSubmitting} color="primary">Save</Button>
                </div>

            </div>
        </Card.Body>
    </Card>)
}


const mapStateToProps = (state) => {
    const { user } = state;
    return { user };
}

let wrapper = connect(mapStateToProps)(ManualItemAsin);
export { wrapper as ManualItemAsin };