import React, { Component } from 'react';
import { Form, Grid, Card, Button, Alert, Icon } from 'tabler-react';
import { Formik } from 'formik';
import * as ebayCtrl from '../../services/ebay';
import { toast } from 'react-toastify';
import { CustomToast, CustomSelect } from '../../components';
import { CustomLabel } from '../CustomLabel';
import { InnerComponentLoader } from '../InnerComponentLoader';
import * as logger from '../../services/log';
import { SaveToolbar } from '../SaveToolbar';
import './Returns.scss';

export class ReturnsInner extends Component {
  static displayName = ReturnsInner.name;

  state = {
    loading: true,
    returnPolicy: null
  }

  returnCostsPaidByOptions = [
    { label: "Buyer", value: "BUYER" },
    { label: "Seller", value: "SELLER" }
  ]

  returnWithinOptions = [
    { label: "30", value: "30" },
    { label: "60", value: "60" }
  ]

  async componentDidMount() {
    let returnPolicy = await ebayCtrl.getReturnPolicy(this.props.app);
    this.setState({ loading: false, returnPolicy });
  }

  onUndoBtnClicked = async () => {
    try {
      this.setState({ loading: true });
      let returnPolicy = await ebayCtrl.getReturnPolicy(this.props.app);
      this.setState({ loading: false, returnPolicy });
    }
    catch (e) {
      logger.logError("Ebay Returns onUndoBtnClicked() error", e);
      this.setState({ loading: false });
    }
  }

  render() {

    if (this.state.loading) {
      return (<InnerComponentLoader title="Return Policy" />);
    }

    return (

      <div className="app-settings-content">
        <Card className="returns">
          <Card.Header>

            <h4>Return Policy</h4>
          </Card.Header>

          <div className="card-inner">
            {
              this.state.returnPolicy && this.state.returnPolicy.returnPolicyRequired && !this.props.installWizard &&
              <Alert type="danger">
                Return information not found on this Ebay account. Please update Returns information.
              </Alert>
            }

            <Formik
              initialValues={{
                returnsAccepted: (this.state.returnPolicy && this.state.returnPolicy.returnsAccepted && "yes") || (this.state.returnPolicy && this.state.returnPolicy.returnsAccepted === false && "no") || "yes",
                returnCostsPaidBy: (this.state.returnPolicy && this.state.returnPolicy.returnShippingCostPayer) || "BUYER",
                returnWithin: (this.state.returnPolicy && this.state.returnPolicy.returnPeriod && this.state.returnPolicy.returnPeriod.value && this.state.returnPolicy.returnPeriod.value.toString()) || "30"
              }}
              validate={(values, test) => {

                let errors = {};

                if (values.returnWithin === "") {
                  errors.returnWithin = "Required";
                }

                if (values.returnCostsPaidBy === "") {
                  errors.returnCostsPaidBy = "Required";
                }

                return errors;
              }}
              onSubmit={async (values, { setSubmitting, setErrors, setFieldValue }) => {
                try {

                  let returnsPolicy;
                  if (values.returnsAccepted === "yes") {

                    returnsPolicy = {
                      returnsAccepted: true,
                      returnPeriod: {
                        unit: "DAY",
                        value: parseInt(values.returnWithin)
                      },
                      returnShippingCostPayer: values.returnCostsPaidBy
                    }
                  }
                  else {
                    returnsPolicy = {
                      returnsAccepted: false,
                    }
                  }

                  await ebayCtrl.saveReturnPolicy(this.props.app, returnsPolicy);

                  this.setState((prevState) => {
                    let returnPolicy = { ...prevState.returnPolicy };
                    returnPolicy.returnPolicyRequired = false;
                    return {
                      returnPolicy
                    }
                  });

                  toast.success(<CustomToast message={`Return policy saved.`} />, { position: toast.POSITION.TOP_RIGHT });
                  setSubmitting(false);

                  if (this.props.installWizard) {
                    this.props.loadNextStep();
                  }
                }
                catch (e) {
                  setSubmitting(false);
                }
              }}>

              {
                props => <Form id="returns-form" onSubmit={props.handleSubmit}>
                  <Grid.Row>
                    <Grid.Col width={9}>
                      <Form.Group>
                        <CustomLabel name="Returns accepted" id="returns-accepted-tooltip" message="It's highly recommended to enable returns. Many categories in eBay require a return policy to be set with 30 days or more. It's also possible that products will rank better in search results when they're return friendly." />
                        <Form.Radio
                          isInline
                          label="Yes"
                          name="returnsAccepted"
                          value="yes"
                          onChange={props.handleChange}
                          checked={props.values.returnsAccepted === "yes"}
                        />
                        <Form.Radio
                          isInline
                          label="No"
                          name="returnsAccepted"
                          value="no"
                          onChange={props.handleChange}
                          checked={props.values.returnsAccepted === "no"}
                        />
                      </Form.Group>

                      {
                        props.values.returnsAccepted === "yes" &&
                        <React.Fragment>

                          <Form.Group label="Return costs paid by">

                            <CustomSelect
                              value={this.returnCostsPaidByOptions ? this.returnCostsPaidByOptions.find(option => /* eslint eqeqeq: 0 */ option.value == props.values.returnCostsPaidBy) : ""}
                              error={props.errors && props.touched.returnCostsPaidBy && props.errors.returnCostsPaidBy}
                              options={this.returnCostsPaidByOptions}
                              onChange={(option) => props.setFieldValue("returnCostsPaidBy", (option && option.value) || "")}
                            />
                          </Form.Group>

                          <Form.Group label="Return within (days)">

                            <CustomSelect
                              value={this.returnWithinOptions ? this.returnWithinOptions.find(option => /* eslint eqeqeq: 0 */ option.value == props.values.returnWithin) : ""}
                              error={props.errors && props.touched.returnWithin && props.errors.returnWithin}
                              options={this.returnWithinOptions}
                              onChange={(option) => props.setFieldValue("returnWithin", (option && option.value) || "")}
                            />
                          </Form.Group>
                        </React.Fragment>
                      }
                    </Grid.Col>
                  </Grid.Row>

                  <Grid.Row>
                    <Grid.Col width={12}>
                      <Form.Footer>

                        {
                          this.props.installWizard &&
                          <Button type="submit" color="primary" loading={props.isSubmitting}><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
                        }
                        {
                          !this.props.installWizard &&
                          <SaveToolbar show undoAction={() => this.onUndoBtnClicked()} saveAction={() => { }} formSaving={props.isSubmitting} />
                        }
                      </Form.Footer>
                    </Grid.Col>
                  </Grid.Row>
                </Form>
              }

            </Formik>
          </div>
        </Card>
      </div>
    );
  }
}