import * as apiCall from '../apiCall';

function getProducts(model) {
    const options = {
        appId: model.app
    };

    return apiCall.get(`/api/marketplace/get_products`, options);
}

export { getProducts };