import * as React from "react";
import {
  NavLink,
  withRouter,
  Link
} from "react-router-dom";
import {
  Site,
  Grid,
  List,
  RouterContextProvider
} from "tabler-react";
import { connect } from 'react-redux';
import history from "../history";
import { appType } from "../constants";

class SiteWrapper extends React.Component {

  constructor(props) {
    super(props);

    const userInfo = props.user.info;

    this.accountDropdownProps = {
      description: userInfo.storeName,
    };

    this.navBarItems = this.getNavBarItems();
  }

  getNavBarItems = () => {

    const userInfo = this.props.user.info;

    if (userInfo.appId === appType.bulkDiscountManager) {
      let navBarItems = [];

      navBarItems.push({
        value: "Dashboard",
        to: "/",
        icon: "home",
        LinkComponent: NavLink,
        useExact: true,
      });

      navBarItems.push({
        icon: "user",
        value: "My Account",
        LinkComponent: NavLink,
        to: "/settings/my-account"
      });

      navBarItems.push({
        icon: "mail",
        value: "Support",
        LinkComponent: NavLink,
        to: "/support"
      });

      return navBarItems;
    }
    else if ((userInfo.apps && userInfo.apps.length > 0) || userInfo.firstAppAdded) {

      let navBarItems = [];

      navBarItems.push({
        value: "Dashboard",
        to: "/",
        icon: "home",
        LinkComponent: NavLink,
        useExact: true,
      });

      if (!userInfo.appId) {
        navBarItems.push({
          icon: "box",
          value: "Apps",
          LinkComponent: NavLink,
          to: "/apps"
        });
      }

      navBarItems.push({
        icon: "user",
        value: "My Account",
        LinkComponent: NavLink,
        to: "/settings/my-account"
      });

      navBarItems.push({
        icon: "mail",
        value: "Support",
        LinkComponent: NavLink,
        to: "/support"
      });

      return navBarItems;
    }
    else {

      let noAppsInstalledNav = [];

      if (!userInfo.appId) {
        noAppsInstalledNav.push({
          icon: "box",
          value: "Apps",
          LinkComponent: NavLink,
          to: "/apps"
        });
      }

      return noAppsInstalledNav;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.user.info !== this.props.user.info) {
      this.navBarItems = this.getNavBarItems();
      this.forceUpdate();
    }

    if ((prevProps.installWizard !== this.props.installWizard) || this.props.installWizard) {
      var divsToHide = document.getElementsByClassName("header-toggler"); //divsToHide is an array
      for (var i = 0; i < divsToHide.length; i++) {

        if (this.props.installWizard) {
          divsToHide[i].style.display = "none";
        }
        else {
          divsToHide[i].style.display = "block";
        }
      }
    }
  }

  getUserInitials = () => {
    const userInfo = this.props.user.info;

    let firstChar = userInfo.firstName && userInfo.firstName.length && userInfo.firstName[0].toUpperCase();
    let secondChar = userInfo.lastName && userInfo.lastName.length && userInfo.lastName[0].toUpperCase();

    return firstChar + secondChar;
  }

  render() {

    const userInfo = this.props.user.info;

    return (
      <Site.Wrapper
        headerProps={{
          href: "#",
          alt: "PolyCommerce",
          imageURL: userInfo.logoUrl
        }}
        navProps={{ itemsObjects: !this.props.installWizard && this.navBarItems }}
        routerContextComponentType={withRouter(RouterContextProvider)}
        footerProps={{
          copyright: (
            <React.Fragment>
              Copyright ©{new Date().getFullYear()}
              <a href="."> PolyCommerce</a>.
              <List.Item className="list-inline-item">
                    
                    <a href="https://www.instagram.com/polycommerce_online" target="_blank"><img className="instagram-icon" src="/images/instagram.png" alt="Instagram icon"/></a>
                    <span className="instagram-follow-content">Follow us</span>
                  </List.Item>
            </React.Fragment>
          ),
          nav: (
            <React.Fragment>
              <Grid.Col auto={true}>
                <List className="list-inline list-inline-dots mb-0">

                  <List.Item className="list-inline-item">
                    <Link to="/support">Support</Link>
                  </List.Item>
                </List>
              </Grid.Col>
            </React.Fragment>
          )
        }}
      >
        {this.props.children}
      </Site.Wrapper>

    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
}

export default connect(mapStateToProps)(SiteWrapper);