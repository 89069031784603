import React, { Component } from 'react';
import {
  Button,
  Loader,
  Card,
  Grid,
  Icon,
  Page,
  Dimmer
} from "tabler-react";
import {
  CategoriesModal
} from './CategoriesModal';
import * as categoryMappingCtrl from '../../services/categoryMapping';
import BootstrapTable from 'react-bootstrap-table-next';
import * as logger from '../../services/log';
import SiteWrapper from '../SiteWrapper.react';
import './CategoryMappings.scss';
import {
  categoryMappingProfileType
} from '../../constants';
import history from '../../history';
import {
  UncategorisedAlert
} from '../Shopify';
import {
  connect
} from 'react-redux';
import {
  integrationType
} from '../../constants';
import { SettingsMenu } from '../SettingsMenu';

class CategoryMappings extends Component {
  static displayName = CategoryMappings.name;

  constructor(props) {
    super(props);

    window.addEventListener("resize", this.handleGridSize);

    this.state = {
      modalOpen: false,
      externalCategoryMappings: [],
      loading: true
    }

    switch (this.props.categoryMappingProfileId) {
      case categoryMappingProfileType.google:

        this.taxonomyName = "Google";
        break;

      case categoryMappingProfileType.catch:
        this.taxonomyName = "Catch";
        break;

      default:
        this.taxonomyName = "";
    }

    this.columns = [
      {
        dataField: 'categoryPath',
        text: 'Store Category',
        formatter: (cell, row) => <div>{cell}</div>
      },
      {
        dataField: 'externalCategoryPath',
        text: `${this.taxonomyName} Category`,
        formatter: (cell, row) => <div>
          {row.mappingIgnored && <div title="Category mapping ignored."><Icon color="red" style={{ color: "orange" }} prefix="fa" name="minus-circle" /></div>}
          {row.mappingRequired && !row.mappingIgnored && <div title="Store category does not have a mapping."><Icon color="red" style={{ color: "red" }} prefix="fa" name="exclamation-triangle" /></div>}
          {!row.mappingRequired && !row.mappingIgnored && cell}
        </div>
      },
      {
        dataField: 'products',
        text: 'Products',
        formatter: (cell, row) => <div>{cell}</div>
      },
      {
        dataField: "manage",
        text: "",
        align: "right",
        formatter: (cell, row) => <React.Fragment>
          {
            row.mappingRequired && !row.mappingIgnored && !row.loading && <Button className="ignore-btn" onClick={() => this.ignoreCategoryMapping(row)} size="sm" color="secondary"><Icon prefix="fa" name="minus-circle" />&nbsp;&nbsp;Ignore</Button>
          }
          {
            (row.mappingRequired || row.mappingIgnored) && !row.loading && <Button onClick={() => this.linkCategoryMapping(row)} size="sm" color="secondary"><Icon prefix="fa" name="link" />&nbsp;&nbsp;Link</Button>
          }

          {
            !row.mappingRequired && !row.mappingIgnored && !row.loading && <Button onClick={() => this.unlinkCategoryMapping(row)} size="sm" color="secondary"><Icon prefix="fa" name="unlink" />&nbsp;&nbsp;Unlink</Button>
          }
          {
            row.loading && <div className="category-mapping-loader"><i className="fa fa-spinner fa-spin"></i></div>
          }

        </React.Fragment>
      }
    ]
  }

  async componentDidMount() {
    let externalCategoryMappings = await categoryMappingCtrl.getCategoryMappings(this.props.categoryMappingProfileId);
    this.setState({ externalCategoryMappings, loading: false }, () => { setTimeout(() => this.handleGridSize(), 0) });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleGridSize);
  }

  handleGridSize = () => {

    // number of pixels to offset vertically
    let DesktopVerticalOffset = 320;

    if (window.innerWidth < 768) {
      DesktopVerticalOffset -= 115; // footer
    }

    let alertDiv = document.getElementById('uncategorised-alert');

    if (alertDiv) {
      DesktopVerticalOffset += alertDiv.offsetHeight;
    }

    var myElements = document.querySelectorAll(".react-bootstrap-table");
    for (var i = 0; i < myElements.length; i++) {

      let height = window.innerHeight - DesktopVerticalOffset;

      if (height < 300) {
        height = 300;
      }
      myElements[i].style.height = `${height}px`;
    }
  }

  onCategorySelected = async (externalCategoryId) => {

    let categoryMappingVm = this.tempCategoryMappingVm;

    try {
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: true });

      categoryMappingVm.externalCategoryId = externalCategoryId;
      categoryMappingVm.appId = this.props.app;

      let result = await categoryMappingCtrl.upsertCategoryMapping(categoryMappingVm);

      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false, externalCategoryPath: result.externalCategoryPath, externalCategoryId: result.externalCategoryId, mappingRequired: false, mappingIgnored: false });
    }
    catch (e) {
      logger.logError("onCategorySelected error", e);
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false });
    }
    finally {
      this.tempCategoryMappingVm = null;
    }
  }

  unlinkCategoryMapping = async (categoryMappingVm) => {

    try {
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: true });
      categoryMappingVm.appId = this.props.app;
      await categoryMappingCtrl.deleteCategoryMapping(categoryMappingVm);
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false, mappingRequired: true, mappingIgnored: false });
    }
    catch (e) {
      logger.logError("unlinkCategoryMapping error", e);
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false });
    }
  }

  toggleCategoryMappingLoader = (categoryId, updatedObj) => {
    const externalCategoryMappings = this.state.externalCategoryMappings.map((d) => {
      if (d.categoryId === categoryId) {
        return {
          ...d,
          ...updatedObj
        };
      }
      return d;
    });
    this.setState(curr => ({ ...curr, externalCategoryMappings }));
  }

  linkCategoryMapping = (categoryMappingVm) => {
    this.tempCategoryMappingVm = categoryMappingVm;
    this.externalCategoriesModal.openModal(categoryMappingVm);
  }

  ignoreCategoryMapping = async (categoryMappingVm) => {

    this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: true });

    categoryMappingVm.appId = this.props.app;

    await categoryMappingCtrl.upsertCategoryMapping(categoryMappingVm);

    this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false, mappingIgnored: true, externalCategoryPath: null, mappingRequired: false });

  }

  render() {

    if (this.state.loading) {
      return <SiteWrapper>
        <Page.Content>
          <Grid.Row cards>

            <div className="app-settings-wrapper">
              
              {
                this.props.showSettingsMenu ? <SettingsMenu app={this.props.app} /> : null
              }

              <div className="app-settings-content">
                <Card loading>
                  <Dimmer active loader>
                    <div style={{ height: 150 }}></div>
                  </Dimmer>
                </Card>
              </div>
            </div>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    }

    let uncategorisedCount = this.props.user.info.integrationTypeId === integrationType.Shopify && this.state.externalCategoryMappings.find(ecm => ecm.categoryPath === "Uncategorised")?.products;

    return (
      <SiteWrapper>

        <Page.Content>

          <Grid.Row cards>

            <div className="app-settings-wrapper">

              {
                this.props.showSettingsMenu ? <SettingsMenu app={this.props.app} /> : null
              }


              <div className="app-settings-content">

                {
                  !this.props.showSettingsMenu ? <div onClick={() => history.goBack()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div> : null
                }


                <div id="uncategorised-alert">
                  {
                    this.props.user.info.integrationTypeId === integrationType.Shopify && uncategorisedCount && uncategorisedCount > 0 ?
                      <UncategorisedAlert uncategorisedCount={uncategorisedCount} />
                      :
                      null
                  }
                </div>

                <Card className="external-category-mappings">


                  <Card.Header>

                    <h4>{this.taxonomyName} Category Mappings</h4>
                  </Card.Header>
                  <div className="card-inner">
                    <BootstrapTable
                      bordered={false}
                      classes="card-table table-vcenter text-nowrap external-category-mappings-table"
                      bootstrap4
                      keyField="categoryId"
                      noDataIndication={() => {

                        if (this.state.loading) {
                          return <div className="table-loader-container"><Loader /></div>;
                        }
                        else {
                          return <div className="table-loader-container">No categories to show.</div>;
                        }
                      }
                      }
                      data={this.state.externalCategoryMappings}
                      columns={this.columns} />
                  </div>

                  {
                    this.props.installWizard &&
                    <div className="next-btn">
                      <Button onClick={() => this.props.loadNextStep()} type="submit" color="primary"><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
                    </div>
                  }

                </Card>
                <CategoriesModal taxonomyName={this.taxonomyName} categoryMappingProfileId={this.props.categoryMappingProfileId} onCategorySelected={this.onCategorySelected} ref={(ref) => this.externalCategoriesModal = ref} />
              </div>
            </div>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>

    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
}

let wrapper = connect(mapStateToProps)(CategoryMappings);
export { wrapper as CategoryMappings };