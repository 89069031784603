import * as apiCall from '../apiCall';

function getInventoryLocations(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/ebay/get_inventory_locations`, options);
}

export { getInventoryLocations };