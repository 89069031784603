
// fileData = base64 conversion from byte[]
const downloadFile = (fileData, mimeType, fileName) => {
    let blob = new Blob([s2ab(atob(fileData))], { type: mimeType });// change resultByte to bytes

    let link = document.createElement('a');
    link.style = "visibility:hidden";
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
}

const s2ab = (s) => {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length /* eslint eqeqeq: 0 */; ++i) view[i] = s.charCodeAt(i) & 0xFF;
    return buf;
}

export { downloadFile };