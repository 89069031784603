import * as apiCall from '../apiCall';

function saveShippingPolicyOptions(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/marketplace/save_shipping_policy_options`, model, options);
}

export { saveShippingPolicyOptions };