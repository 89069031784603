import React from 'react';
import { Dimmer } from "tabler-react";

const WidgetBody = ({ loading, children, ref }) => {

    if (loading) {
        return (<div ref={ref} className="custom-panel-body">

            <Dimmer active loader>
                <div style={{ height: 80 }}></div>
            </Dimmer>
        </div>);
    }
    else {
        return (<div className="custom-panel-body">
            {children}
        </div>);
    }
}

export { WidgetBody };