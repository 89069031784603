import React, { Component } from 'react';
import { StandaloneFormPage, Card } from 'tabler-react';
import './AuthoriseStatus.scss';

const queryString = require('query-string');

class AuthoriseStatus extends Component {
    static displayName = AuthoriseStatus.name;

    constructor(props) {
        super(props);

        let queryParams = window.location && window.location.search;

        const parsed = queryString.parse(queryParams);

        this.appName = parsed.appName;

        window.postMessage("message", "*");
    }

    render() {
        return <StandaloneFormPage imageURL={"/images/logo.png"}>
            <Card.Body style={{ textAlign: "center" }} className="p-6 auth-success">
                {
                    this.appName && <Card.Title RootComponent="div"><h2>{this.appName}</h2></Card.Title>
                }
                <Card.Title RootComponent="div"><h4><i style={{ color: "#467fcf" }} className="fas fa-box-check"></i> Authorisation Successful</h4></Card.Title>

                <div>Feel free to close this window.</div>

            </Card.Body>
        </StandaloneFormPage>
    }
}

export { AuthoriseStatus };