import createApp from '@shopify/app-bridge';
import * as logger from '../services/log';

const getShopifyApp = (user) => {

    if (!user.shopifyData.host) {
        logger.logError("user.shopifyData.host can't be undefined or null");
    }

    let shopifyConfig = {
        host: user.shopifyData.host,
        apiKey: user.shopifyData.clientId,
        forceRedirect: true
    }

    const app = createApp(shopifyConfig);

    return app;
}

const redirectToShopifyAdmin = (shopifyShop, shopifyClientId) => {

    console.log("SHOPIFY SHOP", shopifyShop);
    let url = `https://${shopifyShop}/admin/apps/${shopifyClientId}`;
    window.location.href = url;
}

export { getShopifyApp, redirectToShopifyAdmin };