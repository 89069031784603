import React, { useState } from 'react';
import * as shoppingFeedCtrl from '../services/shoppingfeed';
import Modal from 'react-modal';
import {
    Icon,
    Loader,
    Form,
    Button
} from 'tabler-react';
import {
    useFormik
} from 'formik';
import {
    toast
} from 'react-toastify';
import {
    CustomToast
} from './';
import * as logger from '../services/log';
import './CustomModal.css';
import "./AddTemplateModal.scss";

const AddTemplateModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);

    let appId = props.app || props.shoppingFeedApp?.id;

    function closeModal() {
        props.hideModal();
    }

    const validate = values => {
        const errors = {};

        if (values.name.trim() === "") {
            errors.nameError = "Required";
        }

        return errors;
    }

    const addTemplate = async () => {
        try {
            setFormSaving(true);


            let model = {
                name: formik.values.name
            };

            await shoppingFeedCtrl.saveTemplate(appId, model)


            toast.success(<CustomToast message={`Template created.`} />, { position: toast.POSITION.TOP_RIGHT });

            await props.refreshTemplates();
            setFormSaving(false);
            closeModal();

        }
        catch (e) {
            logger.logError(`addTemplate() error`, e);
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            template: "",
            name: ""
        },
        onSubmit: async () => {
            await addTemplate();
        },
        validate
    });

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            onAfterClose={() => {
                if (props.gridApi) {
                    setTimeout(() => {
                        props.gridApi.sizeColumnsToFit();
                    }, 120);
                }
            }}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />
            <h2 className="category-header" style={{ textAlign: "center" }}>Add Template</h2>
            <hr className="divider"></hr>

            <div className="add-template">

                <Form autocomplete="off" onSubmit={formik.handleSubmit}>

                    <Form.Group label="Name">
                        <Form.Input
                            name="name"
                            type="text"
                            error={formik.touched.name && formik.errors.nameError}
                            autocomplete="off"
                            disabled={formSaving}
                            value={formik.values.name || ""}
                            onChange={formik.handleChange}
                        />
                    </Form.Group>

                    <div className="add-template-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Create</Button>
                        </div>
                    </div>

                </Form>


            </div>

        </Modal>
    )
}

export { AddTemplateModal };