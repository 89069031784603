import * as apiCall from '../apiCall';

function getCategories(level, categoryMappingProfileId, parentCategoryId = null) {

    let url = `/api/categorymapping/get_categories?level=${level}&categoryMappingProfileId=${categoryMappingProfileId}`;

    if (parentCategoryId) {

        let encodedParentCategoryId = encodeURIComponent(parentCategoryId); 
        url += `&parentCategoryId=${encodedParentCategoryId}`;
    }

    return apiCall.get(url);
}

export { getCategories };