import * as apiCall from '../apiCall';
import * as browserUtility from '../../utilities/browserUtility';

function logError(message, ex = null) {

    const options = {
        handleErrorManually: true,
        isAnonymous: true
    };

    console.log(message);
    console.log(ex || "<no exception info available>");

    let browserInfo = browserUtility.getBrowserInfo();

    message = browserInfo + message;

    if (ex && ex.toString === typeof "function") {
        message += `|Exception: ${ex.toString()}`;
    }
    else if (ex) {
        message += `|Exception: ${ex.message}`;
    }

    let model = {
        logLevel: "error",
        message: message
    }

    return apiCall.post(`/api/log/log_message`, model, options);
}

export { logError };