import React, { useState } from 'react';
import Modal from 'react-modal';
import { 
    Icon, 
    Form, 
    Button 
} from 'tabler-react';
import { 
    useFormik 
} from 'formik';
import * as ebayCtrl from '../../../services/ebay';
import { 
    listingPriorityType 
} from '../../../constants';
import { 
    toast 
} from 'react-toastify';
import { 
    CustomToast, 
    CustomSelect 
} from '../../../components';
import '../../CustomModal.css';
import "./SetListingPriorityModal.scss";

const SetListingPriorityModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);

    function closeModal() {
        props.hideModal();
    }

    const listingPriorityOptions = [
        {
            label: "Don't List",
            value: listingPriorityType.DontList
        },
        {
            label: "Low",
            value: listingPriorityType.Low
        },
        {
            label: "Normal",
            value: listingPriorityType.Normal
        },
        {
            label: "High",
            value: listingPriorityType.High
        }      
    ];

    const validate = values => {
        const errors = {};

        if (values.listingPriority === "") {
            errors.listingPriorityError = "Required"
        }

        return errors;
    }

    const applyListingPriority = async () => {
        try {
            setFormSaving(true);

            let model = {
                productIds: props.products.map(p => p.id),
                id: parseInt(formik.values.listingPriority)
            };

            await ebayCtrl.saveListingPriorityOptions(props.appId, model);

            toast.success(<CustomToast message={`Updated ${model.productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

            props.refreshProducts();
            closeModal();
        }
        finally {
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            listingPriority: ""

        },
        onSubmit: async (values) => {
            await applyListingPriority();
        },
        validate
    });

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>Priority</h2>
            <hr className="divider"></hr>

            <div className="set-listing-priority-modal-content">
                <Form autocomplete="off" onSubmit={formik.handleSubmit}>
                    <Form.Group label="Listing Priority">

                        <CustomSelect
                            value={listingPriorityOptions ? listingPriorityOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.listingPriority) : ""}
                            isDisabled={formSaving}
                            error={formik.errors.listingPriorityError}
                            options={listingPriorityOptions}
                            onChange={(option) => formik.setFieldValue("listingPriority", (option && option.value) || "")}
                        />

                    </Form.Group>

                    <div className="set-listing-priority-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                        </div>
                    </div>
                </Form>

            </div>

        </Modal>
    )
}

export { SetListingPriorityModal };