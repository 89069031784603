import * as apiCall from '../apiCall';

function getProducts(appId) {

    const options = {
        appId
    };

    return apiCall.get(`/api/ebay/get_products`, options);
}

export { getProducts };