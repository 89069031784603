import * as apiCall from '../apiCall';

function getReturnPolicy(appId) {

    const options = {
        appId
    };

    let url = `/api/ebay/get_return_policy`;  
    return apiCall.get(url, options);
}

export { getReturnPolicy };