import * as apiCall from '../apiCall';

function saveListingPriorityOptions(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/trademe/save_listing_priority_options`, model, options);
}

export { saveListingPriorityOptions };