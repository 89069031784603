import * as apiCall from '../apiCall';

function saveInventoryLocationOptions(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/save_inventory_location_options`, model, options);
}

export { saveInventoryLocationOptions };