import React, { useState, useEffect } from 'react';
import SiteWrapper from './SiteWrapper.react';
import { Page, 
    Card, 
    Grid 
} from "tabler-react";
import { 
    CustomLabel 
} from '../components/CustomLabel';
import * as currencyCtrl from '../services/currency';
import { 
    AgGridReact 
} from "ag-grid-react";
import { 
    CustomLoadingOverlay 
} from './CustomLoadingOverlay';
import moment from 'moment';
import {default as browserHistory} from '../history';
import './Currency.scss';

let gridApi;

const Currency = () => {

    const [formSubmitted, setFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(true);
    const [history, setHistory] = useState([]);
    const [currencyVm, setCurrencyVm] = useState({});

    useEffect(() => {
        const fetchSettings = async () => {

            let currencyVm = await currencyCtrl.main();

            setCurrencyVm(currencyVm);
            setHistory(currencyVm.history);
            setLoading(false);
        }

        fetchSettings();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const frameworkComponents = {
        customLoadingOverlay: CustomLoadingOverlay
    }

    const columns = [
        {
            field: 'date',
            headerName: 'Date',
            filter: 'agDateColumnFilter',
            valueGetter: function (params) {
                return moment(params.data.date).toDate().toLocaleDateString();
            },
            width: 120
        },
        {
            field: 'fromCurrencyCode',
            headerName: 'Base',
            width: 120
        },
        {
            field: 'toCurrencyCode',
            headerName: 'Target',
            width: 120
        },
        {
            field: 'currencyRate',
            headerName: 'Exchange Rate',
            minWidth: 150
        }
    ];

    const defaultColDef = {
        resizable: true,
        sortable: true,
        filter: true,
        filterParams: {
            clearButton: true
        }
    };

    const onGridReady = (params) => {
        gridApi = params.api;

        const isMobile = /iPhone|iPad|iPod|Android/i.test(window.navigator.userAgent);

        if (!isMobile) {
            gridApi.sizeColumnsToFit();
        }

    };

    if (loading) {
        return (
            <SiteWrapper>
                <Page.Content className="currency">
                </Page.Content>
            </SiteWrapper>
        )
    }

    return (
        <SiteWrapper>
            
            <Page.Content className="currency">

                <div onClick={() => browserHistory.goBack()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>

                <Grid.Row cards>
                    <Grid.Col sm={12}>
                        <Card>
                            <Card.Header>
                                <h4>Exchange Rate History</h4>
                            </Card.Header>

                            <div className="currency-card">
                                <div style={{ width: "100%", height: "100%", }}>

                                    <div>
                                        <CustomLabel
                                            id="default-currency"
                                            name="Store currency"
                                            message="This is set automatically depending on the currency configured for your store." />
                                    </div>
                                    <div style={{marginBottom: 15}}>
                                        {currencyVm.currencies.find(c => c.id === currencyVm.defaultCurrency)?.currency}
                                    </div>

                                    <div
                                        id="currency-grid"
                                        style={{
                                            minHeight: 300,
                                            height: 500
                                        }}
                                        className="ag-theme-alpine">
                                        <AgGridReact
                                            columnDefs={columns}
                                            animateRows={false}
                                            enableBrowserTooltips
                                            enableCellTextSelection
                                            defaultColDef={defaultColDef}
                                            suppressRowClickSelection={true}
                                            rowSelection="multiple"
                                            onGridReady={onGridReady}
                                            loadingOverlayComponent="customLoadingOverlay"
                                            frameworkComponents={frameworkComponents}
                                            rowData={history}
                                            multiSortKey="ctrl"
                                        />
                                    </div>
                                </div>
                            </div>
                        </Card>

                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export { Currency }