import * as apiCall from '../apiCall';

function upsertCategoryMapping(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/trademe/upsert_category_mapping`, model, options);
}

export { upsertCategoryMapping };