const alertCode = {
    InvalidEbayToken: "InvalidEbayToken",
    EbayCategoryMapping: "EbayCategoryMapping",
    InvalidTradeMeToken: "InvalidTradeMeToken",
    TradeMeCategoryMapping: "TradeMeCategoryMapping",
    AmazonNoTemplates: "AmazonNoTemplates",
    AmazonFeedInProgress: "AmazonFeedInProgress",
    EbayFtpFileInProgress: "EbayFtpFileInProgress",
    EbayUnexpectedProductsError: "EbayUnexpectedProductsError",
    AmazonUnexpectedProductsError: "AmazonUnexpectedProductsError",
    EbaySftpConnectionError: "EbaySftpConnectionError",
    OutOfStockPreferenceError: "OutOfStockPreferenceError",
    TradeMeServiceUnavailableError: "TradeMeServiceUnavailableError"
};

export { alertCode };