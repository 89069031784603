import React, { useState, useEffect } from 'react';
import SiteWrapper from '../SiteWrapper.react';
import {
    Page,
    Card,
    Grid,
    Button,
    Form
} from "tabler-react";
import history from '../../history';

const NewDiscountCampaign = () => {

    const [loading, setLoading] = useState(false);

    const backBtnHandler = () => {
        history.goBack();
    }

    const PAGE_NAME = "New Discount Campaign";

    return (<SiteWrapper>
        <Page.Content>

            <Grid.Row cards>

                <Grid.Col sm={12}>

                    <div onClick={() => backBtnHandler()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>
                    <Card className="new-discount-campaign" loading={loading}>
                        <Card.Header>

                            <h4>{PAGE_NAME}</h4>

                        </Card.Header>

                        <Card.Body>
                            <div>Hello World</div>

                        </Card.Body>


                    </Card>
                </Grid.Col>
            </Grid.Row>

        </Page.Content>
    </SiteWrapper>);
}

export { NewDiscountCampaign };