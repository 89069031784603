import React from 'react';
import './Support.scss';
import SiteWrapper from './SiteWrapper.react';
import { 
    Page, 
    Card, 
    Grid, 
    Button, 
    Form 
} from "tabler-react";
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import { CustomToast } from '../components/CustomToast';
import history from '../history';
import * as supportCtrl from '../services/support';
import * as logger from '../services/log';

const Support = () => {

    function validate(values) {
        let errors = {};

        if (values.supportMessage === null || values.supportMessage === undefined || values.supportMessage.trim() === "") {
            errors.supportMessageError = "Required";
        }

        return errors;
    }

    const formik = useFormik({
        initialValues: {
            supportMessage: null
        },
        validate,
        onSubmit: async (values) => {
            try {
                
                let supportMessage = values.supportMessage.replace(/(?:\r\n|\r|\n)/g, '<br/>');
                await supportCtrl.sendMessage(supportMessage);
                toast.success(<CustomToast message={`Your message has been sent and we will get back to you shortly via e-mail.`} />, { position: toast.POSITION.TOP_RIGHT });
                history.push("/");
            }
            catch (e) {
                logger.logError(`Support onSubmit() error`, e);
            }
            finally {
                formik.setSubmitting(false);
            }
        }
    });

    return (
        <SiteWrapper>
            <Page.Content title="Support" className="support">

                <Grid.Row>
                    <Grid.Col sm={12} md={12} lg={12} xl={12}>
                        <Card>

                            <div className="support-card">
                                <div className="more-info">
                                    Please describe your request with as much detail as possible.
                                </div>

                                <Form.Textarea
                                    style={{ whiteSpace: "pre-wrap" }}
                                    error={formik.touched.supportMessage && formik.errors.supportMessageError}
                                    value={formik.values.supportMessage}
                                    disabled={formik.isSubmitting}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    name="supportMessage"
                                    rows="15">
                                </Form.Textarea>


                                <div className="form-footer">
                                    <div>
                                        <Button type="submit" onClick={formik.handleSubmit} loading={formik.isSubmitting} color="primary"><i className="far fa-paper-plane"></i>&nbsp;&nbsp;Send</Button>
                                    </div>
                                </div>
                            </div>


                        </Card>
                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export { Support };