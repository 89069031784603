import axios from 'axios';
import {
    toast
} from 'react-toastify';
import {
    storageKeys
} from '../constants';
import store from '../reduxStore';
import {
    CustomToast
} from '../components/CustomToast';
import React from 'react';
import * as authUtility from '../utilities/authUtility';
import * as logger from './log';
import history from '../history';

export function isMockMode() {
    return (process.env.REACT_APP_MOCK_MODE === "True" && process.env.NODE_ENV === "development");
}

export function post(endpoint, payload, options) {

    if (payload === null) {
        payload = {};
    }

    return new Promise((resolve, reject) => {

        options = getOptions(options);

        let headers = {};

        if (!options.isAnonymous) {

            let authToken = authUtility.getAuthToken();

            if (!authToken) {
                logger.logDebug("Invalid auth token during POST. Redirecting to login.");
                authUtility.logout();
            }

            headers["Authorization"] = `Bearer ${authToken}`;
        }

        if (options.isMultiPart) {
            headers["Content-Type"] = "multipart/form-data";
        }

        if (options.appId) {
            headers["AppId"] = options.appId;
        }

        let instance = axios.create({
            headers
        });

        if (options.isFile) {
            instance.defaults.responseType = "blob";
        }

        return instance.post(endpoint, payload)
            .then((result) => {
                handleSuccess(result, options);
                resolve(result.data);
            })
            .catch(error => {
                let errorMessage = handleError(error, options, endpoint);

                if (options.handleErrorManually) {
                    resolve({ inError: true, message: errorMessage });
                }
                else {
                    reject(error);
                }
            });
    });
}

export function get(endpoint, options) {

    return new Promise((resolve, reject) => {
        options = getOptions(options);

        let headers = {};

        if (!options.isAnonymous) {

            let authToken = authUtility.getAuthToken();

            if (!authToken) {
                logger.logDebug("Invalid auth token during GET. Redirecting to login.");
                authUtility.logout();
            }

            headers["Authorization"] = `Bearer ${authToken}`;
        }

        if (options.appId) {
            headers["AppId"] = options.appId;
        }

        let instance = axios.create({
            headers
        });

        if (options.isFile) {
            instance.defaults.responseType = "blob";
        }

        return instance.get(endpoint)
            .then((result) => {
                handleSuccess(result, options);
                resolve(result.data);
            })
            .catch(error => {
                let errorMessage = handleError(error, options, endpoint);

                if (options.handleErrorManually) {
                    resolve({ inError: true, message: errorMessage });
                }
                else {
                    reject(error);
                }
            });

    });

}

function handleSuccess(result, options) {
    if (result.data && result.data.message && result.data.showSuccessMessage) {
        toast.success(<CustomToast message={result.data.message} />, { position: toast.POSITION.TOP_RIGHT });
    }
}

function handleError(error, options, endpoint) {

    let errorObj = null;
    let errorMessage = null;
    let errorResponse = error.response && error.response.data;

    const REMOTE_LOG_ENDPOINT = "/api/log/log_message";

    try {
        if (error.response && endpoint !== REMOTE_LOG_ENDPOINT) {
            logger.logError("Failed network request. Part #1|", JSON.stringify(error.response));
        }
    }
    catch { }

    try {
        if (error.response && error.response.data && endpoint !== REMOTE_LOG_ENDPOINT) {
            logger.logError("Failed network request. Part #2|" + JSON.stringify(error.response.data));
        }
    }
    catch { }

    if (!errorResponse || typeof errorResponse !== "string") {
        errorMessage = "An unexpected error occurred."
    }
    else if (typeof errorResponse === "string") {
        errorMessage = errorResponse;
    }

    if (errorResponse && errorResponse.code && errorResponse.message) {
        errorObj = errorResponse;
        errorMessage = errorObj.message;
    }

    if (error.response) {
        switch (error.response.status) {
            case 401:
                {
                    if (window.location.pathname !== "/account/login") {
                        authUtility.logout(true);
                    }

                    return;
                }
            case 403: {

                if (error.response.data && error.response.data.code === "subscription_invalid") {
                    console.log("Subscription invalid...");
                    history.push("/account/subscription");
                }
                else if (window.location.pathname !== "/account/login") {
                    authUtility.logout(true);
                }

                return;
            }
            case 404:
                toast.error(<CustomToast message="Page not found." />, { position: toast.POSITION.TOP_RIGHT });
                break;
            case 400:
            case 500:
                if (!options.handleErrorManually && errorMessage) {
                    toast.error(<CustomToast message={errorMessage} />, { position: toast.POSITION.TOP_RIGHT });
                }
                else {
                    toast.error(<CustomToast message="An unexpected error occurred." />, { position: toast.POSITION.TOP_RIGHT });
                }
                break;

            default:
                break;
        }
    }

    return errorMessage;
}


function getOptions(options) {
    var defaultOptions = {
        handleErrorManually: false,
        successMessage: null,
        isAnonymous: false,
        isMultiPart: false,
        isFile: false
    };

    if (!options) {
        return defaultOptions;
    }

    options.handleErrorManually = getOption(options.handleErrorManually, defaultOptions.handleErrorManually);
    options.successMessage = getOption(options.successMessage, defaultOptions.successMessage);
    options.isAnonymous = getOption(options.isAnonymous, defaultOptions.isAnonymous);
    options.isMultiPart = getOption(options.isMultiPart, defaultOptions.isMultiPart);
    options.isFile = getOption(options.isFile, defaultOptions.isFile);

    return options;
}

function getOption(opt, def) {
    if (opt == null) {
        return def;
    }
    return opt;
}

// delay: milliseconds to delay the call
// func: function that returns result
export function simulateDelay(delay, func) {
    return new Promise(function (resolve) {
        setTimeout(function () {
            resolve(func())
        }, delay)
    });
}