import React, { Component } from 'react';
import { Container, Page } from 'tabler-react';

export default class PreAuthLayout extends Component {
    displayName = PreAuthLayout.name

    render() {
        return (
            <Page>
                <Container>{this.props.children}</Container>
            </Page>

        );
    }
}
