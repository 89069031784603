import React, { useEffect, useState } from 'react';
import {
    Grid,
    Card,
    Page,
    Button,
    Alert
} from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import {
    SettingsMenu
} from '../SettingsMenu';
import * as tradeMeCtrl from '../../services/trademe';
import {
    FormLoader
} from '../FormLoader';
import * as authUtility from '../../utilities/authUtility';
import * as logger from '../../services/log';
import "./Authorisation.scss";

const Authorisation = (props) => {

    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({});
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [pollTimer, setPollTimer] = useState(null);

    useEffect(() => {
        const fetchData = async () => {

            try {
                let userDetails = await tradeMeCtrl.getUserDetails(props.app);
                setUserDetails(userDetails);
                setLoading(false);
            }
            catch (e) {
                logger.logError("Authorisation useEffect() error", e);
                setLoading(false);
            }
        }

        fetchData();

        return () => {
            if (pollTimer) {
                clearInterval(pollTimer);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    async function onAuthorize() {

        if (authorizeLoading) {
            return;
        }

        setAuthorizeLoading(true);

        let pollTimer = authUtility.openPopupWithPoller(() => tradeMeCtrl.getAuthorizationUrl(props.app), async () => {
            try {
                setLoading(true);
                let userDetails = await tradeMeCtrl.getUserDetails(props.app);
                setUserDetails(userDetails);
                setLoading(false);
                setAuthorizeLoading(false);
            }
            catch (e) {
                logger.logError("TradeMe Authorisation pollTimer error", e);
                setLoading(false);
                setAuthorizeLoading(false);
            }

        }, () => {
            setAuthorizeLoading(false);
        });

        setPollTimer(pollTimer);
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={"Authorisation"} />)
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="trademe-connect">
                                <Card.Header>
                                    <h4>Authorisation</h4>
                                </Card.Header>
                                <div className="card-inner">
                                    {
                                        userDetails.tradeMeTokenStatus && userDetails.tradeMeTokenStatus !== "OK" &&
                                        <Alert type="danger">
                                            Unable to authorise with TradeMe. Please <span className="anchor" onClick={(e) => { e.preventDefault(); onAuthorize(); }}>reauthorise</span> your account.
                                        </Alert>
                                    }

                                    <div className="connect-settings">
                                        <div className="connect-label">
                                            TradeMe email
                                        </div>
                                        <div className="connect-value">
                                            {userDetails.email || ""}
                                        </div>
                                    </div>
                                    <div className="connect-settings">
                                        <div className="connect-label">
                                            TradeMe username
                                        </div>
                                        <div className="connect-value">
                                            {userDetails.username || ""}
                                        </div>
                                    </div>
                                    <div className="connect-settings">
                                        <div className="connect-label">
                                            Connection status
                                        </div>
                                        <div className="connect-value">
                                            {userDetails.tradeMeTokenStatus || ""}
                                        </div>
                                    </div>
                                </div>
                                <div className="connect-footer">
                                    <div className="button-container">
                                        <Button onClick={() => onAuthorize()} type="button" color="primary" loading={authorizeLoading} disabled={authorizeLoading}><i className="fas fa-sign-in"></i>&nbsp;&nbsp;Authorise</Button>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>

    );
}

export { Authorisation };