import React, { useState } from 'react';
import * as ebayCtrl from '../../services/ebay';
import Modal from 'react-modal';
import { Form, Button, Icon } from 'tabler-react';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components/CustomToast';
import { useFormik } from 'formik';
import '../CustomModal.css';
import "./InventoryLocationModal.scss";

const EditInventoryLocationModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);

    const validate = values => {
        const errors = {};

        if (values.inventoryLocation.name === null || values.inventoryLocation.name === undefined || values.inventoryLocation.name.trim() === "") {
            errors.nameError = "Required";
        }

        return errors;
    }

    const formik = useFormik({
        initialErrors: {},
        initialValues: {
            inventoryLocation: props.showInventoryModalSettings.location
        },
        onSubmit: async (values) => {
            await updateInventoryLocation();
        },
        validate
    });

    function closeModal() {
        props.hideModal();
    }

    async function updateInventoryLocation() {
        setFormSaving(true);

        try {
            await ebayCtrl.updateInventoryLocation(props.app, formik.values.inventoryLocation.merchantLocationKey, formik.values.inventoryLocation);
            toast.success(<CustomToast message="Updated inventory location." />, { position: toast.POSITION.TOP_RIGHT });
            props.hideModal();
            props.refreshInventoryLocations();
        }
        finally {
            setFormSaving(false);
        }
    }

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={false}
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>Edit Inventory Location</h2>
            <hr className="divider"></hr>

            <Form autocomplete="off" onSubmit={formik.handleSubmit} className="inventory-location-form">

                <Form.Group label="Name">
                    <Form.Input
                        type="text"
                        disabled={formSaving}
                        value={formik.values.inventoryLocation.name || ""}
                        autocomplete="off"
                        onBlur={formik.handleBlur}
                        name="inventoryLocation.name"
                        error={formik.touched.name && formik.errors.nameError}
                        onChange={formik.handleChange} />

                </Form.Group>

                <div className="inventory-locations-footer">
                    <div>
                        <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                        <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                    </div>
                </div>
            </Form>


        </Modal>
    )
}

export { EditInventoryLocationModal };