import React, { useState } from 'react';
import Modal from 'react-modal';
import { Icon, Form, Button } from 'tabler-react';
import { useFormik } from 'formik';
import * as tradeMeCtrl from '../../../services/trademe';
import { appType } from '../../../constants';
import { toast } from 'react-toastify';
import { CustomToast, CustomSelect } from '../../../components';
import '../../CustomModal.css';
import "./SetShippingMethodModal.scss";

const SetShippingMethodModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);

    const shippingMethodOptions = props.shippingMethodOptions.map(t => ({ label: t.name, value: t.id }));

    function closeModal() {
        props.hideModal();
    }

    const validate = values => {
        const errors = {};

        if (values.shippingMethod === "") {
            errors.shippingMethodError = "Required"
        }

        return errors;
    }

    const applyShippingMethod = async () => {
        try {
            setFormSaving(true);

            let model = {
                productIds: props.products.map(p => p.id),
                id: parseInt(formik.values.shippingMethod)
            };

            await tradeMeCtrl.saveShippingMethodOptions(appType.tradeMe, model);

            toast.success(<CustomToast message={`Updated ${model.productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

            props.refreshProducts();
            closeModal();
        }
        finally {
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            shippingMethod: ""
        },
        onSubmit: async (values) => {
            await applyShippingMethod();
        },
        validate
    });

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>Shipping Method</h2>
            <hr className="divider"></hr>

            <div className="set-shipping-method-modal-content">

                <Form autocomplete="off" onSubmit={formik.handleSubmit}>
                    <Form.Group label="Shipping Method">

                        <CustomSelect
                            value={shippingMethodOptions ? shippingMethodOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.shippingMethod) : ""}
                            isDisabled={formSaving}
                            error={formik.errors.shippingMethodError}
                            options={shippingMethodOptions}
                            onChange={(option) => formik.setFieldValue("shippingMethod", (option && option.value) || "")}
                        />
                    </Form.Group>

                    <div className="set-shipping-method-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                        </div>
                    </div>
                </Form>

            </div>
        </Modal>
    )
}

export { SetShippingMethodModal };