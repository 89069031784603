import React, { Component } from 'react';
import { Page, Grid } from "tabler-react";
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { ShippingInner } from './ShippingInner';
import './Shipping.scss';

export class Shipping extends Component {
  static displayName = Shipping.name;

  render() {

    return (
      <SiteWrapper>
        <Page.Content>
          <Grid.Row cards>
            <div className="app-settings-wrapper">
              <SettingsMenu app={this.props.app} />
              <ShippingInner app={this.props.app} />
            </div>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>

    );
  }
}