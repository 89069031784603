import React, { useEffect, useState } from 'react';
import history from '../history';
import {
    SubscriptionCard
} from './SubscriptionCard';
import * as subscribeCtrl from '../services/subscribe';
import { LoadingPage } from './LoadingPage';
import "./Subscription.scss";

const Subscription = (props) => {

    const [loading, setLoading] = useState(true);
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);

    useEffect(() => {

        const fetch = async () => {
            let subscriptionPlans = await subscribeCtrl.getPlans();

            setSubscriptionPlans(subscriptionPlans);
            setLoading(false);
        }

        fetch();
    }, []);

    let mode = props.match?.params?.mode?.toLowerCase();

    if (loading) {
        return (<LoadingPage />);
    }

    return (
        <div style={{ height: "100%" }}>
            {
                mode === "edit" ? <div style={{ position: "absolute", top: 20, left: 20 }} onClick={() => history.goBack()} className="anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div> : null
            }
            <div className="subscription-wrapper">

                <div className="subscription-wrapper-inner">
                    <div style={{ display: "flex", justifyContent: "center", marginBottom: 30 }}>
                        <img src="/images/logo.png" alt="Logo" />
                    </div>
                    <div className="subscriptions">
                        {
                            subscriptionPlans.map(plan => (<SubscriptionCard key={plan.planId} planDetail={plan} />))
                        }
                    </div>

                </div>

            </div>
        </div>

    );
}

export default Subscription;