import React, { useState, useEffect } from 'react';
import { Form } from "tabler-react";
import * as logger from '../../../services/log';
import './Products.scss';

const DontListPriorityOptions = ({ products }) => {

    const [unlistImmediately, setUnlistImmediately] = useState(true);

    useEffect(() => {
        setUnlistImmediately(true);
    }, []);

    return (<React.Fragment>You are about to update the priority to <strong>Don't List</strong> for {products.length} product{products.length > 1 ? "s" : ""}.

        <p style={{ marginTop: 20 }}>
            Please select an option for products that are currently active on TradeMe?
      </p>
        <Form.Group>
            <Form.Radio
                isInline={false}
                label="Unlist immediately"
                name="unlistImmediately"
                value="unlistImmediately"
                onChange={(e) => {
                    logger.logDebug("TradeMe - User selected 'Unlist immediately'.");
                    setUnlistImmediately(true);
                }}
                checked={unlistImmediately}
            />
            <Form.Radio
                isInline={false}
                label="Remain listed until the end date"
                name="remainListed"
                value="remainListed"
                onChange={(e) => {
                    logger.logDebug("TradeMe - User selected 'remain listed until the end date'.");
                    setUnlistImmediately(false);
                }}
                checked={!unlistImmediately}
            />
        </Form.Group>

    </React.Fragment>);
}

export default DontListPriorityOptions;