import * as apiCall from '../apiCall';

function getCategoryMappings(appId) {

    const options = {
        appId
    };

    let url = `/api/trademe/category_mappings`;  
    return apiCall.get(url, options);
}

export { getCategoryMappings };