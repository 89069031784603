import * as apiCall from '../apiCall';

function getFeedbackSettings(appId) {

    const options = {
        appId
    };

    let url = `/api/trademe/get_feedback_settings`;
    
    return apiCall.get(url, options);
}

export { getFeedbackSettings };