import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import {
    Icon,
    Form,
    Button
} from 'tabler-react';
import {
    useFormik
} from 'formik';
import * as marketplaceCtrl from '../../services/marketplace';
import {
    toast
} from 'react-toastify';
import {
    CustomToast,
    CustomSelect
} from '../../components';
import {
    CustomLabel
} from '../CustomLabel';
import * as logger from '../../services/log';
import '../CustomModal.css';
import "./ShippingMethodModal.scss";

const ShippingMethodModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [formLoaded, setFormLoaded] = useState(false);
    const [shipInternationally, setShipInternationally] = useState(false);

    const handlingDaysOptions = [
        {
            label: "Same business day",
            value: 0
        },
        {
            label: "1 business day",
            value: 1
        },
        {
            label: "2 business days",
            value: 2
        },
        {
            label: "3 business days",
            value: 3
        },
        {
            label: "4 business days",
            value: 4
        },
        {
            label: "5 business days",
            value: 5
        }
    ];

    useEffect(() => {

        if (props.shippingModalSettings.shippingPolicy) {

            setIsEdit(true);

            formik.setValues({
                handlingDays: props.shippingModalSettings.shippingPolicy.handlingDays,
                name: props.shippingModalSettings.shippingPolicy.name,
                domesticPrice: props.shippingModalSettings.shippingPolicy.domesticPrice,
                internationalPrice: props.shippingModalSettings.shippingPolicy.internationalPrice,
                id: props.shippingModalSettings.shippingPolicy.id
            });
        }
        else {
            formik.setValues({
                handlingDays: 1,
                name: "",
                domesticPrice: "",
                internationalPrice: ""
            });
        }



        setFormLoaded(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.shippingModalSettings]);

    function closeModal() {
        props.hideModal();
    }

    function shipInternationallyChanged() {
        setShipInternationally(prevState => !prevState);
    }

    const validate = values => {

        const errors = {};

        setFormSubmitted(true);

        if (values.name === undefined || values.name === null || values.name.trim() === "") {
            errors.name = "Required";
        }
        else if (values.name.length > 100) {
            errors.name = "Max length is 100 characters.";
        }
        else if (values.name.trim().length < 3) {
            errors.name = "Min length is 3 characters.";
        }

        if (values.handlingDays === null || values.handlingDays === undefined || values.handlingDays === "") {
            errors.handlingDaysError = "Required";
        }

        if (values.domesticPrice === null || values.domesticPrice === undefined || values.domesticPrice === "") {
            errors.domesticPriceError = "Required";
        }

        if (shipInternationally && (values.internationalPrice === null || values.internationalPrice === undefined || values.internationalPrice === "")) {
            errors.internationalPriceError = "Required";
        }

        return errors;
    }

    const save = async (values) => {
        try {

            setFormSaving(true);

            let model = {
                handlingDays: parseInt(values.handlingDays),
                name: values.name,
                domesticPrice: parseFloat(values.domesticPrice),
                internationalPrice: shipInternationally ? parseFloat(values.internationalPrice) : null,
                id: (values.id && parseInt(values.id)) || 0
            }

            await marketplaceCtrl.saveShippingPolicy(props.app, model);

            toast.success(<CustomToast message={`Shipping method saved.`} />, { position: toast.POSITION.TOP_RIGHT });

            props.refreshShippingMethods();
            closeModal();
        }
        catch (e) {
            logger.logError("ShippingMethodModal.save() error", e);
        }
        finally {
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        validateOnMount: false,
        validateOnBlur: formSubmitted,
        validateOnChange: formSubmitted,
        onSubmit: async (values) => {
            await save(values);
        },
        validate
    });

    if (!formLoaded) {
        return null;
    }

    return (
        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick={!formSaving}
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>{isEdit ? "Edit" : "Add"} Shipping Policy</h2>
            <hr className="divider"></hr>

            <div className="shipping-method-modal">
                <Form autocomplete="off" onSubmit={formik.handleSubmit}>

                    <Form.Group>
                        <CustomLabel place="right" name="Name" id="shipping-policy-name" message="Choose a name for this shipping method." />
                        <Form.Input
                            name="name"
                            error={formik.errors.name}
                            disabled={formSaving}
                            onChange={formik.handleChange}
                            value={formik.values.name || ""}
                        />
                    </Form.Group>

                    <Form.Group>
                        <CustomLabel place="right" name="Handling Time" id="handling-days-tooltip" message="Number of days before item is dispatched." />
                        <CustomSelect
                            value={handlingDaysOptions ? handlingDaysOptions.find(option => /* eslint eqeqeq: 0 */ option.value === parseInt(formik.values.handlingDays)) : ""}
                            isDisabled={formSaving}
                            error={formik.errors.handlingDaysError}
                            options={handlingDaysOptions}
                            onChange={(option) => formik.setFieldValue("handlingDays", (option && option.value) || "")}
                        />
                    </Form.Group>


                    <Form.Group>
                        <CustomLabel place="right" name="Domestic Price" id="shipping-policy-domestic-price" message="Enter 0.00 for free shipping." />
                        <Form.Input
                            type="number"
                            name="domesticPrice"
                            min="0.00"
                            max="9999"
                            error={formik.errors.domesticPriceError}
                            disabled={formSaving}
                            onChange={formik.handleChange}
                            value={(formik.values.domesticPrice === 0 ? "0.00" : (formik.values.domesticPrice || ""))}
                        />
                    </Form.Group>

                    <Form.Group>
                        <CustomLabel id="shipping-policy-international-price" name="Ship Internationally" message="Enter 0.00 for free shipping." />
                        <Form.Radio
                            isInline
                            disabled={formSaving}
                            label="Yes"
                            name="shipInternationally"
                            value="yes"
                            onChange={() => shipInternationallyChanged()}
                            checked={shipInternationally}
                        />
                        <Form.Radio
                            isInline
                            disabled={formSaving}
                            label="No"
                            name="shipInternationally"
                            value="no"
                            onChange={() => shipInternationallyChanged()}
                            checked={!shipInternationally}
                        />
                    </Form.Group>

                    {
                        shipInternationally ?
                            <Form.Group>
                                <CustomLabel place="right" name="International Price" id="name-tooltip" />
                                <Form.Input
                                    type="number"
                                    name="internationalPrice"
                                    min="0.00"
                                    max="9999"
                                    error={formik.errors.internationalPriceError}
                                    disabled={formSaving}
                                    onChange={formik.handleChange}
                                    value={(formik.values.internationalPrice === 0 ? "0.00" : (formik.values.internationalPrice || ""))}
                                />
                            </Form.Group> : null
                    }

                    <div className="shipping-method-modal-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                        </div>
                    </div>
                </Form>

            </div>

        </Modal>
    )
}

export { ShippingMethodModal };