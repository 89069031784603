import React from 'react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { Page, Grid } from "tabler-react";
import { InventoryLocationsInner } from './InventoryLocationsInner';
import "./InventoryLocations.scss";

const InventoryLocations = (props) => {

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />
                        <InventoryLocationsInner app={props.app} />
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    );
}

export { InventoryLocations };