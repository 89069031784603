import { specialRuleType } from '../../constants';

function getSpecialRuleTypes() {
    const specialRuleTypes = [
        { label: "Remove HTML Tags", value: specialRuleType.removeHtmlTags },
        { label: "Apply Title Case", value: specialRuleType.applyTitleCase },
        { label: "Apply Sentence Case", value: specialRuleType.applySentenceCase },
        { label: "Replace Text", value: specialRuleType.replaceText }
    ];

    return specialRuleTypes;
}

export { getSpecialRuleTypes };