import React, { useEffect, useState } from 'react';
import * as dashboardCtrl from '../../services/dashboard';
import { 
    Widget, 
    WidgetBody, 
    WidgetHeader 
} from './';
import moment from 'moment-timezone';

const ShoppingFeedWidget = ({ userWidget }) => {

    const [loading, setLoading] = useState(true);
    const [widgetData, setWidgetData] = useState({});

    useEffect(() => {

        refreshWidget();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshWidget = async() => {
        setLoading(true);
        let widget = await dashboardCtrl.getShoppingFeedWidget(userWidget.widget.appId);

        setWidgetData(widget);
        setLoading(false);
    }

    const formatDateFromUtc = (date) => {

        if (!date) {
            return null;
        }

        let utcDate = moment.utc(date).toDate();

        let localDate = moment(utcDate).local().format("DD-MMM-YYYY HH:mm").toUpperCase();

        return localDate;
    }

    return (
        <Widget loading={loading} userWidget={userWidget}>
            <WidgetHeader userWidget={userWidget} refreshWidget={refreshWidget} />
            <WidgetBody loading={loading}>
                {
                    !loading ?
                        <React.Fragment>
                            {
                                Array.isArray(widgetData.templateFeedHistory) && widgetData.templateFeedHistory.length > 0 ?
                                    widgetData.templateFeedHistory.map((tfh, i) => (
                                        <div key={i.toString()} style={{ marginTop: 20, minWidth: 300 }}>
                                            <div className="row-outer">
                                                <div className="row-inner">
                                                    <div className="row-name">
                                                        Template
                                                    </div>
                                                    <div style={{ fontWeight: "bold" }} className="row-value">
                                                        {
                                                            tfh.templateName
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row-outer">
                                                <div className="row-inner row-border">
                                                    <div className="row-name">
                                                        Last Run Date
                                                    </div>
                                                    {
                                                        tfh.createdDate ?
                                                            <div className="row-value">
                                                                {
                                                                    formatDateFromUtc(tfh.createdDate)
                                                                }
                                                            </div>
                                                            :
                                                            <div className="row-value">-</div>
                                                    }
                                                </div>
                                            </div>
                                            {
                                                tfh.productCount ?
                                                    <div className="row-outer">
                                                        <div className="row-inner row-border">
                                                            <div className="row-name">
                                                                Products
                                                            </div>
                                                            <div className="row-value">
                                                                {
                                                                    tfh.productCount
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                tfh.productVariationCount ?
                                                    <div className="row-outer">
                                                        <div className="row-inner row-border">
                                                            <div className="row-name">
                                                                Variations
                                                            </div>
                                                            <div className="row-value">
                                                                {
                                                                    tfh.productVariationCount
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }

                                            {
                                                tfh.durationSeconds ?
                                                    <div className="row-outer">
                                                        <div className="row-inner row-border">
                                                            <div className="row-name">
                                                                Run Time
                                                            </div>
                                                            <div className="row-value">{tfh.durationSeconds.toFixed(2)} seconds</div>
                                                        </div>
                                                    </div>
                                                    :
                                                    null
                                            }



                                        </div>)) : null
                            }
                        </React.Fragment>

                        : null
                }

            </WidgetBody>
        </Widget>);
}

export { ShoppingFeedWidget };