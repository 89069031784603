import React, { useState, useEffect } from 'react';
import SiteWrapper from '../SiteWrapper.react';
import {
    SettingsMenu
} from '../SettingsMenu';
import {
    FormLoader
} from '../FormLoader';
import {
    Page,
    Loader,
    Card,
    Grid,
    Form
} from "tabler-react";
import * as ebayCtrl from '../../services/ebay';
import {
    itemSpecificsRuleConditionType
} from '../../constants';
import {
    toast
} from 'react-toastify';
import {
    CustomToast,
    CustomSelect,
    CustomCreatableMulti
} from '../../components';
import {
    ItemSpecificsValueRecommendationModal
} from './ItemSpecificsValueRecommendationModal';
import * as logger from '../../services/log';
import {
    SaveToolbar
} from '../SaveToolbar';
import * as templateCtrl from '../../services/template';
import "./ItemSpecifics.scss";

let formSubmitted = {};

const ItemSpecifics = (props) => {

    const [loading, setLoading] = useState(true);
    const [itemSpecifics, setItemSpecifics] = useState([]);
    const [formSaving, setFormSaving] = useState(false);
    const [itemSpecificsRules, setItemSpecificsRules] = useState({});
    const [errors, setErrors] = useState({});
    const [showValueRecommendationModalSettings, setShowValueRecommendationModalSettings] = useState({ show: false });

    const selectHeight = 43;

    const initialise = async () => {
        try {

            setItemSpecificsRules({});

            let itemSpecifics = await ebayCtrl.getItemSpecifics(props.app);
            setItemSpecifics(itemSpecifics);

            let accordions = document.getElementsByClassName("accordion");

            if (accordions && accordions.length > 0) {
                for (let i = 0; i < accordions.length; i++) {
                    accordions[i].classList.remove("active");

                    var panel = accordions[i].nextElementSibling;
                    if (panel.style.display === "block") {
                        panel.style.display = "none";
                    }
                }
            }

            setLoading(false);

        }
        catch (e) {
            logger.logError("ItemSpecifics useEffect() error", e);
            setLoading(false);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await initialise();
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const itemSpecificsRuleConditionTypes = templateCtrl.getRuleTypes();

    function arrayMove(oldArr, old_index, new_index, categoryRecommendationId) {

        if (new_index < 0) {
            return;
        }

        if (new_index > oldArr.length - 1) {
            return;
        }

        let arr = [...oldArr];

        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            rulesCopy[categoryRecommendationId] = arr;
            return rulesCopy;
        });

        let elem = document.getElementById(`mapping-${categoryRecommendationId}-${new_index}`);
        elem.classList.add("rule-container-animate");

        setTimeout(() => {
            elem.classList.remove("rule-container-animate");
        }, 1000);
    };

    const validate = (categoryRecommendationId, itemSpecificsRulesCopy, submitAction) => {
        const errors = {};
        let isValid = true;

        if (!formSubmitted[categoryRecommendationId] && !submitAction) {
            return;
        }

        errors[categoryRecommendationId] = {};

        for (let i = 0; i < itemSpecificsRulesCopy.length; i++) {

            let r = itemSpecificsRulesCopy[i];

            if (!errors[categoryRecommendationId][i]) {
                errors[categoryRecommendationId][i] = { conditions: {} };
            }

            if (!r.categorySpecificsId || r.categorySpecificsId === "") {
                errors[categoryRecommendationId][i]["categorySpecificsId"] = "Required";
                isValid = false;
            }
            else if (errors[categoryRecommendationId][i]) {
                delete (errors[categoryRecommendationId][i]["categorySpecificsId"]);
            }

            if (r.selectedCustomValues === undefined || r.selectedCustomValues === null || r.selectedCustomValues.length === 0) {
                errors[categoryRecommendationId][i]["selectedCustomValues"] = "Required";

                isValid = false;
            }
            else if (errors[categoryRecommendationId][i]) {
                delete (errors[categoryRecommendationId][i]["selectedCustomValues"]);
            }

            // rule validation
            if (r.ebayItemSpecificsRuleClauses && r.ebayItemSpecificsRuleClauses.length > 0) {
                for (let j = 0; j < r.ebayItemSpecificsRuleClauses.length; j++) {
                    let c = r.ebayItemSpecificsRuleClauses[j];

                    if (!errors[categoryRecommendationId][i]["conditions"]) {
                        errors[categoryRecommendationId][i]["conditions"] = {};
                    }

                    if (!errors[categoryRecommendationId][i]["conditions"][j]) {
                        errors[categoryRecommendationId][i]["conditions"][j] = {};
                    }

                    if (!c.selectedProductAttribute || c.selectedProductAttribute === "") {
                        errors[categoryRecommendationId][i]["conditions"][j]["selectedProductAttribute"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[categoryRecommendationId][i]["conditions"][j]["selectedProductAttribute"]);
                    }

                    if (!c.itemSpecificsRuleConditionTypeId || c.itemSpecificsRuleConditionTypeId === "") {
                        errors[categoryRecommendationId][i]["conditions"][j]["itemSpecificsRuleConditionTypeId"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[categoryRecommendationId][i]["conditions"][j]["itemSpecificsRuleConditionTypeId"]);
                    }
                }
            }
        }

        setErrors(errors);

        return isValid;
    }

    function getErrorMessage(categoryRecommendationId, fieldName, mappingIndex, conditionIndex = null) {

        if (conditionIndex === null) {
            return (formSubmitted[categoryRecommendationId] && errors[categoryRecommendationId] && errors[categoryRecommendationId][mappingIndex] && errors[categoryRecommendationId][mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted[categoryRecommendationId] && errors[categoryRecommendationId] && errors[categoryRecommendationId][mappingIndex] && errors[categoryRecommendationId][mappingIndex]["conditions"] && errors[categoryRecommendationId][mappingIndex]["conditions"][conditionIndex] && errors[categoryRecommendationId][mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    async function loadCategorySpecificsRules(categoryRecommendationId) {

        if (!itemSpecificsRules[categoryRecommendationId]) {
            let rules = await ebayCtrl.getItemSpecificsRules(props.app, categoryRecommendationId);

            if (!rules || rules.length === 0) {
                rules = [];
            }

            setItemSpecificsRules((prevState) => {
                let rulesCopy = { ...prevState };

                rulesCopy[categoryRecommendationId] = rules;
                return rulesCopy;
            });
        }

        return itemSpecificsRules[categoryRecommendationId];

    }

    function addNewSpecificsRule(categoryRecommendationId) {

        if (formSaving) {
            return;
        }

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            rulesCopy[categoryRecommendationId].push({ setSpecificTo: "CUSTOM_VALUE" });

            return rulesCopy;
        });
    }

    function removeSpecificsRule(categoryRecommendationId, idx) {

        if (formSaving) {
            return;
        }

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            rulesCopy[categoryRecommendationId].splice(idx, 1);

            return rulesCopy;
        });
    }

    async function handleAccordionClick(e, categoryRecommendationId) {
        e.currentTarget.classList.toggle("active");

        var panel = e.currentTarget.nextElementSibling;
        if (panel.style.display === "block") {
            panel.style.display = "none";
        } else {
            panel.style.display = "block";
        }

        await loadCategorySpecificsRules(categoryRecommendationId);
    }

    function specificsFieldChanged(categoryRecommendationId, index, fieldName, value) {

        if (formSaving) {
            return;
        }

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            if (fieldName === "selectedProductAttribute") {
                rulesCopy[categoryRecommendationId][index].customValue = null;
            }
            else if (fieldName === "customValue") {
                rulesCopy[categoryRecommendationId][index].selectedProductAttribute = null;
                rulesCopy[categoryRecommendationId][index].selectedProductAttributeType = null;
            }

            if (fieldName === "selectedCustomValues") {
                rulesCopy[categoryRecommendationId][index].selectedCustomValues = null;
            }

            rulesCopy[categoryRecommendationId][index][fieldName] = value;

            return rulesCopy;
        });

        validate(categoryRecommendationId, itemSpecificsRules);
    }

    function specificsConditionFieldChange(categoryRecommendationId, mappingIndex, conditionIndex, fieldName, value) {

        if (formSaving) {
            return;
        }

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            rulesCopy[categoryRecommendationId][mappingIndex].ebayItemSpecificsRuleClauses[conditionIndex][fieldName] = value;

            return rulesCopy;
        });

        validate(categoryRecommendationId, itemSpecificsRules);
    }

    function addCondition(categoryRecommendationId, mappingIndex) {

        if (formSaving) {
            return;
        }

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            if (rulesCopy[categoryRecommendationId][mappingIndex].ebayItemSpecificsRuleClauses === undefined) {
                rulesCopy[categoryRecommendationId][mappingIndex].ebayItemSpecificsRuleClauses = [{}];
            }

            rulesCopy[categoryRecommendationId][mappingIndex].ebayItemSpecificsRuleClauses.push({});

            return rulesCopy;
        });
    }

    function removeCondition(categoryRecommendationId, mappingIndex, conditionIndex) {

        if (formSaving) {
            return;
        }

        setItemSpecificsRules((prevState) => {
            let rulesCopy = { ...prevState };

            rulesCopy[categoryRecommendationId][mappingIndex].ebayItemSpecificsRuleClauses.splice(conditionIndex, 1);

            return rulesCopy;
        });
    }

    async function saveMappings() {

        try {

            setFormSaving(true);

            for (let categoryRecommendationId in itemSpecificsRules) {

                logger.logInfo(`Saving item specifics. categoryRecommendationId = ${categoryRecommendationId}`);

                let itemSpecificsRulesCopy = (itemSpecificsRules && [...(itemSpecificsRules[categoryRecommendationId])]) || [];

                formSubmitted[categoryRecommendationId] = true;

                let isValid = validate(categoryRecommendationId, itemSpecificsRulesCopy, true);

                if (!isValid) {

                    toast.error(<CustomToast message="There is one or more validation errors on the page. Please review form and try again." />, { position: toast.POSITION.TOP_RIGHT });

                    setFormSaving(false);

                    return;
                }

                // massage data before sending to API.
                for (let i = 0; i < itemSpecificsRulesCopy.length; i++) {
                    let r = itemSpecificsRulesCopy[i];

                    if (typeof r.categorySpecificsId === "string") {
                        r.categorySpecificsId = parseInt(r.categorySpecificsId);
                    }

                    r.sortOrder = i + 1;

                    if (r.ebayItemSpecificsRuleClauses && r.ebayItemSpecificsRuleClauses.length > 0) {
                        for (let j = 0; j < r.ebayItemSpecificsRuleClauses.length; j++) {
                            let c = r.ebayItemSpecificsRuleClauses[j];

                            if (typeof c.itemSpecificsRuleConditionTypeId === "string") {
                                c.itemSpecificsRuleConditionTypeId = parseInt(c.itemSpecificsRuleConditionTypeId);
                            }
                        }
                    }
                }

                let model = {
                    categoryRecommendationId: categoryRecommendationId,
                    ebayItemSpecificsRules: itemSpecificsRulesCopy
                };

                await ebayCtrl.saveItemSpecificsRules(props.app, model);

            }

            toast.success(<CustomToast message="Mappings saved." />, { position: toast.POSITION.TOP_RIGHT });

            setFormSaving(false);
        }
        catch (e) {
            logger.logError(`Ebay ItemSpecifics saveMappings() error`, e);

            setFormSaving(false);
        }
    }

    function showRecommendedValues(itemSpecificRule, ebayCategorySpecifics) {

        var categorySpecificsName = ebayCategorySpecifics.find(ecs => ecs.id === itemSpecificRule.categorySpecificsId).name;
        setShowValueRecommendationModalSettings({ show: true, categorySpecificsId: itemSpecificRule.categorySpecificsId, categorySpecificsName })
    }

    const getValueFieldOptions = (itemSpecifics) => {

        return itemSpecifics.valueFields.map(vf => ({
            label: vf.value,
            value: vf.value
        }));
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={"Auto Item Specifics"} />)
    }

    return (
        <SiteWrapper>
            <Page.Content>

                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="item-specifics" loading={loading}>
                                <Card.Header>
                                    <h4>Auto Item Specifics</h4>
                                </Card.Header>
                                <div className="card-inner">

                                    {
                                        !loading && (!itemSpecifics.categoryRecommendations || itemSpecifics.categoryRecommendations.length === 0) &&
                                        <div className="no-category-mappings-message">
                                            No Ebay category mappings exist. Please update Category Mappings for your store.
                                        </div>
                                    }

                                    {
                                        !loading &&
                                        itemSpecifics.categoryRecommendations &&
                                        itemSpecifics.categoryRecommendations.length > 0 &&
                                        itemSpecifics.categoryRecommendations.map(cr => {

                                            return (
                                                <div id={`rec-${cr.id}`} key={cr.id}>
                                                    <button onClick={(e) => handleAccordionClick(e, cr.id)} className="accordion">
                                                        {cr.ebayCategoryPath}
                                                    </button>
                                                    <div className="panel">

                                                        {
                                                            !itemSpecificsRules[cr.id] &&
                                                            <div className="specifics-loader">
                                                                <Loader />
                                                            </div>
                                                        }

                                                        {
                                                            itemSpecificsRules[cr.id] && itemSpecificsRules[cr.id].length === 0 &&
                                                            <div className="no-specifics">There are no item specifics mappings to show.</div>
                                                        }

                                                        {
                                                            itemSpecificsRules[cr.id] && itemSpecificsRules[cr.id].map((itemSpecificRule, i) => {

                                                                return (
                                                                    <div className="rule-container" key={i} id={`mapping-${cr.id}-${i}`}>
                                                                        <div className="mapping-header">
                                                                            <h3>Mapping #{i + 1} <i title="Remove mapping" onClick={() => removeSpecificsRule(cr.id, i)} className="fa fa-minus-circle remove-mapping-icon"></i></h3>
                                                                            <div className="sort-icon-wrapper">
                                                                                <i onClick={(e) => arrayMove(itemSpecificsRules[cr.id], i, i - 1, cr.id)} title="Move up one position. Rules at the top will be evaluated first." className={`fal fa-chevron-up sort-up${i === 0 ? " hover-ban" : ""}`}></i>
                                                                                <i onClick={(e) => arrayMove(itemSpecificsRules[cr.id], i, i + 1, cr.id)} title="Move down one position. Rules at the bottom will be evaluated first." className={`fal fa-chevron-down sort-down${i === itemSpecificsRules[cr.id].length - 1 ? " hover-ban" : ""}`}></i>
                                                                            </div>
                                                                        </div>

                                                                        <div className="item-specific-rule">
                                                                            <div className="specific-col">
                                                                                <div className="set-specific">SET ITEM SPECIFIC</div>

                                                                                <CustomSelect
                                                                                    height={selectHeight}
                                                                                    value={cr.ebayCategorySpecifics ? cr.ebayCategorySpecifics.map(c => ({ label: c.name, value: c.id })).find(option => /* eslint eqeqeq: 0 */ option.value == itemSpecificRule.categorySpecificsId) : ""}
                                                                                    isDisabled={formSaving}
                                                                                    error={getErrorMessage(cr.id, "categorySpecificsId", i)}
                                                                                    options={cr.ebayCategorySpecifics.map(c => ({ label: c.name, value: c.id }))}
                                                                                    onChange={(option) => specificsFieldChanged(cr.id, i, "categorySpecificsId", (option && option.value) || "")}
                                                                                />
                                                                            </div>

                                                                            <div className="specific-col">
                                                                                <div className="set-specific">TO {itemSpecificRule.categorySpecificsId && <span onClick={() => showRecommendedValues(itemSpecificRule, cr.ebayCategorySpecifics)} className="anchor">Show Recommendations</span>}</div>
                                                                                <CustomCreatableMulti
                                                                                    hideSelectedOptions={false}
                                                                                    isDisabled={formSaving}
                                                                                    height={selectHeight}
                                                                                    error={getErrorMessage(cr.id, "selectedCustomValues", i)}
                                                                                    options={getValueFieldOptions(itemSpecifics)}
                                                                                    values={itemSpecificRule.selectedCustomValues}
                                                                                    handleChange={(values) => { specificsFieldChanged(cr.id, i, "selectedCustomValues", values) }} />
                                                                            </div>

                                                                        </div>

                                                                        <div className="specific-conditions">

                                                                            {
                                                                                itemSpecificRule.ebayItemSpecificsRuleClauses &&
                                                                                itemSpecificRule.ebayItemSpecificsRuleClauses.length > 0 &&
                                                                                <div style={{ marginBottom: 10, marginTop: 20 }} className="set-specific">WHEN</div>
                                                                            }

                                                                            {
                                                                                itemSpecificRule.ebayItemSpecificsRuleClauses &&
                                                                                itemSpecificRule.ebayItemSpecificsRuleClauses.length > 0 &&
                                                                                itemSpecificRule.ebayItemSpecificsRuleClauses.map((clause, j) => {
                                                                                    return (
                                                                                        <React.Fragment key={j}>
                                                                                            {
                                                                                                j >= 1 &&
                                                                                                <div className="specifics-and-condition">
                                                                                                    <strong>AND</strong>
                                                                                                </div>
                                                                                            }

                                                                                            <div className="item-specific-condition">
                                                                                                <div className="specific-col">
                                                                                                    <div className="condition-header">ATTRIBUTE</div>

                                                                                                    <CustomSelect
                                                                                                        height={selectHeight}
                                                                                                        value={clause.selectedProductAttribute ? getValueFieldOptions(itemSpecifics).find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                                                        isDisabled={formSaving}
                                                                                                        error={getErrorMessage(cr.id, "selectedProductAttribute", i, j)}
                                                                                                        options={getValueFieldOptions(itemSpecifics)}
                                                                                                        onChange={(option) => {
                                                                                                            let value = (option && option.value) || "";
                                                                                                            specificsConditionFieldChange(cr.id, i, j, "selectedProductAttribute", value);
                                                                                                        }}
                                                                                                    />
                                                                                                </div>

                                                                                                <div className="specific-col">
                                                                                                    <div className="condition-header">CONDITION</div>

                                                                                                    <CustomSelect
                                                                                                        height={selectHeight}
                                                                                                        value={itemSpecificsRuleConditionTypes ? itemSpecificsRuleConditionTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.itemSpecificsRuleConditionTypeId) : ""}
                                                                                                        isDisabled={formSaving}
                                                                                                        error={getErrorMessage(cr.id, "itemSpecificsRuleConditionTypeId", i, j)}
                                                                                                        options={itemSpecificsRuleConditionTypes}
                                                                                                        onChange={(option) => specificsConditionFieldChange(cr.id, i, j, "itemSpecificsRuleConditionTypeId", (option && option.value) || "")}
                                                                                                    />

                                                                                                </div>

                                                                                                {
                                                                                                    clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                                                    clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&

                                                                                                    <div className="condition-header">
                                                                                                        <div className="set-specific">VALUE</div>
                                                                                                        <Form.Input
                                                                                                            disabled={formSaving}
                                                                                                            value={clause.customValue || ""}
                                                                                                            onChange={(e) => {
                                                                                                                var value = e.target.value;
                                                                                                                specificsConditionFieldChange(cr.id, i, j, "customValue", value);
                                                                                                            }}
                                                                                                        />
                                                                                                    </div>
                                                                                                }

                                                                                                <div className="remove-condition-icon-container"><i title="Remove condition" onClick={() => removeCondition(cr.id, i, j)} className="fa fa-minus-circle remove-condition-icon"></i></div>

                                                                                            </div>
                                                                                        </React.Fragment>

                                                                                    );
                                                                                })

                                                                            }

                                                                            <div onClick={() => { addCondition(cr.id, i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }

                                                        {
                                                            itemSpecificsRules[cr.id] &&
                                                            <React.Fragment>
                                                                <div onClick={() => addNewSpecificsRule(cr.id)} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Mapping</div>
                                                            </React.Fragment>
                                                        }

                                                    </div>
                                                </div>

                                            );
                                        })
                                    }
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>

                {
                    showValueRecommendationModalSettings.show &&
                    <ItemSpecificsValueRecommendationModal app={props.app} showValueRecommendationModalSettings={showValueRecommendationModalSettings} hideModal={() => setShowValueRecommendationModalSettings({ show: false })} />
                }

                <SaveToolbar show undoAction={() => initialise()} saveAction={() => saveMappings()} formSaving={formSaving} />

            </Page.Content>
        </SiteWrapper>

    );
}

export { ItemSpecifics };