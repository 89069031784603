import * as apiCall from '../apiCall';

function authoriseAccount(appId, model) {

    let options = {
        appId
    };

    return apiCall.post(`/api/catch/authorise_account`, model, options);
}

export { authoriseAccount };