import { itemSpecificsRuleConditionType } from '../../constants';

function getRuleTypes() {
    const templateRuleTypes = [
        { label: "Contains", value: itemSpecificsRuleConditionType.contains },
        { label: "Does Not Contain", value: itemSpecificsRuleConditionType.doesNotContain },
        { label: "Equals", value: itemSpecificsRuleConditionType.equals },
        { label: "Does Not Equal", value: itemSpecificsRuleConditionType.doesNotEqual },
        { label: "Starts With", value: itemSpecificsRuleConditionType.startsWith },
        { label: "Ends With", value: itemSpecificsRuleConditionType.endsWith },
        { label: "Less Than", value: itemSpecificsRuleConditionType.lessThan },
        { label: "Less Than Or Equal To", value: itemSpecificsRuleConditionType.lessThanOrEqualTo },
        { label: "Greater Than", value: itemSpecificsRuleConditionType.greaterThan },
        { label: "Greater Than Or Equal To", value: itemSpecificsRuleConditionType.greaterThanOrEqualTo },
        { label: "Regex Match", value: itemSpecificsRuleConditionType.regexMatch },
        { label: "Is Empty", value: itemSpecificsRuleConditionType.isEmpty },
        { label: "Not Empty", value: itemSpecificsRuleConditionType.notEmpty },
        { label: "Character Count Equals", value: itemSpecificsRuleConditionType.characterCountEquals },
        { label: "Character Count Less Than", value: itemSpecificsRuleConditionType.characterCountLessThan },
        { label: "Character Count Greater Than", value: itemSpecificsRuleConditionType.characterCountGreaterThan },
        { label: "Character Count Less Than Or Equal To", value: itemSpecificsRuleConditionType.characterCountLessThanOrEqualTo },
        { label: "Character Count Greater Than Or Equal To", value: itemSpecificsRuleConditionType.characterCountGreaterThanOrEqualTo }
    ];

    return templateRuleTypes;
}

export { getRuleTypes };