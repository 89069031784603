import React, { useEffect, useState } from 'react';
import * as dashboardCtrl from '../../services/dashboard';
import {
    Widget,
    WidgetBody,
    WidgetHeader
} from './';
import moment from 'moment-timezone';
import {
    Link
} from 'react-router-dom';
import * as chartUtility from '../../utilities/chartUtility';
import {
    WidgetChartRefresher
} from '../WidgetChartRefresher';

const EbayWidget = ({ userWidget }) => {

    const [loading, setLoading] = useState(true);
    const [ebayData, setEbayData] = useState({});

    useEffect(() => {

        refreshWidget();

        let widgetRefreshId = 'widgetRefresh';

        let widgetRefreshEvent = window.addEventListener(widgetRefreshId, (e) => {
            refreshWidget();
        });

        return () => {
            window.removeEventListener(widgetRefreshId, widgetRefreshEvent);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const refreshWidget = async () => {

        setLoading(true);

        var tz = moment.tz.guess();
        let encodedTz = encodeURIComponent(tz);

        let ebayData = await dashboardCtrl.getEbayWidget(encodedTz, userWidget.widget.appId);

        setEbayData(ebayData);

        setLoading(false);

        chartUtility.bindChart(ebayData, `#${ebayData.chartWrapperId}`);
    }

    return (
        <Widget loading={loading} userWidget={userWidget}>
            <WidgetHeader userWidget={userWidget} refreshWidget={refreshWidget} />

            <WidgetBody loading={loading}>

                <WidgetChartRefresher widgetData={ebayData} />

                {
                    !loading ?
                        <div>
                            <div>

                                <div className="row-outer">
                                    <div className="row-inner row-border">
                                        <div className="row-name">
                                            Active
                                        </div>
                                        <div className="row-value bold">
                                            {ebayData.activeListings === 0 && ebayData.activeListings}
                                            {
                                                ebayData.activeListings > 0 && <Link className="active-link" to={`/marketplaces/${ebayData.slug}/products?filterByActive=true`}>{ebayData.activeListings}</Link>
                                            }

                                        </div>
                                    </div>
                                </div>
                                <div className="row-outer">
                                    <div className="row-inner">
                                        <div className="row-name">
                                            Errors
                                        </div>
                                        <div className="row-value bold">
                                            {
                                                ebayData.listingsWithErrors === 0 && ebayData.listingsWithErrors
                                            }
                                            {
                                                ebayData.listingsWithErrors > 0 && <Link className="errors-link" to={`/marketplaces/${ebayData.slug}/products?filterByErrors=true`}>{ebayData.listingsWithErrors}</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="row-outer">
                                    <div className="row-inner">
                                        <div className="row-name">
                                            Sync Pending
                                        </div>
                                        <div className="row-value bold">
                                            {
                                                ebayData.needToSyncCount === 0 && ebayData.needToSyncCount
                                            }
                                            {
                                                ebayData.needToSyncCount > 0 && <Link className="misc-link" to={`/marketplaces/${ebayData.slug}/products?filterByPendingSync=true`}>{ebayData.needToSyncCount}</Link>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="sales-inner">
                                <div className="chart-wrapper" id={ebayData.chartWrapperId}></div>

                                <div className="sales-summary-outer sales-summary-border">
                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Today
                                        </div>
                                        <div className="period-value">
                                            ${ebayData.lastDayTotalSales?.toFixed(2)} ({ebayData.lastDayTotalUnits})
                                        </div>
                                    </div>
                                </div>

                                <div className="sales-summary-outer sales-summary-border">
                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Last 7 Days
                                        </div>
                                        <div className="period-value">
                                            ${ebayData.last7DaysTotalSales?.toFixed(2)} ({ebayData.last7DaysTotalUnits})
                                        </div>
                                    </div>
                                </div>

                                <div className="sales-summary-outer sales-summary-border">
                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Last 30 Days
                                        </div>
                                        <div className="period-value">
                                            ${ebayData.last30DaysTotalSales?.toFixed(2)} ({ebayData.last30DaysTotalUnits})
                                        </div>
                                    </div>
                                </div>

                                <div className="sales-summary-outer">

                                    <div className="sales-summary">
                                        <div className="period-name">
                                            Last 90 Days
                                        </div>
                                        <div className="period-value">
                                            ${ebayData.last90DaysTotalSales?.toFixed(2)} ({ebayData.last90DaysTotalUnits})
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> : null
                }

            </WidgetBody>

        </Widget>);
}

export { EbayWidget };