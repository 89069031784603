import React, { useState } from 'react';
import {
    storeIntegrationType
} from '../constants';
import * as subscribeCtrl from '../services/subscribe';
import {
    Redirect
} from '@shopify/app-bridge/actions';
import {
    useSelector
} from "react-redux";
import * as shopifyUtility from '../utilities/shopifyUtility';
import "./Subscription.scss";

const SubscriptionCard = ({ planDetail, onClick }) => {

    const user = useSelector(state => state.user);
    const [planLoading, setPlanLoading] = useState(false);
    

    const onSubscribe = async (planDetail) => {

        if (planLoading) {
            return;
        }

        setPlanLoading(true);

        let result = await subscribeCtrl.create(planDetail);

        switch (result.storeIntegrationTypeId) {
            case storeIntegrationType.Shopify:

                let app = shopifyUtility.getShopifyApp(user);
                const redirect = Redirect.create(app);
                redirect.dispatch(Redirect.Action.REMOTE, {url: result.confirmationUrl});

                return;

            default:
                window.location.href = result.confirmationUrl;
                return;
        }
    }

    return (<div onClick={onClick} className="subscription-card">
        <div style={{ flex: 1 }}>
            <div className="plan-name">
                {planDetail.name}
            </div>
            <div className="plan-price">
                {planDetail.price} {!planDetail.isEnterprise ? <small> / month</small> : null}
            </div>
            {
                !user.info.subscriptionPlanId && !planDetail.isEnterprise ?
                    <div>
                        <div className="plan-attribute">
                            <i className="fa fa-check plan-check" />&nbsp;&nbsp;14 day <b>free</b> trial. Cancel any time.
                        </div>
                    </div>
                    : null
            }
            {
                planDetail.products ?
                    <div className="plan-attribute">
                        <i className="fa fa-check plan-check" />&nbsp;&nbsp;{planDetail.products} Products
                    </div>
                    : null
            }
            {
                planDetail.shoppingFeeds ?
                    <div className="plan-attribute">
                        <i className="fa fa-check plan-check" />&nbsp;&nbsp;{planDetail.shoppingFeeds} Shopping feeds
                    </div>
                    : null
            }
            {
                planDetail.marketplaces ?
                    <div className="plan-attribute">
                        <i className="fa fa-check plan-check" />&nbsp;&nbsp;{planDetail.marketplaces} Marketplace{planDetail.marketplaces !== "1" && planDetail.marketplaces !== 1 ? "s" : ""}
                    </div>
                    : null
            }

            {
                planDetail.isEnterprise ?
                    <div className="plan-attribute">
                        <i className="fa fa-check plan-check" />&nbsp;&nbsp;Please contact us to discuss your needs.
                    </div>
                    : null
            }

        </div>

        {
            user.info.subscriptionPlanId === planDetail.planId ?
                <div style={{ alignSelf: "flex-end", width: "100%", marginBottom: 10 }}>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <div className="selected-plan"><i style={{ color: "#fff", marginRight: 5 }} className="fa fa-check" />Current Plan</div>
                    </div>

                </div>
                :
                !planDetail.isEnterprise ?
                    <div onClick={() => onSubscribe(planDetail)} style={{ alignSelf: "flex-end", width: "100%", marginBottom: 10 }}>
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div className="plan-button">
                                {
                                    planLoading ? <i style={{ color: "#fff", textAlign: "center" }} className="fas fa-spinner fa-spin"></i> : (user.info.subscriptionPlanId ? "Subscribe" : "Start Trial")
                                }

                            </div>
                        </div>
                    </div>
                    :
                    <a href="https://www.polycommerce.com/contact" target="_blank">
                        <div style={{ alignSelf: "flex-end", width: "100%", marginBottom: 10 }}>
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>

                                <div className="plan-button">
                                    Contact Us
                                </div>


                            </div>

                        </div>
                    </a>

        }

    </div>);
}

export { SubscriptionCard };