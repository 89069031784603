import React from 'react';
import {
  Card,
  Dimmer
} from 'tabler-react';

const InnerComponentLoader = ({ title }) => {
  return (<div className="app-settings-content">
    <Card loading>
      <Card.Header>
        <h4>{title}</h4>
      </Card.Header>
      <Dimmer active loader>
        <div style={{ height: 150 }}></div>
      </Dimmer>
    </Card>
  </div>);
}

export { InnerComponentLoader };