import React, { useState, useEffect } from 'react';
import { Grid, Card, Page, Form, Button, Icon } from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { useFormik } from 'formik';
import * as ebayCtrl from '../../services/ebay';
import { pricingRulesOptionType, listingPriorityType } from '../../constants';
import { FormLoader } from '../FormLoader';
import { toast } from 'react-toastify';
import { CustomToast, CustomSelect, CustomLabel } from '../../components';
import Modal from 'react-modal';
import * as logger from '../../services/log';
import { SaveToolbar } from '../SaveToolbar';
import {
    useSelector
} from "react-redux";
import {
    Link
} from "react-router-dom";
import "./General.scss";

const General = (props) => {

    const [loading, setLoading] = useState(true);
    const [showHtmlTemplateExample, setShowHtmlTemplateExample] = useState(false);
    const user = useSelector(state => state.user);

    const app = user.info.apps.find(a => a.id === props.app);

    const listingPriorityOptions = [
        {
            label: "Don't List",
            value: listingPriorityType.DontList
        },
        {
            label: "Low",
            value: listingPriorityType.Low
        },
        {
            label: "Normal",
            value: listingPriorityType.Normal
        },
        {
            label: "High",
            value: listingPriorityType.High
        }
    ];

    useEffect(() => {
        const fetchData = async () => {
            let generalSettings = await ebayCtrl.getGeneralSettings(props.app);
            instantiateForm(generalSettings);
            setLoading(false);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const onUndoBtnClicked = async () => {
        try {
            setLoading(true);

            let generalSettings = await ebayCtrl.getGeneralSettings(props.app);
            instantiateForm(generalSettings);

            setLoading(false);
        }
        catch (e) {
            logger.logError("Ebay General onUndoBtnClicked() error", e);
            setLoading(false);
        }
    }

    function instantiateForm(generalSettings) {

        formik.resetForm({
            values: {
                currencyConversionEnabled: generalSettings.currencyConversionEnabled ? "yes" : "no",
                currencyConversionUpdateFrequency: generalSettings.currencyConversionUpdateFrequency || 1,
                maxListingsEnabled: generalSettings.maxListingsEnabled ? "yes" : "no",
                maxListings: generalSettings.maxListings,
                pricingRulesOptionId: generalSettings.pricingRulesOptionId.toString(),
                fixedPriceRule: generalSettings.fixedPriceRule && generalSettings.fixedPriceRule.toFixed(2),
                percentagePriceRule: generalSettings.percentagePriceRule && generalSettings.percentagePriceRule.toFixed(2),
                htmlTemplate: generalSettings.htmlTemplate || "",
                initialListingPriority: generalSettings.initialListingPriority,
                defaultMinimumStockBuffer: generalSettings.defaultMinimumStockBuffer
            }
        });
    }

    function validate(values) {

        let errors = {};

        if (values.pricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString()) {

            if (values.fixedPriceRule === null || values.fixedPriceRule === undefined || values.fixedPriceRule === "") {
                errors.fixedPriceRuleError = "Required";
            }
            else if (values.fixedPriceRule !== null && values.fixedPriceRule !== undefined && values.fixedPriceRule === 0) {
                errors.fixedPriceRuleError = "Dollar amount can't be 0";
            }

        }
        else if (values.pricingRulesOptionId === pricingRulesOptionType.percentage.toString()) {

            if (values.percentagePriceRule === null || values.percentagePriceRule === undefined || values.percentagePriceRule === "") {
                errors.percentagePriceRuleError = "Required";
            }
            if (values.percentagePriceRule && values.percentagePriceRule < -75) {
                errors.percentagePriceRuleError = "Percentage can't be less than 75";
            }
            if (values.percentagePriceRule && values.percentagePriceRule > 75) {
                errors.percentagePriceRuleError = "Percentage can't be more than 75";
            }
            if (values.percentagePriceRule !== null && values.percentagePriceRule !== undefined && values.percentagePriceRule === 0) {
                errors.percentagePriceRuleError = "Percentage can't be 0";
            }
        }

        if (values.maxListingsEnabled === "yes") {

            if (values.maxListings === null || values.maxListings === undefined) {
                errors.maxListingsError = "Required";
            }
            else if (values.maxListings < 0) {
                errors.maxListingsError = "Please enter a value greater than or equal to 0";
            }

        }

        if (values.defaultMinimumStockBuffer === null || values.defaultMinimumStockBuffer === undefined || values.defaultMinimumStockBuffer === "") {
            errors.defaultMinimumStockBufferError = "Required";
        }
        else if (parseInt(values.defaultMinimumStockBuffer) < 1) {
            errors.defaultMinimumStockBufferError = "Value must be more than or equal to 1";
        }

        return errors;
    }

    const formik = useFormik({
        validateOnMount: false,
        onSubmit: async (values) => {

            try {
                let vm = {
                    currencyConversionEnabled: values.currencyConversionEnabled === "yes",
                    currencyConversionUpdateFrequency: values.currencyConversionUpdateFrequency,
                    htmlTemplate: values.htmlTemplate,
                    maxListingsEnabled: values.maxListingsEnabled === "yes",
                    maxListings: ((values.maxListings || values.maxListings === 0) && parseInt(values.maxListings)) || null,
                    pricingRulesOptionId: values.pricingRulesOptionId && parseInt(values.pricingRulesOptionId),
                    fixedPriceRule: values.fixedPriceRule && parseFloat(values.fixedPriceRule),
                    percentagePriceRule: values.percentagePriceRule && parseFloat(values.percentagePriceRule),
                    initialListingPriority: values.initialListingPriority && parseInt(values.initialListingPriority),
                    defaultMinimumStockBuffer: parseInt(values.defaultMinimumStockBuffer)
                }

                await ebayCtrl.saveGeneralSettings(props.app, vm);
                let generalSettings = await ebayCtrl.getGeneralSettings(props.app);
                instantiateForm(generalSettings);

                toast.success(<CustomToast message={`General settings saved.`} />, { position: toast.POSITION.TOP_RIGHT });
            }
            catch (e) {
                logger.logError("Ebay General onSubmit() error", e);
            }
            finally {
                formik.setSubmitting(false);
            }
        },
        validate
    });

    const openLinkInWindow = (e, url) => {
        let newWindow = window.open("", '_blank', 'location=yes,height=600,width=600,toolbar=no,menubar=no,resizable=yes,scrollbars=yes,status=no');
        newWindow.location.href = url;
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={"General"} />);
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="ebay-general">
                                <Card.Header>
                                    <h4>General</h4>
                                </Card.Header>
                                <div className="card-inner">
                                    <Form onSubmit={formik.handleSubmit}>
                                        <Grid.Row>
                                            <Grid.Col width={6}>

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="listings-limit"
                                                        name="Enable listings limit"
                                                        message="When enabled, PolyCommerce will ensure that the number of live listings does not exceed 'Max listings' setting. This setting can be useful to prevent high volume listing fees. Check your eBay store subscription details for more information on listing fees." />

                                                    <Form.Radio
                                                        isInline
                                                        label="Yes"
                                                        name="maxListingsEnabled"
                                                        value="yes"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("maxListings", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.maxListingsEnabled === "yes"}
                                                    />
                                                    <Form.Radio
                                                        isInline
                                                        label="No"
                                                        name="maxListingsEnabled"
                                                        value="no"
                                                        onChange={(e) => {
                                                            formik.setFieldValue("maxListings", null, false);
                                                            formik.handleChange(e);
                                                        }}
                                                        disabled={formik.isSubmitting}
                                                        checked={formik.values.maxListingsEnabled === "no"}
                                                    />
                                                </Form.Group>

                                                {
                                                    formik.values.maxListingsEnabled === "yes" &&
                                                    <Form.Group className="max-listings-group indent" label="Max listings">
                                                        <Form.Input
                                                            name="maxListings"
                                                            disabled={formik.isSubmitting}
                                                            error={formik.touched.maxListings && formik.errors.maxListingsError}
                                                            type="number"
                                                            value={formik.values.maxListings}
                                                            onBlur={formik.handleBlur}
                                                            onChange={formik.handleChange} />
                                                    </Form.Group>
                                                }


                                                {
                                                    app.currencyCode !== user.info.baseCurrency ?
                                                        <React.Fragment>
                                                            <Form.Group>
                                                                <CustomLabel id="currency-conversion"
                                                                    name="Enable Currency Conversion"
                                                                    message="When enabled, product prices will be converted from the store base currency to the target marketplace currency." />

                                                                <div style={{ fontSize: "0.9em" }}>Conversions applied from <strong style={{ textDecoration: "underline" }}>{user.info.baseCurrency}</strong> to <strong style={{ textDecoration: "underline" }}>{app.currencyCode}</strong></div>
                                                                <div style={{ marginBottom: 15 }}>
                                                                    <Link style={{ fontSize: "0.9em" }} to="/settings/currency">Latest exchange rates we use</Link>
                                                                </div>

                                                                <Form.Radio
                                                                    isInline
                                                                    label="Yes"
                                                                    name="currencyConversionEnabled"
                                                                    value="yes"
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue("currencyConversionEnabled", null, false);
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    disabled={formik.isSubmitting}
                                                                    checked={formik.values.currencyConversionEnabled === "yes"}
                                                                />
                                                                <Form.Radio
                                                                    isInline
                                                                    label="No"
                                                                    name="currencyConversionEnabled"
                                                                    value="no"
                                                                    onChange={(e) => {
                                                                        formik.setFieldValue("currencyConversionEnabled", null, false);
                                                                        formik.handleChange(e);
                                                                    }}
                                                                    disabled={formik.isSubmitting}
                                                                    checked={formik.values.currencyConversionEnabled === "no"}
                                                                />
                                                            </Form.Group>

                                                            {
                                                                formik.values.currencyConversionEnabled === "yes" ?
                                                                    <Form.Group className="form-group indent">
                                                                        <CustomLabel id="global-pricing-rule"
                                                                            name="Update Frequency (days)"
                                                                            message="Determines how frequently prices should be updated." />
                                                                        <Form.Input
                                                                            name="currencyConversionUpdateFrequency"
                                                                            disabled={formik.isSubmitting}
                                                                            error={formik.touched.currencyConversionUpdateFrequency && formik.errors.currencyConversionUpdateFrequency}
                                                                            type="number"
                                                                            min="1"
                                                                            value={formik.values.currencyConversionUpdateFrequency}
                                                                            onBlur={formik.handleBlur}
                                                                            onChange={formik.handleChange} />
                                                                    </Form.Group>
                                                                    : null
                                                            }
                                                        </React.Fragment> : null
                                                }


                                                <Form.Group>
                                                    <CustomLabel id="global-pricing-rule"
                                                        name="Global pricing rule"
                                                        message="When enabled, pricing rule will apply to all products. To increase prices, use a positive number. To decrease prices, use a negative number." />
                                                    <div className="block-radio">
                                                        <Form.Radio
                                                            label="None"
                                                            name="pricingRulesOptionId"
                                                            value={pricingRulesOptionType.none}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            disabled={formik.isSubmitting}
                                                            checked={formik.values.pricingRulesOptionId === pricingRulesOptionType.none.toString()}
                                                        />
                                                    </div>

                                                    <div className="block-radio">
                                                        <Form.Radio
                                                            label="Add/subtract dollar amount"
                                                            name="pricingRulesOptionId"
                                                            value={pricingRulesOptionType.fixedPrice}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            disabled={formik.isSubmitting}
                                                            checked={formik.values.pricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString()}
                                                        />
                                                    </div>

                                                    <div className="block-radio">
                                                        <Form.Radio
                                                            label="Add/subtract percentage"
                                                            name="pricingRulesOptionId"
                                                            value={pricingRulesOptionType.percentage}
                                                            onChange={(e) => {
                                                                formik.handleChange(e);
                                                            }}
                                                            disabled={formik.isSubmitting}
                                                            checked={formik.values.pricingRulesOptionId === pricingRulesOptionType.percentage.toString()}
                                                        />
                                                    </div>

                                                </Form.Group>

                                                {
                                                    formik.values.pricingRulesOptionId === pricingRulesOptionType.fixedPrice.toString() &&
                                                    <Form.Group className="indent" label="Dollar amount">
                                                        <Form.InputGroup>
                                                            <Form.InputGroupPrepend>
                                                                <Form.InputGroupText>$</Form.InputGroupText>
                                                            </Form.InputGroupPrepend>
                                                            <Form.Input
                                                                name="fixedPriceRule"
                                                                error={formik.touched.fixedPriceRule && formik.errors.fixedPriceRuleError}
                                                                disabled={formik.isSubmitting}
                                                                value={formik.values.fixedPriceRule}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                type="number" />
                                                        </Form.InputGroup>
                                                    </Form.Group>
                                                }

                                                {
                                                    formik.values.pricingRulesOptionId === pricingRulesOptionType.percentage.toString() &&
                                                    <Form.Group className="indent" label="Percentage value">
                                                        <Form.InputGroup>
                                                            <Form.InputGroupPrepend>
                                                                <Form.InputGroupText>%</Form.InputGroupText>
                                                            </Form.InputGroupPrepend>
                                                            <Form.Input
                                                                name="percentagePriceRule"
                                                                error={formik.touched.percentagePriceRule && formik.errors.percentagePriceRuleError}
                                                                disabled={formik.isSubmitting}
                                                                value={formik.values.percentagePriceRule}
                                                                onBlur={formik.handleBlur}
                                                                onChange={formik.handleChange}
                                                                type="number" />
                                                        </Form.InputGroup>

                                                    </Form.Group>
                                                }

                                                <Form.Group>
                                                    <CustomLabel id="initial-listing-priority"
                                                        name="Initial Listing Priority"
                                                        message="Defines the initial listing priority when a product is created." />

                                                    <CustomSelect
                                                        value={listingPriorityOptions ? listingPriorityOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.initialListingPriority) : ""}
                                                        isDisabled={formik.isSubmitting}
                                                        isSearchable={false}
                                                        isClearable={false}
                                                        options={listingPriorityOptions}
                                                        onChange={(option) => formik.setFieldValue("initialListingPriority", (option && option.value) || "")}
                                                    />

                                                </Form.Group>

                                                <Form.Group>

                                                    <CustomLabel
                                                        id="default-minimum-stock-buffer"
                                                        name="Default Minimum Stock Buffer"
                                                        message="Defines the default minimum inventory level for all products. If the inventory level for an active product falls below this value, the product will be withdrawn from Ebay." />

                                                    <Form.Input
                                                        name="defaultMinimumStockBuffer"
                                                        type="number"
                                                        onChange={formik.handleChange}
                                                        disabled={formik.isSubmitting}
                                                        value={formik.values.defaultMinimumStockBuffer}
                                                        onBlur={formik.handleBlur}
                                                        error={formik.touched.defaultMinimumStockBuffer && formik.errors.defaultMinimumStockBufferError}
                                                    />
                                                </Form.Group>

                                            </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Col width={12}>
                                                <Form.Group label="HTML product description template">
                                                    <span className="template-example-btn" onClick={() => setShowHtmlTemplateExample(true)}>Example</span>
                                                    <Form.Textarea
                                                        name="htmlTemplate"
                                                        error={formik.touched.htmlTemplate && formik.errors.htmlTemplateError}
                                                        disabled={formik.isSubmitting}
                                                        value={formik.values.htmlTemplate}
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        rows={6}>
                                                    </Form.Textarea>
                                                </Form.Group>

                                            </Grid.Col>
                                        </Grid.Row>

                                        {
                                            showHtmlTemplateExample &&
                                            <Modal
                                                appElement={document.body}
                                                isOpen
                                                onRequestClose={() => setShowHtmlTemplateExample(false)}
                                                shouldCloseOnOverlayClick
                                                className="html-template-modal"
                                                overlayClassName="custom-modal-overlay">

                                                <Icon onClick={() => setShowHtmlTemplateExample(false)} className="close-icon" prefix="fa" name="times" />

                                                <h2 style={{ textAlign: "center", marginTop: 10 }}>HTML Template Example</h2>
                                                <hr className="divider"></hr>

                                                <div className="html-template-content">
                                                    Templates are compatible with eBay's MIP specification. Documentation for eBay's MIP specification can be found here: <span className="anchor" onClick={(e) => openLinkInWindow(e, "https://developer.ebay.com/devzone/merchant-products/mipng/user-guide-en/default.html#about-description-templates.html")}>
                                                        https://developer.ebay.com/devzone/merchant-products/mipng/user-guide-en/default.html#about-description-templates.html
                                                    </span>
                                                    <pre className="code-font">
                                                        {`<!DOCTYPE html>
<head>
  <meta charset="utf-8">
    <style type="text/css">
        @media screen and (min-width: 1200px) {
            .w-container {
                max-width: 1170px;
            }
        }
    </style>
  </head>
  <body>
    <div>
      <h1>\${PRODUCT.Title}</h1>
    </div>
    <div>
      <div>
        <h2>ProductDescription</h2>
      </div>
      <p>\${PRODUCT.Description}</p>
    </div>
    <div>
      <label for="image--1">
        <span/>
      </label>
      <img alt=" src="\${PRODUCT.CustomFields.Image1}">
      </div>
      <div>
        <div>
          <h2>Features</h2>
        </div>
        <p>\${PRODUCT.CustomFields.Features}</p>
      </div>
      <div>
        <h2>Shipping</h2>
      </div>
      <p> \${PRODUCT.CustomFields.Shipping}</p>
    </div>
  </body>
</html>`}
                                                    </pre>
                                                    <div className="html-template-footer">
                                                        <div className="html-template-modal-buttons">

                                                            <div style={{ marginRight: 10 }}>
                                                                <Button onClick={() => setShowHtmlTemplateExample(false)} type="button" color="secondary">Close</Button>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </Modal>
                                        }

                                        <SaveToolbar show undoAction={() => onUndoBtnClicked()} saveAction={() => { }} formSaving={formik.isSubmitting} />
                                    </Form>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>

    );
}

export { General };