import * as apiCall from '../apiCall';

function getPaymentDetails(appId) {

    const options = {
        appId
    };

    let url = `/api/trademe/get_payment_details`;
    
    return apiCall.get(url, options);
}

export { getPaymentDetails };