import React from 'react';
import Select from 'react-select';
import { Form } from 'tabler-react';

const CustomSelect = ({ id, label, options, error, value, onChange, defaultValue, isDisabled, isMulti = false, isSearchable = true, isClearable = true, placeholder = "", menuPlacement = "auto", height = 38 }) => {

    if (!onChange || typeof onChange !== "function") {
        throw new Error("onChange is required");
    }

    if (!options) {
        return null;
    }

    return (
        <React.Fragment>
            {
                label && <Form.Label>{label}</Form.Label>
            }

            <Select
                id={id}
                menuPlacement={menuPlacement}
                menuPortalTarget={document.body}
                styles={{
                    control: (base, state) => ({
                        ...base,
                        boxShadow: state.isFocused ? "inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 5px rgba(25, 145, 235, 0.5)" : "none",
                        borderColor: state.isFocused ? "#1991eb" : base.borderColor,
                        border: error ? "1px solid #cd201f" : !state.isFocused ? "1px solid rgba(0, 40, 100, 0.12)" : base.border,
                        outline: state.isFocused ? "0" : base.outline,
                        minHeight: height,
                        borderRadius: "3px",
                        '&:hover': {
                            border: error ? "1px solid #cd201f" : !state.isFocused ? "1px solid rgba(0, 40, 100, 0.12)" : base.border
                        }
                        // You can also use state.isFocused to conditionally style based on the focus state
                    }),
                    singleValue: (styles) => ({
                        ...styles,
                        color: "#495057"
                    }),
                    clearIndicator: (styles) => ({
                        ...styles,
                        padding: '2px 8px',
                    }),
                    dropdownIndicator: (styles) => ({
                        ...styles,
                        padding: '2px 8px',
                    }),
                    loadingIndicator: (styles) => ({
                        ...styles,
                        padding: '2px 8px',
                    }),
                    container: (styles) => ({
                        ...styles,
                        zIndex: 9999
                    }),
                    menu: (styles) => ({
                        ...styles,
                        zIndex: 9999, // Without this menu will be overlapped by other fields
                    }),
                    menuPortal: base => ({ 
                        ...base, 
                        zIndex: 9999 
                    })
                }}
                isMulti={isMulti}
                onChange={onChange}
                className="basic-single"
                classNamePrefix="select"
                defaultValue={defaultValue}
                value={value}
                isDisabled={isDisabled}
                isClearable={isClearable}
                isSearchable={isSearchable}
                placeholder={placeholder}
                options={options}
            />
            <span style={{ display: error ? "block" : "none" }} className="invalid-feedback">{error}</span>
        </React.Fragment>
    );
}

export { CustomSelect };