import * as apiCall from '../apiCall';

function createShippingPolicy(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/create_shipping_policy`, model, options);
}

export { createShippingPolicy };