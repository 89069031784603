import {
    USER_INFO,
    TOKEN,
    SHOPIFY_DATA,
    ADD_FIRST_APP_SHOWN
} from '../actions/types';

const initialState = {
    info: {},
    token: null,
    shopifyData: null,
    firstAppModalShown: false
};

const userReducer = (state = initialState, action) => {

    switch (action.type) {

        case SHOPIFY_DATA:
            return {
                ...state,
                shopifyData: action.payload
            }

        case USER_INFO:

            return {
                ...state,
                info: { ...action.payload.userInfo }
            };

        case TOKEN:
            return {
                ...state,
                token: action.payload.token
            }

        case ADD_FIRST_APP_SHOWN:
            return {
                ...state,
                firstAppModalShown: true
            }

        default:
            return state;
    }
}

export default userReducer;