import { createBrowserHistory } from 'history';

export default createBrowserHistory({
    /* pass a configuration object here if needed */
});

const disableBackButton = () => {
    window.history.pushState(null, document.title, window.location.href);
    window.addEventListener('popstate', function (event) {
        window.history.pushState(null, document.title, window.location.href);
    });
}

export { disableBackButton };