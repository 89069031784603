import React, { useEffect, useState, useRef } from 'react';
import {
    Card,
    Button,
    Dropdown,
    Icon
} from "tabler-react";
import * as dashboardCtrl from '../../services/dashboard';
import {
    Widget,
    WidgetBody
} from './';
import * as signalR from '@microsoft/signalr';
import { useSelector } from "react-redux";
import * as logger from '../../services/log';
import {
    customConfirmAlert
} from '../CustomConfirmAlert';
import {
    CustomModal,
    PauseProductsModalContent,
    ProductsPausedMessage
} from '../../components';
import {
    MarketplaceHealth
} from '../MarketplaceHealth';
import moment from 'moment-timezone';
import "../CustomConfirmAlert.scss";
import { appType } from '../../constants';

const ProductSyncWidget = ({ userWidget }) => {

    const user = useSelector(state => state.user);
    const productSyncCardWidth = useRef(320);
    const [loading, setLoading] = useState(true);
    const [pauseProductsModalOpen, setPauseProductsModalOpen] = useState(false);
    const [syncProductsButtonLoading, setSyncProductsButtonLoading] = useState(false);
    const [appProgress, setAppProgress] = useState(user.info.apps && user.info.apps.length && user.info.apps.map(a => ({ appId: a.id, percentCompleted: 0, waiting: true })));
    const [productSyncProgress, setProductSyncProgress] = useState(0);
    const [vm, setVm] = useState({});
    const connectionHub = useRef(null);

    useEffect(() => {

        const fetchData = async () => {

            let tz = moment.tz.guess();
            let encodedTz = encodeURIComponent(tz);

            let vm = await dashboardCtrl.getProductSyncWidget(encodedTz);

            setVm(vm);

            setLoading(false);

            connectionHub.current = await buildNewSocket(vm);

            connectionHub.current.onreconnected(() => subscribeToProgressUpdates(connectionHub.current, vm));

            await connectionHub.current.start();
            await connectionHub.current.send("SubscribeToProgressUpdates", vm.websocketId);

        };

        fetchData();

        return () => {

            if (connectionHub.current) {
                connectionHub.current.stop();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const subscribeToProgressUpdates = async (connectionHub, vm) => {
        if (connectionHub) {

            console.log("subscribeToProgressUpdates with websocket ", vm.websocketId);

            await connectionHub.send("SubscribeToProgressUpdates", vm.websocketId);
        }
    }

    const toggleSyncStatus = async (syncInProgress) => {

        setVm((prevState) => {

            let clonedVm = { ...prevState };
            clonedVm.syncInProgress = syncInProgress;

            return clonedVm;
        });
    }

    const buildNewSocket = async () => {

        var url = window.location.href;
        var arr = url.split("/");
        var result = arr[0] + "//" + arr[2];

        const liveLogHubUrl = `${result}/sync-progress`;

        let isDev = !process.env.NODE_ENV || process.env.NODE_ENV === 'development';
        let logLevel = isDev ? signalR.LogLevel.Debug : signalR.LogLevel.Error;

        let socket = new signalR.HubConnectionBuilder()
            .withUrl(liveLogHubUrl)
            .withAutomaticReconnect()
            .configureLogging(logLevel)
            .build();

        socket.on("NewProgressEntry", onNewProgressEntry);
        socket.on("NewProductImportProgressEntry", onNewProductImportProgressEntry);
        socket.on("SyncStatusChanged", onSyncStatusChanged);

        return socket;
    }

    const onSyncStatusChanged = async (syncInProgress) => {

        if (!syncInProgress) {
            setProductSyncProgress(0);
            setAppProgress(user.info.apps && user.info.apps.length && user.info.apps.map(a => ({ appId: a.id, percentCompleted: 0, waiting: true })));

            setLoading(true);
            let tz = moment.tz.guess();
            let encodedTz = encodeURIComponent(tz);
            let vm = await dashboardCtrl.getProductSyncWidget(encodedTz);
            setVm(vm);
            setLoading(false);
        }
        toggleSyncStatus(syncInProgress);
    }

    const onNewProductImportProgressEntry = (percentCompleted) => {

        if (vm && !vm.syncInProgress) {
            toggleSyncStatus(true);
        }

        setProductSyncProgress(percentCompleted);
    }

    const onNewProgressEntry = async (appProgress) => {

        if (vm && !vm.syncInProgress) {
            toggleSyncStatus(true);
        }

        setAppProgress(appProgress);
    }

    const syncProducts = async (purgeAndReplaceProducts = false) => {

        try {
            setSyncProductsButtonLoading(true);

            let model = {
                purgeAndReplaceProducts
            };

            await dashboardCtrl.syncProducts(model);
            toggleSyncStatus(true);
            setSyncProductsButtonLoading(false);
        }
        catch (e) {
            logger.logError("Dashboard syncProducts() error", e);
            toggleSyncStatus(false);
            setSyncProductsButtonLoading(false);
        }
    }

    const getProductImportPercentInfo = () => {
        if (!vm.syncInProgress) {
            return null;
        }

        let percentCompleted = productSyncProgress.toFixed(2);

        let percentInfo = {
            percentCompleted,
            percentText: parseInt((percentCompleted * 100).toFixed(2)) + "%"
        }

        return percentInfo;

    }

    const getPercentInfo = (appId) => {
        if (!vm.syncInProgress) {
            return null;
        }

        let currApp = appProgress.find(ap => ap.appId === appId);

        if (!currApp) {
            return null;
        }

        let percentCompleted = currApp.percentCompleted.toFixed(2);

        let percentInfo = {
            percentCompleted,
            percentText: currApp.waiting ? "Waiting" : parseInt((percentCompleted * 100).toFixed(2)) + "%"
        }

        return percentInfo;
    }

    const showPauseProductSyncModal = () => {
        let elems = document.getElementsByClassName("dropdown-menu");

        if (elems.length && elems[0]) {
            elems[0].classList.remove("show");
        }

        setPauseProductsModalOpen(true);
    }

    const showPurgeAndReplaceProductsModal = () => {

        if (vm.syncInProgress) {
            return;
        }

        let elems = document.getElementsByClassName("dropdown-menu");

        if (elems.length && elems[0]) {
            elems[0].classList.remove("show");
        }

        customConfirmAlert(() => syncProducts(true), <React.Fragment>Inventory reconciliation will reimport all of your products and correct any discrepancies. <strong>Note that this process may take some time.</strong> It's therefore recommended that you use this action sparingly.</React.Fragment>);
    }

    const refreshDashboard = async () => {

        const widgetRefreshEvent = new CustomEvent("widgetRefresh");
        window.dispatchEvent(widgetRefreshEvent);

        setLoading(true);

        var tz = moment.tz.guess();
        let encodedTz = encodeURIComponent(tz);

        let vm = await dashboardCtrl.getProductSyncWidget(encodedTz);

        setVm(vm);
        setLoading(false);

    }

    return (<Widget loading={loading} userWidget={userWidget}>
        <div>
            <Card.Header>

                <div className="card-header-inner">
                    <div className="icon-wrapper">
                        <i className={userWidget.widget.iconClass}></i>
                    </div>
                    <h4>Status</h4>

                    <div className="sync-header">


                        {
                            !vm.productSyncPausedUntilDate &&
                            <div className="sync-now-button">
                                <Button color="primary" disabled={vm.syncInProgress} loading={syncProductsButtonLoading} onClick={() => syncProducts()} size="sm">Sync Now</Button>
                            </div>
                        }

                        {
                            user.info.appId !== appType.bulkDiscountManager ?
                                <div className="purge-and-replace-button">
                                    <Dropdown
                                        disabled={vm.syncInProgress}
                                        trigger={<Dropdown.Trigger toggle={false}>
                                            <Button color="secondary" disabled={vm.syncInProgress} size="sm"><Icon name="more-horizontal" /></Button>
                                        </Dropdown.Trigger>}
                                        position="right"
                                        items={[<Dropdown.Item disabled={vm.syncInProgress} key={1} onClick={() => showPauseProductSyncModal()} position="right">Pause Product Sync</Dropdown.Item>,
                                        <Dropdown.Item disabled={vm.syncInProgress} key={2} onClick={() => showPurgeAndReplaceProductsModal()} position="right">Inventory Reconciliation</Dropdown.Item>]}
                                    />
                                </div> : null
                        }

                    </div>
                </div>
            </Card.Header>
        </div>

        <WidgetBody loading={loading}>

            {
                !loading &&
                <div className="last-sync">
                    {
                        vm.productSyncPausedUntilDate &&
                        <ProductsPausedMessage refreshDashboard={refreshDashboard} productSyncPausedUntilDate={vm.productSyncPausedUntilDate} />
                    }

                    <div>
                        <MarketplaceHealth progressBarWidth={productSyncCardWidth.current} percentInfo={getProductImportPercentInfo()} syncInProgress={vm.syncInProgress} marketplace="Product Import" lastSync={vm.lastProductImportDateFormatted || "Pending"} />
                    </div>

                    <div>
                        {
                            Array.isArray(vm.marketplaces) && vm.marketplaces.length > 0 &&
                            vm.marketplaces.map(m => {
                                return (<MarketplaceHealth key={`marketplacehealth-${m.appId}`} progressBarWidth={productSyncCardWidth.current} appId={m.appId} percentInfo={getPercentInfo(m.appId)} syncInProgress={vm.syncInProgress} marketplace={m.marketplaceName} lastSync={m.lastSyncFormatted} />)
                            })
                        }
                    </div>


                </div>
            }

        </WidgetBody>

        <CustomModal isOpen={pauseProductsModalOpen} closeModal={() => setPauseProductsModalOpen(false)} title="Pause Product Sync">
            <PauseProductsModalContent refreshDashboard={() => refreshDashboard()} closeModal={() => setPauseProductsModalOpen(false)} />
        </CustomModal>

    </Widget>);
}

export { ProductSyncWidget };