import React from 'react';
import Modal from 'react-modal';
import { Icon } from 'tabler-react';
import * as tradeMeCtrl from '../../services/trademe';
import Fade from 'react-reveal/Fade';
import * as logger from '../../services/log';
import '../CategoriesModal.css';

class TradeMeCategoriesModal extends React.Component {

    constructor() {
        super();

        this.defaultParentCategories = [{ name: "All Categories", level: 0, currentlySelected: true, tradeMeCategoryId: null, tradeMeParentCategoryId: null, expandable: false }];

        this.categoryMappingVm = {};

        this.state = {
            modalIsOpen: false,
            allCategoriesLoading: true,
            categoryLoading: {},
            categories: [],
            parentCategories: [...this.defaultParentCategories],
            categoryRecommendations: [],
            categoryRecommendationsInput: "",
            categoryRecommendationsLoading: true,
            categoryRecommendationsInputFocused: false,
            searchLoading: false
        };
    }

    openModal = (categoryMappingVm) => {

        this.categoryMappingVm = categoryMappingVm;

        let parentCategories = [...this.defaultParentCategories];
        parentCategories[0].currentlySelected = true;
        this.setState(
            {
                modalIsOpen: true,
                parentCategories,
                categories: [],
                categoryRecommendations: [],
                categoryRecommendationsInput: "",
                searchLoading: false,
                categoryRecommendationsLoading: true,
                categoryRecommendationsInputFocused: false
            });
    }

    afterOpenModal = async () => {
        // load first level of categories

        this.setState({ allCategoriesLoading: true });



        tradeMeCtrl.getCategoryRecommendations(this.props.app, this.categoryMappingVm.categoryPath).then((categoryRecommendations) => {
            this.setState({
                categoryRecommendations,
                categoryRecommendationsLoading: false
            });

        }).catch((e) => {
            logger.logError("afterOpenModal error", e);
        });

        const topLevelCategories = await tradeMeCtrl.getCategories(this.props.app, 1);

        this.setState({ categories: topLevelCategories, allCategoriesLoading: false });
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    toggleCategoryLoading = (tradeMeCategoryId, loading) => {
        this.setState(prevState => {
            let categoryLoading = { ...prevState.categoryLoading };

            if (!loading && categoryLoading[tradeMeCategoryId]) {
                delete (categoryLoading[tradeMeCategoryId]);
            }
            else if (loading) {
                categoryLoading[tradeMeCategoryId] = true;
            }

            return {
                categoryLoading
            };
        })
    }

    onCategoryRecommendationSelected = (tradeMeCategoryId) => {
        this.closeModal();
        this.props.onTradeMeCategorySelected(tradeMeCategoryId);
    }

    onSelectCategory = async (category) => {

        if (category.leafCategory) {
            this.closeModal();
            this.props.onTradeMeCategorySelected(category.tradeMeCategoryId);
        }

        if (Object.keys(this.state.categoryLoading).length > 0) {
            return;
        }

        this.toggleCategoryLoading(category.tradeMeCategoryId, true);

        let parentCategories = [...this.state.parentCategories];
        parentCategories.forEach(x => x.currentlySelected = false);
        parentCategories.push({ name: category.name, level: category.level, currentlySelected: true, tradeMeCategoryId: category.tradeMeCategoryId, tradeMeParentCategoryId: category.tradeMeParentCategoryId });

        const categories = await tradeMeCtrl.getCategories(this.props.app, category.level + 1, category.tradeMeCategoryId);

        this.toggleCategoryLoading(category.tradeMeCategoryId, false);

        this.setState({ categories, parentCategories }, () => {
            let elem = document.querySelector(".ReactModal__Content");

            if (elem) {
                elem.scrollTo(0, 0);
            }

        });
    }

    onSearchCategoryRecommendations = async () => {

        if (this.state.categoryRecommendationsInput && this.state.categoryRecommendationsInput.length > 2) {

            try {
                this.setState({ searchLoading: true, categoryRecommendations: [] });
                let categoryRecommendations = await tradeMeCtrl.getCategoryRecommendations(this.props.app, this.state.categoryRecommendationsInput);

                this.setState({ categoryRecommendations, searchLoading: false });
            }
            catch (e) {
                logger.logError("TradeMeCategoriesModal onSearchCategoryRecommendations() error", e);
                this.setState({ searchLoading: false });
            }
        }
    }

    onSelectParentCategory = async (parentCategory) => {
        if (parentCategory.currentlySelected) {
            return;
        }

        if (Object.keys(this.state.categoryLoading).length > 0) {
            return;
        }

        this.toggleCategoryLoading(parentCategory.tradeMeCategoryId, true);

        if (parentCategory.level === 0) {
            let parentCategories = [...this.defaultParentCategories];
            parentCategories[0].currentlySelected = true;
            this.setState({ parentCategories, categories: [] });

            const topLevelCategories = await tradeMeCtrl.getCategories(this.props.app, 1);

            this.toggleCategoryLoading(parentCategory.tradeMeCategoryId, false);

            this.setState({ categories: topLevelCategories });
            return;
        }

        let parentCategories = [...this.state.parentCategories].filter(pc => pc.level <= parentCategory.level);

        parentCategories.forEach(x => x.currentlySelected = false);
        parentCategories[parentCategories.length - 1].currentlySelected = true;

        this.setState({ parentCategories, categories: [] });

        const categories = await tradeMeCtrl.getCategories(this.props.app, parentCategory.level + 1, parentCategory.tradeMeCategoryId);

        this.toggleCategoryLoading(parentCategory.tradeMeCategoryId, false);

        this.setState({ categories }, () => {
            let elem = document.querySelector(".ReactModal__Content");

            if (elem) {
                elem.scrollTo(0, 0);
            }
        });
    }

    render() {
        return (
            <Modal
                appElement={document.body}
                isOpen={this.state.modalIsOpen}
                onAfterOpen={this.afterOpenModal}
                onRequestClose={this.closeModal}
                className="category-modal"
                overlayClassName="category-modal-overlay"
                shouldCloseOnOverlayClick
                contentLabel="Category">

                <Icon onClick={this.closeModal} className="close-icon" prefix="fa" name="times" />
                <div className="category-header">
                    <h2>TradeMe Categories</h2>
                    <div><strong>Mapping store Category:</strong> <span className="mapping-category">{this.categoryMappingVm.categoryPath}</span></div>
                </div>

                <hr className="divider"></hr>

                <div className="category-wrapper">
                    {/* No categories are being returned in production. Also - the quality of results is poor.
                    
                    <div className="category-recommendations">
                        <div className="category-recommendations-header">
                            Recommendations
                        </div>
                        {
                            this.state.categoryRecommendationsLoading && <div style={{display: "flex", alignItems: "center", height: 120}}><Loader /></div>
                        }
                        {
                            !this.state.categoryRecommendationsLoading &&
                            <div className="category-recommendations-body">
                                <div className="category-recommendations-search">
                                    <Form.InputGroup append={<Button color="primary" disabled={this.state.searchLoading} loading={this.state.searchLoading} onClick={() => { this.onSearchCategoryRecommendations() }}>Search</Button>}>
                                        <Form.Input onKeyDown={(e) => { if (e.key === "Enter") { this.onSearchCategoryRecommendations() } }} onChange={(e) => this.setState({ categoryRecommendationsInput: e.target.value })} value={this.state.categoryRecommendationsInput} className="category-recommendations-input" placeholder="Search for..." />
                                    </Form.InputGroup>
                                </div>

                                {
                                    this.state.categoryRecommendations.length > 0 && this.state.categoryRecommendations.map(c => (<div key={c.tradeMeCategoryId}>
                                        <div onClick={() => this.onCategoryRecommendationSelected(c.tradeMeCategoryId)} className="anchor category-recommendation">{c.tradeMeCategoryPath}</div>
                                    </div>))
                                }
                                {
                                    this.state.categoryRecommendations.length === 0 && !this.state.searchLoading &&
                                    <div className="no-category-recommendations">No category recommendations found.</div>
                                }

                            </div>
                        }

                    </div> */}

                    <ul className="parent-categories">
                        {
                            this.state.parentCategories.map((pc, i) => {
                                return <li key={`parent-category-${i}`} onClick={() => { this.onSelectParentCategory(pc); }}>
                                    <div className={`parent-category${!pc.currentlySelected ? " expandable" : ""}`}>
                                        <div className="parent-category-icon">
                                            {
                                                !pc.currentlySelected &&
                                                !this.state.categoryLoading[pc.tradeMeCategoryId] &&
                                                <Icon prefix="fa" name="chevron-up" />
                                            }
                                            {
                                                (this.state.categoryLoading[pc.tradeMeCategoryId] || this.state.allCategoriesLoading) &&
                                                <i className="fa fa-spinner fa-spin"></i>
                                            }
                                        </div>
                                        <div className="parent-category-header">
                                            {pc.name}
                                        </div>


                                    </div>
                                </li>
                            })
                        }
                    </ul>
                    <ul className="child-categories">
                        {
                            this.state.categories.map(c => {
                                return <li key={c.tradeMeCategoryId} onClick={() => { this.onSelectCategory(c); }} >
                                    <Fade force top duration={400}>
                                        <div className="child-category">
                                            <div className="child-category-header">
                                                {c.name}
                                            </div>
                                            <div className="child-category-icon">
                                                {
                                                    !c.leafCategory &&
                                                    !this.state.categoryLoading[c.tradeMeCategoryId] &&
                                                    <Icon prefix="fa" name="chevron-down" />
                                                }
                                                {
                                                    !c.leafCategory &&
                                                    this.state.categoryLoading[c.tradeMeCategoryId] &&
                                                    <i className="fa fa-spinner fa-spin"></i>
                                                }
                                            </div>
                                        </div>
                                    </Fade>

                                </li>
                            })
                        }
                    </ul>
                </div>





            </Modal>
        );
    }

}

export { TradeMeCategoriesModal };