import { 
    TOKEN, 
    USER_INFO,
    SHOPIFY_DATA,
    ADD_FIRST_APP_SHOWN
} from './types';

export const saveUserInfo = (userInfo) => {

    if (userInfo.authToken) {
        delete (userInfo.authToken);
    }

    return {
        type: USER_INFO,
        payload: { userInfo }
    }
}

export const saveShopifyData = (shopifyData) => {
    return {
        type: SHOPIFY_DATA,
        payload: shopifyData
    }
}

export const saveToken = (token) => {
    return {
        type: TOKEN,
        payload: { token }
    }
}

export const saveFirstAppModalShown = () => {
    return {
        type: ADD_FIRST_APP_SHOWN,
        payload: { firstAppModalShown: true }
    }
}