import React, { useEffect, useState } from 'react';
import SiteWrapper from './SiteWrapper.react';
import {
    Page,
    Card,
    Grid
} from "tabler-react";
import {
    CustomLabel
} from '../components/CustomLabel';
import * as accountCtrl from '../services/account';
import * as subscribeCtrl from '../services/subscribe';
import * as logger from '../services/log';
import history from '../history';
import { LoadingPage } from './LoadingPage';
import './AccountInfo.scss';

const AccountInfo = () => {

    const [loading, setLoading] = useState(true);
    const [accountInfo, setAccountInfo] = useState({});
    const [subscriptionPlans, setSubscriptionPlans] = useState([]);

    const Enterprise = 4;

    useEffect(() => {
        const fetch = async () => {
            let accountInfo = await accountCtrl.getAccountInfo();
            setAccountInfo(accountInfo);

            let subscriptionPlans = await subscribeCtrl.getPlans();
            setSubscriptionPlans(subscriptionPlans);

            setLoading(false);
        }

        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onChangePlan = () => {
        if (accountInfo.planId) {
            history.push(`/account/subscription/edit`);
        }
        else {
            logger.logError("planId is null. Can't navigate to subscription page");
        }
    }

    const onManageBilling = async () => {

        try {
            setLoading(true);
            let sessionInfo = await subscribeCtrl.createBillingPortalSession();

            if (sessionInfo.nextUrl) {
                window.location.href = sessionInfo.nextUrl;
            }
            else {
                setLoading(false);
            }
        }
        catch (e) {
            logger.logError("Could not load billing portal session", e);
            setLoading(false);
        }

    }

    if (loading) {
        return (<LoadingPage includeHeader />);
    }

    return (<SiteWrapper>
        <Page.Content title="Account Info" className="account-info">
            <Grid.Row cards>
                <Grid.Col sm={12}>
                    <Card className="account-info-card">

                        <h4>
                            Overview
                        </h4>
                        <div className="label">
                            <CustomLabel
                                id="total-products"
                                name="Total Products"
                                message="Excludes product variations" />
                        </div>
                        <div>
                            {accountInfo.totalProducts} {accountInfo.totalProductsExceedsSubscription ? <span style={{ color: "orange" }}>(Exceeds maximum products for the {accountInfo.subscription} plan. Please consider upgrading)</span> : null}
                        </div>
                        <div className="label">
                            <CustomLabel
                                id="currency"
                                name="Currency" />
                        </div>
                        <div>
                            {accountInfo.baseCurrency}
                        </div>
                        <hr />
                        <h4>
                            Subscription
                        </h4>

                        <div className="label">
                            <CustomLabel
                                id="plan-name"
                                name="Plan" />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div>
                                {accountInfo.subscription}
                            </div>
                            {
                                accountInfo.planId !== Enterprise && subscriptionPlans.length > 1 ? <div>
                                    <span onClick={onChangePlan} style={{ marginLeft: 8, fontSize: 14 }} className="anchor">Change</span>
                                </div> : null
                            }

                            {
                                accountInfo.showManageButton && accountInfo.planId !== Enterprise ? <div style={{ marginLeft: 5, marginRight: 5 }}>|</div> : null
                            }
                            {
                                accountInfo.showManageButton && accountInfo.planId !== Enterprise ?
                                    <div>
                                        <span onClick={onManageBilling} style={{ fontSize: 14 }} className="anchor">Manage</span>
                                    </div>
                                    : null
                            }

                        </div>
                        <div className="label">
                            <CustomLabel
                                id="plan-status"
                                name="Status" />
                        </div>

                        <div>
                            {accountInfo.subscriptionStatus?.toUpperCase()}
                        </div>

                        {
                            accountInfo.subscriptionMaxProducts ?
                                <React.Fragment>
                                    <div className="label">
                                        <CustomLabel
                                            id="plan-products"
                                            name="Products"
                                            message="Total number of products permitted for this plan. Excludes product variations." />
                                    </div>
                                    <div>
                                        {accountInfo.subscriptionMaxProducts}
                                    </div>
                                </React.Fragment>
                                : null
                        }

                        {
                            accountInfo.subscriptionMaxMarketplaces ?
                                <React.Fragment>
                                    <div className="label">
                                        <CustomLabel
                                            id="plan-marketplaces"
                                            name="Marketplaces"
                                            message="Total number of marketplaces permitted for this plan." />
                                    </div>
                                    <div>
                                        {accountInfo.subscriptionMaxMarketplaces}
                                    </div>
                                </React.Fragment>
                                : null
                        }
                        {
                            accountInfo.subscriptionMaxShoppingFeeds ?
                                <React.Fragment>
                                    <div className="label">
                                        <CustomLabel
                                            id="plan-shoppingfeeds"
                                            name="Shopping Feeds"
                                            message="Total number of shopping feeds permitted for this plan." />
                                    </div>
                                    <div>
                                        {accountInfo.subscriptionMaxShoppingFeeds}
                                    </div>
                                </React.Fragment>
                                : null
                        }

                    </Card>
                </Grid.Col>
            </Grid.Row>

        </Page.Content>
    </SiteWrapper>)
}

export { AccountInfo };