import * as apiCall from '../apiCall';

function getMarketplaceWidget(timezone, appId) {

    const options = {
        appId
    };

    let url = `/api/dashboard/get_marketplace_widget?timezone=${timezone}`;
    
    return apiCall.get(url, options);
}

export { getMarketplaceWidget };