import React, { Component } from 'react';
import {
  Router
} from 'react-router-dom';
import Routes from './Routes';
import history from './history';
import {
  ErrorBoundary
} from './components/ErrorBoundary';
import * as authUtility from './utilities/authUtility';
import * as accountCtrl from './services/account';
import {
  LoadingPage
} from './components/LoadingPage';
import {
  connect
} from 'react-redux';
import {
  bindActionCreators
} from 'redux';
import {
  saveUserInfo,
  saveToken
} from './actions/user';
import {
  ToastContainer
} from 'react-toastify';
import * as logger from './services/log';
import * as browserUtility from './utilities/browserUtility';
import * as shopifyUtility from './utilities/shopifyUtility';
import * as appUtility from './utilities/appUtility';
import 'react-toastify/dist/ReactToastify.css';
import "tabler-react/dist/Tabler.css";
import "ag-grid-enterprise/dist/styles/ag-grid.css";
import "ag-grid-enterprise/dist/styles/ag-theme-alpine.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'react-image-lightbox/style.css'; // This only needs to be imported once in your app
import "./BootstrapTable.css";
import './App.scss';
const queryString = require('query-string');

class App extends Component {
  static displayName = App.name;

  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      showloadingPage: false
    };

  }

  async componentDidMount() {

    try {

      if (window.location.pathname && (window.location.pathname.startsWith("/authorise-status") || window.location.pathname.startsWith("/unexpected-error") || window.location.pathname.startsWith("/account/subscription/confirm"))) {
        return;
      }

      let authToken = authUtility.getAuthToken();

      if (!browserUtility.inIframe()) {

        let params = queryString.parse(window.location && window.location.search);

        if (params.shopifyShop) {
          shopifyUtility.redirectToShopifyAdmin(params.shopifyShop, params.shopifyClientId);
          this.setState({ showloadingPage: true });
          return;
        }

        let isValid = false;

        if ((window.location.pathname && window.location.pathname === "/account/login" && params.emailLogin) || authToken) {
          isValid = true;
        }


        if (!isValid) {
          history.push("/page-not-found");
          return;
        }

      }

      if (!authToken) {
        authUtility.logout(true);
        return;
      }

      if (window.location.pathname && !window.location.pathname.startsWith("/account/")) {
        let userInfo = await accountCtrl.getUserData();

        this.props.saveUserInfo(userInfo);

        if (userInfo.authToken) {
          authUtility.setAuthToken(userInfo.authToken);
        }

        if (appUtility.handleAppRedirect(userInfo)) {
          return;
        }

        if (userInfo.apps && userInfo.apps.length === 0 && !userInfo.firstAppAdded && ['/authorise-status', '/install/trademe', '/install/ebay', 'install/amazon', 'install/catch', '/apps', '/unexpected-error'].indexOf(window.location.pathname) < 0) {
          history.push("/apps");
          return;
        }
      }


    }
    catch (e) {
      logger.logError("App componentDidMount() error", e);
    }
    finally {
      this.setState({ loading: false });
    }

  }

  render() {

    if (this.state.loading || this.state.showloadingPage) {
      return <LoadingPage />
    }
    else {
      return (
        <ErrorBoundary>
          <Router history={history}>
            <Routes />
          </Router>
          <ToastContainer pauseOnFocusLoss={false} />
        </ErrorBoundary>
      );
    }

  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
}

const mapDispatchToProps = dispatch => (
  bindActionCreators({
    saveUserInfo,
    saveToken
  }, dispatch)
);

export default connect(mapStateToProps, mapDispatchToProps)(App);
