import React, { useEffect, useState } from 'react';
import { Alert } from "tabler-react";
import moment from 'moment-timezone';
import * as dashboardCtrl from '../services/dashboard';
import { customConfirmAlert } from './CustomConfirmAlert';

const ProductsPausedMessage = ({ productSyncPausedUntilDate, refreshDashboard }) => {

    const [minutesLeft, setMinutesLeft] = useState(null);

    const forceResume = async () => {

        customConfirmAlert(async () => { await dashboardCtrl.resumeProductSync(); refreshDashboard(); }, <React.Fragment>Product Sync will resume.</React.Fragment>);
    }

    useEffect(() => {

        let date = moment.utc(productSyncPausedUntilDate);
        let dateNow = moment.utc();
        var diff = date.diff(dateNow);

        let timer = null;

        if (diff > 0) {
            var duration = moment.duration(diff);
            setMinutesLeft(Math.ceil(duration.asMinutes()));

            timer = setInterval(() => {

                let date = moment.utc(productSyncPausedUntilDate);
                let dateNow = moment.utc();
                var diff = date.diff(dateNow);

                if (timer && diff < 0) {
                    setMinutesLeft(null);
                    clearInterval(timer);
                    refreshDashboard();
                    return;
                }

                var duration = moment.duration(diff);

                setMinutesLeft(Math.ceil(duration.asMinutes()));

            }, 1000);
        }
        else {
            refreshDashboard();
        }

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        }

     // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (minutesLeft) {
        return (<div style={{ marginTop: 15, marginLeft: 25, marginRight: 25 }}>
            <Alert type="primary" icon="pause">
                Product Sync is paused for <strong>{minutesLeft}</strong> more minute{minutesLeft > 1 ? "s" : ""}. <span className="anchor" onClick={() => forceResume()}>Resume</span> Product Sync.
      </Alert>
        </div>);
    }

    return null;

}

export { ProductsPausedMessage };