import * as apiCall from '../apiCall';

function unlistProduct(appId, productId) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/unlist_product/${productId}`, null, options);
}

export { unlistProduct };