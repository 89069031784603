import React, { useEffect, useState, useRef } from 'react';
import {
    Form
} from "tabler-react";
import * as dashboardCtrl from '../../services/dashboard';
import {
    Widget,
    WidgetBody,
    WidgetHeader
} from './';
import moment from 'moment-timezone';
import * as logger from '../../services/log';
import * as c3 from 'c3';

const UnitsSoldWidget = ({ userWidget }) => {

    const [loading, setLoading] = useState(true);
    const [pieGraphDays, setPieGraphDays] = useState(7);
    const pieChart = useRef(null);
    const [marketplaces, setMarketplaces] = useState([]);
    const [vm, setVm] = useState(null);
    const pieChartRef = useRef(null);

    useEffect(() => {
        refreshWidget();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (loading) {
            return;
        }

        generatePieChart(marketplaces, vm.pieGraphDays);


    }, [loading, marketplaces, vm])

    const pieGraphDaysChanged = (days) => {
        setPieGraphDays(days);
        generatePieChart(marketplaces, days);

        let dashboardModel = {
            pieGraphDays: days
        };

        dashboardCtrl.saveDashboardSettings(dashboardModel);

    }

    const refreshWidget = async() => {
        setLoading(true);

        pieChart.current = null;

        var tz = moment.tz.guess();
        let encodedTz = encodeURIComponent(tz);

        let vm = await dashboardCtrl.getUnitsSoldWidget(encodedTz);

        let marketplaces = [];

        if (Array.isArray(vm.ebayStores) && vm.ebayStores.length > 0) {
            vm.ebayStores.forEach(ebayStore => {
                marketplaces.push(ebayStore);
            });
        }

        if (vm.tradeMeData) {
            marketplaces.push(vm.tradeMeData);
        }

        if (vm.catchData) {
            marketplaces.push(vm.catchData);
        }

        if (Array.isArray(vm.amazonStores) && vm.amazonStores.length > 0) {
            vm.amazonStores.forEach(amazonStore => {
                marketplaces.push(amazonStore);
            });
        }

        setVm(vm);
        setPieGraphDays(vm.pieGraphDays);
        setMarketplaces(marketplaces);
        setLoading(false);
    }

    const generatePieChart = (marketplaces, period) => {

        let columns = [];
        let total = 0;
        let colors = {};

        marketplaces.forEach(m => {

            colors[m.marketplaceName] = m.pieColour;

            switch (period) {
                case 7:
                    total += m.last7DaysTotalUnits;
                    columns.push([m.marketplaceName, m.last7DaysTotalUnits]);
                    break;
                case 30:
                    total += m.last30DaysTotalUnits;
                    columns.push([m.marketplaceName, m.last30DaysTotalUnits]);
                    break;
                case 90:
                    total += m.last90DaysTotalUnits;
                    columns.push([m.marketplaceName, m.last90DaysTotalUnits]);
                    break;
                default:
                    logger.logError("Pie chart period not recognised.");
                    break;
            }

        });

        if (total === 0) {
            columns = [];
        }

        const noDataMsg = "No data available";

        if (!pieChart.current) {

            let newPieChart = c3.generate({
                bindto: "#marketplace-pie",
                data: {
                    columns,
                    type: "pie",
                    colors,
                    empty: { label: { text: noDataMsg } }
                },
                legend: {
                    position: 'right'
                },
                pie: {
                    label: {
                        format: function (value, ratio, id) {
                            return value;
                        }
                    }

                }
            });

            newPieChart.load({ columns });
            pieChart.current = newPieChart;
        }
        else if (total === 0) {
            pieChart.current.load({
                columns: []
            });

            //pieChart.current.flush();
        }
        else if (total > 0) {
            pieChart.current.load({
                columns
            });
        }
    }

    return (<Widget loading={loading} userWidget={userWidget}>
        <WidgetHeader userWidget={userWidget} useIcon refreshWidget={refreshWidget} />
        <WidgetBody loading={loading}>

            {
                !loading ?
                    <div className="sales">
                        <Form.Group className="pie-graph-day-group">
                            <Form.SelectGroup>
                                <Form.SelectGroupItem
                                    label="7 days"
                                    name="pie-graph-days"
                                    value="7"
                                    checked={pieGraphDays === 7}
                                    onChange={() => pieGraphDaysChanged(7)}
                                />
                                <Form.SelectGroupItem
                                    label="30 days"
                                    name="pie-graph-days"
                                    value="30"
                                    checked={pieGraphDays === 30}
                                    onChange={() => pieGraphDaysChanged(30)}
                                />
                                <Form.SelectGroupItem
                                    label="90 days"
                                    name="pie-graph-days"
                                    value="90"
                                    checked={pieGraphDays === 90}
                                    onChange={() => pieGraphDaysChanged(90)}
                                />
                            </Form.SelectGroup>
                        </Form.Group>
                        <div ref={pieChartRef} id="marketplace-pie"></div>
                    </div>
                    : null
            }

        </WidgetBody>
    </Widget>);
}

export { UnitsSoldWidget };