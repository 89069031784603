import * as apiCall from '../apiCall';

function getCategoryMappings(appId) {

    const options = {
        appId
    };

    let url = `/api/ebay/category_mappings`;
    
    return apiCall.get(url, options);
}

export { getCategoryMappings };