import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
    Grid,
    Card,
    Page,
    Form,
    Button,
    Icon,
    Dimmer
} from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import { InstallProgress } from '../InstallProgress';
import * as installCtrl from '../../services/install';
import * as tradeMeCtrl from '../../services/trademe';
import * as accountCtrl from '../../services/account';
import { PaymentInstructionsInner } from './PaymentInstructionsInner';
import { ShippingInner } from './ShippingInner';
import { CategoryMappingsInner } from './CategoryMappingsInner';
import history from '../../history';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components';
import { saveUserInfo } from '../../actions/user';
import { appType } from '../../constants';
import * as authUtility from '../../utilities/authUtility';
import * as logger from '../../services/log';
import {
    LoadingPage
} from '../LoadingPage';
import { useSelector } from "react-redux";
import "./Install.scss";

const Install = (props) => {

    const userInfo = useSelector(state => state.user.info);

    const [loading, setLoading] = useState(true);
    const [installStepHeader, setInstallStepHeader] = useState(null);
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [pollTimer, setPollTimer] = useState(null);

    const dispatch = useDispatch();

    useEffect(() => {
        const fetchData = async () => {
            let tradeMeSteps = await installCtrl.getTradeMeSteps();

            setInstallStepHeader(tradeMeSteps);

            setLoading(false);
        }

        fetchData();

        return () => {
            if (pollTimer) {
                clearInterval(pollTimer);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function onAuthorize() {

        if (authorizeLoading) {
            return;
        }

        setAuthorizeLoading(true);

        let pollTimer = authUtility.openPopupWithPoller(() => tradeMeCtrl.getAuthorizationUrl(props.app), async () => {
            try {
                setLoading(true);

                let tradeMeSteps = await installCtrl.getTradeMeSteps();

                setInstallStepHeader(tradeMeSteps);

                setLoading(false);
                setAuthorizeLoading(false);
            }
            catch (e) {
                logger.logError("onAuthorize error", e);
                setLoading(false);
                setAuthorizeLoading(false);
            }

        }, () => {
            setAuthorizeLoading(false);
        });

        setPollTimer(pollTimer);
    }

    const renderComponent = () => {
        const selectedInstallStep = installStepHeader && installStepHeader.installSteps.find(x => x.active);

        if (selectedInstallStep) {
            switch (selectedInstallStep.id) {
                case "authorisation":
                    return authorisationComponent();
                case "payment_instructions":
                    return <PaymentInstructionsInner app={appType.tradeMe} loadNextStep={loadNextStep} installWizard />;
                case "shipping":
                    return <ShippingInner app={appType.tradeMe} loadNextStep={loadNextStep} installWizard />;
                case "category_mappings":
                    return <CategoryMappingsInner app={appType.tradeMe} loadNextStep={loadNextStep} installWizard />;
                default:
                    throw new Error(`Trademe install step '${selectedInstallStep.id}' not recognised.`);
            }
        }
    }

    const loadNextStep = async () => {

        setLoading(true);

        try {
            let nextStopRes = await installCtrl.nextTradeMeStep();

            if (nextStopRes.installComplete) {
                toast.success(<CustomToast message={`TradeMe installation successful. If you have any questions, please contact us.`} />, { position: toast.POSITION.TOP_RIGHT });

                let userInfo = await accountCtrl.getUserData();

                dispatch(saveUserInfo(userInfo));

                history.push(`/marketplaces/trademe/products`);
                return;
            }

            let tradeMeSteps = await installCtrl.getTradeMeSteps();

            setInstallStepHeader(tradeMeSteps);
        }
        finally {
            setLoading(false);
        }
    }

    const authorisationComponent = () => {
        return (<div className="install-app-content">
            <Card className="trademe-install">
                <Card.Header>
                    <h4>Authorisation</h4>
                </Card.Header>
                <div className="card-inner">
                    <div>
                        <Grid.Row>
                            <Grid.Col width={12}>
                                By clicking connect, you will be redirected to TradeMe's website to authenticate. Once authenticated, you will return to this screen to continue installation.
                            </Grid.Col>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Col width={12}>
                                <Form.Footer className="install-footer">
                                    <Button size="lg" type="button" color="primary" onClick={() => onAuthorize()} disabled={authorizeLoading}><Icon prefix="fa" name="plug" />&nbsp;&nbsp;Connect</Button>
                                </Form.Footer>
                            </Grid.Col>
                        </Grid.Row>
                    </div>
                </div>
            </Card>
        </div>);
    }

    if (loading && !userInfo.firstAppAdded && userInfo.appId) {
        return (<LoadingPage />);
    }

    if (loading) {
        return (<SiteWrapper installWizard>
            <Page.Content>
                <Grid.Row cards>

                    <div className="install-app-wrapper">
                        {
                            installStepHeader && <InstallProgress installStepHeader={installStepHeader} />
                        }

                        <div className="install-app-content">
                            <Card loading>
                                <Card.Header>
                                    <h4>...</h4>
                                </Card.Header>
                                <Dimmer active loader>
                                    <div style={{ height: 150 }}></div>
                                </Dimmer>
                            </Card>
                        </div>
                    </div>

                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
    }

    return <SiteWrapper installWizard>
        <Page.Content>
            <div onClick={() => history.goBack()} className="back-btn anchor"><i style={{ fontSize: "0.9em" }} className="fas fa-chevron-left"></i> Back</div>
            <Grid.Row cards>
                <div className="install-app-wrapper">

                    {
                        installStepHeader && <InstallProgress installStepHeader={installStepHeader} />
                    }

                    {
                        renderComponent()
                    }

                </div>

            </Grid.Row>
        </Page.Content>
    </SiteWrapper>
}

export { Install };