import * as apiCall from '../apiCall';

function getAmazonSteps(appId) {
    let url = `/api/install/amazon/get_steps`;

    const options = {
        appId
    };

    return apiCall.get(url, options);
}

export { getAmazonSteps };