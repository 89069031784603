import React from 'react';
import { Card, Form } from "tabler-react";

const ProductInformation = ({ product }) => {
    return (<Card>
        <Card.Header>
            <h4>Product Detail</h4>
        </Card.Header>
        <Card.Body>
            <Form.Group label="Title">
                <div>{product.name}</div>
            </Form.Group>
            <Form.Group label="Description">
                {
                    product.description && product.description.indexOf('</') !== -1 ? (
                        <div dangerouslySetInnerHTML={{ __html: product.description.replace(/(<? *script)/gi, 'illegalscript') }} >
                        </div>
                    )
                        : product.description
                }
            </Form.Group>
            {
                product.vendor && product.vendor !== "" &&
                <Form.Group label="Vendor">
                    <div>{product.vendor}</div>
                </Form.Group>
            }

            {
                product.productVariations && product.productVariations.length === 0 &&
                <React.Fragment>
                    <Form.Group label="PolyCommerce SKU">
                        <div>{product.internalSku}</div>
                    </Form.Group>

                    <Form.Group label="Shop SKU">
                        <div>{product.sku}</div>
                    </Form.Group>

                    <Form.Group label="Inventory">
                        <div>{product.inventoryLevel}</div>
                    </Form.Group>
                    <Form.Group label="Price">
                        <div>{product.price && product.price.toFixed(2)}</div>
                    </Form.Group>
                    {
                        product.gtin ?
                            <Form.Group label="GTIN">
                                <div>{product.gtin}</div>
                            </Form.Group> : null
                    }
                    {
                        product.barcode ?
                            <Form.Group label="Barcode">
                                <div>{product.barcode}</div>
                            </Form.Group> : null
                    }
                    {
                        product.upc ?
                            <Form.Group label="UPC">
                                <div>{product.upc}</div>
                            </Form.Group> : null
                    }
                    {
                        product.ean ?
                            <Form.Group label="EAN">
                                <div>{product.ean}</div>
                            </Form.Group> : null
                    }
                    {
                        product.mpn ?
                            <Form.Group label="MPN">
                                <div>{product.mpn}</div>
                            </Form.Group> : null
                    }
                    {
                        product.epid ?
                            <Form.Group label="EPID">
                                <div>{product.epid}</div>
                            </Form.Group> : null
                    }
                    {
                        product.isbn ?
                            <Form.Group label="ISBN">
                                <div>{product.isbn}</div>
                            </Form.Group> : null
                    }
                    {
                        product.tags ?
                            <Form.Group label="Tags">
                                <div>{product.tags}</div>
                            </Form.Group> : null
                    }
                    {
                        product.weight ?
                            <Form.Group label="Weight">
                                <div>{product.weight}</div>
                            </Form.Group> : null
                    }
                    {
                        product.width ?
                            <Form.Group label="Width">
                                <div>{product.width}</div>
                            </Form.Group> : null
                    }
                    {
                        product.length ?
                            <Form.Group label="Length">
                                <div>{product.length}</div>
                            </Form.Group> : null
                    }
                    {
                        product.height ?
                            <Form.Group label="Height">
                                <div>{product.height}</div>
                            </Form.Group> : null
                    }

                </React.Fragment>
            }

            <Form.Group label="Condition">
                <div>{product.condition}</div>
            </Form.Group>


            {
                product.category && product.category.categoryPath &&
                <React.Fragment>
                    <Form.Group label="Category">
                        <div>{product.category.categoryPath}</div>
                    </Form.Group>
                </React.Fragment>
            }

        </Card.Body>
    </Card>);
}

export { ProductInformation };