import React, { useState, useEffect } from 'react';
import * as ebayCtrl from '../../services/ebay';
import BootstrapTable from 'react-bootstrap-table-next';
import { Button, Loader, Card, Grid, Icon } from "tabler-react";
import { AddInventoryLocationModal } from './AddInventoryLocationModal';
import { EditInventoryLocationModal } from './EditInventoryLocationModal';
import { isoCountries } from '../../constants';
import { InnerComponentLoader } from '../InnerComponentLoader';
import "./InventoryLocations.scss";

const InventoryLocationsInner = (props) => {

    const [inventoryLocations, setInventoryLocations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [inventoryLocationsLoading, setInventoryLocationsLoading] = useState(false);
    const [showAddInventoryModalSettings, setShowAddInventoryModalSettings] = useState({ show: false });
    const [showEditInventoryModalSettings, setShowEditInventoryModalSettings] = useState({ show: false, location: null });
    const [defaultMerchantLocationKey, setDefaultMerchantLocationKey] = useState(null);

    const columns = [
        {
            dataField: "merchantLocationKey",
            hidden: true
        },
        {
            dataField: 'name',
            text: 'Name',
            formatter: (cell, row) => <React.Fragment>
                {cell} {row.merchantLocationKey === defaultMerchantLocationKey && <strong>(default)</strong>}
            </React.Fragment>
        },
        {
            dataField: "city",
            text: "City",
            formatter: (cell, row) => <React.Fragment>
                {row.location && row.location.address && row.location.address.city}
            </React.Fragment>
        },
        {
            dataField: "country",
            text: "Country",
            formatter: (cell, row) => <React.Fragment>
                {row.location && row.location.address && row.location.address.country && isoCountries[row.location.address.country].name}
            </React.Fragment>
        },
        {
            dataField: "manage",
            text: "",
            align: "right",
            formatter: (cell, row) => <React.Fragment>
                <Button className="edit-btn" onClick={() => onEditInventoryLocation(row)} size="sm" color="secondary"><Icon prefix="fa" name="edit" /> Edit</Button>
            </React.Fragment>
        }
    ];

    useEffect(() => {
        refreshInventoryLocations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function onAddInventoryLocation() {
        setShowAddInventoryModalSettings({ show: true });
    }

    function onEditInventoryLocation(location) {
        setShowEditInventoryModalSettings({ show: true, location });
    }

    async function onSetDefaultInventoryLocation(merchantLocationKey) {

        setInventoryLocations([]);
        setInventoryLocationsLoading(true);

        await ebayCtrl.setDefaultInventoryLocation(props.app, merchantLocationKey);
        refreshInventoryLocations();
    }

    async function refreshInventoryLocations() {

        setInventoryLocations([]);
        setInventoryLocationsLoading(true);
        setLoading(true);

        let result = await ebayCtrl.getInventoryLocations(props.app);

        if (!result.errors.length) {
            setDefaultMerchantLocationKey(result.defaultMerchantLocationKey);
            setInventoryLocations(result.locations);
            setInventoryLocationsLoading(false);
            setLoading(false);
        }
    }

    if (loading) {
        return (<InnerComponentLoader title="Inventory Locations" />);
    }

    return (

        <div className="app-settings-content">
            <Card className="inventory-locations">
                <Card.Header>

                    <h4 className="inventory-locations-header">Inventory Locations</h4>
                    <div className="add-inventory-location-btn">
                        <Button color="primary" onClick={() => onAddInventoryLocation()}><i className="far fa-plus-circle" aria-hidden="true"></i>&nbsp;&nbsp;Add</Button>
                        <div className="clear-fix"></div>
                    </div>
                </Card.Header>
                <div className="card-inner">

                    {
                        !inventoryLocationsLoading && inventoryLocations.length === 0 &&
                        <div>
                            There are no inventory locations to show.
                        </div>
                    }

                    {
                        !inventoryLocationsLoading && inventoryLocations.length > 0 &&
                        <BootstrapTable
                            bordered={false}
                            classes="card-table table-vcenter text-nowrap ebay-category-mappings-table"
                            bootstrap4
                            keyField="merchantLocationKey"
                            noDataIndication={() => inventoryLocationsLoading && <div className="table-loader-container"><Loader /></div>}
                            data={inventoryLocations}
                            columns={columns} />
                    }


                </div>
                {
                    props.installWizard &&
                    <div className="next-btn">
                        <Button onClick={() => props.loadNextStep()} type="submit" color="primary"><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
                    </div>
                }
            </Card>

            {
                showAddInventoryModalSettings.show &&
                <AddInventoryLocationModal app={props.app} refreshInventoryLocations={refreshInventoryLocations} showInventoryModalSettings={showAddInventoryModalSettings} hideModal={() => setShowAddInventoryModalSettings({ show: false })} />
            }

            {
                showEditInventoryModalSettings.show &&
                <EditInventoryLocationModal app={props.app} refreshInventoryLocations={refreshInventoryLocations} showInventoryModalSettings={showEditInventoryModalSettings} hideModal={() => setShowEditInventoryModalSettings({ show: false, location: null })} />
            }
        </div>
    );
}

export { InventoryLocationsInner };