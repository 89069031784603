import React from 'react';
import SiteWrapper from '../SiteWrapper.react';
import { Grid, Page } from 'tabler-react';
import { SettingsMenu } from '../SettingsMenu';
import { PaymentInstructionsInner } from './PaymentInstructionsInner';
import './PaymentInstructions.scss';

const PaymentInstructions = (props) => {

  return (
    <SiteWrapper>
      <Page.Content>
        <Grid.Row cards>
          <div className="app-settings-wrapper">
            <SettingsMenu app={props.app} />
            <PaymentInstructionsInner app={props.app} />
          </div>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>

  );
}

export { PaymentInstructions };