import history from '../history';

const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
}

const getBrowserInfo = () => {
    try {
        var nAgt = navigator.userAgent;
        var browserName = navigator.appName;
        var fullVersion = '' + parseFloat(navigator.appVersion);
        var majorVersion = parseInt(navigator.appVersion, 10);
        var nameOffset, verOffset, ix;

        // In Opera, the true version is after "Opera" or after "Version"

        //eslint-disable-next-line
        if ((verOffset = nAgt.indexOf("Opera")) != -1) {
            browserName = "Opera";
            fullVersion = nAgt.substring(verOffset + 6);

            //eslint-disable-next-line
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In MSIE, the true version is after "MSIE" in userAgent

        //eslint-disable-next-line
        else if ((verOffset = nAgt.indexOf("MSIE")) != -1) {
            browserName = "Microsoft Internet Explorer";
            fullVersion = nAgt.substring(verOffset + 5);
        }
        // In Chrome, the true version is after "Chrome" 

        //eslint-disable-next-line
        else if ((verOffset = nAgt.indexOf("Chrome")) != -1) {
            browserName = "Chrome";
            fullVersion = nAgt.substring(verOffset + 7);
        }
        // In Safari, the true version is after "Safari" or after "Version" 

        //eslint-disable-next-line
        else if ((verOffset = nAgt.indexOf("Safari")) != -1) {
            browserName = "Safari";
            fullVersion = nAgt.substring(verOffset + 7);

            //eslint-disable-next-line
            if ((verOffset = nAgt.indexOf("Version")) != -1)
                fullVersion = nAgt.substring(verOffset + 8);
        }
        // In Firefox, the true version is after "Firefox" 

        //eslint-disable-next-line
        else if ((verOffset = nAgt.indexOf("Firefox")) != -1) {
            browserName = "Firefox";
            fullVersion = nAgt.substring(verOffset + 8);
        }
        // In most other browsers, "name/version" is at the end of userAgent 
        else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) <
            (verOffset = nAgt.lastIndexOf('/'))) {
            browserName = nAgt.substring(nameOffset, verOffset);
            fullVersion = nAgt.substring(verOffset + 1);

            //eslint-disable-next-line
            if (browserName.toLowerCase() == browserName.toUpperCase()) {
                browserName = navigator.appName;
            }
        }
        // trim the fullVersion string at semicolon/space if present

        //eslint-disable-next-line
        if ((ix = fullVersion.indexOf(";")) != -1)
            fullVersion = fullVersion.substring(0, ix);

        //eslint-disable-next-line
        if ((ix = fullVersion.indexOf(" ")) != -1)
            fullVersion = fullVersion.substring(0, ix);

        majorVersion = parseInt('' + fullVersion, 10);
        if (isNaN(majorVersion)) {
            fullVersion = '' + parseFloat(navigator.appVersion);
            majorVersion = parseInt(navigator.appVersion, 10);
        }

        var OSName = "Unknown OS";

        //eslint-disable-next-line
        if (navigator.appVersion.indexOf("Win") != -1) OSName = "Windows";

        //eslint-disable-next-line
        if (navigator.appVersion.indexOf("Mac") != -1) OSName = "MacOS";

        //eslint-disable-next-line
        if (navigator.appVersion.indexOf("X11") != -1) OSName = "UNIX";

        //eslint-disable-next-line
        if (navigator.appVersion.indexOf("Linux") != -1) OSName = "Linux";

        let currentPage = (history && history.location && history.location.pathname) || "";

        return `CLIENT LOGGER: Page = '${currentPage}', Operating system = '${OSName}', Browser name = '${browserName}', Full version = '${fullVersion}', Major version = '${majorVersion}', navigator.appName = '${navigator.appName}', navigator.userAgent = '${navigator.userAgent}'|`;
    }
    catch (e) {

        let currentPage = (history && history.location && history.location.pathname) || "";

        return `CLIENT LOGGER: Page = '${currentPage}', <Unable to retrieve browser info> ${e.toString === typeof "function" ? e.toString() : e.message}|`;
    }
}

export { getBrowserInfo, inIframe };