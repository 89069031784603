import React from 'react';

const RoundedLogo = ({logoUrl, height, width, style}) => {

    return (
        <div style={{...style, height, width, minWidth: width}}>
            <img style={{height: height, width, borderRadius: 8}} src={logoUrl} alt="App Logo" />
        </div>
    );
}

export { RoundedLogo };