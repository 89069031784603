const itemSpecificsRuleConditionType = {
    contains: 1,
    equals: 2,
    startsWith: 3,
    endsWith: 4,
    lessThan: 5,
    greaterThan: 6,
    regexMatch: 7,
    doesNotContain: 8,
    doesNotEqual: 9,
    isEmpty: 10,
    notEmpty: 11,
    greaterThanOrEqualTo: 12,
    lessThanOrEqualTo: 13,
    characterCountEquals: 14,
    characterCountLessThan: 15,
    characterCountGreaterThan: 16,
    characterCountLessThanOrEqualTo: 17,
    characterCountGreaterThanOrEqualTo: 18
}

export { itemSpecificsRuleConditionType };