import React, { useEffect, useState, useRef } from 'react';
import {
  Page,
  Loader,
  Icon,
  Button
} from "tabler-react";
import SiteWrapper from './SiteWrapper.react';
import * as dashboardCtrl from '../services/dashboard';
import {
  GridStack
} from 'gridstack';
import {
  EbayWidget,
  ShoppingFeedWidget,
  AmazonWidget,
  TradeMeWidget,
  UnitsSoldWidget,
  AlertsWidget,
  ProductSyncWidget,
  MarketplaceWidget
} from './Widgets';
import { useSelector } from "react-redux";
import * as mobileUtility from '../utilities/mobileUtility';
import { 
  appType 
} from '../constants';
import history from '../history';
import 'gridstack/dist/h5/gridstack-dd-native';
import 'gridstack/dist/gridstack.min.css';
import '../c3.css';
import './Dashboard.scss';


const componentLookup = {
  "TradeMeWidget": TradeMeWidget,
  "EbayWidget": EbayWidget,
  "ShoppingFeedWidget": ShoppingFeedWidget,
  "AmazonWidget": AmazonWidget,
  "UnitsSoldWidget": UnitsSoldWidget,
  "AlertsWidget": AlertsWidget,
  "ProductSyncWidget": ProductSyncWidget,
  "CatchWidget": MarketplaceWidget,
  "KoganWidget": MarketplaceWidget,
  "WishWidget": MarketplaceWidget
};

const Dashboard = () => {

  const [widgets, setWidgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const gridStackInstance = useRef(null);
  const isMobile = useRef(false);
  const user = useSelector(state => state.user);

  useEffect(() => {

    isMobile.current = mobileUtility.mobileCheck();

    const fetchData = async () => {
      let widgets = await dashboardCtrl.getWidgets();
      setWidgets(widgets);
      setLoading(false);
    }

    fetchData();
  }, []);

  useEffect(() => {

    if (!loading && !isMobile.current) {

      gridStackInstance.current = GridStack.init({
        margin: 20,
        minWidth: 12,
        minRow: 1,
        cellHeight: "70px",
        draggable: {
          handle: ".draggable-heading"
        }
      });

      gridStackInstance.current.on("resize", (event, element) => {
        let widgetUserId = element.getAttribute("g-id");
        const widgetResizeEvent = new CustomEvent(`widgetResize-${widgetUserId}`, { element });
        window.dispatchEvent(widgetResizeEvent);
      });

      gridStackInstance.current.on("change", async (event, items) => {

        if (!items) {
          return;
        }

        updateWidgets(items);
      });

    }
  }, [loading]);

  const updateWidgets = async (items) => {

    var widgets = [];

    for (let i = 0; i < items.length; i++) {

      let userWidget = {
        width: items[i].el.getAttribute("gs-w"),
        height: items[i].el.getAttribute("gs-h"),
        posX: items[i].el.getAttribute("gs-x"),
        posY: items[i].el.getAttribute("gs-y"),
        userWidgetId: items[i].el.getAttribute("g-id")
      }
      widgets.push(userWidget);
    }

    let updateGadgetPositionModel = {
      widgets
    }

    await dashboardCtrl.updateWidgetPosition(updateGadgetPositionModel)
  }

  if (loading) {
    return <SiteWrapper>
      <Page.Content className="dashboard">
        <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 50 }}>
          <Loader />
        </div>
      </Page.Content>
    </SiteWrapper>
  }

  return (
    <SiteWrapper>
      <Page.Content className="dashboard">

        {
          user.info.appId === appType.bulkDiscountManager ?
          <div style={{marginBottom: 20}}>
            <Button type="button" color="primary" onClick={() => {history.push("/bulk-discount/new-campaign")}}><Icon prefix="fa" name="tag" />&nbsp;&nbsp;New Discount Campaign</Button>
          </div> : null
        }

        <div className="grid-stack-wrapper">

          <div className={`${isMobile.current ? "" : "grid-stack"}`}>
            {
              Array.isArray(widgets) ? widgets.filter(w => w.widget && w.widget.reactComponent && componentLookup[w.widget.reactComponent]).map(w => {
                let Component = componentLookup[w.widget.reactComponent];
                return <Component key={w.id} userWidget={w} />
              }) : null
            }
          </div>
        </div>
      </Page.Content>
    </SiteWrapper>
  );
}

export { Dashboard };