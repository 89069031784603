import * as apiCall from '../apiCall';

function getAuthorizationUrl(appId) {

    const options = {
        appId
    };

    let url = `/api/ebay/get_authorization_url`;  
    return apiCall.get(url, options);
}

export { getAuthorizationUrl };