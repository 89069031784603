import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "react-router-dom";
import App from "./App";
import history from "./history";
import reduxStore from "./reduxStore";
import { Provider } from "react-redux";
import { LicenseManager } from "ag-grid-enterprise";

LicenseManager.setLicenseKey(
  "BOARD4ALL_NDEwMjM1MTIwMDAwMA==8f4481b5cc626ad79fe91bc5f4e52e3d"
);

const rootElement = document.getElementById("root");

const root = ReactDOM.createRoot(rootElement);

root.render(
  <Provider store={reduxStore}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);
