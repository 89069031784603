import * as apiCall from '../apiCall';

function getSellerId(appId) {

    const options = {
        appId
    };

    let url = `/api/amazon/get_seller_id`;
    
    return apiCall.get(url, options);
}

export { getSellerId };