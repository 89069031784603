import * as apiCall from '../apiCall';

function getProductDetail(model) {

    const options = {
        appId: model.app
    };

    return apiCall.get(`/api/marketplace/get_product_detail?productId=${model.productId}`, options);
}

export { getProductDetail };