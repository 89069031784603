import React from 'react';
import SiteWrapper from './SiteWrapper.react';
import {
    Grid,
    Card,
    Page,
    Dimmer
} from 'tabler-react';
import {
    SettingsMenu
} from './SettingsMenu';

const FormLoader = (props) => {

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} shoppingFeedApp={props.shoppingFeedApp} />

                        <div className="app-settings-content">
                            <Card loading>
                                <Card.Header>
                                    <h4>{props.formTitle}</h4>
                                </Card.Header>
                                <Dimmer active loader>
                                    <div style={{ height: 150 }}></div>
                                </Dimmer>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export { FormLoader };