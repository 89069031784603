import React from 'react';
import { ProgressBar } from './ProgressBar';
import './MarketplaceHealth.scss';

const MarketplaceHealth = ({ appId, marketplace, lastSync, syncInProgress, percentInfo, progressBarWidth }) => {

    if (syncInProgress && percentInfo) {
        return (<div className="marketplace-health">
            <div className="row-border">
                <div className="row-progress-name">
                    {marketplace}
                </div>
                <div className="row-progress-value">
                    <ProgressBar percent={percentInfo.percentCompleted} width="100%" />
                    <div id={`progress-app-${appId}`}></div>
                    <div style={{width: "100%"}} className="progress-status">{percentInfo.percentText}</div>
                </div>
            </div>
        </div>);
    }

    return (<div className="marketplace-health">
        <div className="row-inner row-border">
            <div className="row-name">
                {marketplace}
            </div>
            <div className="row-value">
                {lastSync || "Pending"}
            </div>
        </div>
    </div>);
}

export { MarketplaceHealth };