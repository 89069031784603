import React from 'react';
import { Button } from "tabler-react";
import { customConfirmAlert } from './CustomConfirmAlert';

const SaveToolbar = ({ show, saveAction, undoAction, cancelAction, formSaving }) => {

    const performAction = (action) => {
        customConfirmAlert(async () => {
            action();
        }, `Revert latest changes?`);
    }
    if (show) {
        return (<div className="save-toolbar">
            <div className="save-toolbar-inner">
                {
                    cancelAction &&
                    <Button className="toolbar-cancel-action" disabled={formSaving} onClick={() => performAction(() => cancelAction())} type="submit" color="danger"><i className="fa fa-backward" aria-hidden="true"></i>&nbsp;&nbsp;Undo</Button>
                }
                {
                    undoAction &&
                    <Button onClick={() => performAction(() => undoAction())} disabled={formSaving} className="undo-btn" type="button" color="secondary"><i className="fa fa-trash-undo" aria-hidden="true"></i>&nbsp;&nbsp;Undo</Button>
                }
                {
                    saveAction &&
                    <Button loading={formSaving} onClick={() => saveAction()} type="submit" color="primary"><i className="fa fa-cloud" aria-hidden="true"></i>&nbsp;&nbsp;Save</Button>
                }
            </div>
        </div>);
    }
    else {
        return null;
    }
}
export { SaveToolbar };