import React, { useState, useEffect } from 'react';
import {
    Grid,
    Card,
    Form,
    Button,
    Icon,
    Page
} from 'tabler-react';
import SiteWrapper from '../SiteWrapper.react';
import * as installCtrl from '../../services/install';
import * as koganCtrl from '../../services/kogan';
import * as logger from '../../services/log';
import { useFormik } from 'formik';
import { appType } from '../../constants';
import { FormLoader } from '../FormLoader';
import { SettingsMenu } from '../SettingsMenu';
import "./Install.scss";

const Authorisation = (props) => {

    const [loading, setLoading] = useState(true);
    const [authorizeLoading, setAuthorizeLoading] = useState(false);
    const [userDetails, setUserDetails] = useState({});
    const [formSubmitted, setFormSubmitted] = useState(false);

    useEffect(() => {

        const fetchData = async () => {

            let userDetails = await koganCtrl.getAuthorisationDetail(props.app);
            let steps = await installCtrl.getMarketplaceSteps(props.app);

            setUserDetails(userDetails);

            formik.resetForm({
                values: {
                    sellerId: userDetails.sellerId,
                    sellerToken: userDetails.sellerToken
                }
            });

            setLoading(false);
        }

        fetchData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const validate = values => {
        const errors = {};

        setFormSubmitted(true);

        if (values.sellerToken === null
            || values.sellerToken === undefined
            || values.sellerToken.trim() === "") {
            errors.sellerTokenError = "Required";
        }

        if (values.sellerId === null
            || values.sellerId === undefined
            || values.sellerId.trim() === "") {
            errors.sellerIdError = "Required";
        }

        return errors;
    }

    const formik = useFormik({
        validateOnBlur: formSubmitted,
        validateOnChange: formSubmitted,
        initialValues: {
            sellerToken: "",
            sellerId: ""
        },
        onSubmit: async (values) => {
            await authoriseAccount();
        },
        validate
    });


    const authoriseAccount = async () => {
        if (authorizeLoading) {
            return;
        }

        try {
            setAuthorizeLoading(true);

            let model = {
                sellerId: formik.values.sellerId,
                sellerToken: formik.values.sellerToken
            };

            await koganCtrl.authoriseAccount(appType.kogan, model);
            let userDetails = await koganCtrl.getAuthorisationDetail(props.app);
            setUserDetails(userDetails);

            formik.resetForm({
                values: {
                    sellerId: userDetails.sellerId,
                    sellerToken: userDetails.sellerToken
                }
            });

            setAuthorizeLoading(false);
        }
        catch (e) {
            setAuthorizeLoading(false);
            logger.logError("Could not authorise Kogan account.", e);
        }
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={"Authorisation"} />);
    }

    return (

        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>

                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />


                        <div className="app-settings-content">
                            <Card className="kogan-install">
                                <Card.Header>
                                    <h4>Authorisation</h4>
                                </Card.Header>
                                <div className="card-inner">
                                    <div>
                                        <Grid.Row>
                                            <Grid.Col width={12}>
                                                Authorise your account with Kogan.
                                            </Grid.Col>
                                        </Grid.Row>

                                        {
                                            userDetails.sellerId && userDetails.sellerToken && !authorizeLoading ? <div style={{ marginTop: 20 }}>
                                                Status: <span style={{ color: userDetails.connectionValid ? "green" : "red", fontWeight: userDetails.connectionValid ? "bold" : "normal" }}>{userDetails.koganTokenStatus}</span>
                                            </div> : null
                                        }

                                        <Grid.Row>
                                            <Grid.Col width={12}>
                                                <Form autocomplete="off" onSubmit={formik.handleSubmit} className="install-kogan-form">

                                                    <div className="api-key-wrapper">
                                                        <div style={{ width: "100%" }}>
                                                            <Form.Group label="Seller ID">
                                                                <Form.Input
                                                                    type="text"
                                                                    disabled={(authorizeLoading)}
                                                                    error={formik.errors.sellerIdError}
                                                                    value={formik.values.sellerId || ""}
                                                                    autocomplete="off"
                                                                    name="sellerId"
                                                                    onChange={formik.handleChange} />

                                                            </Form.Group>

                                                            <Form.Group label="Seller Token">
                                                                <Form.Input
                                                                    type="text"
                                                                    disabled={(authorizeLoading)}
                                                                    error={formik.errors.sellerTokenError}
                                                                    value={formik.values.sellerToken || ""}
                                                                    autocomplete="off"
                                                                    name="sellerToken"
                                                                    onChange={formik.handleChange} />

                                                            </Form.Group>
                                                        </div>
                                                    </div>

                                                </Form>
                                            </Grid.Col>
                                        </Grid.Row>

                                        <Grid.Row>
                                            <Grid.Col width={12}>
                                                <Form.Footer className="install-footer">
                                                    <Button size="lg" type="button" color="primary" onClick={formik.handleSubmit} disabled={(authorizeLoading)} loading={authorizeLoading}><Icon prefix="fa" name="plug" />&nbsp;&nbsp;Connect</Button>
                                                </Form.Footer>
                                            </Grid.Col>
                                        </Grid.Row>
                                    </div>
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>);
}

export { Authorisation };