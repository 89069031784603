import React, { useState, useEffect } from 'react';
import {
    Card,
    Table,
    Form
} from "tabler-react";
import ReactTooltip from 'react-tooltip';
import { toast } from 'react-toastify';
import { CustomToast } from '../components';
import * as logger from '../services/log';
import { useFormik } from 'formik';
import * as marketplaceCtrl from '../services/marketplace';

const Variants = ({ product, isAmazonApp, app }) => {

    const [asinSaving, setAsinSaving] = useState({});
    const [asin, setAsin] = useState({});

    useEffect(() => {

        if (Array.isArray(product.productVariations)) {
            let asinVal = {};
            product.productVariations.forEach(pv => {
                asinVal[pv.id] = pv.asin;
            });
            setAsin(asinVal);
        }
    }, []);

    const renderVariantImage = (images) => {
        if (!images) {
            return (<div className="variant-image">
                <i className="fal fa-image image-placeholder" />
            </div>);
        }

        // use first image
        let image = images.split(",")[0];

        return (<div className="variant-image">
            <img src={image} alt="Product Variant" />
        </div>);

    }

    const saveAsin = async (productVariationId) => {

        let asinSavingVal = {...asinSaving};

        try {
            
            asinSavingVal[productVariationId] = true;
            setAsinSaving(asinSavingVal);

            let model = {
                asin: asin[productVariationId],
                productId: productVariationId,
                isVariation: true
            }

            await marketplaceCtrl.saveAsin(app, model);

            toast.success(<CustomToast message={`ASIN updated.`} />, { position: toast.POSITION.TOP_RIGHT });
            
            asinSavingVal = {...asinSaving};
            asinSavingVal[productVariationId] = false;
            setAsinSaving(asinSavingVal);
        }
        catch (e) {
            logger.logError("Error saving ASIN for product variation", e);
            asinSavingVal = {...asinSaving};
            asinSavingVal[productVariationId] = false;
            setAsinSaving(asinSavingVal);
        }

    }

    return (<Card>
        <Card.Header>
            <h4>Variants</h4>
        </Card.Header>

        <Card.Body>
            {
                (!product.productVariations || product.productVariations.length === 0) ?
                    <div>This product does not contain any variants.</div>
                    :
                    null
            }
            {
                (product.productVariations && product.productVariations.length > 0) ?
                    <div className="product-variations">
                        <Table>
                            <Table.Header>
                                <Table.Row>
                                    <Table.ColHeader></Table.ColHeader>
                                    <Table.ColHeader>SKU</Table.ColHeader>
                                    <Table.ColHeader>Price</Table.ColHeader>
                                    <Table.ColHeader>Inventory</Table.ColHeader>
                                    <Table.ColHeader>Options</Table.ColHeader>
                                    {
                                        isAmazonApp ? <Table.ColHeader>ASIN<div style={{ display: "inline-block", marginLeft: 4, textTransform: "none" }}>
                                            <i data-tip data-for="asin-tooltip" className="fa fa-info-circle"></i>
                                            <ReactTooltip place="top" id="asin-tooltip" effect="solid">
                                                <div className="tooltip-message">
                                                    Opt for matching by ASIN when there are issues matching a product on Amazon. Assigning an ASIN to an Amazon product eliminates ambiguity when multiple products on Amazon have the same identifier (UPC/EAN/GTIN/ISBN).
                                                </div>
                                            </ReactTooltip>
                                        </div></Table.ColHeader> : null
                                    }
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {
                                    product.productVariations.map(pv => (
                                        <Table.Row key={pv.id}>
                                            <Table.Col>
                                                {renderVariantImage(pv.images)}
                                            </Table.Col>
                                            <Table.Col>{pv.sku}</Table.Col>
                                            <Table.Col>{pv.price.toFixed(2)}</Table.Col>
                                            <Table.Col>{pv.inventoryLevel}</Table.Col>
                                            <Table.Col>
                                                {
                                                    pv.productVariationOptionValues && pv.productVariationOptionValues.length > 0 &&
                                                    pv.productVariationOptionValues.map(o => (
                                                        <div key={o.id}>
                                                            <strong>{o.option}</strong>: {o.value}
                                                        </div>
                                                    ))
                                                }
                                            </Table.Col>
                                            {
                                                isAmazonApp ? <Table.Col>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                        <div style={{ width: "100%" }}>
                                                            <Form.Input disabled={asinSaving[pv.id]} onChange={(e) => {
                                                                let asinVal = { ...asin };
                                                                asinVal[pv.id] = e.target.value;
                                                                setAsin(asinVal);
                                                            }} value={asin[pv.id] || ""} />
                                                        </div>
                                                        <div>
                                                            {
                                                                asinSaving[pv.id] ? <i className="save-asin-icon fa fa-spinner fa-spin" /> : <i onClick={() => saveAsin(pv.id)} className="save-asin-icon fa fa-save" />
                                                            }

                                                        </div>

                                                    </div>

                                                </Table.Col> : null
                                            }
                                        </Table.Row>
                                    ))
                                }

                            </Table.Body>
                        </Table>
                    </div>
                    :
                    null
            }
        </Card.Body>

    </Card>);
}

export { Variants };