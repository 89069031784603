import * as apiCall from '../apiCall';

function getCategoryRecommendations(appId, keyword) {

    const options = {
        appId
    };

    let url = `/api/ebay/get_category_recommendations?keyword=${keyword}`;
    
    return apiCall.get(url, options);
}

export { getCategoryRecommendations };