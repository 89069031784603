import React from 'react';
import {
    appType
} from '../constants';
import {
    Grid,
    Card,
    Button,
    Form
} from 'tabler-react';
import * as appUtility from '../utilities/appUtility';
import './SettingsMenu.scss';
import history from '../history';

export class SettingsMenu extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            appDetail: this.getAppDetail()
        }
    }

    getAppDetail() {

        let shoppingFeedApp = this.props.shoppingFeedApp;
        if (shoppingFeedApp) {

            let menu = [];

            menu.push({
                name: "Templates",
                link: `/shopping-feeds/${shoppingFeedApp.slug}/templates`
            });

            menu.push({
                name: "Category Mappings",
                link: `/shopping-feeds/${shoppingFeedApp.slug}/category-mappings`
            });

            if (appUtility.getFeedInstructions(shoppingFeedApp) !== null) {
                menu.push({
                    name: "Instructions",
                    link: `/shopping-feeds/${shoppingFeedApp.slug}/instructions`
                });
            }
            return {
                appName: shoppingFeedApp.altName,
                appLogo: shoppingFeedApp.logoUrl,
                menu
            }
        }

        switch (this.props.app) {

            case appType.kogan:
                return {
                    appName: "Kogan",
                    appLogo: "/images/apps/kogan_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/kogan/products" },
                        { name: "Authorisation", link: "/marketplaces/kogan/authorisation" },
                        { name: "Shipping", link: "/marketplaces/kogan/shipping" },
                        { name: "Settings", link: "/marketplaces/kogan/general" },
                        { name: "Category Mappings", link: "/marketplaces/kogan/category-mappings" },
                        { name: "Templates", link: "/marketplaces/kogan/templates" }
                    ]
                }

            case appType.catch:
                return {
                    appName: "Catch",
                    appLogo: "/images/apps/catch_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/catch/products" },
                        { name: "Authorisation", link: "/marketplaces/catch/authorisation" },
                        { name: "Settings", link: "/marketplaces/catch/general" },
                        { name: "Category Mappings", link: "/marketplaces/catch/category-mappings" },
                        { name: "Templates", link: "/marketplaces/catch/templates" }
                    ]
                }

            case appType.tradeMe:
                return {
                    appName: "TradeMe",
                    appLogo: "/images/apps/trademe_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/trademe/products" },
                        { name: "Authorisation", link: "/marketplaces/trademe/authorisation" },
                        { name: "General", link: "/marketplaces/trademe/general" },
                        { name: "Shipping", link: "/marketplaces/trademe/shipping" },
                        { name: "Payment Instructions", link: "/marketplaces/trademe/payment-instructions" },
                        { name: "Category Mappings", link: "/marketplaces/trademe/category-mappings" },
                        { name: "Feedback", link: "/marketplaces/trademe/feedback" },
                        { name: "Custom Rules", link: "/marketplaces/trademe/custom-rules" }
                    ]
                }

            case appType.amazonAU:
                return {
                    appName: "Amazon Australia",
                    appLogo: "/images/apps/amazon_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/amazon-au/products" },
                        { name: "Authorisation", link: "/marketplaces/amazon-au/authorisation" },
                        { name: "General", link: "/marketplaces/amazon-au/general" },
                        { name: "Templates", link: "/marketplaces/amazon-au/templates" },
                        { name: "Feed History", link: "/marketplaces/amazon-au/feed-history" }
                    ]
                }

            case appType.amazonUk:
                return {
                    appName: "Amazon UK",
                    appLogo: "/images/apps/amazon_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/amazon-uk/products" },
                        { name: "Authorisation", link: "/marketplaces/amazon-uk/authorisation" },
                        { name: "General", link: "/marketplaces/amazon-uk/general" },
                        { name: "Templates", link: "/marketplaces/amazon-uk/templates" },
                        { name: "Feed History", link: "/marketplaces/amazon-uk/feed-history" }
                    ]
                }

            case appType.amazonUs:
                return {
                    appName: "Amazon USA",
                    appLogo: "/images/apps/amazon_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/amazon-us/products" },
                        { name: "Authorisation", link: "/marketplaces/amazon-us/authorisation" },
                        { name: "General", link: "/marketplaces/amazon-us/general" },
                        { name: "Templates", link: "/marketplaces/amazon-us/templates" },
                        { name: "Feed History", link: "/marketplaces/amazon-us/feed-history" }
                    ]
                }

            case appType.eBayAU:
                return {
                    appName: "Ebay Australia",
                    appLogo: "/images/apps/ebay_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/ebay-au/products" },
                        { name: "Authorisation", link: "/marketplaces/ebay-au/authorisation" },
                        { name: "General", link: "/marketplaces/ebay-au/general" },
                        { name: "Category Mappings", link: "/marketplaces/ebay-au/category-mappings" },
                        { name: "Shipping", link: "/marketplaces/ebay-au/shipping" },
                        { name: "Returns", link: "/marketplaces/ebay-au/returns" },
                        { name: "Feedback", link: "/marketplaces/ebay-au/feedback" },
                        { name: "Inventory Locations", link: "/marketplaces/ebay-au/inventory-locations" },
                        { name: "Auto Item Specifics", link: "/marketplaces/ebay-au/auto-item-specifics" },
                        { name: "Custom Rules", link: "/marketplaces/ebay-au/custom-rules" }
                    ]
                }

            case appType.ebayUS:
                return {
                    appName: "Ebay United States",
                    appLogo: "/images/apps/ebay_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/ebay-us/products" },
                        { name: "Authorisation", link: "/marketplaces/ebay-us/authorisation" },
                        { name: "General", link: "/marketplaces/ebay-us/general" },
                        { name: "Category Mappings", link: "/marketplaces/ebay-us/category-mappings" },
                        { name: "Shipping", link: "/marketplaces/ebay-us/shipping" },
                        { name: "Returns", link: "/marketplaces/ebay-us/returns" },
                        { name: "Feedback", link: "/marketplaces/ebay-us/feedback" },
                        { name: "Inventory Locations", link: "/marketplaces/ebay-us/inventory-locations" },
                        { name: "Auto Item Specifics", link: "/marketplaces/ebay-us/auto-item-specifics" },
                        { name: "Custom Rules", link: "/marketplaces/ebay-us/custom-rules" }
                    ]
                }

            case appType.ebayUK:
                return {
                    appName: "Ebay United Kingdom",
                    appLogo: "/images/apps/ebay_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/ebay-uk/products" },
                        { name: "Authorisation", link: "/marketplaces/ebay-uk/authorisation" },
                        { name: "General", link: "/marketplaces/ebay-uk/general" },
                        { name: "Category Mappings", link: "/marketplaces/ebay-uk/category-mappings" },
                        { name: "Shipping", link: "/marketplaces/ebay-uk/shipping" },
                        { name: "Returns", link: "/marketplaces/ebay-uk/returns" },
                        { name: "Feedback", link: "/marketplaces/ebay-uk/feedback" },
                        { name: "Inventory Locations", link: "/marketplaces/ebay-uk/inventory-locations" },
                        { name: "Auto Item Specifics", link: "/marketplaces/ebay-uk/auto-item-specifics" },
                        { name: "Custom Rules", link: "/marketplaces/ebay-uk/custom-rules" }
                    ]
                }

            case appType.ebayCA:
                return {
                    appName: "Ebay Canada",
                    appLogo: "/images/apps/ebay_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/ebay-ca/products" },
                        { name: "Authorisation", link: "/marketplaces/ebay-ca/authorisation" },
                        { name: "General", link: "/marketplaces/ebay-ca/general" },
                        { name: "Category Mappings", link: "/marketplaces/ebay-ca/category-mappings" },
                        { name: "Shipping", link: "/marketplaces/ebay-ca/shipping" },
                        { name: "Returns", link: "/marketplaces/ebay-ca/returns" },
                        { name: "Feedback", link: "/marketplaces/ebay-ca/feedback" },
                        { name: "Inventory Locations", link: "/marketplaces/ebay-ca/inventory-locations" },
                        { name: "Auto Item Specifics", link: "/marketplaces/ebay-ca/auto-item-specifics" },
                        { name: "Custom Rules", link: "/marketplaces/ebay-ca/custom-rules" }
                    ]
                }

            case appType.ebayIE:
                return {
                    appName: "Ebay Ireland",
                    appLogo: "/images/apps/ebay_logo.png",
                    menu: [
                        { name: "Products", link: "/marketplaces/ebay-ie/products" },
                        { name: "Authorisation", link: "/marketplaces/ebay-ie/authorisation" },
                        { name: "General", link: "/marketplaces/ebay-ie/general" },
                        { name: "Category Mappings", link: "/marketplaces/ebay-ie/category-mappings" },
                        { name: "Shipping", link: "/marketplaces/ebay-ie/shipping" },
                        { name: "Returns", link: "/marketplaces/ebay-ie/returns" },
                        { name: "Feedback", link: "/marketplaces/ebay-ie/feedback" },
                        { name: "Inventory Locations", link: "/marketplaces/ebay-ie/inventory-locations" },
                        { name: "Auto Item Specifics", link: "/marketplaces/ebay-ie/auto-item-specifics" },
                        { name: "Custom Rules", link: "/marketplaces/ebay-ie/custom-rules" }
                    ]
                }


            default: throw new Error("App not recognised. Could not render menu.");
        }
    }

    handleMenuItemClick = (link) => {
        history.push(link);
    }

    render() {
        return (
            <div className="settings-menu">

                <div className="mobile-settings-menu">
                    <div className="app-header">
                        <div className="app-item-logo">
                            <img src={this.state.appDetail.appLogo} alt="App Logo" />
                        </div>
                        <h4>
                            {this.state.appDetail.appName}
                        </h4>
                    </div>
                    <Form.Select
                        onChange={(e) => this.handleMenuItemClick(e.target.value)}
                        value={history.location.pathname}>
                        {
                            this.state.appDetail.menu.map((m, i) => {
                                return (
                                    <option key={i} value={m.link}>{m.name}</option>
                                )
                            })
                        }

                    </Form.Select>
                </div>
                <Card className="desktop-settings-menu">
                    <div className="card-inner">
                        <div className="app-header">
                            <div className="app-item-logo">
                                <img src={this.state.appDetail.appLogo} alt="App Logo" />
                            </div>
                            <h4>
                                {this.state.appDetail.appName}
                            </h4>
                        </div>


                        <Button.List>
                            {
                                this.state.appDetail.menu.map((m, i) => {
                                    return (
                                        <Button key={i}
                                            square
                                            block
                                            onClick={() => this.handleMenuItemClick(m.link)}
                                            color={`${history.location.pathname === m.link ? "primary" : "secondary"}`}>
                                            {m.name}
                                        </Button>
                                    )
                                })
                            }
                        </Button.List>
                    </div>
                </Card>
            </div>
        )
    }
}