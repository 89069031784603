import React from 'react';
import {
    Alert
  } from "tabler-react";

const UncategorisedAlert = ({uncategorisedCount}) => {
    return (<Alert type="primary" icon="alert-triangle">
        There are {uncategorisedCount} product{`${uncategorisedCount > 1 ? "s" : ""}`} Uncategorised. In Shopify, the 'Product Type' determines the category. It's a good idea to set 'Product Type' on all your products (within Shopify).
    </Alert>);
}

export { UncategorisedAlert };