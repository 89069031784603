import React, { useState, useEffect } from 'react';
import * as tradeMeCtrl from '../../services/trademe';
import { Form, Grid, Card, Button, Alert, Icon } from 'tabler-react';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components/CustomToast';
import { useFormik } from 'formik';
import { tradeMePaymentMethodType } from '../../constants';
import { InnerComponentLoader } from '../InnerComponentLoader';
import * as logger from '../../services/log';
import { SaveToolbar } from '../SaveToolbar';
import './PaymentInstructions.scss';

const PaymentInstructionsInner = (props) => {

  const [paymentDetails, setPaymentDetails] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [formSaving, setFormSaving] = useState(false);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    refreshPaymentDetails();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onUndoBtnClicked = async () => {
    try {
      setLoading(true);
      await refreshPaymentDetails();
    }
    catch (e) {
      logger.logError("onUndoBtnClicked error", e);
      setLoading(false);
    }
  }

  const refreshPaymentDetails = async () => {
    let paymentDetails = await tradeMeCtrl.getPaymentDetails(props.app);

    let bankDeposit = paymentDetails.paymentMethods.find(pm => pm.tradeMePaymentMethodId === tradeMePaymentMethodType.bankDeposit);
    let creditCard = paymentDetails.paymentMethods.find(pm => pm.tradeMePaymentMethodId === tradeMePaymentMethodType.creditCard);
    let cash = paymentDetails.paymentMethods.find(pm => pm.tradeMePaymentMethodId === tradeMePaymentMethodType.cash);
    let ping = paymentDetails.paymentMethods.find(pm => pm.tradeMePaymentMethodId === tradeMePaymentMethodType.ping);
    let afterpay = paymentDetails.paymentMethods.find(pm => pm.tradeMePaymentMethodId === tradeMePaymentMethodType.afterpay);

    formik.resetForm({
      values: {
        bankDeposit: bankDeposit !== undefined,
        creditCard: creditCard !== undefined,
        cash: cash !== undefined,
        ping: ping !== undefined,
        afterpay: afterpay !== undefined,
        paymentInstructions: bankDeposit ? bankDeposit.paymentInstructions : paymentDetails.defaultPaymentInstructions
      }
    });

    setPaymentDetails(paymentDetails);
    setLoading(false);
  }

  const validate = values => {
    let errors = {};

    return errors;
  };

  const save = async (values) => {
    try {

      setFormSaving(true);
      setFormSubmitted(true);

      let paymentMethods = [];

      if (values.bankDeposit) {
        paymentMethods.push({ tradeMePaymentMethodId: tradeMePaymentMethodType.bankDeposit, paymentInstructions: values.paymentInstructions });
      }

      if (values.creditCard) {
        paymentMethods.push({ tradeMePaymentMethodId: tradeMePaymentMethodType.creditCard });
      }

      if (values.cash) {
        paymentMethods.push({ tradeMePaymentMethodId: tradeMePaymentMethodType.cash });
      }

      if (values.ping) {
        paymentMethods.push({ tradeMePaymentMethodId: tradeMePaymentMethodType.ping });
      }

      if (values.afterpay) {
        paymentMethods.push({ tradeMePaymentMethodId: tradeMePaymentMethodType.afterpay });
      }

      let model = {
        paymentMethods
      }

      await tradeMeCtrl.savePaymentDetails(props.app, model)

      await refreshPaymentDetails();

      toast.success(<CustomToast message={`Payment instructions saved.`} />, { position: toast.POSITION.TOP_RIGHT });

      if (props.installWizard) {
        props.loadNextStep();
      }
    }
    catch (e) {
      logger.logError("PaymentInstructions save() error", e);
    }
    finally {
      setFormSaving(false);
    }
  }

  const formik = useFormik({
    validateOnMount: false,
    validateOnBlur: formSubmitted,
    validateOnChange: formSubmitted,
    initialValues: {},
    onSubmit: async (values) => {
      await save(values);
    },
    validate
  });

  if (loading) {
    return <InnerComponentLoader title="Payment Instructions" />
  }

  return (
    <div className="app-settings-content">
      <Card className="trademe-payment-details">
        <Card.Header>
          <h4>Payment Instructions</h4>
        </Card.Header>

        <Form id="payment-form" onSubmit={formik.handleSubmit}>
          <div>
            <div className="card-inner">
              {
                paymentDetails && !paymentDetails.paymentMethods.length && !props.installWizard &&
                <Alert type="danger">Payment Instructions not found on this TradeMe account. Please update Payment Instructions.</Alert>
              }

              <div>
                <Form.Group className="payment-method">
                  <Form.Checkbox
                    disabled={formSaving}
                    label="Bank Deposit"
                    name="bankDeposit"
                    onChange={formik.handleChange}
                    checked={formik.values.bankDeposit}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group className="payment-method">
                  <Form.Checkbox
                    disabled={formSaving}
                    label="Credit Card (Pay Now)"
                    name="creditCard"
                    onChange={formik.handleChange}
                    checked={formik.values.creditCard}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group className="payment-method">
                  <Form.Checkbox
                    disabled={formSaving}
                    label="Cash"
                    name="cash"
                    onChange={formik.handleChange}
                    checked={formik.values.cash}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group className="payment-method">
                  <Form.Checkbox
                    disabled={formSaving}
                    label="Ping"
                    name="ping"
                    onChange={formik.handleChange}
                    checked={formik.values.ping}
                  />
                </Form.Group>
              </div>
              <div>
                <Form.Group className="payment-method">
                  <Form.Checkbox
                    disabled={formSaving}
                    label="Afterpay"
                    name="afterpay"
                    onChange={formik.handleChange}
                    checked={formik.values.afterpay}
                  />
                </Form.Group>
              </div>

              {
                formik.values.bankDeposit &&
                <Form.Group label="NZ Bank Deposit Instructions">
                  <Form.Textarea rows={10}
                    disabled={formSaving}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="paymentInstructions"
                    value={formik.values.paymentInstructions}
                    error={formik.errors && formik.touched.instructions && formik.errors.instructions}>
                  </Form.Textarea>
                </Form.Group>
              }

            </div>
          </div>


          <div>
            <Form.Footer>
              {
                props.installWizard &&
                <Button type="submit" color="primary" loading={formSaving}><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
              }
              {
                !props.installWizard &&
                <SaveToolbar show undoAction={() => onUndoBtnClicked()} saveAction={() => { }} formSaving={formSaving} />

              }

            </Form.Footer>
          </div>

        </Form>

      </Card>
    </div>
  );

}

export { PaymentInstructionsInner };