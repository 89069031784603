import * as apiCall from '../apiCall';

function saveTemplate(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/shoppingfeed/save_template`, model, options);
}

export { saveTemplate };