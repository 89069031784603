import React from 'react';
import * as ebayCtrl from '../../services/ebay';
import Modal from 'react-modal';
import { 
    Form, 
    Button, 
    Icon, 
    Grid 
} from 'tabler-react';
import { 
    toast 
} from 'react-toastify';
import { 
    CustomToast, 
    CustomSelect 
} from '../../components';
import * as logger from '../../services/log';
import '../CustomModal.css';
import "./ShippingPolicyModal.scss";

const MaxDomesticServices = 4;
const MaxInternationalServices = 5;

export class ShippingPolicyModal extends React.Component {
    constructor(props) {
        super(props);

        this.initialState = {
            internationalPostageSelection: "NO_INTERNATIONAL_POSTAGE",
            domesticPostageSelection: "FLAT_RATE",
            shippingPolicySaveLoading: false,
            handlingTime: null,
            policyName: "",
            isDefault: false,
            editShippingPolicy: null,
            handlingTimeError: null,
            policyNameError: null,
            formIsValid: true,
            handlingCost: ""
        }

        this.handlingTimeOptions = [
            { label: "Same business day", value: 0 },
            { label: "1 business day", value: 1 },
            { label: "2 business days", value: 2 },
            { label: "3 business days", value: 3 }
        ];

        this.state = {
            ...this.initialState,
            modalIsOpen: false,
            countries: [],
            shippingServices: [],
            splitShippingLocations: [],
            selectedDomesticServices: [{}],
            selectedInternationalServices: [{ shippingLocations: {} }],
            selectedInternationalExcludedCountries: []
        };

        this.formSubmitted = false;
    }

    async componentDidMount() {
        const shippingServices = await ebayCtrl.getShippingServices(this.props.app);
        const countries = (await ebayCtrl.getCountries(this.props.app)).map(c => ({ label: c.description, value: c.countryCode }));


        var shippingLocations = await ebayCtrl.getShippingLocations(this.props.app);

        shippingServices.forEach(s => {
            if (s.shippingCategory === null || s.shippingCategory === "NONE") {
                s.shippingCategory = "OTHER";
            }

            if (s.shippingCategory === "ONE_DAY") {
                s.shippingCategory = "EXPRESS SERVICES";
            }
        });

        let splitShippingLocations = this.splitArr(shippingLocations, 3);

        this.setState({ shippingServices, splitShippingLocations, countries });
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.modalIsOpen && !prevState.modalIsOpen && this.state.editShippingPolicy) {
            // map shipping policy

            this.setState({
                ...this.initialState, selectedDomesticServices: [{}],
                selectedInternationalServices: [{ shippingLocations: {} }],
                selectedInternationalExcludedCountries: []
            });

            let editShippingPolicy = { ...this.state.editShippingPolicy };

            if (editShippingPolicy.handlingTime && editShippingPolicy.handlingTime.unit === "DAY") {
                let handlingTime = editShippingPolicy.handlingTime.value;
                this.setState({ handlingTime });
            }
            else {
                logger.logError(`Ebay ShippingPolicyModal componentDidUpdate() error. Could not determine Handling time. ${JSON.stringify(editShippingPolicy.handlingTime)}`);
            }

            if (editShippingPolicy.shipToLocations &&
                editShippingPolicy.shipToLocations.regionExcluded &&
                editShippingPolicy.shipToLocations.regionExcluded.length &&
                this.state.countries.length) {

                this.setState({
                    selectedInternationalExcludedCountries: editShippingPolicy.shipToLocations.regionExcluded.map(re => ({ label: this.state.countries.find(c => c.value === re.regionName).label, value: re.regionName }))
                });
            }


            let domesticOption = editShippingPolicy.shippingOptions
                && editShippingPolicy.shippingOptions.find(o => o.optionType === "DOMESTIC");


            let internationalOption = editShippingPolicy.shippingOptions
                && editShippingPolicy.shippingOptions.find(o => o.optionType === "INTERNATIONAL");

            if (domesticOption) {
                if (domesticOption.costType === "FLAT_RATE" || domesticOption.costType === "CALCULATED") {
                    this.setState({ domesticPostageSelection: domesticOption.costType });
                }
                else {
                    logger.logError("Uknown domestic option selected.");
                }

                if (domesticOption.packageHandlingCost && domesticOption.packageHandlingCost.value && domesticOption.packageHandlingCost.value !== "0") {
                    this.setState({handlingCost: domesticOption.packageHandlingCost.value});
                }

                if (domesticOption.shippingServices.length > 0) {

                    domesticOption.shippingServices.sort(this.orderBySortOrder);

                    let selectedDomesticServices = domesticOption.shippingServices.map((s, i) => {
                        return {
                            domesticService: s.shippingServiceCode || s.shippingCarrierCode,
                            cost: s.shippingCost && s.shippingCost.value && parseFloat(s.shippingCost.value).toFixed(2),
                            additionalItemCost: domesticOption.costType === "FLAT_RATE" && s.additionalShippingCost && s.additionalShippingCost.value && parseFloat(s.additionalShippingCost.value) > 0 ? parseFloat(s.additionalShippingCost.value).toFixed(2).toString() : "",
                            freePostage: s.freeShipping
                        }
                    });

                    this.setState({ selectedDomesticServices });
                }
            }

            if (internationalOption) {
                if (internationalOption.costType === "FLAT_RATE" || internationalOption.costType === "CALCULATED") {
                    this.setState({ internationalPostageSelection: internationalOption.costType });
                }
                else {
                    this.setState({ internationalPostageSelection: "NO_INTERNATIONAL_POSTAGE" });
                }

                if (internationalOption.packageHandlingCost && internationalOption.packageHandlingCost.value && internationalOption.packageHandlingCost.value !== "0") {
                    this.setState({handlingCost: internationalOption.packageHandlingCost.value});
                }

                if (internationalOption.shippingServices.length > 0) {

                    internationalOption.shippingServices.sort(this.orderBySortOrder);

                    let selectedInternationalServices = internationalOption.shippingServices.map((s, i) => {

                        let shippingLocations = {};

                        if (s.shipToLocations && s.shipToLocations.regionIncluded && s.shipToLocations.regionIncluded.length) {
                            s.shipToLocations.regionIncluded.forEach(r => shippingLocations[r.regionName] = true);
                        }

                        return {
                            internationalService: s.shippingServiceCode || s.shippingCarrierCode,
                            cost: internationalOption.costType === "FLAT_RATE" && s.shippingCost && s.shippingCost.value && parseFloat(s.shippingCost.value).toFixed(2).toString(),
                            additionalItemCost: s.additionalShippingCost && s.additionalShippingCost.value && parseFloat(s.additionalShippingCost.value) > 0 ? parseFloat(s.additionalShippingCost.value).toFixed(2) : null,
                            shippingLocations
                        }
                    });

                    this.setState({ selectedInternationalServices: selectedInternationalServices });
                }

            }

            this.formSubmitted = false;
            this.setState({ policyName: editShippingPolicy.name, shippingPolicySaveLoading: false, editShippingPolicy });

        }
        else if (this.state.modalIsOpen && !prevState.modalIsOpen) {
            this.formSubmitted = false;
            this.setState({ ...this.initialState, selectedDomesticServices: [{}], selectedInternationalServices: [{ shippingLocations: {} }], editShippingPolicy: null });
        }
    }

    orderBySortOrder = (a, b) => {
        if (a.sortOrder < b.sortOrder) {
            return -1;
        }
        if (a.sortOrder > b.sortOrder) {
            return 1;
        }
        return 0;
    }

    openModal = (editShippingPolicy = null) => {

        this.setState({ editShippingPolicy: null });

        this.setState({ modalIsOpen: true });

        if (editShippingPolicy) {
            this.setState({ editShippingPolicy: { ...editShippingPolicy }, fulfillmentPolicyId: editShippingPolicy.fulfillmentPolicyId })
        }
        else {
            this.setState({ editShippingPolicy: null, fulfillmentPolicyId: null });
        }
    }

    closeModal = () => {
        this.setState({ modalIsOpen: false });
    }

    handlingCostChanged = (e) => {
        this.setState({ handlingCost: e.target.value }, () => this.validateShippingPolicy());
    }

    policyNameChanged = (e) => {
        this.setState({ policyName: e.target.value }, () => this.validateShippingPolicy());
    }

    validateShippingPolicy = () => {

        if (!this.formSubmitted) {
            return;
        }

        let isValid = true;

        let selectedDomesticServices = [...this.state.selectedDomesticServices];
        let selectedInternationalServices = [...this.state.selectedInternationalServices];

        selectedDomesticServices.forEach(d => {
            if (!d.domesticService || d.domesticService === "") {
                d.domesticServiceError = "Required";
                isValid = false;
            }
            else {
                delete (d.domesticServiceError);
            }

            if (!d.freePostage && this.state.domesticPostageSelection === "FLAT_RATE" && (d.cost === null || d.cost === undefined || d.cost.trim() === "")) {
                d.costError = "Required";
                isValid = false;
            }
            else {
                delete (d.costError);
            }
        });

        if (this.state.internationalPostageSelection !== "NO_INTERNATIONAL_POSTAGE") {
            selectedInternationalServices.forEach(d => {
                if (!d.internationalService || d.internationalService === "") {
                    d.internationalServiceError = "Required";
                    isValid = false;
                }
                else {
                    delete (d.internationalServiceError);
                }

                if (this.state.internationalPostageSelection === "FLAT_RATE" && (d.cost === null || d.cost === undefined || d.cost.trim() === "")) {
                    d.costError = "Required";
                    isValid = false;
                }
                else {
                    delete (d.costError);
                }

                if (!d.shippingLocations || !Object.keys(d.shippingLocations).length) {
                    d.shipToLocationError = "At least one location is required.";
                    isValid = false;
                }
                else {
                    delete (d.shipToLocationError);
                }
            });
        }

        if ((!this.state.handlingTime && this.state.handlingTime !== 0) || this.state.handlingTime === "") {
            isValid = false;
            this.setState({ handlingTimeError: "Required" });
        }
        else {
            this.setState({ handlingTimeError: null });
        }

        if (!this.state.policyName && this.state.policyName !== 0) {
            isValid = false;
            this.setState({ policyNameError: "Required" });
        }
        else {
            this.setState({ policyNameError: null });
        }

        this.setState({ selectedDomesticServices, selectedInternationalServices, formIsValid: isValid });


        return isValid;
    }

    saveShippingPolicy = async () => {


        this.formSubmitted = true;

        let validationResult = this.validateShippingPolicy();

        if (!validationResult) {
            toast.warn(<CustomToast message={`Shipping policy could not be saved. Please review form, fix any errors and try again.`} />, { position: toast.POSITION.TOP_RIGHT });
            return;
        }

        try {
            this.setState({ shippingPolicySaveLoading: true });

            let shippingPolicy = {
                name: this.state.policyName,
                shippingOptions: [],
                handlingTime: {
                    unit: "DAY",
                    value: parseInt(this.state.handlingTime)
                },
                shipToLocations: {
                    regionExcluded: [],
                    regionIncluded: []
                }
            };

            let domesticShippingOption = {
                costType: this.state.domesticPostageSelection,
                optionType: "DOMESTIC"
            }

            let domesticHandlingCost = this.state.handlingCost.toString();      

            if (this.state.domesticPostageSelection === "CALCULATED") {

                domesticShippingOption.packageHandlingCost = {
                    value: domesticHandlingCost === "" ? "0" : domesticHandlingCost
                }
            }
            else {
                domesticShippingOption.packageHandlingCost = {
                    value: "0"
                }
            }

            domesticShippingOption.shippingServices = this.state.selectedDomesticServices.map((s, i) => {
                return {
                    shippingCost: (!s.freePostage && this.state.domesticPostageSelection === "FLAT_RATE" && {
                        value: s.cost
                    }) || null,
                    additionalShippingCost: (s.additionalItemCost && s.additionalItemCost.trim() !== "" && {
                        value: s.additionalItemCost.toString()
                    }) || {
                        value: "0"
                    },
                    freeShipping: s.freePostage || false,
                    shippingServiceCode: s.domesticService,
                    sortOrder: (i + 1)
                };
            });

            shippingPolicy.shippingOptions.push(domesticShippingOption);

            if (this.state.internationalPostageSelection !== "NO_INTERNATIONAL_POSTAGE") {
                let internationalShippingOption = {
                    costType: this.state.internationalPostageSelection,
                    optionType: "INTERNATIONAL"
                }

                let intlHandlingCost = this.state.handlingCost.toString();

                if (this.state.internationalPostageSelection === "CALCULATED") {
                    internationalShippingOption.packageHandlingCost = {
                        value: intlHandlingCost === "" ? "0" : intlHandlingCost
                    }
                }
                else {
                    internationalShippingOption.packageHandlingCost = {
                        value: "0"
                    }
                }

                if (this.state.selectedInternationalExcludedCountries && this.state.selectedInternationalExcludedCountries.length) {
                    shippingPolicy.shipToLocations.regionExcluded = this.state.selectedInternationalExcludedCountries.map(c => ({
                        regionName: c.value,
                        regionType: "COUNTRY"
                    }));
                }

                internationalShippingOption.shippingServices = this.state.selectedInternationalServices.map((s, i) => {

                    let regionIncluded = [];

                    if (s.shippingLocations) {
                        regionIncluded = Object.keys(s.shippingLocations).map(sl => ({ regionName: sl }));
                    }

                    return {
                        shippingCost: (!s.freePostage && this.state.internationalPostageSelection === "FLAT_RATE" && {
                            value: s.cost
                        }) || null,
                        additionalShippingCost: (s.additionalItemCost && s.additionalItemCost.trim() !== "" && {
                            value: s.additionalItemCost.toString()
                        }) || {
                            value: "0"
                        },
                        optionType: "INTERNATIONAL",
                        shippingServiceCode: s.internationalService,
                        sortOrder: (i + 1),
                        shipToLocations: {
                            regionIncluded
                        }
                    };
                });

                shippingPolicy.shippingOptions.push(internationalShippingOption);
            }

            if (this.state.fulfillmentPolicyId) {
                shippingPolicy.fulfillmentPolicyId = this.state.fulfillmentPolicyId;

                await ebayCtrl.updateShippingPolicy(this.props.app, shippingPolicy);
                toast.success(<CustomToast message={`Updated shipping policy.`} />, { position: toast.POSITION.TOP_RIGHT });
            }
            else {
                await ebayCtrl.createShippingPolicy(this.props.app, shippingPolicy);
                toast.success(<CustomToast message={`Created shipping policy '${shippingPolicy.name}'.`} />, { position: toast.POSITION.TOP_RIGHT });
            }

        }
        finally {
            this.setState({ shippingPolicySaveLoading: false, modalIsOpen: false });
            this.props.refreshFulfillmentOptions && this.props.refreshFulfillmentOptions();
        }
    }

    changePostageSelection = (val, type) => {
        if (type === "domestic") {
            this.setState({ domesticPostageSelection: val, selectedDomesticServices: [{}] }, () => {
                this.validateShippingPolicy();
            });
        }
        else if (type === "international") {
            this.setState({ internationalPostageSelection: val, selectedInternationalServices: [{ shippingLocations: {} }] }, () => {
                this.validateShippingPolicy();
            });
        }
    }

    getShippingServicesWithDisabledOptions(shippingServices) {
        let alteredShippingServices = [];
        let shippingCategory = "";

        shippingServices.forEach(s => {
            let shipCatOption = false;
            if (shippingCategory !== s.shippingCategory) {
                shippingCategory = s.shippingCategory;
                shipCatOption = true;
            }

            if (shipCatOption) {
                alteredShippingServices.push({ disabled: true, id: -1, description: shippingCategory });
            }

            alteredShippingServices.push(s);

        });

        return alteredShippingServices;

    }

    onHandlingTimeChanged = (option) => {
        this.setState({ handlingTime: option }, () => this.validateShippingPolicy());
    }

    onChangeDomesticService = (option, iteration) => {

        this.setState((prevState) => {

            let selectedDomesticServices = [...prevState.selectedDomesticServices];
            selectedDomesticServices[iteration].domesticService = option;

            return {
                selectedDomesticServices
            }

        }, () => this.validateShippingPolicy());
    }

    onChangeInternationalService = (option, iteration) => {

        this.setState(prevState => {

            let selectedInternationalServices = [...prevState.selectedInternationalServices];
            selectedInternationalServices[iteration].internationalService = option;

            return {
                selectedInternationalServices
            }
        }, () => this.validateShippingPolicy());
    }

    onChangeExcludeCountries = (option) => {

        this.setState(prevState => {
            return {
                selectedInternationalExcludedCountries: option
            }
        }, () => this.validateShippingPolicy());
    }

    onInternationalShippingLocationChanged = (iteration, location) => {
        this.setState((prevState) => {
            let selectedInternationalServices = [...prevState.selectedInternationalServices];

            if (location === "Worldwide" && (selectedInternationalServices[iteration] && !selectedInternationalServices[iteration].shippingLocations["Worldwide"])) {
                selectedInternationalServices[iteration].shippingLocations = {};
                selectedInternationalServices[iteration].shippingLocations["Worldwide"] = true;
            }
            else if (selectedInternationalServices[iteration].shippingLocations && selectedInternationalServices[iteration].shippingLocations[location]) {
                delete (selectedInternationalServices[iteration].shippingLocations[location]);
            }
            else if (selectedInternationalServices[iteration].shippingLocations) {
                selectedInternationalServices[iteration].shippingLocations[location] = true;
            }
            else {
                selectedInternationalServices[iteration].shippingLocations = {};
                selectedInternationalServices[iteration].shippingLocations[location] = true;
            }

            return {
                selectedInternationalServices
            }
        }, () => this.validateShippingPolicy());
    }

    addDomesticService = () => {

        this.setState((prevState) => {
            let selectedDomesticServices = [...prevState.selectedDomesticServices];

            if (selectedDomesticServices.length < MaxDomesticServices) {
                selectedDomesticServices.push({});

                return {
                    selectedDomesticServices
                }

            }
            else {
                return null;
            }

        }, () => this.validateShippingPolicy());
    }

    removeDomesticService = (iteration) => {

        if (this.state.shippingPolicySaveLoading) {
            return;
        }

        this.setState((prevState) => {
            let selectedDomesticServices = [...prevState.selectedDomesticServices];

            selectedDomesticServices.splice(iteration, 1);

            return {
                selectedDomesticServices
            }

        }, () => this.validateShippingPolicy());
    }

    addInternationalService = () => {

        this.setState((prevState) => {
            let selectedInternationalServices = [...prevState.selectedInternationalServices];

            if (selectedInternationalServices.length < MaxInternationalServices) {
                selectedInternationalServices.push({ shippingLocations: {} });

                return {
                    selectedInternationalServices
                }
            }
            else {
                return null;
            }

        }, () => this.validateShippingPolicy());
    }

    removeInternationalService = (iteration) => {

        if (this.state.shippingPolicySaveLoading) {
            return;
        }

        this.setState((prevState) => {

            let selectedInternationalServices = [...prevState.selectedInternationalServices];
            selectedInternationalServices.splice(iteration, 1);

            return {
                selectedInternationalServices
            }

        }, () => this.validateShippingPolicy());
    }

    domesticPostageCostChanged = (iteration, val) => {

        this.setState((prevState) => {

            let selectedDomesticServices = [...prevState.selectedDomesticServices];

            selectedDomesticServices[iteration].cost = val;

            return {
                selectedDomesticServices
            }

        }, () => this.validateShippingPolicy());
    }

    internationalPostageCostChanged = (iteration, val) => {

        this.setState((prevState) => {

            let selectedInternationalServices = [...prevState.selectedInternationalServices];

            selectedInternationalServices[iteration].cost = val;

            return {
                selectedInternationalServices
            }

        }, () => this.validateShippingPolicy());
    }

    domesticAdditionalItemCostChanged = (iteration, val) => {

        this.setState((prevState) => {

            let selectedDomesticServices = [...prevState.selectedDomesticServices];

            selectedDomesticServices[iteration].additionalItemCost = val;

            return {
                selectedDomesticServices
            }

        }, () => this.validateShippingPolicy());
    }

    internationalAdditionalItemCostChanged = (iteration, val) => {
        this.setState((prevState) => {
            let selectedInternationalServices = [...prevState.selectedInternationalServices];
            selectedInternationalServices[iteration].additionalItemCost = val;

            return {
                selectedInternationalServices
            }
        }, () => this.validateShippingPolicy());
    }

    domesticFreePostageChanged = (iteration, val) => {

        this.setState((prevState) => {

            let selectedDomesticServices = [...prevState.selectedDomesticServices];

            selectedDomesticServices[iteration].freePostage = val;

            if (!val) {
                selectedDomesticServices[iteration].cost = "";
                selectedDomesticServices[iteration].additionalItemCost = "";
            }

            return {
                selectedDomesticServices
            }

        }, () => this.validateShippingPolicy());
    }

    getShippingServices(type) {
        if (type === "domestic" && this.state.domesticPostageSelection === "FLAT_RATE") {

            let items = this.getShippingServicesWithDisabledOptions(this.state.shippingServices.filter((s, i) => !s.internationalService && s.flatRate && i !== 0)).map((s, i) => {

                if (s.disabled) {
                    return { label: s.description, value: s.description, isDisabled: true };
                }

                return {
                    label: this.getShippingDescription(s),
                    value: s.shippingService
                }
            });
            
            return items;
        }
        else if (type === "domestic" && this.state.domesticPostageSelection === "CALCULATED") {
            return this.getShippingServicesWithDisabledOptions(this.state.shippingServices.filter((s, i) => !s.internationalService && s.calculated && i !== 0)).map((s, i) => {

                if (s.disabled) {
                    return { label: s.description, value: s.description, isDisabled: true };
                }

                return {
                    label: this.getShippingDescription(s),
                    value: s.shippingService
                }
            });
        }
        else if (type === "international" && this.state.internationalPostageSelection === "FLAT_RATE") {
            return this.getShippingServicesWithDisabledOptions(this.state.shippingServices.filter((s, i) => s.internationalService && s.flatRate && i !== 0)).map((s, i) => {

                if (s.disabled) {
                    return { label: s.description, value: s.description, isDisabled: true };
                }

                return {
                    label: this.getShippingDescription(s),
                    value: s.shippingService
                }
            });
        }
        else if (type === "international" && this.state.internationalPostageSelection === "CALCULATED") {
            return this.getShippingServicesWithDisabledOptions(this.state.shippingServices.filter((s, i) => s.internationalService && s.calculated && i !== 0)).map((s, i) => {

                if (s.disabled) {
                    return { label: s.description, value: s.description, isDisabled: true };
                }

                return {
                    label: this.getShippingDescription(s),
                    value: s.shippingService
                }
            });
        }

        return [];
    }

    getShippingDescription = (shippingService) => {
        return shippingService.description + `${shippingService.shippingTimeMin && shippingService.shippingTimeMax ? ` (${shippingService.shippingTimeMin} to ${shippingService.shippingTimeMax} business days)` : ""}`
    };

    splitArr = (arr, n) => {
        var rest = arr.length % n,
            restUsed = rest,
            partLength = Math.floor(arr.length / n),
            result = [];

        for (var i = 0; i < arr.length; i += partLength) {
            var end = partLength + i,
                add = false;

            if (rest !== 0 && restUsed) {
                end++;
                restUsed--;
                add = true;
            }

            result.push(arr.slice(i, end));

            if (add) {
                i++;
            }
        }

        return result;
    }

    render() {

        return (

            <Modal
                appElement={document.body}
                isOpen={this.state.modalIsOpen}
                onRequestClose={this.closeModal}
                shouldCloseOnOverlayClick={false}
                className="custom-modal"
                overlayClassName="custom-modal-overlay">

                <Icon onClick={this.closeModal} className="close-icon" prefix="fa" name="times" />

                <h2 className="category-header" style={{ textAlign: "center" }}>{this.state.editShippingPolicy ? "Edit" : "Add"} Shipping Policy</h2>
                <hr className="divider"></hr>

                <div className="shipping-services">

                    <Form.Group label="Policy Name">
                        <Form.Input
                            type="text"
                            disabled={this.state.shippingPolicySaveLoading}
                            value={this.state.policyName}
                            error={this.state.policyNameError}
                            onChange={this.policyNameChanged}
                            className="policy-name-input" />

                    </Form.Group>
                    <div className="domestic-postage">
                        <h3>Domestic postage</h3>

                        <Form.Group>
                            <div>
                                <Form.Radio
                                    onChange={() => this.changePostageSelection("FLAT_RATE", "domestic")}
                                    disabled={this.state.shippingPolicySaveLoading}
                                    label="Flat: Same cost to all buyers"
                                    name="domestic-service"
                                    value="FLAT_RATE"
                                    checked={this.state.domesticPostageSelection === "FLAT_RATE"} />
                            </div>

                            <div>
                                <Form.Radio
                                    onChange={() => this.changePostageSelection("CALCULATED", "domestic")}
                                    disabled={this.state.shippingPolicySaveLoading}
                                    label="Calculated: Cost varies by buyer location"
                                    name="domestic-service"
                                    value="CALCULATED"
                                    checked={this.state.domesticPostageSelection === "CALCULATED"} />
                            </div>


                        </Form.Group>

                        <h5>Services</h5>

                        {
                            this.state.selectedDomesticServices.map((ds, i) => {

                                return (<div key={i} style={{ marginTop: this.state.domesticPostageSelection === "CALCULATED" ? 10 : 0 }}>
                                    {
                                        this.state.shippingServices.length > 0 &&
                                        <div className="postage-selection">
                                            <Form.Group>

                                                <CustomSelect
                                                    value={this.getShippingServices("domestic") ? this.getShippingServices("domestic").find(option => /* eslint eqeqeq: 0 */ option.value == this.state.selectedDomesticServices[i].domesticService) : ""}
                                                    isDisabled={this.state.shippingPolicySaveLoading}
                                                    error={this.formSubmitted && this.state.selectedDomesticServices[i].domesticServiceError}
                                                    options={this.getShippingServices("domestic")}
                                                    onChange={(option) => this.onChangeDomesticService(option && option.value, i)}
                                                />
                                            </Form.Group>
                                            {
                                                i > 0 && <div className="remove-shipping-service-btn"><Icon onClick={() => this.removeDomesticService(i)} prefix="fa" name="trash" /></div>
                                            }
                                        </div>
                                    }

                                    {
                                        this.state.domesticPostageSelection === "FLAT_RATE" &&
                                        <div>
                                            {
                                                i === 0 &&
                                                <Form.Group className="free-postage-group">
                                                    <Form.Checkbox
                                                        disabled={this.state.shippingPolicySaveLoading}
                                                        label="Free postage"
                                                        name="free-postage"
                                                        onChange={(e) => { this.domesticFreePostageChanged(i, !this.state.selectedDomesticServices[i].freePostage) }}
                                                        checked={this.state.selectedDomesticServices[i].freePostage ? this.state.selectedDomesticServices[i].freePostage : false}
                                                    />
                                                </Form.Group>
                                            }

                                            <div className="postage-detail-input">
                                                <Form.Group label="Cost">
                                                    <Form.InputGroup>
                                                        <Form.InputGroupPrepend>
                                                            <Form.InputGroupText>$</Form.InputGroupText>
                                                        </Form.InputGroupPrepend>
                                                        <Form.Input
                                                            error={this.formSubmitted && this.state.selectedDomesticServices[i].costError}
                                                            disabled={this.state.selectedDomesticServices[i].freePostage || this.state.shippingPolicySaveLoading}
                                                            value={(this.state.selectedDomesticServices[i].freePostage && "0.00") || (this.state.selectedDomesticServices[i].cost || "")}
                                                            onChange={(e) => this.domesticPostageCostChanged(i, e.target.value)}
                                                            type="number"
                                                            className="cost-input" />
                                                    </Form.InputGroup>
                                                </Form.Group>
                                                <Form.Group label="Each additional (optional)">
                                                    <Form.InputGroup>
                                                        <Form.InputGroupPrepend>
                                                            <Form.InputGroupText>$</Form.InputGroupText>
                                                        </Form.InputGroupPrepend>
                                                        <Form.Input
                                                            disabled={this.state.selectedDomesticServices[i].freePostage || this.state.shippingPolicySaveLoading}
                                                            value={(this.state.selectedDomesticServices[i].freePostage && "0.00") || (this.state.selectedDomesticServices[i].additionalItemCost || "")}
                                                            onChange={(e) => this.domesticAdditionalItemCostChanged(i, e.target.value)}
                                                            type="number"
                                                            className="cost-input" />
                                                    </Form.InputGroup>
                                                </Form.Group>
                                            </div>
                                        </div>
                                    }

                                </div>)
                            })
                        }

                        <Button
                            color="primary"
                            disabled={this.state.selectedDomesticServices.length === MaxDomesticServices || this.state.shippingPolicySaveLoading}
                            icon="plus-circle"
                            onClick={() => this.addDomesticService()}
                            className="add-shipping-service-btn"
                            size="sm">Add domestic service</Button>

                    </div>


                    <div className="international-postage">
                        <h3>International postage</h3>

                        <Form.Group>
                            <div>
                                <Form.Radio
                                    onChange={() => this.changePostageSelection("NO_INTERNATIONAL_POSTAGE", "international")}
                                    label="No international postage"
                                    disabled={this.state.shippingPolicySaveLoading}
                                    name="international-service"
                                    value="NO_INTERNATIONAL_POSTAGE"
                                    checked={this.state.internationalPostageSelection === "NO_INTERNATIONAL_POSTAGE"} />
                            </div>

                            <div>
                                <Form.Radio
                                    onChange={() => this.changePostageSelection("FLAT_RATE", "international")}
                                    label="Flat: Same cost to all buyers"
                                    disabled={this.state.shippingPolicySaveLoading}
                                    name="international-service"
                                    value="FLAT_RATE"
                                    checked={this.state.internationalPostageSelection === "FLAT_RATE"} />
                            </div>

                            <div>
                                <Form.Radio
                                    onChange={() => this.changePostageSelection("CALCULATED", "international")}
                                    label="Calculated: Cost varies by buyer location"
                                    disabled={this.state.shippingPolicySaveLoading}
                                    name="international-service"
                                    value="CALCULATED"
                                    checked={this.state.internationalPostageSelection === "CALCULATED"} />
                            </div>

                        </Form.Group>

                        {
                            this.state.internationalPostageSelection !== "NO_INTERNATIONAL_POSTAGE" &&
                            <React.Fragment>
                                <h5>Services</h5>
                                {
                                    this.state.selectedInternationalServices.map((ds, i) => {
                                        return (<div key={i}>
                                            {
                                                this.state.shippingServices.length > 0 &&
                                                <div className="postage-selection">
                                                    <Form.Group>

                                                        <CustomSelect
                                                            value={this.getShippingServices("international") ? this.getShippingServices("international").find(option => /* eslint eqeqeq: 0 */ option.value == this.state.selectedInternationalServices[i].internationalService) : ""}
                                                            isDisabled={this.state.shippingPolicySaveLoading}
                                                            error={this.formSubmitted && this.state.selectedInternationalServices[i].internationalServiceError}
                                                            options={this.getShippingServices("international")}
                                                            onChange={(option) => this.onChangeInternationalService(option && option.value, i)}
                                                        />
                                                    </Form.Group>

                                                    {
                                                        i > 0 && <div className="remove-shipping-service-btn"><Icon onClick={() => this.removeInternationalService(i)} prefix="fa" name="trash" /></div>
                                                    }
                                                </div>
                                            }

                                            {
                                                this.state.internationalPostageSelection === "FLAT_RATE" &&
                                                <div className="postage-detail-input">
                                                    <Form.Group label="Cost">
                                                        <Form.InputGroup>
                                                            <Form.InputGroupPrepend>
                                                                <Form.InputGroupText>$</Form.InputGroupText>
                                                            </Form.InputGroupPrepend>
                                                            <Form.Input
                                                                error={this.formSubmitted && this.state.selectedInternationalServices[i].costError}
                                                                disabled={this.state.shippingPolicySaveLoading}
                                                                value={this.state.selectedInternationalServices[i].cost || ""}
                                                                onChange={(e) => this.internationalPostageCostChanged(i, e.target.value)}
                                                                type="number"
                                                                className="cost-input" />
                                                        </Form.InputGroup>
                                                    </Form.Group>
                                                    <Form.Group label="Each additional (optional)">
                                                        <Form.InputGroup>
                                                            <Form.InputGroupPrepend>
                                                                <Form.InputGroupText>$</Form.InputGroupText>
                                                            </Form.InputGroupPrepend>
                                                            <Form.Input
                                                                disabled={this.state.shippingPolicySaveLoading}
                                                                value={this.state.selectedInternationalServices[i].additionalItemCost || ""}
                                                                onChange={(e) => this.internationalAdditionalItemCostChanged(i, e.target.value)}
                                                                type="number"
                                                                className="cost-input" />
                                                        </Form.InputGroup>
                                                    </Form.Group>
                                                </div>
                                            }

                                            {
                                                this.state.splitShippingLocations.length > 0 &&
                                                <div className="international-shipping-locations">
                                                    <Form.Label>Post to</Form.Label>
                                                    {
                                                        this.state.selectedInternationalServices[i].shipToLocationError &&
                                                        <div style={{ color: "red" }}>{this.state.selectedInternationalServices[i].shipToLocationError}</div>
                                                    }
                                                    <Grid.Row>
                                                        <Grid.Col>
                                                            <Form.Group>
                                                                {
                                                                    this.state.splitShippingLocations[0].map(sl => {

                                                                        return (
                                                                            <div key={sl.location}>
                                                                                <Form.Checkbox
                                                                                    disabled={this.state.shippingPolicySaveLoading || (sl.location !== "Worldwide" && this.state.selectedInternationalServices[i].shippingLocations && this.state.selectedInternationalServices[i].shippingLocations["Worldwide"])}
                                                                                    label={sl.description}
                                                                                    onChange={(e) => { this.onInternationalShippingLocationChanged(i, sl.location) }}
                                                                                    checked={this.state.selectedInternationalServices[i].shippingLocations && this.state.selectedInternationalServices[i].shippingLocations[sl.location] ? true : false}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Group>
                                                        </Grid.Col>

                                                        <Grid.Col>
                                                            <Form.Group>
                                                                {
                                                                    this.state.splitShippingLocations[1].map(sl => {

                                                                        return (
                                                                            <div key={sl.location}>
                                                                                <Form.Checkbox
                                                                                    disabled={this.state.shippingPolicySaveLoading || (sl.location !== "Worldwide" && this.state.selectedInternationalServices[i].shippingLocations && this.state.selectedInternationalServices[i].shippingLocations["Worldwide"])}
                                                                                    label={sl.description}
                                                                                    onChange={(e) => { this.onInternationalShippingLocationChanged(i, sl.location) }}
                                                                                    checked={this.state.selectedInternationalServices[i].shippingLocations && this.state.selectedInternationalServices[i].shippingLocations[sl.location] ? true : false}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Group>
                                                        </Grid.Col>

                                                        <Grid.Col>
                                                            <Form.Group>
                                                                {
                                                                    this.state.splitShippingLocations[2].map(sl => {

                                                                        return (
                                                                            <div key={sl.location}>
                                                                                <Form.Checkbox
                                                                                    disabled={this.state.shippingPolicySaveLoading || (sl.location !== "Worldwide" && this.state.selectedInternationalServices[i].shippingLocations && this.state.selectedInternationalServices[i].shippingLocations["Worldwide"])}
                                                                                    label={sl.description}
                                                                                    onChange={(e) => { this.onInternationalShippingLocationChanged(i, sl.location) }}
                                                                                    checked={this.state.selectedInternationalServices[i].shippingLocations && this.state.selectedInternationalServices[i].shippingLocations[sl.location] ? true : false}
                                                                                />
                                                                            </div>
                                                                        )
                                                                    })
                                                                }
                                                            </Form.Group>
                                                        </Grid.Col>
                                                    </Grid.Row>
                                                </div>
                                            }
                                        </div>)
                                    })
                                }

                                <Form.Label>Exclude Countries</Form.Label>
                                <Form.Group>

                                    <CustomSelect
                                        placeholder="None"
                                        isMulti
                                        value={this.state.selectedInternationalExcludedCountries}
                                        isDisabled={this.state.shippingPolicySaveLoading}
                                        options={this.state.countries}
                                        onChange={(option) => this.onChangeExcludeCountries(option)}
                                    />
                                </Form.Group>

                                <Button
                                    color="primary"
                                    disabled={this.state.selectedInternationalServices.length === MaxInternationalServices || this.state.shippingPolicySaveLoading}
                                    icon="plus-circle"
                                    onClick={() => this.addInternationalService()}
                                    className="add-shipping-service-btn"
                                    size="sm">Add international service</Button>
                            </React.Fragment>
                        }

                    </div>

                    <div className="handling-detail-input">
                        <Form.Group label="Handling time">

                            <CustomSelect
                                value={this.handlingTimeOptions.find(option => /* eslint eqeqeq: 0 */ option.value == this.state.handlingTime)}
                                placeholder="Select an option"
                                isDisabled={this.state.shippingPolicySaveLoading}
                                error={this.formSubmitted && this.state.handlingTimeError}
                                options={this.handlingTimeOptions}
                                onChange={(option) => this.onHandlingTimeChanged(option && option.value)}
                            />
                        </Form.Group>
                        {
                            (this.state.domesticPostageSelection === "CALCULATED" || this.state.internationalPostageSelection === "CALCULATED") &&
                            <Form.Group label="Handling cost (optional)">
                                <Form.InputGroup>
                                    <Form.InputGroupPrepend>
                                        <Form.InputGroupText>$</Form.InputGroupText>
                                    </Form.InputGroupPrepend>
                                    <Form.Input value={this.state.handlingCost} onChange={this.handlingCostChanged} disabled={this.state.shippingPolicySaveLoading} type="number" className="cost-input" />
                                </Form.InputGroup>
                            </Form.Group>
                        }
                    </div>

                    <div className="shipping-policy-footer">
                        <div>
                            <Button onClick={this.closeModal} disabled={this.state.shippingPolicySaveLoading}>Cancel</Button>
                            <Button onClick={this.saveShippingPolicy} loading={this.state.shippingPolicySaveLoading} color="success">Save</Button>

                        </div>
                    </div>
                </div>

            </Modal>
        )
    }
}