const appType = {
    eBayAU: 1,
    amazonAU: 2,
    tradeMe: 3,
    ebayUS: 4,
    ebayCA: 5,
    ebayUK: 6,
    ebayIE: 7,
    googleShopping: 8,
    amazonSg: 9,
    amazonJp: 10,
    amazonTr: 11,
    amazonSa: 12,
    amazonNl: 13,
    amazonIt: 14,
    amazonIn: 15,
    amazonUk: 16,
    amazonFr: 17,
    amazonEs: 18,
    amazonEg: 19,
    amazonDe: 20,
    amazonAe: 21,
    amazonUs: 22,
    amazonMx: 23,
    amazonCa: 24,
    amazonBr: 25,
    facebook: 26,
    bing: 27,
    pinterest: 28,
    catch: 29,
    kogan: 30,
    wish: 31,
    etsy: 32,
    bulkDiscountManager: 33
}

export { appType };