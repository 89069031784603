import { listingPriorityType } from '../../constants';

const listingPriorityRenderer = (params) => {
    let eDiv = document.createElement("span");
    
    params.value = parseInt(params.value);

    switch (parseInt(params.value)) {
      case listingPriorityType.DontList:
        eDiv.innerHTML = "Dont List";
        break;

      case listingPriorityType.Low:
        eDiv.innerHTML = "Low";
        break;

      case listingPriorityType.Normal:
        eDiv.innerHTML = "Normal";
        break;

      case listingPriorityType.High:
        eDiv.innerHTML = "High";
        break;

      default:
        throw new Error("Could not determine listingPriority type");
    };

    return eDiv;
  }

  export { listingPriorityRenderer };