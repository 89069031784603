import * as apiCall from '../apiCall';

function saveItemSpecificsRules(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/save_item_specifics_rules`, model, options);
}

export { saveItemSpecificsRules };