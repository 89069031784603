import React, { Component } from 'react';
import {
  Button,
  Loader,
  Card,
  Grid,
  Icon
} from "tabler-react";
import {
  TradeMeCategoriesModal
} from './TradeMeCategoriesModal';
import * as tradeMeCtrl from '../../services/trademe';
import BootstrapTable from 'react-bootstrap-table-next';
import {
  InnerComponentLoader
} from '../InnerComponentLoader';
import * as logger from '../../services/log';
import {
  Link
} from 'react-router-dom';
import {
  UncategorisedAlert
} from '../Shopify';
import {
  integrationType
} from '../../constants';
import {
  connect
} from 'react-redux';
import './CategoryMappings.scss';

class CategoryMappingsInner extends Component {
  static displayName = CategoryMappingsInner.name;

  constructor(props) {
    super(props);

    window.addEventListener("resize", this.handleGridSize);

    this.state = {
      modalOpen: false,
      tradeMeCategoryMappings: [],
      loading: true
    };

    this.columns = [
      {
        dataField: 'categoryPath',
        text: 'Store Category',
        formatter: (cell, row) => <div>{cell}</div>
      },
      {
        dataField: 'tradeMeCategoryPath',
        text: 'TradeMe Category',
        formatter: (cell, row) => <div>
          {row.mappingIgnored && <div title="Category mapping ignored."><Icon color="red" style={{ color: "orange" }} prefix="fa" name="minus-circle" /></div>}
          {row.mappingRequired && !row.mappingIgnored && <div title="Store category does not have a mapping."><Icon color="red" style={{ color: "red" }} prefix="fa" name="exclamation-triangle" /></div>}
          {!row.mappingRequired && !row.mappingIgnored && cell}
        </div>
      },
      {
        dataField: 'products',
        text: 'Products',
        formatter: (cell, row) => <div>{cell > 0 ? <Link to={`/marketplaces/trademe/products?filterByCat=${row.category}`}>{cell}</Link> : cell}</div>
      },
      {
        dataField: "manage",
        text: "",
        align: "right",
        formatter: (cell, row) => <React.Fragment>
          {
            row.mappingRequired && !row.mappingIgnored && !row.loading && <Button className="ignore-btn" onClick={() => this.ignoreCategoryMapping(row)} size="sm" color="secondary"><Icon prefix="fa" name="minus-circle" />&nbsp;&nbsp;Ignore</Button>
          }
          {
            (row.mappingRequired || row.mappingIgnored) && !row.loading && <Button onClick={() => this.linkCategoryMapping(row)} size="sm" color="secondary"><Icon prefix="fa" name="link" />&nbsp;&nbsp;Link</Button>
          }

          {
            !row.mappingRequired && !row.mappingIgnored && !row.loading && <Button onClick={() => this.unlinkCategoryMapping(row)} size="sm" color="secondary"><Icon prefix="fa" name="unlink" />&nbsp;&nbsp;Unlink</Button>
          }
          {
            row.loading && <div className="category-mapping-loader"><i className="fa fa-spinner fa-spin"></i></div>
          }

        </React.Fragment>
      }
    ]
  }

  async componentDidMount() {
    let tradeMeCategoryMappings = await tradeMeCtrl.getCategoryMappings(this.props.app);
    this.setState({ tradeMeCategoryMappings, loading: false }, () => { setTimeout(() => this.handleGridSize(), 0) });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleGridSize);
  }

  handleGridSize = () => {

    // number of pixels to offset vertically
    let DesktopVerticalOffset = 320;

    if (window.innerWidth < 768) {
      DesktopVerticalOffset -= 115; // footer
    }

    let alertDiv = document.getElementById('uncategorised-alert');

    if (alertDiv) {
      DesktopVerticalOffset += alertDiv.offsetHeight;
    }

    var myElements = document.querySelectorAll(".react-bootstrap-table");
    for (var i = 0; i < myElements.length; i++) {

      let height = window.innerHeight - DesktopVerticalOffset;
      myElements[i].style.height = `${height}px`;
    }
  }

  onTradeMeCategorySelected = async (tradeMeCategoryId) => {

    let categoryMappingVm = this.tempCategoryMappingVm;

    try {
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: true });

      categoryMappingVm.tradeMeCategoryId = tradeMeCategoryId;
      let result = await tradeMeCtrl.upsertCategoryMapping(this.props.app, categoryMappingVm);

      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false, tradeMeCategoryPath: result.tradeMeCategoryPath, mappingRequired: false, mappingIgnored: false });
    }
    catch (e) {
      logger.logError("onTradeMeCategorySelected error", e);
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false });
    }
    finally {
      this.tempCategoryMappingVm = null;
    }
  }

  unlinkCategoryMapping = async (categoryMappingVm) => {

    try {
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: true });
      await tradeMeCtrl.deleteCategoryMapping(this.props.app, categoryMappingVm);
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false, mappingRequired: true, mappingIgnored: false });
    }
    catch (e) {
      logger.logError("unlinkCategoryMapping error", e);
      this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false });
    }
  }

  toggleCategoryMappingLoader = (categoryId, updatedObj) => {
    const tradeMeCategoryMappings = this.state.tradeMeCategoryMappings.map((d) => {
      if (d.categoryId === categoryId) {
        return {
          ...d,
          ...updatedObj
        };
      }
      return d;
    });
    this.setState(curr => ({ ...curr, tradeMeCategoryMappings }));
  }

  linkCategoryMapping = (categoryMappingVm) => {
    this.tempCategoryMappingVm = categoryMappingVm;
    this.tradeMeCategoriesModal.openModal(categoryMappingVm);
  }

  ignoreCategoryMapping = async (categoryMappingVm) => {

    this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: true });

    await tradeMeCtrl.upsertCategoryMapping(this.props.app, categoryMappingVm);

    this.toggleCategoryMappingLoader(categoryMappingVm.categoryId, { loading: false, mappingIgnored: true, tradeMeCategoryPath: null, mappingRequired: false });

  }

  render() {

    if (this.state.loading) {
      return <InnerComponentLoader title="TradeMe Category Mappings" />
    }

    let uncategorisedCount = this.props.user.info.integrationTypeId === integrationType.Shopify && this.state.tradeMeCategoryMappings.find(ecm => ecm.category === "Uncategorised")?.products;

    return (
      <div className="app-settings-content">
        <div id="uncategorised-alert">
          {
            this.props.user.info.integrationTypeId === integrationType.Shopify && uncategorisedCount && uncategorisedCount > 0 ?
              <UncategorisedAlert uncategorisedCount={uncategorisedCount} />
              :
              null
          }
        </div>

        <Card className="trademe-category-mappings">
          <Card.Header>

            <h4>TradeMe Category Mappings</h4>
          </Card.Header>
          <div className="card-inner">
            <BootstrapTable
              bordered={false}
              classes="card-table table-vcenter text-nowrap trademe-category-mappings-table"
              bootstrap4
              keyField="categoryId"
              noDataIndication={() => {

                if (this.state.loading) {
                  return <div className="table-loader-container"><Loader /></div>;
                }
                else {
                  return <div className="table-loader-container">No categories to show.</div>;
                }
              }
              }
              data={this.state.tradeMeCategoryMappings}
              columns={this.columns} />
          </div>

          {
            this.props.installWizard &&
            <div className="next-btn">
              <Button onClick={() => this.props.loadNextStep()} type="submit" color="primary"><Icon prefix="fa" name="arrow-circle-right" />&nbsp;&nbsp;Next</Button>
            </div>
          }

        </Card>
        <TradeMeCategoriesModal app={this.props.app} onTradeMeCategorySelected={this.onTradeMeCategorySelected} ref={(ref) => this.tradeMeCategoriesModal = ref} />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
}

let wrapper = connect(mapStateToProps)(CategoryMappingsInner);
export { wrapper as CategoryMappingsInner };