import React from 'react';
import moment from 'moment';
import { Card, Form } from "tabler-react";

const ProductMetaData = ({ product }) => {

    const renderLocalTime = (date) => {
        var local = moment.utc(date).local().format("dddd DD MMM YYYY HH:mm:ss");

        return <div>{local}</div>
    }

    return (<Card>
        <Card.Header>
            <h4>Metadata</h4>
        </Card.Header>
        <Card.Body>
            <Form.Group label="Created On">
                <div>{renderLocalTime(product.dateCreated)}</div>
            </Form.Group>
            <Form.Group label="Updated On">
                <div>{renderLocalTime(product.dateModified)}</div>
            </Form.Group>
        </Card.Body>
    </Card>);
}

export { ProductMetaData };