import * as apiCall from '../apiCall';

function getShippingPolicies(appId) {

    const options = {
        appId
    };

    let url = `/api/marketplace/get_shipping_policies`;
    
    return apiCall.get(url, options);
}

export { getShippingPolicies };