import * as apiCall from '../apiCall';

function getEbaySteps(appId) {
    let url = `/api/install/ebay/get_steps`;

    const options = {
        appId
    };

    return apiCall.get(url, options);
}

export { getEbaySteps };