import React from 'react';
import { Route } from 'react-router-dom';
import { Error404Page } from 'tabler-react';

const AuthorisedRoute = ({path, exact, render, isAuthorised, component}) => {

    if (!isAuthorised) {
        return <Error404Page/>
    }

    return (<Route exact={exact} path={path} render={render} component={component} />);
}

export { AuthorisedRoute };