import React, { useState } from 'react';
import { Card, Loader } from "tabler-react";
import Lightbox from 'react-image-lightbox';

const LoadableImage = ({ src, alt, onClick }) => {
    const [loading, setLoading] = useState(true);
    
    return (<div className="product-image-container">
        {
            loading && <div style={{ justifyContent: "center", display: "flex", alignItems: "center", height: 120 }}><Loader /></div>
        }

        {
            <img className="main-image" src={src} alt={alt} onLoad={() => setLoading(false)} onClick={onClick} />
        }

    </div>);
}

const MainImages = ({ images }) => {


    const [imageProperties, setImageProperties] = useState({});

    const zoomIntoImage = (splitImages, pos) => {

        let imageProps = {
            isOpen: true,
            splitImages, 
            pos
        }

        setImageProperties(imageProps);
    }

    const renderMainImages = (images) => {
        if (!images) {
            return (<div className="main-images">
                <i className="fal fa-image image-placeholder" />
            </div>);
        }

        let splitImages = images.split(",");

        if (splitImages.length) {
            return <div className="main-images">
                {
                    splitImages.map((si, i) => (<LoadableImage onClick={() => zoomIntoImage(splitImages, i)} src={si} alt="Product Image" key={i} />))
                }
            </div>
        }
    }

    return (<Card>
        <Card.Header>
            <h4>Images</h4>
        </Card.Header>

        <Card.Body>
            {renderMainImages(images)}
        </Card.Body>

        {
            imageProperties.isOpen &&
            <Lightbox
                mainSrc={imageProperties.splitImages[imageProperties.pos]}
                nextSrc={imageProperties.splitImages[(imageProperties.pos + 1) % imageProperties.splitImages.length]}
                prevSrc={imageProperties.splitImages[(imageProperties.pos + imageProperties.splitImages.length - 1) % imageProperties.splitImages.length]}
                onCloseRequest={() => setImageProperties({})}
                onMovePrevRequest={() =>
                    setImageProperties((prevState) => {
                        let imageProps = {...prevState};

                        imageProps.pos = (imageProps.pos + imageProps.splitImages.length - 1) % imageProps.splitImages.length;

                        return imageProps;
                    })
                }
                onMoveNextRequest={() =>
                    setImageProperties((prevState) => {
                        let imageProps = {...prevState};

                        imageProps.pos = (imageProps.pos + 1) % imageProps.splitImages.length;

                        return imageProps;
                    })
                }
            />
        }

    </Card>);

}

export { MainImages };