import React, { Component } from 'react';
import {
  Page,
  Grid
} from "tabler-react";
import SiteWrapper from '../SiteWrapper.react';
import { SettingsMenu } from '../SettingsMenu';
import { CategoryMappingsInner } from './CategoryMappingsInner';
import './CategoryMappings.scss';

export class CategoryMappings extends Component {
  static displayName = CategoryMappings.name;

  render() {
    return (
      <SiteWrapper>
        <Page.Content>
          <Grid.Row cards>
            <div className="app-settings-wrapper">
              <SettingsMenu app={this.props.app} />
              <CategoryMappingsInner app={this.props.app} />
            </div>
          </Grid.Row>
        </Page.Content>
      </SiteWrapper>
    );
  }
}