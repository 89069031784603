import React, { useEffect, useState } from 'react';
import SiteWrapper from './SiteWrapper.react';
import { FormLoader } from './FormLoader';
import { Form, Grid, Card, Page } from "tabler-react";
import { itemSpecificsRuleConditionType, specialRuleType } from '../constants';
import { toast } from 'react-toastify';
import { CustomToast, CustomSelect, CustomCreatableMulti } from './';
import * as templateCtrl from '../services/template';
import { SettingsMenu } from './SettingsMenu';
import * as logger from '../services/log';
import { SaveToolbar } from './SaveToolbar';
import * as fileUtility from '../utilities/fileUtility';
import "./EditTemplateMappings.scss";

const PAGE_NAME = "Custom Rules";

let formSubmitted = false;

const CustomRules = (props) => {

    const [loading, setLoading] = useState(true);
    const [formSaving, setFormSaving] = useState(false);
    const [templateMappingRules, setTemplateMappingRules] = useState([]);
    const [errors, setErrors] = useState({});
    const [templateMappingsVm, setTemplateMappingsVm] = useState({});
    const [templateDefinitionId, setTemplateDefinitionId] = useState(null);
    const [specialTemplateRules, setSpecialTemplateRules] = useState([]);
    const [specialTemplateRuleErrors, setSpecialTemplateRuleErrors] = useState({});
    const [shippingProfileOptions, setShippingProfileOptions] = useState([]);

    const selectHeight = 43;

    const initialise = async () => {
        try {

            setLoading(true);
            let templateMappingsVm = await templateCtrl.getAppMappings(props.app);

            setTemplateDefinitionId(parseInt(templateMappingsVm.templateMappingDefinitionId));

            setTemplateMappingsVm(templateMappingsVm);

            setTemplateMappingRules(templateMappingsVm.templateMappingRules);

            setSpecialTemplateRules(templateMappingsVm.templateSpecialRules);

            setShippingProfileOptions(templateMappingsVm.shippingProfileOptions);

            setLoading(false);

        }
        catch (e) {
            logger.logError("CustomRules useEffect() error", e);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            await initialise();
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const templateRuleTypes = templateCtrl.getRuleTypes();

    const applyTemplateMappingOptions = [
        { label: "To all products", value: "TO_ALL_PRODUCTS" }
    ];

    function specialTemplateFieldChanged(index, fieldName, value) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {

            let specialTemplateRulesCopy = [...prevState];

            specialTemplateRulesCopy[index][fieldName] = value;

            return specialTemplateRulesCopy;

        });

        validateSpecialRules(specialTemplateRules);
    }

    const validateSpecialRules = (specialRulesCopy, submitAction) => {
        let errors = {};
        let isValid = true;

        if (!formSubmitted && !submitAction) {
            return;
        }

        for (let i = 0; i < specialRulesCopy.length; i++) {

            let r = specialRulesCopy[i];

            if (!errors[i]) {
                errors[i] = {
                    conditions: {}
                };
            }

            if (!r.selectedProductAttribute || r.selectedProductAttribute === "") {
                isValid = false;
                errors[i]["selectedProductAttribute"] = "Required";
            }

            // rule validation
            if (r.templateSpecialRuleClauses && r.templateSpecialRuleClauses.length > 0) {
                for (let j = 0; j < r.templateSpecialRuleClauses.length; j++) {
                    let c = r.templateSpecialRuleClauses[j];

                    if (!errors[i]["conditions"][j]) {
                        errors[i]["conditions"][j] = {};
                    }

                    if (!c.selectedProductAttribute || c.selectedProductAttribute === "") {
                        errors[i]["conditions"][j]["selectedProductAttribute"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["selectedProductAttribute"]);
                    }

                    if (!c.templateRuleTypeId || c.templateRuleTypeId === "") {
                        errors[i]["conditions"][j]["templateRuleTypeId"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["templateRuleTypeId"]);
                    }
                }
            }
        }

        setSpecialTemplateRuleErrors(errors);

        return isValid;
    }

    const validate = (templateMappingRulesCopy, submitAction) => {
        let errors = {};
        let isValid = true;

        if (!formSubmitted && !submitAction) {
            return;
        }

        for (let i = 0; i < templateMappingRulesCopy.length; i++) {

            let r = templateMappingRulesCopy[i];

            if (!errors[i]) {
                errors[i] = {
                    conditions: {}
                };
            }

            if (!r.templateHeaderColumnId || r.templateHeaderColumnId === "") {
                errors[i]["templateHeaderColumnId"] = "Required";
                isValid = false;
            }
            else if (errors[i]) {
                delete (errors[i]["templateHeaderColumnId"]);
            }

            if (r.selectedCustomValues === undefined || r.selectedCustomValues === null || r.selectedCustomValues.length === 0) {
                errors[i]["selectedCustomValues"] = "Required";
                isValid = false;
            }
            else if (errors[i]) {
                delete (errors[i]["selectedCustomValues"]);
            }

            // rule validation
            if (r.templateMappingRuleClauses && r.templateMappingRuleClauses.length > 0) {
                for (let j = 0; j < r.templateMappingRuleClauses.length; j++) {
                    let c = r.templateMappingRuleClauses[j];

                    if (!errors[i]["conditions"][j]) {
                        errors[i]["conditions"][j] = {};
                    }

                    if (!c.selectedProductAttribute || c.selectedProductAttribute === "") {
                        errors[i]["conditions"][j]["selectedProductAttribute"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["selectedProductAttribute"]);
                    }

                    if (!c.templateRuleTypeId || c.templateRuleTypeId === "") {
                        errors[i]["conditions"][j]["templateRuleTypeId"] = "Required";
                        isValid = false;
                    }
                    else {
                        delete (errors[i]["conditions"][j]["templateRuleTypeId"]);
                    }
                }
            }
        }

        setErrors(errors);

        return isValid;
    }

    function getSpecialTemplateRuleErrorMessage(fieldName, mappingIndex, conditionIndex = null) {
        if (conditionIndex === null) {
            return (formSubmitted && specialTemplateRuleErrors[mappingIndex] && specialTemplateRuleErrors[mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted && specialTemplateRuleErrors[mappingIndex] && specialTemplateRuleErrors[mappingIndex]["conditions"] && specialTemplateRuleErrors[mappingIndex]["conditions"][conditionIndex] && specialTemplateRuleErrors[mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    function getErrorMessage(fieldName, mappingIndex, conditionIndex = null) {

        if (conditionIndex === null) {
            return (formSubmitted && errors[mappingIndex] && errors[mappingIndex][fieldName]) || null;
        }

        var errorMessage = (formSubmitted && errors[mappingIndex] && errors[mappingIndex]["conditions"] && errors[mappingIndex]["conditions"][conditionIndex] && errors[mappingIndex]["conditions"][conditionIndex][fieldName]) || null;

        return errorMessage;
    }

    function addNewTemplateMappingRule() {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let templateMappingRulesCopy = [...prevState];

            templateMappingRulesCopy.push({ setValueTo: "CUSTOM_VALUE", apply: "TO_ALL_PRODUCTS" });

            return templateMappingRulesCopy;
        });
    }

    function removeTemplateMappingRule(idx) {

        if (formSaving) {
            return;
        }


        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            rulesCopy.splice(idx, 1);

            return rulesCopy;
        });
    }

    function templateMappingsFieldChanged(index, fieldName, value) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            if (fieldName === "apply" && value === "WHEN") {
                rulesCopy[index].templateMappingRuleClauses = [{}];
            }
            else if (fieldName === "apply" && value === "TO_ALL_PRODUCTS") {
                rulesCopy[index].templateMappingRuleClauses = [];
            }

            if (fieldName === "selectedProductAttribute") {
                rulesCopy[index].customValue = null;
            }
            else if (fieldName === "customValue") {
                rulesCopy[index].selectedProductAttribute = null;
            }

            rulesCopy[index][fieldName] = value;

            return rulesCopy;
        });

        validate(templateMappingRules);
    }

    function templateRuleTypeChange(mappingIndex, conditionIndex, fieldName, value) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            rulesCopy[mappingIndex].templateMappingRuleClauses[conditionIndex][fieldName] = value;

            return rulesCopy;
        });

        validate(templateMappingRules);
    }

    function specialTemplateRuleTypeChange(mappingIndex, conditionIndex, fieldName, value) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {

            let specialTemplateRulesCopy = [...prevState];
            specialTemplateRulesCopy[mappingIndex].templateSpecialRuleClauses[conditionIndex][fieldName] = value;

            return specialTemplateRulesCopy;

        });

        validateSpecialRules(specialTemplateRules);
    }

    function addCondition(mappingIndex) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            if (!rulesCopy[mappingIndex].templateMappingRuleClauses) {
                rulesCopy[mappingIndex].templateMappingRuleClauses = [{}];
            }
            else {
                rulesCopy[mappingIndex].templateMappingRuleClauses.push({});
            }


            return rulesCopy;
        });
    }

    function removeCondition(mappingIndex, conditionIndex) {

        if (formSaving) {
            return;
        }

        setTemplateMappingRules((prevState) => {
            let rulesCopy = [...prevState];

            rulesCopy[mappingIndex].templateMappingRuleClauses.splice(conditionIndex, 1);

            return rulesCopy;
        });
    }

    function addSpecialRuleCondition(mappingIndex) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialRulesCopy = [...prevState];

            if (!specialRulesCopy[mappingIndex].templateSpecialRuleClauses) {
                specialRulesCopy[mappingIndex].templateSpecialRuleClauses = [{}];
            }
            else {
                specialRulesCopy[mappingIndex].templateSpecialRuleClauses.push({});
            }

            return specialRulesCopy;
        });
    }

    function removeSpecialRuleCondition(mappingIndex, conditionIndex) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialRulesCopy = [...prevState];

            specialRulesCopy[mappingIndex].templateSpecialRuleClauses.splice(conditionIndex, 1);

            return specialRulesCopy;
        });
    }


    function addNewSpecialTemplateRule() {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialTemplateRulesCopy = [...prevState];
            specialTemplateRulesCopy.push({ templateSpecialRuleClauses: [] });
            return specialTemplateRulesCopy;
        });
    }

    function removeSpecialTemplateRule(idx) {
        if (formSaving) {
            return;
        }

        setSpecialTemplateRules((prevState) => {
            let specialTemplateRulesCopy = [...prevState];

            specialTemplateRulesCopy.splice(idx, 1);

            return specialTemplateRulesCopy;
        });
    }

    function arrayMove(oldArr, old_index, new_index, setter, idPrefix) {

        if (new_index < 0) {
            return;
        }

        if (new_index > oldArr.length - 1) {
            return;
        }

        let arr = [...oldArr];

        while (old_index < 0) {
            old_index += arr.length;
        }
        while (new_index < 0) {
            new_index += arr.length;
        }
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);

        setter(arr);

        let elem = document.getElementById(`${idPrefix}${new_index}`);
        elem.classList.add("rule-container-animate");

        setTimeout(() => {
            elem.classList.remove("rule-container-animate");
        }, 1000);
    };

    async function saveTemplateMappings() {

        try {

            setFormSaving(true);

            let templateMappingRulesCopy = (templateMappingRules && [...templateMappingRules]) || [];
            let templateSpecialRulesCopy = (specialTemplateRules && [...specialTemplateRules]) || [];

            formSubmitted = true;

            let isValid = validate(templateMappingRulesCopy, true) && validateSpecialRules(templateSpecialRulesCopy, true);

            if (!isValid) {
                setFormSaving(false);

                toast.error(<CustomToast message="There is one or more validation errors on the page. Please review form and try again." />, { position: toast.POSITION.TOP_RIGHT });
                return;
            }

            templateSpecialRulesCopy.forEach((sf, i) => sf.sortOrder = i + 1);

            // massage data before sending to API.
            for (let i = 0; i < templateMappingRulesCopy.length; i++) {
                let r = templateMappingRulesCopy[i];

                if (typeof r.templateHeaderColumnId === "string") {
                    r.templateHeaderColumnId = parseInt(r.templateHeaderColumnId);
                }

                r.sortOrder = i + 1;

                if (r.templateMappingRuleClauses && r.templateMappingRuleClauses.length > 0) {
                    for (let j = 0; j < r.templateMappingRuleClauses.length; j++) {
                        let c = r.templateMappingRuleClauses[j];

                        if (typeof c.templateRuleTypeId === "string") {
                            c.templateRuleTypeId = parseInt(c.templateRuleTypeId);
                        }
                    }
                }
                else if (!r.templateMappingRuleClauses) {
                    r.templateMappingRuleClauses = [];
                }
            }

            for (let i = 0; i < templateSpecialRulesCopy.length; i++) {
                let r = templateSpecialRulesCopy[i];

                if (typeof r.templateSpecialRuleTypeId === "string") {
                    r.templateSpecialRuleTypeId = parseInt(r.templateSpecialRuleTypeId);
                }

                r.sortOrder = i + 1;

                // Hardcode to Shipping Profile
                r.templateSpecialRuleTypeId = specialRuleType.shippingProfile;

                if (Array.isArray(r.templateSpecialRuleClauses) && r.templateSpecialRuleClauses.length > 0) {
                    for (let j = 0; j < r.templateSpecialRuleClauses.length; j++) {
                        let c = r.templateSpecialRuleClauses[j];

                        if (typeof c.templateRuleTypeId === "string") {
                            c.templateRuleTypeId = parseInt(c.templateRuleTypeId);
                        }
                    }
                }
                else if (!r.templateSpecialRuleClauses) {
                    r.templateSpecialRuleClauses = [];
                }
            }

            let model = {
                templateMappingDefinitionId: templateDefinitionId,
                templateMappingRules: templateMappingRulesCopy,
                templateSpecialRules: templateSpecialRulesCopy,
                appId: props.app
            };

            await templateCtrl.saveTemplateMappings(model);

            toast.success(<CustomToast message="Mappings saved." />, { position: toast.POSITION.TOP_RIGHT });

            setFormSaving(false);

        }
        catch (e) {
            logger.logError("CustomRules saveTemplateMappings() error", e);
            setFormSaving(false);
        }
    }

    const getValueFieldOptions = () => {

        return templateMappingsVm.valueFields.map(vf => ({
            label: vf.value,
            value: vf.value
        }));
    }

    const previewMappings = async () => {
        let response = await templateCtrl.previewAppMappings(props.app);
        fileUtility.downloadFile(response.fileData, "text/plain;base64,", response.fileName);
    }

    if (loading) {
        return (<FormLoader {...props} formTitle={PAGE_NAME} />)
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} />

                        <div className="app-settings-content">
                            <Card className="edit-template-mappings" loading={loading}>
                                <Card.Header>
                                    <h4>{PAGE_NAME}</h4>

                                </Card.Header>

                                <div className="card-inner">
                                    {
                                        templateMappingRules.length > 0 ? <div style={{ display: "inline-block" }}><div className="anchor" style={{ fontSize: 16, display: "flex", flexDirection: "row", alignItems: "center" }}><i style={{ color: "#467fcf" }} className="fa fa-file-excel" /><div onClick={() => previewMappings()} style={{ marginLeft: 6 }}>Preview</div></div></div> : null
                                    }

                                    <div className="template-subheader">
                                        <h3>Field Mappings</h3>
                                    </div>
                                    {
                                        templateMappingRules.length === 0 ?
                                            <div className="no-mappings">There are no mappings to show.</div> : null
                                    }

                                    {
                                        templateMappingRules.length > 0 &&
                                        templateMappingRules.map((templateMappingRule, i) => (
                                            <div className="rule-container" key={i} id={`mapping-${i}`} >
                                                <div className="mapping-header">
                                                    <h3>Mapping #{i + 1} <i title="Remove mapping" onClick={() => removeTemplateMappingRule(i)} className="fa fa-minus-circle remove-mapping-icon"></i></h3>
                                                    <div className="sort-icon-wrapper">
                                                        <i onClick={(e) => arrayMove(templateMappingRules, i, i - 1, (arr) => setTemplateMappingRules(arr), "mapping-")} title="Move up one position. Rules at the top will be evaluated first." className={`fal fa-chevron-up sort-up${i === 0 ? " hover-ban" : ""}`}></i>
                                                        <i onClick={(e) => arrayMove(templateMappingRules, i, i + 1, (arr) => setTemplateMappingRules(arr), "mapping-")} title="Move down one position. Rules at the bottom will be evaluated first." className={`fal fa-chevron-down sort-down${i === templateMappingRules.length - 1 ? " hover-ban" : ""}`}></i>
                                                    </div>
                                                </div>

                                                <div className="item-specific-rule">
                                                    <div className="specific-col">
                                                        <div className="set-specific">SET</div>

                                                        <CustomSelect
                                                            height={selectHeight}
                                                            value={templateMappingsVm.templateColumns.filter(t => !t.immutable && t.columnName.startsWith("[P].")).map(c => ({ label: c.columnName, value: c.templateHeaderColumnId })).find(option => /* eslint eqeqeq: 0 */ option.value == templateMappingRule.templateHeaderColumnId)}
                                                            isDisabled={formSaving}
                                                            error={getErrorMessage("templateHeaderColumnId", i)}
                                                            options={templateMappingsVm.templateColumns.filter(t => !t.immutable && t.columnName.startsWith("[P].")).map(c => ({ label: c.columnName, value: c.templateHeaderColumnId }))}
                                                            onChange={(option) => templateMappingsFieldChanged(i, "templateHeaderColumnId", (option && option.value) || "")} />
                                                    </div>

                                                    <div className="specific-col">
                                                        <div className="set-specific">TO</div>
                                                        <CustomCreatableMulti
                                                            hideSelectedOptions={false}
                                                            isDisabled={formSaving}
                                                            height={selectHeight}
                                                            error={getErrorMessage("selectedCustomValues", i)}
                                                            options={getValueFieldOptions()}
                                                            values={templateMappingRules[i].selectedCustomValues}
                                                            handleChange={(values) => { templateMappingsFieldChanged(i, "selectedCustomValues", values) }} />
                                                    </div>
                                                </div>

                                                <div className="specific-conditions">

                                                    {
                                                        (templateMappingsVm && templateMappingsVm.template && templateMappingsVm.template.includeParentProductsWithVariations) &&
                                                        <div style={{ width: 360 }} className="specific-col">
                                                            <div className="set-specific">APPLY</div>

                                                            <CustomSelect
                                                                height={selectHeight}
                                                                isClearable={false}
                                                                value={applyTemplateMappingOptions.find(option => /* eslint eqeqeq: 0 */ option.value == templateMappingRule.apply) || applyTemplateMappingOptions[0]}
                                                                isDisabled={formSaving}
                                                                options={applyTemplateMappingOptions}
                                                                onChange={(option) => templateMappingsFieldChanged(i, "apply", (option && option.value) || "")}
                                                            />

                                                        </div>
                                                    }

                                                    {
                                                        templateMappingRule.templateMappingRuleClauses &&
                                                        templateMappingRule.templateMappingRuleClauses.length > 0 &&
                                                        <div style={{ marginBottom: 10, marginTop: 20 }} className="set-specific">WHEN</div>
                                                    }

                                                    {
                                                        templateMappingRule.templateMappingRuleClauses &&
                                                        templateMappingRule.templateMappingRuleClauses.length > 0 &&
                                                        templateMappingRule.templateMappingRuleClauses.map((clause, j) => {

                                                            return (
                                                                <React.Fragment key={j}>
                                                                    {
                                                                        j >= 1 &&
                                                                        <div className="specifics-and-condition">
                                                                            <strong>AND</strong>
                                                                        </div>
                                                                    }

                                                                    <div className="item-specific-condition">
                                                                        <div className="specific-col">
                                                                            <div className="condition-header">ATTRIBUTE</div>

                                                                            <CustomSelect
                                                                                height={selectHeight}
                                                                                value={clause.selectedProductAttribute ? getValueFieldOptions().find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                                isDisabled={formSaving}
                                                                                error={getErrorMessage("selectedProductAttribute", i, j)}
                                                                                options={getValueFieldOptions()}
                                                                                onChange={(option) => {
                                                                                    let value = (option && option.value) || "";
                                                                                    templateRuleTypeChange(i, j, "selectedProductAttribute", value);
                                                                                }}
                                                                            />
                                                                        </div>

                                                                        <div className="specific-col">
                                                                            <div className="condition-header">CONDITION</div>

                                                                            <CustomSelect
                                                                                height={selectHeight}
                                                                                value={templateRuleTypes ? templateRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.templateRuleTypeId) : ""}
                                                                                isDisabled={formSaving}
                                                                                error={getErrorMessage("templateRuleTypeId", i, j)}
                                                                                options={templateRuleTypes}
                                                                                onChange={(option) => templateRuleTypeChange(i, j, "templateRuleTypeId", (option && option.value) || "")}
                                                                            />

                                                                        </div>


                                                                        {
                                                                            clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                            clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&
                                                                            <div className="condition-header">
                                                                                <div className="set-specific">VALUE</div>
                                                                                <Form.Input
                                                                                    disabled={formSaving}
                                                                                    value={clause.customValue || ""}
                                                                                    onChange={(e) => {
                                                                                        var value = e.target.value;
                                                                                        templateRuleTypeChange(i, j, "customValue", value);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }


                                                                        <div className="remove-condition-icon-container"><i title="Remove condition" onClick={() => removeCondition(i, j)} className="fa fa-minus-circle remove-condition-icon"></i></div>

                                                                    </div>
                                                                </React.Fragment>

                                                            );
                                                        })

                                                    }

                                                    {
                                                        <div onClick={() => { addCondition(i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                    }


                                                </div>
                                            </div>
                                        ))
                                    }

                                    <div onClick={() => addNewTemplateMappingRule()} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Mapping</div>

                                    {
                                        shippingProfileOptions.length > 0 ?
                                            <React.Fragment>
                                                <div className="template-subheader">
                                                    <h3>Shipping Rules</h3>
                                                </div>

                                                {
                                                    Array.isArray(specialTemplateRules) && specialTemplateRules.length === 0 &&
                                                    <div className="no-mappings">There are no Shipping rules to show.</div>
                                                }

                                                {
                                                    specialTemplateRules.length > 0 &&
                                                    specialTemplateRules.map((specialTemplateRule, i) => (
                                                        <div className="rule-container" key={i} id={`special-rule-${i}`} >
                                                            <div className="mapping-header">
                                                                <h4>Shipping Rule #{i + 1} <i title="Remove filter" onClick={() => removeSpecialTemplateRule(i)} className="fa fa-minus-circle remove-mapping-icon"></i></h4>

                                                                <div className="sort-icon-wrapper">
                                                                    {
                                                                        <React.Fragment>
                                                                            <i onClick={(e) => arrayMove(specialTemplateRules, i, i - 1, (arr) => setSpecialTemplateRules(arr), "special-rule-")} title="Move up one position. Rules at the top will be evaluated first." className={`fal fa-chevron-up sort-up${i === 0 ? " hover-ban" : ""}`}></i>
                                                                            <i onClick={(e) => arrayMove(specialTemplateRules, i, i + 1, (arr) => setSpecialTemplateRules(arr), "special-rule-")} title="Move down one position. Rules at the bottom will be evaluated first." className={`fal fa-chevron-down sort-down${i === templateMappingRules.length - 1 ? " hover-ban" : ""}`}></i>
                                                                        </React.Fragment>
                                                                    }

                                                                </div>
                                                            </div>


                                                            <div className="item-specific-rule">

                                                                <div className="specific-col">
                                                                    <div className="condition-header">APPLY PROFILE</div>

                                                                    <CustomSelect
                                                                        height={selectHeight}
                                                                        value={specialTemplateRule.selectedProductAttribute ? shippingProfileOptions.find(option => /* eslint eqeqeq: 0 */ option.value == specialTemplateRule.selectedProductAttribute) : ""}
                                                                        isDisabled={formSaving}
                                                                        error={getSpecialTemplateRuleErrorMessage("selectedProductAttribute", i)}
                                                                        options={shippingProfileOptions}
                                                                        onChange={(option) => {
                                                                            let value = (option && option.value) || "";
                                                                            specialTemplateFieldChanged(i, "selectedProductAttribute", value);
                                                                        }}
                                                                    />
                                                                </div>

                                                            </div>


                                                            <div className="specific-conditions">

                                                                {
                                                                    specialTemplateRule.templateSpecialRuleClauses &&
                                                                    specialTemplateRule.templateSpecialRuleClauses.length > 0 &&
                                                                    <div style={{ marginBottom: 10, marginTop: 20 }} className="set-specific">WHEN</div>
                                                                }


                                                                {
                                                                    specialTemplateRule.templateSpecialRuleClauses &&
                                                                    specialTemplateRule.templateSpecialRuleClauses.length > 0 &&
                                                                    specialTemplateRule.templateSpecialRuleClauses.map((clause, j) => {

                                                                        return (
                                                                            <React.Fragment key={j}>
                                                                                {
                                                                                    j >= 1 &&
                                                                                    <div className="specifics-and-condition">
                                                                                        <select onChange={(e) => {
                                                                                            specialTemplateRuleTypeChange(i, j, "logicalOperator", e.target.value);
                                                                                        }} value={clause.logicalOperator || "AND"} className="form-control specifics-select">
                                                                                            <option value="AND">AND</option>
                                                                                            <option value="OR">OR</option>
                                                                                        </select>
                                                                                    </div>
                                                                                }

                                                                                <div className="item-specific-condition">
                                                                                    <div className="specific-col">
                                                                                        <div className="condition-header">ATTRIBUTE</div>

                                                                                        <CustomSelect
                                                                                            height={selectHeight}
                                                                                            value={clause.selectedProductAttribute ? getValueFieldOptions().find(option => /* eslint eqeqeq: 0 */ option.value == clause.selectedProductAttribute) : ""}
                                                                                            isDisabled={formSaving}
                                                                                            error={getSpecialTemplateRuleErrorMessage("selectedProductAttribute", i, j)}
                                                                                            options={getValueFieldOptions()}
                                                                                            onChange={(option) => {
                                                                                                let value = (option && option.value) || "";
                                                                                                specialTemplateRuleTypeChange(i, j, "selectedProductAttribute", value);
                                                                                            }}
                                                                                        />
                                                                                    </div>

                                                                                    <div className="specific-col">
                                                                                        <div className="condition-header">CONDITION</div>

                                                                                        <CustomSelect
                                                                                            height={selectHeight}
                                                                                            value={templateRuleTypes ? templateRuleTypes.find(option => /* eslint eqeqeq: 0 */ option.value == clause.templateRuleTypeId) : ""}
                                                                                            isDisabled={formSaving}
                                                                                            error={getSpecialTemplateRuleErrorMessage("templateRuleTypeId", i, j)}
                                                                                            options={templateRuleTypes}
                                                                                            onChange={(option) => specialTemplateRuleTypeChange(i, j, "templateRuleTypeId", (option && option.value) || "")}
                                                                                        />

                                                                                    </div>

                                                                                    {
                                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.isEmpty &&
                                                                                        clause.templateRuleTypeId !== itemSpecificsRuleConditionType.notEmpty &&
                                                                                        <div className="condition-header">
                                                                                            <div className="set-specific">VALUE</div>
                                                                                            <Form.Input
                                                                                                disabled={formSaving}
                                                                                                value={clause.customValue || ""}
                                                                                                onChange={(e) => {
                                                                                                    var value = e.target.value;
                                                                                                    specialTemplateRuleTypeChange(i, j, "customValue", value);
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                    }


                                                                                    <div className="remove-condition-icon-container"><i title="Remove condition" onClick={() => removeSpecialRuleCondition(i, j)} className="fa fa-minus-circle remove-condition-icon"></i></div>

                                                                                </div>
                                                                            </React.Fragment>

                                                                        );
                                                                    })

                                                                }

                                                                {
                                                                    <div onClick={() => { addSpecialRuleCondition(i) }} className="anchor add-condition-btn"><i className="fa fa-plus-circle"></i> Add Condition</div>
                                                                }


                                                            </div>

                                                        </div>
                                                    ))
                                                }

                                                < div >
                                                    <div onClick={() => addNewSpecialTemplateRule()} className="anchor add-new-rule-button"><i className="fa fa-plus-circle"></i> Add Shipping Rule</div>
                                                </div>
                                            </React.Fragment>
                                            : null
                                    }



                                </div>

                            </Card>
                        </div>
                    </div>
                </Grid.Row>
                <SaveToolbar show undoAction={() => initialise()} saveAction={() => saveTemplateMappings()} formSaving={formSaving} />
            </Page.Content>
        </SiteWrapper>)
}

export { CustomRules };