import * as apiCall from '../apiCall';

function deleteTemplate(appId, templateMappingDefinitionId) {

    const options = {
        appId
    };

    return apiCall.post(`/api/template/delete_template/${templateMappingDefinitionId}`, null, options);
}

export { deleteTemplate };