import React, { useEffect, useState, useRef } from 'react';
import {
    SettingsMenu
} from './SettingsMenu';
import {
    FormLoader
} from './FormLoader';
import {
    AgGridReact
} from "ag-grid-react";
import {
    CustomToast
} from '../components';
import {
    CustomLoadingOverlay
} from './CustomLoadingOverlay';
import {
    AddTemplateModal
} from './AddTemplateModal';
import {
    useSelector
} from "react-redux";
import {
    customConfirmAlert
} from './CustomConfirmAlert';
import {
    toast
} from 'react-toastify';
import {
    Grid,
    Card,
    Page,
    Button
} from 'tabler-react';
import * as logger from '../services/log';
import history from '../history';
import * as templateCtrl from '../services/template';
import SiteWrapper from './SiteWrapper.react';
import "./Templates.scss";

const PAGE_NAME = "Templates";

let gridApi;

const Templates = (props) => {

    const [loading, setLoading] = useState(true);
    const [templates, setTemplates] = useState([]);
    const [showAddTemplateModalSettings, setShowAddTemplateModalSettings] = useState({ show: false, templateType: null });

    const apps = useSelector(state => state.user.info.apps);
    const shoppingFeedApp = useRef(apps.find(a => a.slug === props.match?.params?.shoppingFeedSlug));

    const resizeWindow = (e) => {
        gridApi && gridApi.sizeColumnsToFit();
    }

    const refreshTemplates = async () => {
        try {
            setLoading(true);
            let appId = props.app || (shoppingFeedApp.current && shoppingFeedApp.current.id);
            let vm = await templateCtrl.getTemplates(appId);
            setTemplates(vm.templates);

            setLoading(false);
        }
        catch (e) {
            logger.logError(`Templates refreshTemplates() error`, e);
        }
    }

    useEffect(() => {

        const fetchData = async () => {

            try {
                let appId = props.app || (shoppingFeedApp.current && shoppingFeedApp.current.id);
                let vm = await templateCtrl.getTemplates(appId);
                setTemplates(vm.templates);

                setLoading(false);
            }
            catch (e) {
                logger.logError(`useEffect() error`, e);
            }
        }

        window.addEventListener("resize", resizeWindow);

        fetchData();

        return () => {
            window.removeEventListener("resize", resizeWindow);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.app]);

    const copyToClipboard = (text) => {
        if (text) {
            const el = document.createElement('textarea');
            el.value = text;
            el.setAttribute('readonly', '');
            el.style.position = 'absolute';
            el.style.left = '-9999px';
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
        }
    }

    const showCopiedToClipboard = (e) => {

        let parentElement = e.target.parentElement;

        if (parentElement) {
            let copiedClipboardElement = parentElement.getElementsByClassName("copied-clipboard-outer");

            if (copiedClipboardElement) {
                copiedClipboardElement[0].style.display = "block";

                setTimeout(() => {
                    copiedClipboardElement[0].style.display = "none";
                }, 2000);
            }

        }
    }

    const columns = [
        {
            field: 'name',
            headerName: 'Name',
            tooltipField: "name",
            width: 100,
            resizable: true
        },
        {
            field: "feedUrl",
            headerName: "URL",
            tooltipField: "feedUrl",
            resizable: true,
            width: 300,
            cellRenderer: (params) => {
                let outerDiv = document.createElement("div");

                var a = document.createElement('a');
                a.href = params.value;

                var linkText = document.createTextNode(params.value);
                a.appendChild(linkText);

                let copyToClipboardIcon = document.createElement("i");

                copyToClipboardIcon.title = "Copy to Clipboard";
                copyToClipboardIcon.classList.add("fa");
                copyToClipboardIcon.classList.add("fa-clipboard");
                copyToClipboardIcon.classList.add("clipboard-icon");
                copyToClipboardIcon.onclick = (e) => { copyToClipboard(params.value); showCopiedToClipboard(e) };

                let copiedToClipboardOuter = document.createElement("div");
                copiedToClipboardOuter.classList.add("copied-clipboard-outer");

                let copiedToClipboardText = document.createElement("div");
                copiedToClipboardText.innerHTML = "Copied";

                let copiedToClipboardIcon = document.createElement("i");
                copiedToClipboardIcon.classList.add("fa");
                copiedToClipboardIcon.classList.add("fa-chevron-up");
                copiedToClipboardIcon.classList.add("copied-clipboard-icon");

                copiedToClipboardOuter.appendChild(copiedToClipboardText);

                outerDiv.appendChild(copiedToClipboardOuter);
                outerDiv.appendChild(copyToClipboardIcon);
                outerDiv.appendChild(a);

                return outerDiv;
            }
        },
        {
            field: "manage",
            headerName: "",
            sortable: false,
            headerClass: "manage-header",
            width: 50,
            cellClass: "manage-cell",
            cellRenderer: (params) => {
                let outerDiv = document.createElement("div");
                outerDiv.classList.add("manage-btns");

                let editMappingsBtn = document.createElement("i");
                editMappingsBtn.title = "Edit";
                editMappingsBtn.classList.add("fa");
                editMappingsBtn.classList.add("fa-edit");
                editMappingsBtn.classList.add("template-icon");
                editMappingsBtn.onclick = () => {
                    let data = params.data;

                    if (shoppingFeedApp.current) {
                        history.push(`/shopping-feeds/${props.match.params.shoppingFeedSlug}/edit-template-mappings/${data.templateMappingDefinitionId}`);
                    }
                    else {
                        history.push(`${props.path}/${data.templateMappingDefinitionId}`);
                    }
                }

                outerDiv.appendChild(editMappingsBtn);

                let deleteBtn = document.createElement("i");
                deleteBtn.title = "Delete";
                deleteBtn.classList.add("fa");
                deleteBtn.classList.add("fa-minus-circle");
                deleteBtn.classList.add("template-icon");
                deleteBtn.classList.add("template-icon-danger");
                deleteBtn.classList.add("delete-template-btn");

                deleteBtn.onclick = () => {


                    let data = params.data;

                    customConfirmAlert(async () => {

                        try {
                            setLoading(true);

                            let appId = props.app || (shoppingFeedApp.current && shoppingFeedApp.current.id);
                            await templateCtrl.deleteTemplate(appId, data.templateMappingDefinitionId);

                            let vm = await templateCtrl.getTemplates(appId);
                            setTemplates(vm.templates);

                            setLoading(false);

                            toast.success(<CustomToast message={`Template '${data.name}' has been deleted.`} />, { position: toast.POSITION.TOP_RIGHT });
                        }
                        catch (e) {
                            setLoading(false);
                            logger.logError(`Template onDelete() error`, e);
                        }

                    }, `Are you sure you want to delete the template '${data.name}'`);

                }

                outerDiv.appendChild(deleteBtn);

                return outerDiv;
            }
        }
    ];

    const defaultColDef = {
        resizable: false,
        sortable: true,
        filter: false
    };

    const frameworkComponents = {
        customLoadingOverlay: CustomLoadingOverlay
    }

    const onGridReady = (params) => {
        gridApi = params.api;

        gridApi && gridApi.sizeColumnsToFit();
    };

    const showAddTemplateModal = () => {
        setShowAddTemplateModalSettings({ show: true });
    }

    if (loading) {
        return (<FormLoader {...props} shoppingFeedApp={shoppingFeedApp.current} formTitle={PAGE_NAME} />)
    }

    return (
        <SiteWrapper>
            <Page.Content>
                <Grid.Row cards>
                    <div className="app-settings-wrapper">
                        <SettingsMenu app={props.app} shoppingFeedApp={shoppingFeedApp.current} />

                        <div className="app-settings-content">
                            <Card className="templates-wrapper">
                                <Card.Header>
                                    <h4>{PAGE_NAME}</h4>
                                    <div className="add-template-btn">
                                        <Button color="primary" onClick={showAddTemplateModal}>Add Template</Button>
                                        <div className="clear-fix"></div>
                                    </div>
                                </Card.Header>
                                <div className="card-inner">

                                    {
                                        !loading && templates.length === 0 &&
                                        <div>
                                            There are no templates to show.
                                        </div>
                                    }
                                    {
                                        !loading && templates.length > 0 &&
                                        <div
                                            style={{
                                                minHeight: 300,
                                                height: 500
                                            }}
                                            className="ag-theme-alpine">
                                            <AgGridReact
                                                gridOptions={{
                                                    localeText: { noRowsToShow: 'No templates to show.' }
                                                }
                                                }
                                                columnDefs={columns}
                                                animateRows={false}
                                                enableBrowserTooltips
                                                suppressContextMenu
                                                noRow
                                                enableCellTextSelection
                                                defaultColDef={defaultColDef}
                                                suppressRowClickSelection={true}
                                                onGridReady={onGridReady}
                                                loadingOverlayComponent="customLoadingOverlay"
                                                frameworkComponents={frameworkComponents}
                                                rowData={templates}
                                                multiSortKey="ctrl"
                                            />
                                        </div>
                                    }
                                </div>
                            </Card>
                        </div>
                    </div>
                </Grid.Row>

                {
                    showAddTemplateModalSettings.show ?
                        <AddTemplateModal gridApi={gridApi} app={props.app} shoppingFeedApp={shoppingFeedApp.current} refreshTemplates={() => refreshTemplates()} hideModal={() => setShowAddTemplateModalSettings({ show: false })} />
                        : null
                }

            </Page.Content>
        </SiteWrapper>

    );
}

export { Templates };