import * as apiCall from '../apiCall';

function previewAppMappings(appId) {

    const options = {
        appId
    };

    let url = `/api/template/preview_app_mappings`;
    return apiCall.get(url, options);
}

export { previewAppMappings };