import * as apiCall from '../apiCall';

function saveDashboardSettings(model) {

    let options = {
        handleErrorManually: true
    };

    return apiCall.post(`/api/dashboard/main/save_dashboard_settings`, model, options);
}

export { saveDashboardSettings };