import React, { useEffect, useState } from 'react';
import SiteWrapper from './SiteWrapper.react';
import * as appCtrl from '../services/apps';
import * as accountCtrl from '../services/account';
import history from '../history';
import {
    RoundedLogo
} from './';
import {
    useSelector
} from "react-redux";
import Modal from 'react-modal';
import {
    useDispatch
} from 'react-redux';
import {
    saveUserInfo,
    saveFirstAppModalShown
} from '../actions/user';
import {
    toast
} from 'react-toastify';
import {
    CustomToast
} from '../components';
import {
    customConfirmAlert
} from './CustomConfirmAlert';
import * as logger from '../services/log';
import {
    appCategory
} from '../constants';
import {
    Page,
    Card,
    Grid,
    Icon,
    Button,
    Loader
} from "tabler-react";
import * as shoppingFeedCtrl from '../services/shoppingfeed';
import './CustomModal.css';
import './Apps.scss';

const Apps = () => {

    const [loading, setLoading] = useState(true);
    const [apps, setApps] = useState([]);

    const user = useSelector(state => state.user);
    const [showGetStartedModal, setShowGetStartedModal] = useState(false);
    const [shoppingFeedAppModalSettings, setShoppingFeedAppModalSettings] = useState({ show: false });
    const [shoppingFeedInstallLoading, setShoppingFeedInstallLoading] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchApps = async () => {
            let apps = await appCtrl.getApps();
            setApps(apps);
            setLoading(false);
        }

        setShowGetStartedModal(user.info && user.info.apps && user.info.apps.length === 0 && !user.info.firstAppAdded && !user.firstAppModalShown);
        dispatch(saveFirstAppModalShown());

        fetchApps();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function handleSettingsClick(app) {
        if (!app.isDisabled) {

            if (app.appCategoryId === appCategory.marketplace) {
                history.push(`/marketplaces/${app.slug}/${app.initialRoute ?? "authorisation"}`);
            }
            else if (app.appCategoryId === appCategory.shoppingFeed) {
                history.push(`/shopping-feeds/${app.slug}/${app.initialRoute ?? "templates"}`);
            }
            else {
                throw new Error(`appCategory ${app.appCategoryId} not recognised.`);
            }
        }
    }

    function handleInstallClick(app) {

        let canInstallResult = canInstall(app);

        if (!canInstallResult.canInstall) {
            customConfirmAlert(() => { }, <React.Fragment>{canInstallResult.message}</React.Fragment>, { header: "Unable to install app", showCancelButton: false, confirmButtonName: "Ok" });
            return;
        }

        if (app.appCategoryId === appCategory.shoppingFeed) {
            setShoppingFeedAppModalSettings({ show: true, app });
        }
        else {
            history.push(`/install/${app.slug}`);
        }
    }

    function hideShowGetStartedModal() {
        setShowGetStartedModal(false);
    }

    function handleDisableAppClick(app) {
        customConfirmAlert(() => confirmDisableAppClick(app), <React.Fragment>Please confirm you would like to disable <strong>{app && app.altName}</strong>.</React.Fragment>);
    }

    async function handleEnableAppClick(app) {

        let canInstallResult = canInstall(app);

        if (!canInstallResult.canInstall) {
            customConfirmAlert(() => { }, <React.Fragment>{canInstallResult.message}</React.Fragment>, { header: "Unable to install app", showCancelButton: false, confirmButtonName: "Ok" });
            return;
        }

        customConfirmAlert(() => confirmEnableAppClick(app), <React.Fragment>Please confirm you would like to enable <strong>{app && app.altName}</strong>.</React.Fragment>);
    }

    function canInstall(app) {

        let installedMarketplaceApps = apps.filter(a => a.appCategoryId === appCategory.marketplace && a.installed && !a.isDisabled).length;
        let installedShoppingFeedApps = apps.filter(a => a.appCategoryId === appCategory.shoppingFeed && a.installed && !a.isDisabled).length;

        if (app.appCategoryId === appCategory.marketplace && user.info.maxMarketplaces !== undefined && user.info.maxMarketplaces !== null && installedMarketplaceApps >= user.info.maxMarketplaces) {
            return {
                canInstall: false,
                message: `Maximum marketplaces of ${user.info.maxMarketplaces} for ${user.info.planName} plan has been reached. Please upgrade your plan to enable more marketplaces.`
            }
        }

        if (app.appCategoryId === appCategory.shoppingFeed && user.info.maxShoppingFeeds !== undefined && user.info.maxShoppingFeeds !== null && installedShoppingFeedApps >= user.info.maxShoppingFeeds) {
            return {
                canInstall: false,
                message: `Maximum shopping feeds of ${user.info.maxShoppingFeeds} for ${user.info.planName} plan has been reached. Please upgrade your plan to enable more shopping feeds.`
            };
        }

        return {
            canInstall: true,
            message: null
        };
    }

    async function confirmDisableAppClick(app) {

        try {
            await appCtrl.disableApp(app.id);
            let userInfo = await accountCtrl.getUserData();
            dispatch(saveUserInfo(userInfo));

            let apps = await appCtrl.getApps();
            setApps(apps);

            toast.success(<CustomToast message={`App '${app.altName}' has been disabled.`} />, { position: toast.POSITION.TOP_RIGHT });
        }
        catch (e) {
            logger.logError("Store Apps confirmDisableAppClick() error", e);
        }

    }

    async function confirmEnableAppClick(app) {

        try {
            await appCtrl.enableApp(app.id);
            let userInfo = await accountCtrl.getUserData();
            dispatch(saveUserInfo(userInfo));

            let apps = await appCtrl.getApps();
            setApps(apps);

            if (app.appCategoryId === appCategory.shoppingFeed) {
                history.push(`/shopping-feeds/${app.slug}/templates`);
            }
            else if (app.appCategoryId === appCategory.marketplace) {
                history.push(`/marketplaces/${app.slug}/products`);
            }

            toast.success(<CustomToast message={`App '${app.altName}' successfully enabled.`} />, { position: toast.POSITION.TOP_RIGHT });
        }
        catch (e) {
            logger.logError("Store Apps confirmEnableAppClick() error", e);
        }

    }

    const onInstallShoppingFeed = async (app) => {

        try {

            setShoppingFeedInstallLoading(true);
            await shoppingFeedCtrl.install(app.id, null);

            let apps = await appCtrl.getApps();
            setApps(apps);

            let userInfo = await accountCtrl.getUserData();
            dispatch(saveUserInfo(userInfo));

            setShoppingFeedInstallLoading(false);

            toast.success(<CustomToast message={`App '${app.altName}' successfully installed.`} />, { position: toast.POSITION.TOP_RIGHT });
            history.push(`/shopping-feeds/${app.slug}/templates`);

        }
        catch (e) {
            logger.logError("Could not install shopping feed.", e);
            setShoppingFeedInstallLoading(false);
        }
    }

    const RenderAppList = (apps) => {

        
        return Array.isArray(apps) ? apps.map((app, i) => {
            return (
                <Grid.Col key={i} xs={12} sm={6} md={6} lg={4} xl={3}>
                    <Card className="app-item">

                        {
                            app.installed && !app.isDisabled &&
                            <React.Fragment>
                                <div className="triangle-top-right"></div>
                                <i className="installed-tick fa fa-check"></i>
                            </React.Fragment>
                        }

                        {
                            app.installed && app.isDisabled &&
                            <React.Fragment>
                                <div className="triangle-top-right false-triangle"></div>
                                <i className="installed-tick fa fa-times"></i>
                            </React.Fragment>
                        }


                        <div className="app-item-header">

                            <RoundedLogo height={85} width={85} logoUrl={app.logoUrl} />
                            <div className="app-item-header-title">
                                
                                <span className="title">{app.altName}</span>
                                {
                                    app.isNew ? <span className="new-app">New!</span> : null
                                }

                                <div className="app-item-category">
                                    <small>{app.appCategory}</small>
                                </div>
                            </div>
                        </div>

                        <div className="app-item-body">

                            <div className="app-item-body-inner">
                                {app.description}
                            </div>

                            {
                                app.installed && !app.isDisabled &&
                                <div className="disable-app">
                                    <span onClick={() => handleDisableAppClick(app)}>Disable</span>
                                </div>
                            }
                            {
                                app.isDisabled &&
                                <div className="disable-app">
                                    <span onClick={() => handleEnableAppClick(app)}>Enable</span>
                                </div>
                            }

                            {
                                app.installed && !app.isDisabled &&
                                <div onClick={() => handleSettingsClick(app)} className={`configure-button`}>
                                    <i className="fa fa-cog" />&nbsp;&nbsp;Settings
                                </div>
                            }

                            {
                                !app.installed &&
                                <div onClick={() => handleInstallClick(app)} className="install-button">
                                    <i className="fa fa-download" />&nbsp;&nbsp;Install
                                </div>
                            }
                        </div>
                    </Card>
                </Grid.Col>
            );
        }) : null
    }

    if (loading) {
        return (
            <SiteWrapper>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: 50 }}>
                    <Loader />
                </div>

            </SiteWrapper>
        );
    }

    let marketplaceApps = apps.filter(a => a.appCategoryId === appCategory.marketplace);
    let shoppingFeedApps = apps.filter(a => a.appCategoryId === appCategory.shoppingFeed);

    let installedMarketplaceApps = marketplaceApps.filter(a => a.installed && !a.isDisabled).length;
    let installedShoppingFeedApps = shoppingFeedApps.filter(a => a.installed && !a.isDisabled).length;

    return (
        <SiteWrapper>
            <Page.Content className="apps">

                <h3 className="category-header" style={{ marginBottom: user.info.maxMarketplaces !== undefined && user.info.maxMarketplaces !== null ? 5 : 20 }}>Marketplaces</h3>
                {
                    user.info.maxMarketplaces !== undefined && user.info.maxMarketplaces !== null ?
                        <div className="category-subheader">{installedMarketplaceApps} / {user.info.maxMarketplaces} installed</div> : null
                }

                <Grid.Row cards deck>
                    {RenderAppList(marketplaceApps)}
                </Grid.Row>

                <hr />

                <h3 className="category-header" style={{ marginBottom: user.info.maxShoppingFeeds !== undefined && user.info.maxShoppingFeeds !== null ? 5 : 20 }}>Shopping Feeds</h3>
                {
                    user.info.maxShoppingFeeds !== undefined && user.info.maxShoppingFeeds !== null ?
                        <div className="category-subheader">{installedShoppingFeedApps} / {user.info.maxShoppingFeeds} installed</div> : null
                }
                <Grid.Row cards deck>
                    {RenderAppList(shoppingFeedApps)}
                </Grid.Row>

                {
                    showGetStartedModal ?
                        <Modal
                            appElement={document.body}
                            isOpen
                            onRequestClose={() => hideShowGetStartedModal()}
                            shouldCloseOnOverlayClick
                            style={{ width: 360, height: 220 }}
                            className="get-started-modal"
                            overlayClassName="custom-modal-overlay">

                            <Icon onClick={() => hideShowGetStartedModal()} className="close-icon" prefix="fa" name="times" />

                            <h2 style={{ textAlign: "center", marginTop: 10 }}>Getting Started</h2>
                            <hr className="divider"></hr>

                            <div className="get-started-content">

                                Welcome to PolyCommerce! Please add your first app to get started.
                                <div className="get-started-footer">
                                    <div>
                                        <Button onClick={() => hideShowGetStartedModal()} type="button" color="primary">Ok</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                        : null
                }

                {
                    shoppingFeedAppModalSettings.show ?
                        <Modal
                            appElement={document.body}
                            isOpen
                            onRequestClose={() => setShoppingFeedAppModalSettings({ show: false })}
                            shouldCloseOnOverlayClick
                            style={{ width: 360, height: 220 }}
                            className="shopping-feed-modal"
                            overlayClassName="custom-modal-overlay">


                            <Icon onClick={() => setShoppingFeedAppModalSettings({ show: false })} className="close-icon" prefix="fa" name="times" />
                            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", alignContent: "center" }}>
                                <h2 style={{ marginTop: 10, marginBottom: 0 }}>Install App</h2>
                                <h5 style={{ textAlign: "center", marginTop: 5 }}>{shoppingFeedAppModalSettings.app.altName}</h5>
                            </div>


                            <hr className="divider"></hr>

                            <div className="shopping-feed-modal-content">

                                <div>
                                    <img src={shoppingFeedAppModalSettings.app.logoUrl} alt="Logo" />
                                </div>
                                <div style={{ marginTop: 10 }}>
                                    {shoppingFeedAppModalSettings.app.description}
                                </div>

                                <div className="shopping-feed-modal-footer">
                                    <div className="shopping-feed-modal-footer-buttons">
                                        <div style={{ marginRight: 10 }}>
                                            <Button onClick={() => setShoppingFeedAppModalSettings({ show: false })} type="button" color="danger">Cancel</Button>
                                        </div>
                                        <div>
                                            <Button loading={shoppingFeedInstallLoading} onClick={async () => onInstallShoppingFeed(shoppingFeedAppModalSettings.app)} type="button" color="primary"><i className="fa fa-download" />&nbsp;&nbsp;Install</Button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </Modal>
                        : null
                }

            </Page.Content>

        </SiteWrapper>
    );

}

export { Apps };