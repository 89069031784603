import * as c3 from 'c3';
import * as d3 from 'd3';
import moment from 'moment-timezone';

const bindChart = (orderData, id) => {
    c3.generate({
        bindto: id, // id of chart wrapper
        data: {
            x: 'x',
            columns: [
                ['x', ...orderData.orderHistory.map(x => x.date)],
                ['Sales', ...orderData.orderHistory.map(x => x.total)],
                ["UnitsSold", ...orderData.orderHistory.map(x => x.salesCount)]
            ],
            type: "bar",
            axes: {
                "Sales": "y",
                "UnitsSold": "y2"
            },
            names: {
                "Sales": `Sales (${orderData.currencyCode})`,
                "UnitsSold": "Volume"
            },
            types: {
                "UnitsSold": "line"
            }
        },
        legend: {
            show: true
        },
        tooltip: {
            format: {
                title: (d, i) => {
                    let md = moment(orderData.orderHistory[i].date);
                    let mdf = md.format("dddd DD MMM YYYY");
                    return mdf;
                }
            }
        },
        axis: {
            x: {
                type: "category",
                tick: {
                    culling: {
                        max: 5
                    },
                    width: 50,
                    format: (i) => {

                        let md = moment(orderData.orderHistory[i].date);
                        let mdf = md.format("DD MMM");

                        return mdf
                    }
                }
            },
            y: {
                show: true,
                tick: {
                    format: function (d) {
                        return "$" + d3.format(".2f")(d);
                    }
                }
            },
            y2: {
                min: 0,
                show: orderData.last90DaysTotalSales > 0,
                padding: { bottom: 4 },
                tick: {
                    format: function (d) {
                        return (parseInt(d) === d) ? d : null;
                    }
                }
            }
        }

    });
}

export { bindChart };