import * as apiCall from '../apiCall';

function saveShippingPolicy(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/marketplace/save_shipping_policy`, model, options);
}

export { saveShippingPolicy };