import React, { useState } from 'react';
import Modal from 'react-modal';
import {
    Icon,
    Form,
    Button
} from 'tabler-react';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import {
    CustomToast,
    CustomSelect
} from '../../components';
import * as marketplaceCtrl from '../../services/marketplace';
import '../CustomModal.css';
import "./SetListingTemplateModal.scss";

const SetListingTemplateModal = (props) => {

    const [formSaving, setFormSaving] = useState(false);

    function closeModal() {
        props.hideModal();
    }

    let modalSettings = props.setListingTemplateModalSettings;

    const listingTemplateOptions = [{ label: "➖ None", value: null }].concat(props.listingTemplates.map(lt => ({
        label: lt.name,
        value: lt.templateMappingDefinitionId
    })));

    const validate = values => {
        const errors = {};

        return errors;
    }

    const applyListingTemplate = async () => {
        try {
            setFormSaving(true);

            let model = {
                productIds: props.products.map(p => p.id),
                templateMappingDefinitionId: parseInt(formik.values.templateMappingDefinitionId),
                templateId: modalSettings.templateId
            };

            await marketplaceCtrl.assignListingTemplate(props.app, model);

            toast.success(<CustomToast message={`Updated ${model.productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

            props.refreshProducts();
            closeModal();
        }
        finally {
            setFormSaving(false);
        }
    }

    const formik = useFormik({
        initialValues: {
            templateMappingDefinitionId: ""
        },
        onSubmit: async (values) => {
            await applyListingTemplate();
        },
        validate
    });

    return (

        <Modal
            appElement={document.body}
            isOpen
            onRequestClose={closeModal}
            shouldCloseOnOverlayClick
            className="custom-modal"
            overlayClassName="custom-modal-overlay">

            <Icon onClick={closeModal} className="close-icon" prefix="fa" name="times" />

            <h2 className="category-header" style={{ textAlign: "center" }}>Listing Template</h2>
            <hr className="divider"></hr>

            <div className="set-listing-template-modal-content">
                <Form autocomplete="off" onSubmit={formik.handleSubmit}>
                    <Form.Group label="Listing Template">

                        <CustomSelect
                            value={listingTemplateOptions ? listingTemplateOptions.find(option => /* eslint eqeqeq: 0 */ option.value == formik.values.templateMappingDefinitionId) : ""}
                            isDisabled={formSaving}
                            options={listingTemplateOptions}
                            onChange={(option) => formik.setFieldValue("templateMappingDefinitionId", (option && option.value) || "")}
                        />

                    </Form.Group>

                    <div className="set-listing-template-footer">
                        <div>
                            <Button type="button" onClick={closeModal} disabled={formSaving}>Cancel</Button>
                            <Button type="submit" onClick={formik.handleSubmit} loading={formSaving} color="success">Save</Button>
                        </div>
                    </div>
                </Form>

            </div>

        </Modal>
    )
}

export { SetListingTemplateModal };