import * as apiCall from '../apiCall';

function getTemplateTypes(appId) {

    let options = {
        appId
    };
    
    return apiCall.get(`/api/template/get_template_types`, options);
}

export { getTemplateTypes };