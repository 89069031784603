import * as apiCall from '../apiCall';

function downloadFeed(appId, id, type) {

    const options = {
        appId
    };

    let url = `/api/amazon/download_feed/${id}/${type}`;
    return apiCall.get(url, options);
}

export { downloadFeed };