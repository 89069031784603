import reduxStore from '../reduxStore';
import {
    preAuthPages,
    storageKeys
} from '../constants';
import history from '../history';
import {
    saveToken
} from '../actions/user';
import store from 'store';

const queryString = require('query-string');
const USE_LOCAL_STORAGE = process.env.NODE_ENV === "development";

const getAuthToken = () => {

    if (USE_LOCAL_STORAGE) {
        return store.get(storageKeys.authToken);
    }
    else {
        return reduxStore.getState().user.token;
    }
}

const setAuthToken = (token) => {

    if (USE_LOCAL_STORAGE) {
        store.set(storageKeys.authToken, token);
    }
    else {
        reduxStore.dispatch(saveToken(token));
    }
}

const logout = (redirectAfterLogin) => {

    reduxStore.dispatch(saveToken(null));

    if (redirectAfterLogin === true && window.location.search.indexOf("redirectUri") === -1 && preAuthPages.indexOf(window.location.pathname) === -1) {
        let encodedPart = encodeURIComponent(`${window.location.pathname + window.location.search}`);
        history.push({ pathname: `/account/login`, search: `?redirectUri=${encodedPart}` });
    }
    else if (window.location.pathname !== "/account/login") {
        history.push("/account/login");
    }
}

const openPopupWithPoller = async (getUrlPromise, onSuccess, onWindowClosed) => {

    let newWindow = window.open("", '_blank', 'location=yes,height=600,width=600,toolbar=no,menubar=no,resizable=yes,scrollbars=yes,status=no');

    newWindow.document.write("Loading...");

    let url = await getUrlPromise();

    newWindow.location.href = url;

    let successCalled = false;

    var pollTimer = window.setInterval(async function () {
        if (newWindow.closed !== false) { // !== is required for compatibility with Opera
            window.clearInterval(pollTimer);

            if (!successCalled) {
                onSuccess();
            }

            onWindowClosed && onWindowClosed();
        }

        try {
            if (newWindow.document && newWindow.document.domain === document.domain) {

                const parsed = queryString.parse((newWindow.location && newWindow.location.search));

                if (parsed.success) {
                    onSuccess();
                    successCalled = true;
                    window.clearInterval(pollTimer);
                }
            }
        }
        catch (e) {
            // cross origin exception... do nothing - keep polling
        }


    }, 500);

    return pollTimer;

}

export {
    logout,
    openPopupWithPoller,
    getAuthToken,
    setAuthToken
};