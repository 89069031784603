import { connect } from 'react-redux';
import React from 'react';
import {
  Page,
  Grid,
  Card,
  Dropdown,
  Form,
  Button
} from "tabler-react";
import SiteWrapper from '../SiteWrapper.react';
import { ProductErrorModal } from '../ProductErrorModal';
import {
  productErrorMessageType,
  syncStatus
} from '../../constants';
import { AgGridReact } from "ag-grid-react";
import { CustomLoadingOverlay } from '../CustomLoadingOverlay';
import { SetListingTemplateModal } from './SetListingTemplateModal';
import { toast } from 'react-toastify';
import { CustomToast } from '../../components';
import { syncStatusRenderer } from '../CellRenderers/syncStatusRenderer';
import { customConfirmAlert } from '../CustomConfirmAlert';
import * as mobileUtility from '../../utilities/mobileUtility';
import * as fileUtility from '../../utilities/fileUtility';
import { SettingsMenu } from '../SettingsMenu';
import * as marketplaceCtrl from '../../services/marketplace';
import { NoProductsAlert } from '../NoProductsAlert';
import { ProductDetail } from './ProductDetail';
import { SetShippingPolicyModal } from './SetShippingPolicyModal';
import './Products.scss';

const queryString = require('query-string');

class Products extends React.Component {

  constructor(props) {
    super(props);

    window.addEventListener("resize", this.handleGridSize);

    this.state = {
      productsVm: {},
      marketplaceUrl: "",
      loading: false,
      errorModalSettings: { show: false, category: productErrorMessageType.error, productTitle: null, errors: [] },
      setListingTemplateModalSettings: { show: false, products: [] },
      setShippingPolicyModalSettings: { show: false, products: [] },
      gridHeight: 0,
      showProductDetail: false,
      productId: null
    }

    this.app = this.props.user.info.apps.find(a => a.id === this.props.app);

    this.columns = this.getColumns();
    this.multiTemplateColumnsApplied = false;

    this.defaultColDef = {
      headerCheckboxSelection: this.isFirstColumn,
      checkboxSelection: this.isFirstColumn,
      resizable: true,
      sortable: true,
      filter: true,
      menuTabs: ['filterMenuTab'],
      filterParams: {
        clearButton: true
      }
    };

    this.frameworkComponents = {
      customLoadingOverlay: CustomLoadingOverlay
    }
  }

  componentDidMount() {
    this.handleGridSize();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleGridSize);
  }

  productDetailRenderer = (params) => {
    let elem = document.createElement("div");

    elem.innerHTML = params.data.name;
    elem.classList.add("anchor");

    elem.addEventListener("click", (e) => {
      e.stopPropagation();

      this.showProductDetail(params.data.id);

    });

    return elem;

  }

  activeListingRenderer = (params) => {

    let elem = params.value ? document.createElement("a") : document.createElement("span");
    elem.classList.add("status-icon");

    if (params.value === "true" || params.value === true) {
      elem.classList.add("bg-success");
      elem.title = "Active";
    }
    else {
      elem.classList.add("bg-danger");
      elem.title = "Inactive";
    }

    return elem;
  }

  getColumns = () => {

    let columns = [];

    columns.push({
      field: 'name',
      colId: "name",
      headerName: 'Name',
      tooltipField: "name",
      width: 600,
      sortable: true,
      cellRenderer: this.productDetailRenderer,
      filterParams: {
        cellRenderer: this.productDetailRenderer,
        suppressMiniFilter: true
      },
      filter: "agTextColumnFilter",
    });


    columns.push({
      field: "polySku",
      colId: "polySku",
      headerName: "Poly SKU",
      sortable: false,
      filterParams: {
        suppressMiniFilter: true
      }
    });

    columns.push({
      field: "sku",
      colId: "sku",
      hide: true,
      headerName: "Shop SKU",
      sortable: false,
      filterParams: {
        suppressMiniFilter: true
      }
    });

    columns.push({
      field: "errorCount",
      colId: "errorCount",
      headerName: "Errors",
      sortable: true,
      width: 125,
      filter: "agNumberColumnFilter",
      valueGetter: function (params) {
        return params.data.errorCount
      },
      cellRenderer: (params) => {

        let eDiv = document.createElement("div");

        if (params.data.errorCount === 0) {
          eDiv.innerHTML = "0";
          return eDiv;
        }

        eDiv.innerHTML = params.data.errorCount;
        eDiv.classList.add("product-error");

        eDiv.addEventListener("click", (e) => {
          e.stopPropagation();
          params.colDef.cellRendererParams.showErrorModal(productErrorMessageType.error, params.data)
        });

        return eDiv;
      },
      cellRendererParams: {
        showErrorModal: this.showErrorModal
      }
    });

    columns.push({
      width: 125,
      field: 'stock',
      colId: "stock",
      headerName: 'Stock',
      sortable: true,
      filter: "agNumberColumnFilter"
    });

    columns.push({
      width: 165,
      field: 'active',
      colId: "active",
      headerName: 'Active',
      valueGetter: function (params) {
        return params.data.active
      },
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        cellRenderer: this.activeListingRenderer,
        suppressMiniFilter: true
      },
      cellRenderer: this.activeListingRenderer
    });

    if (this.state.productsVm.shippingPolicyOptions && this.state.productsVm.shippingPolicyOptions.length > 0) {
      columns.push({
        width: 180,
        field: 'shippingPolicy',
        tooltipField: "shippingPolicy",
        headerName: 'Shipping Policy',
        filter: "agSetColumnFilter",
        colId: "shippingPolicy",
        sortable: true,
        filterParams: {
          suppressMiniFilter: true
        }
      });
    }

    columns.push({
      field: 'template',
      colId: "template",
      headerName: 'Template',
      tooltipField: "template",
      width: 170,
      sortable: true,
      filterParams: {
        suppressMiniFilter: true
      },
      filter: "agTextColumnFilter",
    });

    columns.push({
      width: 150,
      field: "syncStatus",
      colId: "syncStatus",
      tooltipField: "syncStatus",
      headerName: "Sync Status",
      sortable: true,
      filter: "agSetColumnFilter",
      filterParams: {
        cellRenderer: syncStatusRenderer,
        suppressMiniFilter: true
      },
      cellRenderer: syncStatusRenderer
    });

    columns.push({
      width: 120,
      field: "category",
      colId: "category",
      headerName: "Category",
      filter: "agSetColumnFilter",
      sortable: true,
      filterParams: {
        suppressMiniFilter: true
      }
    });

    columns.push({
      width: 120,
      field: "vendor",
      colId: "vendor",
      headerName: "Vendor",
      filter: "agSetColumnFilter",
      sortable: true,
      filterParams: {
        suppressMiniFilter: true
      }
    });

    columns.push({
      width: 120,
      field: "weight",
      colId: "weight",
      headerName: "Weight",
      filter: "agSetColumnFilter",
      sortable: true,
      filterParams: {
        suppressMiniFilter: true
      }
    });

    return columns;
  }

  handleGridSize = () => {

    // number of pixels to offset vertically
    let DesktopVerticalOffset = 320;

    // currently the navbar and footer are the same height accross all widths (e.g. desktop first design). 
    // This method will need modification if these components change.

    if (window.innerWidth < 768) {
      DesktopVerticalOffset -= 115; // footer
    }

    let gridHeight = window.innerHeight - DesktopVerticalOffset;
    this.setState({ gridHeight });
  }

  showSetListingTemplateModal = (templateId) => {
    let products = this.gridApi.getSelectedRows();

    if (!this.validateBulkAction(products)) {
      return;
    }

    this.setState({ setListingTemplateModalSettings: { show: true, products, templateId, listingTemplates: this.state.productsVm.listingTemplates.filter(lt => templateId === undefined || lt.templateId === templateId) } });
  }

  validateBulkAction = (products) => {

    if (products.length === 0) {
      toast.info(<CustomToast message={`No products have been selected.`} />, { position: toast.POSITION.TOP_RIGHT });
      return false;
    }

    return true;
  }

  showErrorModal = (category, product) => {

    this.setState({
      errorModalSettings: {
        show: true,
        category,
        product,
        productTitle: product.name
      }
    });
  }

  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.refreshProducts();
  };

  refreshProducts = async () => {
    this.gridApi.showLoadingOverlay();

    let productsVm = await marketplaceCtrl.getProducts({
      app: this.props.app
    });

  
    if (productsVm.multiTemplateOptions && !this.multiTemplateColumnsApplied) {
      let columnsCopy = this.getColumns();

      let templateColumnIdx = columnsCopy.findIndex(c => c.field === "template");

      if (templateColumnIdx > -1) {

        columnsCopy[templateColumnIdx].headerName = productsVm.multiTemplateOptions.primaryTemplatePrefix + " Template";

        let secondaryTemplateColumnIdx = columnsCopy.findIndex(c => c.field === "secondaryTemplate");

        if (secondaryTemplateColumnIdx < 0) {

          let secondaryTemplateHeaderName = productsVm.multiTemplateOptions.secondaryTemplatePrefix + " Template";
          let secondaryTemplateColumn = {
            field: 'secondaryTemplate',
            colId: "secondaryTemplate",
            headerName: secondaryTemplateHeaderName,
            tooltipField: "secondaryTemplate",
            width: 170,
            sortable: true,
            filterParams: {
              suppressMiniFilter: true
            },
            filter: "agTextColumnFilter",
          };
          columnsCopy.splice(templateColumnIdx + 1, 0, secondaryTemplateColumn);
        }

        this.columns = columnsCopy;
        this.gridApi.setColumnDefs(this.columns);
        this.multiTemplateColumnsApplied = true;
      }
    }

    this.multiTemplateColumnsApplied = true;

    this.gridApi.hideOverlay();

    const parsed = queryString.parse(window.location.search);

    if (parsed.filterByErrors === "true") {
      this.gridApi.setSortModel([
        {
          colId: "errorCount",
          sort: "desc"
        }
      ]);
    }

    if (parsed.filterByActive === "true") {
      this.gridApi.setSortModel([
        {
          colId: "active",
          sort: "desc"
        }
      ]);
    }

    this.setState({ productsVm, marketplaceUrl: productsVm.marketplaceUrl }, () => {

      let filterInstance = null;

      let columnsCopy = [...this.getColumns()];
      this.columns = columnsCopy;
      this.gridApi.setColumnDefs(columnsCopy);

      if (parsed.filterByPendingSync === "true") {
        // Get a reference to the 'name' filter instance
        filterInstance = this.gridApi.getFilterInstance('syncStatus');

        filterInstance.setModel({
          type: 'set',
          values: [syncStatus.syncPending.toString()]
        });

        this.gridApi.onFilterChanged();
      }

      if (parsed.filterByQueued === "true") {
        // Get a reference to the 'name' filter instance
        filterInstance = this.gridApi.getFilterInstance('syncStatus');

        filterInstance.setModel({
          type: 'set',
          values: [syncStatus.queuedListing.toString()]
        });

        this.gridApi.onFilterChanged();
      }
    });
  }

  isFirstColumn = (params) => {
    var displayedColumns = params.columnApi.getAllDisplayedColumns();
    var thisIsFirstColumn = displayedColumns[0] === params.column;
    return thisIsFirstColumn;
  }

  onQuickFilterChanged = (e) => {
    this.gridApi.setQuickFilter(e.target.value);
  }

  forceRetryProducts = async () => {

    let productIds = this.gridApi.getSelectedRows().map(p => p.id);

    if (!this.validateBulkAction(productIds)) {
      return;
    }

    const model = {
      productIds
    };

    await marketplaceCtrl.forceRetry(this.props.app, model);

    toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

    this.refreshProducts();

  }

  updateShippingPolicy = async (productIds, shippingPolicyId) => {
    let model = {
      productIds,
      id: shippingPolicyId
    };

    await marketplaceCtrl.saveShippingPolicyOptions(this.props.app, model);

    toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

    this.refreshProducts();
  }

  getTemplatesSubmenu = (isPreview, templateId) => {

    let subMenu = [];

    if (!isPreview) {
      subMenu.push({
        name: "None",
        icon: `➖`,
        action: () => {

          let productIds = this.gridApi.getSelectedRows().map(p => p.id);

          customConfirmAlert(async () => {

            if (!this.validateBulkAction(productIds)) {
              return;
            }

            const model = {
              productIds,
              templateMappingDefinitionId: null,
              templateId
            };

            await marketplaceCtrl.assignListingTemplate(this.props.app, model);

            toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

            this.refreshProducts();

          }, `Assign template for the ${productIds.length} selected product${productIds.length > 1 ? "s" : ""}?`);
        }
      })
    }

    subMenu = subMenu.concat(this.state.productsVm.listingTemplates.filter(lt => templateId === undefined || lt.templateId === templateId).map(lt => ({
      name: lt.name,
      action: () => {

        let productIds = this.gridApi.getSelectedRows().map(p => p.id);

        const model = {
          productIds,
          templateMappingDefinitionId: lt.templateMappingDefinitionId,
          templateId
        };

        if (isPreview) {
          customConfirmAlert(async () => {
            let previewTemplate = await marketplaceCtrl.previewTemplate(this.props.app, model);
            fileUtility.downloadFile(previewTemplate.fileData, "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,", previewTemplate.fileName);
          }, <React.Fragment>Preview will be generated for {productIds.length} product{`${productIds.length > 1 || productIds.length === 0 ? "s" : ""}`}. An excel spreadsheet will be downloaded.</React.Fragment>, { header: "Confirm your action" });

          return;
        }

        customConfirmAlert(async () => {

          if (!this.validateBulkAction(productIds)) {
            return;
          }

          console.log("Model", model);
          await marketplaceCtrl.assignListingTemplate(this.props.app, model);

          toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });
          this.refreshProducts();

        }, `Assign ${productIds.length} selected products to the template '${lt.name}'`);
      }
    })));

    if (isPreview && subMenu.length === 0) {
      subMenu.push({ name: "No templates to show.", disabled: true, action: () => { } });
    }

    return subMenu;
  }

  getContextMenuItems = () => {

    if (mobileUtility.mobileCheck()) {
      return [];
    }

    let productIds = this.gridApi.getSelectedRows().map(p => p.id);

    if (!productIds.length) {
      return [{
        disabled: true,
        name: "No products have been selected.",
      }];
    }

    let menu = [];

    menu.push({
      name: `${productIds.length} product${productIds.length > 1 ? "s" : ""} selected`,
      disabled: true
    });

    if (this.state.productsVm.shippingPolicyOptions && this.state.productsVm.shippingPolicyOptions.length > 0) {
      menu.push({
        name: "Shipping Policy",
        subMenu: this.state.productsVm.shippingPolicyOptions.map(o => ({
          name: o.name,
          action: () => {
            customConfirmAlert(() => this.updateShippingPolicy(productIds, o.id), <React.Fragment>You are about to update the shipping policy to <strong>{o.name}</strong> for {productIds.length} product{productIds.length > 1 ? "s" : ""}.</React.Fragment>);
          }
        }))
      });
    }

    if (this.state.productsVm.multiTemplateOptions) {


      console.log("Options", this.state.productsVm.multiTemplateOptions);

      menu.push({
        name: this.state.productsVm.multiTemplateOptions.primaryTemplatePrefix + " Template",
        subMenu: this.getTemplatesSubmenu(false, this.state.productsVm.multiTemplateOptions.primaryTemplateId)
      });

      menu.push({
        name: this.state.productsVm.multiTemplateOptions.secondaryTemplatePrefix + " Template",
        subMenu: this.getTemplatesSubmenu(false, this.state.productsVm.multiTemplateOptions.secondaryTemplateId)
      });
    }
    else {
      menu.push({
        name: "Template",
        subMenu: this.getTemplatesSubmenu(false)
      });
    }

    menu.push({
      name: "Preview",
      subMenu: this.getTemplatesSubmenu(true)
    });

    menu.push({
      name: "Force Sync",
      action: async () => {

        let model = {
          productIds
        }

        await marketplaceCtrl.forceRetry(this.props.app, model);

        toast.success(<CustomToast message={`Updated ${productIds.length} product(s).`} />, { position: toast.POSITION.TOP_RIGHT });

        this.refreshProducts();
      }
    });

    return menu;
  }

  showSetShippingPolicyModal = () => {

    let products = this.gridApi.getSelectedRows();

    if (!this.validateBulkAction(products)) {
      return;
    }

    this.setState({ setShippingPolicyModalSettings: { show: true, products, appId: this.props.app } });
  }

  getActionDropDownMenu = () => {
    let actionMenuItems = [];

    if (this.state.productsVm.shippingPolicyOptions && this.state.productsVm.shippingPolicyOptions.length > 0) {
      actionMenuItems.push(                                 {
        value: "Shipping Policy",
        onClick: () => this.showSetShippingPolicyModal()
      });
    }

    if (this.state.productsVm.multiTemplateOptions) {
      actionMenuItems.push({
        value: `Assign ${this.state.productsVm.multiTemplateOptions.primaryTemplatePrefix} Template`,
        onClick: () => this.showSetListingTemplateModal(this.state.productsVm.multiTemplateOptions.primaryTemplateId)
      });

      actionMenuItems.push({
        value: `Assign ${this.state.productsVm.multiTemplateOptions.secondaryTemplatePrefix} Template`,
        onClick: () => this.showSetListingTemplateModal(this.state.productsVm.multiTemplateOptions.secondaryTemplateId)
      });
    }
    else {
      actionMenuItems.push({
        value: "Assign Template",
        onClick: () => this.showSetListingTemplateModal()
      });
    }

    actionMenuItems.push({
      value: "Force Sync",
      onClick: () => this.forceRetryProducts()
    });

    return actionMenuItems;
  }

  hideProductDetail = () => {
    this.setState({ showProductDetail: false, productId: null });
  }

  showProductDetail = (productId) => {
    this.setState({ showProductDetail: true, productId })
  }

  render() {
    return (
      <div>
        <div style={{ display: this.state.showProductDetail ? "block" : "none" }}>
          <ProductDetail app={this.app.id} getProductDetail={marketplaceCtrl.getProductDetail} hideProductDetail={this.hideProductDetail} productId={this.state.productId} />
        </div>
        <div style={{ display: !this.state.showProductDetail ? "block" : "none" }}>
          <SiteWrapper key={this.props.match.path}>
            <Page.Content className="products">
              <Grid.Row cards>
                <div className="app-settings-wrapper">
                  <SettingsMenu app={this.props.app} />

                  <div className="app-settings-content">
                    {Array.isArray(this.state.productsVm.products) && this.state.productsVm.products.length === 0 ? <NoProductsAlert /> : null}
                    <Card>
                      <div style={{ width: "100%", height: "100%", }}>
                        <div style={{ marginBottom: "5px" }}>
                          <div className="products-header">
                            <div className="search-bar">
                              <Form.Input className="search-input" type="text" onChange={this.onQuickFilterChanged} id="quickFilter" placeholder="Search..." />
                            </div>

                            <div className="action-menu">
                              <Dropdown
                                type="button"
                                className="action-dropdown"
                                toggle={false}
                                color="primary"
                                triggerContent="Actions"
                                itemsObject={this.getActionDropDownMenu()}
                              />
                              <Button onClick={() => { this.refreshProducts() }} className="refresh-btn" color="primary">
                                <i className="fa fa-redo" />
                              </Button>
                            </div>

                          </div>
                        </div>

                        <div
                          id="productsGrid"
                          style={{
                            minHeight: 300,
                            height: this.state.gridHeight
                          }}
                          className="ag-theme-alpine">
                          <AgGridReact
                            columnDefs={this.columns}
                            animateRows={false}
                            enableBrowserTooltips
                            enableCellTextSelection
                            defaultColDef={this.defaultColDef}
                            rowSelection="multiple"
                            onGridReady={this.onGridReady}
                            loadingOverlayComponent="customLoadingOverlay"
                            frameworkComponents={this.frameworkComponents}
                            rowData={(this.state.productsVm && this.state.productsVm.products) || []}
                            multiSortKey="ctrl"
                            getContextMenuItems={this.getContextMenuItems}
                            rowMultiSelectWithClick
                          />
                        </div>
                      </div>
                    </Card>

                  </div>
                </div>
              </Grid.Row>

              {
                this.state.setShippingPolicyModalSettings.show &&
                <SetShippingPolicyModal
                  shippingPolicyOptions={this.state.productsVm.shippingPolicyOptions}
                  refreshProducts={this.refreshProducts}
                  {...this.state.setShippingPolicyModalSettings}
                  hideModal={() => this.setState({ setShippingPolicyModalSettings: { show: false, products: [] } })} />
              }

              {
                this.state.errorModalSettings.show &&
                <ProductErrorModal refreshProducts={this.refreshProducts} app={this.props.app} {...this.state.errorModalSettings} hideModal={() => this.setState({ errorModalSettings: { show: false, category: productErrorMessageType.error, productTitle: null } })} />
              }

              {
                this.state.setListingTemplateModalSettings.show &&
                <SetListingTemplateModal app={this.props.app} refreshProducts={this.refreshProducts} setListingTemplateModalSettings={this.state.setListingTemplateModalSettings} {...this.state.setListingTemplateModalSettings} hideModal={() => this.setState({ setListingTemplateModalSettings: { show: false, products: [], listingTemplates: [], templateId: undefined } })} />
              }

            </Page.Content>
          </SiteWrapper>
        </div>
      </div>


    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state;
  return { user };
}

let wrapper = connect(mapStateToProps)(Products);
export { wrapper as Products };