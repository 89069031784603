import * as apiCall from '../apiCall';

function saveManualItemSpecifics(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/ebay/save_manual_item_specifics`, model, options);
}

export { saveManualItemSpecifics };