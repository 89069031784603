import React from 'react';

const CustomToast = ({ id, message }) => {
    return (
        <div style={{ paddingTop: 20, paddingBottom: 20, fontSize: "1.1em" }}>
            {message}
        </div>
    );
}

export { CustomToast };