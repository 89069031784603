import * as apiCall from '../apiCall';

function createTemplate(appId, model) {

    const options = {
        appId
    };

    return apiCall.post(`/api/marketplace/create_template`, model, options);
}

export { createTemplate };