import * as apiCall from '../apiCall';

function getFeedHistory(appId, timezone, pagination) {

    const options = {
        appId
    };

    let sort = null;

    if (pagination.sortModel && pagination.sortModel.length > 0) {
        sort = pagination.sortModel;
    }

    let url = `/api/amazon/get_feed_history?timezone=${timezone}&startRow=${pagination.startRow || 0}&endRow=${pagination.endRow || 100}`;

    if (sort !== null) {
        url += `&sortModel=${JSON.stringify(sort)}`;
    }
    
    return apiCall.get(url, options);
}

export { getFeedHistory };