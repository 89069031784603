import * as apiCall from '../apiCall';

function getTemplates(appId) {
    const options = {
        appId
    };

    return apiCall.get(`/api/marketplace/get_templates`, options);
}

export { getTemplates };